import React, { useEffect, useRef, useState } from "react";
import { Stage, Image as KonvaImage } from "react-konva";
import Konva from "konva";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import useImage from "use-image";
import { dimensions, dimensionsKonva, doRequest } from "../utils/helper";
import { changeDpiDataUrl } from "changedpi";
import JSZip from "jszip";
import Button from "react-bootstrap/Button";
import download from "../assets/img/file_download_black_24dp.svg";
import { config } from "../utils/config";
import TryBeforeYouBuy from "./TryBeforeYouBuy";
import Loader from "../Components/Loader";
import $ from "jquery";
import PercentLoader from "./PercentLoader";
import { Form } from "reactstrap";
import Compressor from "compressorjs";
import ImagesRemaining from "../Components/ImagesRemaining";
import LimitExceedPopup from "../Components/LimitExceedPopup";
import ExportTopDownloadAll from "../component/ExportTopDownloadAll";

export default function ExportNew() {
  let userVariantDetails =
    localStorage.getItem(config.VARIANT_COUNT) &&
    localStorage.getItem(config.VARIANT_COUNT) != "undefined"
      ? JSON.parse(localStorage.getItem(config.VARIANT_COUNT))
      : null;
  const disableBtn = localStorage.getItem("payasuusedisablebtn");
  const planDetails = localStorage.getItem(config.USER_PLAN_DETAILS)
    ? JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
    : null;
  const variationCounts = planDetails?.variant_count;
  var tshirtchangedetails = JSON.parse(
    localStorage.getItem("tshirtchangedetails")
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();

  var selectedImage = state?.selectedImage;
  const [tshirtdetails, setTshirtDetails] = useState(
    JSON.parse(localStorage.getItem("tshirtDetails"))
  );
  const [downloadedIndex, setDownloadedIndex] = useState([]);
  const [planIsPayAsUse, setPlanIsPayAsUse] = useState(
    planDetails && planDetails?.plan_id == "2" ? true : false
  );
  const [disableAllButton, setDisableAllButton] = useState(
    planDetails?.plan_id == "2" && (disableBtn || variationCounts > 0)
      ? true
      : false
  );
  const [firstImageDownloaded, setFirstImageDownloaded] = useState(
    planDetails?.plan_id == "2" && (disableBtn || variationCounts > 0)
      ? true
      : false
  );
  const [processing, setProcessing] = useState(false);
  const [percentDownloaded, setpercentDownloaded] = useState(0);

  const [exportImageType, setExportImageType] = useState("jpg");
  const [exportImageColor, setExportImageColor] = useState("rgb");
  const [exportImageDpi, setExportImageDpi] = useState("300");
  const [cancelDownload, setCancelDownload] = useState(false);
  const [plan, setPlan] = useState({});
  const [showLimitReachedPopup, setShowLimitReachesPopup] = useState(false);
  const [imagesCanDownload, setImagesCanDownload] = useState(0);
  const [loading, setLoading] = useState(false);

  const userPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS);

  const handleRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (userPlanDetails) {
      setPlan(JSON.parse(userPlanDetails));
    }
  }, [userPlanDetails]);

  var inputSearch = useRef();
  const ImgDivRef = useRef([]);
  const downladImage = useRef([]);
  const canvasRef = useRef([]);
  var ResizeImagediv = useRef();
  var stagediv = useRef();
  const cancelDownloadRef = useRef(false);
  const abortControllerRef = useRef(null);

  const [bgimage] = useImage(selectedImage);

  useEffect(() => {
    // When the component mounts, reset the cancelDownload ref and create an AbortController
    cancelDownloadRef.current = false;
    abortControllerRef.current = new AbortController();

    // When the component unmounts or the URL changes, abort the controller
    return () => {
      cancelDownloadRef.current = true;
      abortControllerRef.current?.abort();
    };
  }, [location]);

  const drawResizeStage = (indexSr, index, width, height, size) => {
    setProcessing(true);
    // console.log(index, width, height);
    var playernamedetails = JSON.parse(
      localStorage.getItem("playernamedetails")
    );
    var playernumberdetails = JSON.parse(
      localStorage.getItem("playernumberdetails")
    );
    var changedData = [];
    if (tshirtchangedetails != null) {
      changedData = tshirtchangedetails.filter(
        (item) => item.indexSr == indexSr
      );
      console.log(changedData);
      if (changedData.length != 0) {
        playernamedetails = changedData[0].playernamedetails;
        playernumberdetails = changedData[0].playernumberdetails;

        console.log(playernamedetails, playernumberdetails);
      }
    }
    var convertedwidth = width;
    var convertedheight = height;
    console.log(
      "(playernumberdetails.textSizePer * convertedwidth) / 100",
      (playernumberdetails.textSizePer * convertedwidth) / 100
    );
    stagediv.current.attrs.width = convertedwidth;
    stagediv.current.attrs.height = convertedheight;
    // console.log(stagediv);
    var stage = stagediv.current;
    var layer = new Konva.Layer();
    var image = new Konva.Image({
      x: 0,
      y: 0,
      image: bgimage,
      // width: convertedwidth,
      // height: convertedheight,
      width: dimensionsKonva[size]?.width,
      height: dimensionsKonva[size]?.height,
    });
    layer.add(image);

    var text1 = new Konva.Text({
      x: (playernamedetails.NametextPositionPer.x * convertedwidth) / 100,
      y: (playernamedetails.NametextPositionPer.y * convertedheight) / 100,
      text: tshirtdetails[index].name,
      fontSize: (playernamedetails.NametextSizePer * convertedwidth) / 100,
      fontFamily: playernamedetails.NamefontFamily,
      fill: playernamedetails.NametextColor,
      stroke: playernamedetails.NameoutlineColor,
      strokeWidth: (playernamedetails.NametextBorderPer * convertedwidth) / 100,
      align: "center",
      width: (playernamedetails.NameWidthPer * convertedwidth) / 100,
      rotation: playernamedetails.NamerotationAngle,
      scaleX: playernamedetails.NameScale.x,
      scaleY: playernamedetails.NameScale.y,
    });

    layer.add(text1);
    var text2 = new Konva.Text({
      x: (playernumberdetails.textPositionPer.x * convertedwidth) / 100,
      y: (playernumberdetails.textPositionPer.y * convertedheight) / 100,
      text: tshirtdetails[index].number,
      fontSize: (playernumberdetails.textSizePer * convertedwidth) / 100,
      fontFamily: playernumberdetails.fontFamily,
      fill: playernumberdetails.textColor,
      stroke: playernumberdetails.outlineColor,
      strokeWidth: (playernumberdetails.NotextBorderPer * convertedwidth) / 100,
      align: "center",
      width: (playernumberdetails.NoWidthPer * convertedwidth) / 100,
      rotation: playernumberdetails.rotationAngles,
      scaleX: playernumberdetails.NoScale.x,
      scaleY: playernumberdetails.NoScale.y,
    });
    layer.add(text2);
    var tshirtSizeWatermark = new Konva.Text({
      x: 0,
      y: 0,
      text: size,
      fontSize:
        (playernumberdetails.textSizePer * dimensionsKonva[size]?.width) /
        100 /
        3 /
        2 /
        3,
      // fontFamily: playernumberdetails.fontFamily,
      fontFamily: "IBM Plex Sans",
      fill: "black",
      stroke: playernumberdetails.outlineColor,
      // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
      // align: 'center',
      // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
      // rotation: playernumberdetails.rotationAngles,
      // scaleX: playernumberdetails.NoScale.x,
      // scaleY: playernumberdetails.NoScale.y
    });
    var textWidth = tshirtSizeWatermark.textWidth;
    var textHeight = tshirtSizeWatermark.textHeight;
    var backgroundRect = new Konva.Rect({
      x: tshirtSizeWatermark.x(),
      y: tshirtSizeWatermark.y(),
      width: textWidth,
      height: textHeight,
      fill: "white", // Set background color to white
    });

    layer.add(backgroundRect);
    layer.add(tshirtSizeWatermark);
    const centerX = stage.width() / 2;
    const markLength = 20; // Length of the horizontal mark
    const markThickness = 300; // Thickness of the mark

    // Background lines
    const topMarkBackground = new Konva.Line({
      points: [centerX - markLength / 2, 10, centerX + markLength / 2, 10], // Horizontal line at the top center
      stroke: "white", // Background color of the mark
      strokeWidth: markThickness, // Customize the width of the mark
    });

    const bottomMarkBackground = new Konva.Line({
      points: [
        centerX - markLength / 2,
        stage.height() - 10,
        centerX + markLength / 2,
        stage.height() - 10,
      ], // Horizontal line at the bottom center
      stroke: "white", // Background color of the mark
      strokeWidth: markThickness, // Customize the width of the mark
    });

    // Foreground lines
    const topMarkForeground = new Konva.Line({
      points: [centerX - markLength / 2, 10, centerX + markLength / 2, 10], // Horizontal line at the top center
      stroke: "black", // Foreground color of the mark
      strokeWidth: markThickness / 2, // Customize the width of the mark
    });

    const bottomMarkForeground = new Konva.Line({
      points: [
        centerX - markLength / 2,
        stage.height() - 10,
        centerX + markLength / 2,
        stage.height() - 10,
      ], // Horizontal line at the bottom center
      stroke: "black", // Foreground color of the mark
      strokeWidth: markThickness / 2, // Customize the width of the mark
    });

    layer.add(topMarkBackground);
    layer.add(bottomMarkBackground);
    layer.add(topMarkForeground);
    layer.add(bottomMarkForeground);
    stage.add(layer);
    return stagediv.current;
  };
  const resizeAndConvertImage = async (
    dataURL,
    maxWidth,
    maxHeight,
    fileName,
    dpi
  ) => {
    setProcessing(true);
    return new Promise((resolve, reject) => {
      // Create a new image element
      const img = new Image();

      // Set the image source to the data URL
      img.src = dataURL;

      // When the image is fully loaded
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate the new dimensions while preserving aspect ratio
        let newWidth = img.width;
        let newHeight = img.height;

        if (newWidth > maxWidth) {
          newHeight *= maxWidth / newWidth;
          newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
          newWidth *= maxHeight / newHeight;
          newHeight = maxHeight;
        }

        // Set the canvas dimensions
        console.log("newwith", newWidth, " and newheight", newHeight);
        if (newWidth > 0 && newHeight > 0) {
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
        } else {
          alert("image can not be created");
          return;
        }
        // Draw the image onto the canvas with the new dimensions

        // Convert canvas content to a data URL with the specified DPI
        const resizedDataURL = canvas.toDataURL("image/jpeg", dpi);
        var imageWith300DPi = changeDpiDataUrl(resizedDataURL, 300);

        // Return the resized and converted data URL
        resolve(imageWith300DPi);
      };

      // If there's an error loading the image
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  // const downloadImage = (dataURL, fileName) => {
  //   // Create an anchor element
  //   const link = document.createElement("a");
  //   // Set the href attribute to the data URL
  //   link.href = dataURL;
  //   // Set the download attribute to the file name
  //   link.download = fileName;
  //   // Simulate a click on the anchor element to trigger download
  //   link.click();
  // };

  const handleImageCount = () => {
    console.log("handleimagecount inside");
    let userPlanDetails = planDetails;
    const userVariantDetails =
      localStorage.getItem(config.VARIANT_COUNT) &&
      localStorage.getItem(config.VARIANT_COUNT) != "undefined"
        ? JSON.parse(localStorage.getItem(config.VARIANT_COUNT))
        : null;

    let planId = userPlanDetails?.plan_id;

    let totalImagesCount = 0;
    // if (planId === "1") {
    //   console.log('handleimagecount inside plan is 1')

    //   let variantionDownloaded = userPlanDetails.variant_count;
    //   totalImagesCount = Math.max(0, 20 - variantionDownloaded);
    // } else if (planId == "5") {
    //   let totalVariantion = userPlanDetails.total_variant;
    //   let variantionDownloaded = userPlanDetails.variant_count;
    //   totalImagesCount = Math.min(totalVariantion, tshirtdetails.length);
    //   console.log('handleimagecount inside Math.min(totalVariantion, tshirtdetails.length) is', Math.min(totalVariantion, tshirtdetails.length))

    // }
    if ((planId == "1" || planId == "5") && userVariantDetails) {
      // totalImagesCount = userVariantDetails?.remaning;
      totalImagesCount = Math.min(
        userVariantDetails?.remaning,
        tshirtdetails.length
      );
    } else {
      totalImagesCount = tshirtdetails.length;
    }
    console.log("handleimagecount inside totalimagecount is", totalImagesCount);

    if (totalImagesCount < tshirtdetails?.length) {
      setImagesCanDownload(totalImagesCount);
      setShowLimitReachesPopup(true);
      return null;
    } else {
      setShowLimitReachesPopup(false);
      return totalImagesCount;
    }
  };

  const updateVariationCount = async (count) => {
    try {
      let obj = {
        variation_count: count,
      };
      window.gtag("event", "Variation Downloaded", obj);
    } catch (error) {}
    const planDetails =
      localStorage.getItem(config.USER_PLAN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
    if (!planDetails || !planDetails.plan_id) {
      alert("please choose a plan first");
      navigate("/plans");
      return;
    }
    let user = JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));
    let data = {
      id: user?.user_plan_id, ///user_plan_id
      count: count ? count.toString() : "1",
      user_id: user?.id,
    };
    try {
      setLoading(true);
      let response = await doRequest(data, "POST", "plan/add-variant ");
      if (+response?.resultFlag === 1) {
        localStorage.removeItem(config.USER_PLAN_DETAILS);
        let plan = response?.response?.userPlan;
        let variants = response?.response?.variants;
        localStorage.setItem(config.USER_PLAN_DETAILS, JSON.stringify(plan));
        localStorage.setItem(config.VARIANT_COUNT, JSON.stringify(variants));
      } else {
        console.error(response?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error.message ? error?.message : "Something went wrong");
    }
  };

  // below is the origin handle download function that is commented for some time only to test a new func
  // const handleDownload = async (
  //   canvas,
  //   fileName,
  //   size,
  //   indexSr,
  //   index,
  //   file_type
  // ) => {
  //   const planDetails =
  //     localStorage.getItem(config.USER_PLAN_DETAILS) &&
  //     JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
  //   if (planDetails && planDetails?.plan_id == "1") {
  //     let vCount = planDetails?.variant_count;
  //     const variantLeft = 20 - vCount;
  //     if (variantLeft <= 0) {
  //       alert("You free trial is expired, please upgrade your plan");
  //       navigate("/plans");
  //       return;
  //     }
  //   }

  //   $(".loader_bg").show();
  //   setProcessing(true);
  //   if (planIsPayAsUse) {
  //     localStorage.setItem("payasuusedisablebtn", "true");
  //     setFirstImageDownloaded(true);
  //     setDisableAllButton(true);
  //   }
  //   if (!size) {
  //     alert("please select atleast size of tshirt");
  //     return;
  //   }
  //   const tshirtSize = size;

  //   // console.log(
  //   //   "size of image is ",
  //   //   tshirtSize,
  //   //   "width",
  //   //   dimensions[tshirtSize]?.width
  //   // );
  //   setProcessing(true);
  //   $(".loader_bg").show();
  //   let stageRsize = await drawResizeStage(
  //     indexSr,
  //     index,
  //     dimensions[tshirtSize]?.width,
  //     dimensions[tshirtSize]?.height,
  //     tshirtSize
  //   );
  //   let stageDataURL = await stageRsize.toDataURL();
  //   let imageWith300DPi = changeDpiDataUrl(stageDataURL, 300);
  //   downloadImage(imageWith300DPi, fileName);
  //   stagediv.current.children = [];
  //   setProcessing(false);
  //   $(".loader_bg").hide();

  //   // return;

  //   // resizeAndConvertImage(
  //   //   stageDataURL,
  //   //   dimensions[tshirtSize]?.width,
  //   //   dimensions[tshirtSize]?.height,
  //   //   fileName,
  //   //   "300"
  //   // )
  //   //   .then(async (resizedDataURL) => {
  //   //     // Do something with the resized and converted data URL
  //   //     console.log("Resized and converted data URL:", resizedDataURL);
  //   //     // For example, download the image
  //   //     downloadImage(resizedDataURL, fileName);
  //   //     await updateVariationCount();
  //   //     stagediv.current.children = [];
  //   //     if (file_type != 'all') {
  //   //       setProcessing(false);
  //   //       $(".loader_bg").hide();
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     setProcessing(false);
  //   //     $(".loader_bg").hide();
  //   //     // Handle errors
  //   //     console.error("Error:", error);
  //   //   });
  // };

  const handelInputsearch = () => {
    if (inputSearch.current.value != "") {
      var excelData = JSON.parse(localStorage.getItem("tshirtDetails"));
      var searchvalue = inputSearch.current.value;
      var filterData = excelData.filter(
        (item, index) =>
          item.name.toLowerCase().includes(searchvalue.toLowerCase()) ||
          item.number.toString().includes(inputSearch.current.value)
      );
      for (let val of excelData) {
        if (filterData.length > 0) {
          var searchdata = filterData.filter(
            (item) => item.indexSr == val.indexSr
          );
          if (searchdata.length == 0) {
            ImgDivRef.current[val.indexSr].className =
              "col-xl-3 col-3 hideimage";
          } else {
            ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3";
          }
        } else {
          ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3 hideimage";
        }
      }
    } else {
      var filterData = JSON.parse(localStorage.getItem("tshirtDetails"));
      for (let val of filterData) {
        ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3";
      }
    }
  };

  const drawTextOnCanvas = (text, number, canvas, index, size) => {
    if (canvas) {
      var playernamedetails = JSON.parse(
        localStorage.getItem("playernamedetails")
      );
      var playernumberdetails = JSON.parse(
        localStorage.getItem("playernumberdetails")
      );

      var changedData = [];

      if (tshirtchangedetails != null) {
        changedData = tshirtchangedetails.filter(
          (item) => item.indexSr == index
        );

        if (changedData.length != 0) {
          playernamedetails = changedData[0].playernamedetails;
          playernumberdetails = changedData[0].playernumberdetails;
        }
      }

      const stage = canvas;
      // console.log(stage);
      //  stage.children = [];
      const layer = new Konva.Layer();
      //  console.log(layer);
      var image = new Konva.Image({
        x: 0,
        y: 0,
        image: bgimage,
        // width: convertedwidth,
        // height: convertedheight,
        width: dimensionsKonva[size]?.width,
        height: dimensionsKonva[size]?.height,
      });
      layer.add(image);

      var text1 = new Konva.Text({
        x: (playernamedetails.NametextPositionPer.x * canvas.attrs.width) / 100,
        y:
          (playernamedetails.NametextPositionPer.y * canvas.attrs.height) / 100,
        text: text,
        fontSize:
          (playernamedetails.NametextSizePer * canvas.attrs.width) / 100,
        fontFamily: playernamedetails.NamefontFamily,
        fill: playernamedetails.NametextColor,
        stroke: playernamedetails.NameoutlineColor,
        strokeWidth:
          (playernamedetails.NametextBorderPer * canvas.attrs.width) / 100,
        align: "center",
        width: (playernamedetails.NameWidthPer * canvas.attrs.width) / 100,
        rotation: playernamedetails.NamerotationAngle,
        scaleX: playernamedetails.NameScale.x,
        scaleY: playernamedetails.NameScale.y,
      });

      layer.add(text1);

      var text2 = new Konva.Text({
        x: (playernumberdetails.textPositionPer.x * canvas.attrs.width) / 100,
        y: (playernumberdetails.textPositionPer.y * canvas.attrs.height) / 100,
        text: number,
        fontSize: (playernumberdetails.textSizePer * canvas.attrs.width) / 100,
        fontFamily: playernumberdetails.fontFamily,
        fill: playernumberdetails.textColor,
        stroke: playernumberdetails.outlineColor,
        strokeWidth:
          (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
        align: "center",
        width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
        rotation: playernumberdetails.rotationAngles,
        scaleX: playernumberdetails.NoScale.x,
        scaleY: playernumberdetails.NoScale.y,
      });

      layer.add(text2);
      var tshirtSizeWatermark = new Konva.Text({
        x: 0,
        y: 0,
        text: size,
        fontSize: 20,
        // fontFamily: playernumberdetails.fontFamily,
        fontFamily: "IBM Plex Sans",
        fill: "black",
        stroke: playernumberdetails.outlineColor,
        // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
        // align: 'center',
        // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
        // rotation: playernumberdetails.rotationAngles,
        // scaleX: playernumberdetails.NoScale.x,
        // scaleY: playernumberdetails.NoScale.y
      });
      var textWidth = tshirtSizeWatermark.textWidth;
      var textHeight = tshirtSizeWatermark.textHeight;
      var backgroundRect = new Konva.Rect({
        x: tshirtSizeWatermark.x(),
        y: tshirtSizeWatermark.y(),
        width: textWidth,
        height: textHeight,
        fill: "white", // Set background color to white
      });

      layer.add(backgroundRect);
      layer.add(tshirtSizeWatermark);

      stage.add(layer);
    }
  };

  useEffect(() => {
    if (tshirtdetails) {
      tshirtdetails.forEach((val, index) => {
        drawTextOnCanvas(
          val.name,
          val.number,
          canvasRef.current[val.indexSr],
          val.indexSr,
          val.size
        );
      });
    }
  }, [tshirtdetails]);

  const MAX_CONCURRENT_DOWNLOADS = 1;
  // const handleDownloadAll = async () => {
  //   try {
  //     const zip = new JSZip();
  //     for (let index = 0; index < tshirtdetails.length; index++) {
  //       const val = tshirtdetails[index];
  //       const canvas = canvasRef.current[val.indexSr];
  //       const fileName = `${val.size}${dimensions[val.size]?.size}_${val.name}_${val.number}.png`;

  //       const stageRsize = drawResizeStage(val.indexSr, index, dimensions[val.size].width, dimensions[val.size].height, val.size);
  //       const stageDataURL = stageRsize.toDataURL();

  //       const resizedDataURL = await resizeAndConvertImage(stageDataURL, dimensions[val.size].width, dimensions[val.size].height, fileName, "300");
  //       zip.file(fileName, resizedDataURL.split("base64,")[1], { base64: true });
  //       setDownloadedIndex((prev) => [...prev, index]);

  //       // Add a delay of 3-5 seconds
  //       const delay = Math.floor(Math.random() * 2000) + 3000; // Random delay between 3-5 seconds
  //       await new Promise(resolve => setTimeout(resolve, delay));
  //     }

  //     zip.generateAsync({ type: "blob" }).then((content) => {
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(content);
  //       link.download = "all_images.zip";
  //       link.click();
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  //commented code by dpk on 24-05-2024 to replace this function with a compression code
  // const handleDownloadAll = async () => {
  //   $(".loader_bg").show();

  //   const zip = new JSZip();
  //   const playernamedetails = JSON.parse(
  //     localStorage.getItem("playernamedetails")
  //   );
  //   const playernumberdetails = JSON.parse(
  //     localStorage.getItem("playernumberdetails")
  //   );

  //   const chunkSize = 5;
  //   for (let i = 0; i < tshirtdetails.length; i += chunkSize) {
  //     const chunk = tshirtdetails.slice(i, i + chunkSize);

  //     await Promise.all(
  //       chunk.map(async (item) => {
  //         const stage = new Konva.Stage({
  //           width: dimensions[item.size].width,
  //           height: dimensions[item.size].height,
  //           container: document.createElement("div"),
  //         });

  //         const layer = new Konva.Layer();
  //         const image = new Image();
  //         image.src = selectedImage;

  //         await new Promise((resolve) => {
  //           image.onload = () => {
  //             const bgImage = new Konva.Image({
  //               x: 0,
  //               y: 0,
  //               image: image,
  //               width: dimensionsKonva[item.size].width,
  //               height: dimensionsKonva[item.size].height,
  //             });

  //             layer.add(bgImage);

  //             const text1 = new Konva.Text({
  //               x:
  //                 (playernamedetails.NametextPositionPer.x * stage.width()) /
  //                 100,
  //               y:
  //                 (playernamedetails.NametextPositionPer.y * stage.height()) /
  //                 100,
  //               text: item.name,
  //               fontSize:
  //                 (playernamedetails.NametextSizePer * stage.width()) / 100,
  //               fontFamily: playernamedetails.NamefontFamily,
  //               fill: playernamedetails.NametextColor,
  //               stroke: playernamedetails.NameoutlineColor,
  //               strokeWidth:
  //                 (playernamedetails.NametextBorderPer * stage.width()) / 100,
  //               align: "center",
  //               width: (playernamedetails.NameWidthPer * stage.width()) / 100,
  //               rotation: playernamedetails.NamerotationAngle,
  //               scaleX: playernamedetails.NameScale.x,
  //               scaleY: playernamedetails.NameScale.y,
  //             });

  //             layer.add(text1);

  //             const text2 = new Konva.Text({
  //               x:
  //                 (playernumberdetails.textPositionPer.x * stage.width()) / 100,
  //               y:
  //                 (playernumberdetails.textPositionPer.y * stage.height()) /
  //                 100,
  //               text: item.number,
  //               fontSize:
  //                 (playernumberdetails.textSizePer * stage.width()) / 100,
  //               fontFamily: playernumberdetails.fontFamily,
  //               fill: playernumberdetails.textColor,
  //               stroke: playernumberdetails.outlineColor,
  //               strokeWidth:
  //                 (playernumberdetails.NotextBorderPer * stage.width()) / 100,
  //               align: "center",
  //               width: (playernumberdetails.NoWidthPer * stage.width()) / 100,
  //               rotation: playernumberdetails.rotationAngles,
  //               scaleX: playernumberdetails.NoScale.x,
  //               scaleY: playernumberdetails.NoScale.y,
  //             });

  //             var tshirtSizeWatermark = new Konva.Text({
  //               x: 0,
  //               y: 0,
  //               text: item.size,
  //               fontSize:
  //                 (playernumberdetails.textSizePer *
  //                   dimensionsKonva[item.size].width) /
  //                 100 /
  //                 3 /
  //                 2,
  //               // fontFamily: playernumberdetails.fontFamily,
  //               fontFamily: "IBM Plex Sans",
  //               fill: "black",
  //               stroke: playernumberdetails.outlineColor,
  //               // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
  //               // align: 'center',
  //               // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
  //               // rotation: playernumberdetails.rotationAngles,
  //               // scaleX: playernumberdetails.NoScale.x,
  //               // scaleY: playernumberdetails.NoScale.y
  //             });
  //             var textWidth = tshirtSizeWatermark.textWidth;
  //             var textHeight = tshirtSizeWatermark.textHeight;
  //             var backgroundRect = new Konva.Rect({
  //               x: tshirtSizeWatermark.x(),
  //               y: tshirtSizeWatermark.y(),
  //               width: textWidth,
  //               height: textHeight,
  //               fill: "white", // Set background color to white
  //             });
  //             layer.add(backgroundRect);
  //             layer.add(tshirtSizeWatermark);

  //             layer.add(text2);
  //             stage.add(layer);

  //             const dataURL = stage.toDataURL();
  //             const imageWith300DPi = changeDpiDataUrl(dataURL, 300);

  //             zip.file(
  //               `${item.size}${dimensions[item.size]?.size}_${item.name}_${
  //                 item.number
  //               }.png`,
  //               imageWith300DPi.split("base64,")[1],
  //               { base64: true }
  //             );

  //             stage.destroy();
  //             image.src = "";
  //             resolve();
  //           };
  //         });
  //       })
  //     );

  //     await new Promise((resolve) => setTimeout(resolve, 500));
  //   }

  //   zip
  //     .generateAsync({ type: "blob" })
  //     .then((content) => {
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(content);
  //       link.download = "all_images.zip";
  //       link.click();
  //       URL.revokeObjectURL(link.href);
  //       $(".loader_bg").hide();
  //     })
  //     .catch((error) => {
  //       console.error("Error generating ZIP file:", error);
  //       $(".loader_bg").hide();
  //     });
  // };

  const handleDownloadAll = async (bypass, imgCount) => {
    let totalImagesCount = 0;
    if (!bypass) {
      totalImagesCount = handleImageCount();
      if (!totalImagesCount) return false;
    } else {
      totalImagesCount = imgCount;
    }
    $(".loader_bg").show();
    setProcessing(true);
    // let userPlanDetails = planDetails;
    // let planId = userPlanDetails?.plan_id;
    // let totalImagesCount = 0;

    // if (planId === "1") {
    //   let variantionDownloaded = userPlanDetails.variant_count;
    //   totalImagesCount = Math.max(0, 20 - variantionDownloaded);
    // } else if (planId === "5") {
    //   let totalVariantion = userPlanDetails.total_variant;
    //   let variantionDownloaded = userPlanDetails.variant_count;
    //   totalImagesCount = Math.min(totalVariantion, tshirtdetails.length);
    // } else {
    //   totalImagesCount = tshirtdetails.length;
    // }

    const zip = new JSZip();
    const playernamedetails =
      JSON.parse(localStorage.getItem("playernamedetails")) || {};
    const playernumberdetails =
      JSON.parse(localStorage.getItem("playernumberdetails")) || {};

    const chunkSize = 1; // Adjust chunk size as needed
    const totalImages = tshirtdetails.length;
    let downloadedCount = 0;

    console.log("totalImagesCount are ", totalImagesCount);

    const updateProgress = () => {
      const percentage = Math.round((downloadedCount / totalImagesCount) * 100);
      setpercentDownloaded(percentage);
      $(".progess_cus_bg").css("width", percentage + "%");
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      for (let i = 0; i < totalImagesCount; i += chunkSize) {
        if (cancelDownloadRef.current) {
          console.log("Download canceled.");
          break; // Exit the loop if download is canceled
        }

        const chunk = tshirtdetails.slice(i, i + chunkSize);

        await Promise.all(
          chunk.map(async (item, index) => {
            return new Promise((resolve, reject) => {
              const stage = new Konva.Stage({
                width: dimensions[item.size]?.width || 500, // Default width if undefined
                height: dimensions[item.size]?.height || 500, // Default height if undefined
                container: document.createElement("div"),
              });

              const layer = new Konva.Layer();

              if (selectedImage) {
                const image = new window.Image();
                image.src = selectedImage;

                image.onload = () => {
                  const bgImage = new Konva.Image({
                    x: 0,
                    y: 0,
                    image: image,
                    width: dimensionsKonva[item.size]?.width || 500, // Default width if undefined
                    height: dimensionsKonva[item.size]?.height || 500, // Default height if undefined
                  });

                  layer.add(bgImage);
                  addTextLayers(
                    stage,
                    layer,
                    item,
                    playernamedetails,
                    playernumberdetails
                  );
                  stage.add(layer);

                  saveImage(stage, zip, item, i + index);
                  downloadedCount++;
                  updateProgress();
                  resolve();
                };

                image.onerror = (error) => {
                  console.error(
                    `Error loading image for ${item.name} (${item.size}):`,
                    error
                  );
                  resolve(); // Resolve to continue with the next item even if this one fails
                };
              } else {
                if (exportImageType.toLowerCase() === "jpg") {
                  const bgRect = new Konva.Rect({
                    x: 0,
                    y: 0,
                    width: stage.width(),
                    height: stage.height(),
                    fill: "white", // Set default background color
                  });

                  layer.add(bgRect);
                }
                addTextLayers(
                  stage,
                  layer,
                  item,
                  playernamedetails,
                  playernumberdetails
                );
                stage.add(layer);

                saveImage(stage, zip, item, i + index);
                downloadedCount++;
                updateProgress();
                resolve();
              }
            });
          })
        );

        if (cancelDownloadRef.current) {
          console.log("Download canceled.");
          break; // Exit the loop if download is canceled after processing a chunk
        }

        await sleep(100); // Add a small delay after each chunk to avoid overloading the browser
      }

      if (!cancelDownloadRef.current) {
        const content = await zip.generateAsync({ type: "blob" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "all_images.zip";
        link.click();

        await updateVariationCount(totalImagesCount);
        URL.revokeObjectURL(link.href);
      }

      setProcessing(false);
      $(".loader_bg").hide();
    } catch (error) {
      console.error("Error during download:", error);
      setProcessing(false);
      $(".loader_bg").hide();
    }
  };

  const addTextLayers = (
    stage,
    layer,
    item,
    playernamedetails,
    playernumberdetails
  ) => {
    const text1 = new Konva.Text({
      x: (playernamedetails.NametextPositionPer.x * stage.width()) / 100,
      y: (playernamedetails.NametextPositionPer.y * stage.height()) / 100,
      text: item.name ? item.name : "",
      fontSize: (playernamedetails.NametextSizePer * stage.width()) / 100,
      fontFamily: playernamedetails.NamefontFamily,
      fill: playernamedetails.NametextColor,
      stroke: playernamedetails.NameoutlineColor,
      strokeWidth: (playernamedetails.NametextBorderPer * stage.width()) / 100,
      align: "center",
      width: (playernamedetails.NameWidthPer * stage.width()) / 100,
      rotation: playernamedetails.NamerotationAngle,
      scaleX: playernamedetails.NameScale.x,
      scaleY: playernamedetails.NameScale.y,
    });

    layer.add(text1);

    const text2 = new Konva.Text({
      x: (playernumberdetails.textPositionPer.x * stage.width()) / 100,
      y: (playernumberdetails.textPositionPer.y * stage.height()) / 100,
      text: item.number ? item.number : "",
      fontSize: (playernumberdetails.textSizePer * stage.width()) / 100,
      fontFamily: playernumberdetails.fontFamily,
      fill: playernumberdetails.textColor,
      stroke: playernumberdetails.outlineColor,
      strokeWidth: (playernumberdetails.NotextBorderPer * stage.width()) / 100,
      align: "center",
      width: (playernumberdetails.NoWidthPer * stage.width()) / 100,
      rotation: playernumberdetails.rotationAngles,
      scaleX: playernumberdetails.NoScale.x,
      scaleY: playernumberdetails.NoScale.y,
    });

    layer.add(text2);

    const tshirtSizeWatermark = new Konva.Text({
      x: 0,
      y: 0,
      text: item.size,
      fontSize:
        (playernumberdetails.textSizePer * dimensionsKonva[item.size]?.width) /
        100 /
        3 /
        2 /
        3,
      fontFamily: "IBM Plex Sans",
      fill: "black",
      stroke: playernumberdetails.outlineColor,
    });

    const textWidth = tshirtSizeWatermark.textWidth;
    const textHeight = tshirtSizeWatermark.textHeight;
    const backgroundRect = new Konva.Rect({
      x: tshirtSizeWatermark.x(),
      y: tshirtSizeWatermark.y(),
      width: textWidth,
      height: textHeight,
      fill: "white", // Set background color to white
    });

    layer.add(backgroundRect);
    layer.add(tshirtSizeWatermark);

    // Add the top center mark
    const centerX = stage.width() / 2;
    const markLength = 40; // Length of the horizontal mark
    const markThickness = 100; // Thickness of the mark

    // Background lines
    const topMarkBackground = new Konva.Line({
      points: [centerX - markLength / 2, 10, centerX + markLength / 2, 10], // Horizontal line at the top center
      stroke: "white", // Background color of the mark
      strokeWidth: markThickness, // Customize the width of the mark
    });

    const bottomMarkBackground = new Konva.Line({
      points: [
        centerX - markLength / 2,
        stage.height() - 10,
        centerX + markLength / 2,
        stage.height() - 10,
      ], // Horizontal line at the bottom center
      stroke: "white", // Background color of the mark
      strokeWidth: markThickness, // Customize the width of the mark
    });

    // Foreground lines
    const topMarkForeground = new Konva.Line({
      points: [centerX - markLength / 2, 10, centerX + markLength / 2, 10], // Horizontal line at the top center
      stroke: "black", // Foreground color of the mark
      strokeWidth: markThickness / 2, // Customize the width of the mark
    });

    const bottomMarkForeground = new Konva.Line({
      points: [
        centerX - markLength / 2,
        stage.height() - 10,
        centerX + markLength / 2,
        stage.height() - 10,
      ], // Horizontal line at the bottom center
      stroke: "black", // Foreground color of the mark
      strokeWidth: markThickness / 2, // Customize the width of the mark
    });

    layer.add(topMarkBackground);
    layer.add(bottomMarkBackground);
    layer.add(topMarkForeground);
    layer.add(bottomMarkForeground);
  };

  const saveImage = (stage, zip, item, index) => {
    const dataURL = stage.toDataURL();
    const imageWith300Dpi = changeDpiDataUrl(dataURL, 300);

    zip.file(
      `${item.size}_${item.name}_${item.number}_${index}.${exportImageType}`, // Add index to filename for uniqueness
      imageWith300Dpi.split("base64,")[1],
      { base64: true }
    );

    // Clean up
    stage.destroy();
  };

  const handleDownload = async (
    canvas,
    fileName,
    size,
    indexSr,
    index,
    file_type
  ) => {
    console.log("inside handledownload");
    const planDetails =
      localStorage.getItem(config.USER_PLAN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
    let planId = planDetails?.plan_id;

    const userVariantDetails =
      localStorage.getItem(config.VARIANT_COUNT) &&
      localStorage.getItem(config.VARIANT_COUNT) != "undefined"
        ? JSON.parse(localStorage.getItem(config.VARIANT_COUNT))
        : null;

    console.log("planId is", planId);
    if ((planId == "1" || planId == "5") && userVariantDetails) {
      if (userVariantDetails.remaning < 1) {
        console.log("inside handledownload inside planid is 5");
        setImagesCanDownload(0);
        setShowLimitReachesPopup(true);
        return false;
      } else {
        setShowLimitReachesPopup(false);
      }
    }

    // if (planDetails && planDetails.plan_id == "1") {
    //   let vCount = planDetails?.variant_count;
    //   const variantLeft = 20 - vCount;
    //   if (variantLeft <= 0) {
    //     alert("Your free trial is expired, please upgrade your plan");
    //     navigate("/plans");
    //     return;
    //   }
    // }

    $(".loader_bg").show();
    setProcessing(true);
    if (planIsPayAsUse) {
      localStorage.setItem("payasuusedisablebtn", "true");
      setFirstImageDownloaded(true);
      setDisableAllButton(true);
    }
    if (!size) {
      alert("Please select at least a size for the t-shirt");
      return;
    }

    const tshirtSize = size;
    setProcessing(true);
    $(".loader_bg").show();

    const stageRsize = await drawResizeStage(
      indexSr,
      index,
      dimensions[tshirtSize]?.width,
      dimensions[tshirtSize]?.height,
      tshirtSize
    );
    const stageDataURL = await stageRsize.toDataURL();
    const imageWith300DPi = changeDpiDataUrl(stageDataURL, 300);

    const blob = await fetch(imageWith300DPi).then((res) => res.blob());
    const url = URL.createObjectURL(blob);
    downloadImage(url, fileName);
    URL.revokeObjectURL(url);

    stagediv.current.children = [];
    setProcessing(false);
    $(".loader_bg").hide();

    if (file_type !== "all") {
      await updateVariationCount();
      setProcessing(false);
      $(".loader_bg").hide();
    }
  };

  const downloadImage = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  const upgradePlanBtnClick = () => {
    navigate("/plans");
  };

  console.log("tshirtdetails", tshirtdetails);

  const cancelClickHandler = () => {
    cancelDownloadRef.current = true;
  };
  const downloadClickHandler = () => {
    cancelDownloadRef.current = false;
    handleDownloadAll();
  };
  return (
    <>
      {loading && <Loader />}
      {/* <div style={{ display: "none" }} className="loader_bg"> */}
      {/* <div  className="loader_bg">
        <div className="loader_img">
          <img src="/assets/images/loader.png" alt="" /> */}
      {/* <PercentLoader percent={percentDownloaded} /> */}
      {/* </div>
      </div> */}
      {/* {processing && <Loader />} */}
      <div id="main-container" className="container-fluid main">
        <Sidebar></Sidebar>
        <section
          className="home"
          // style={{ maxHeight: "100vh", overflowY: "scroll" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-2 col-xl-3 col-sm-3 sidebarchange">
                <div className="sidebar_stick">
                  <div className="custom-side">
                    <p className="side-title">Export</p>

                    <div className="img_up img_up_mar">
                      <h6>{exportImageType.toUpperCase()}</h6>
                      {/* <p>
                      Image Type
                      <img
                        src={"/assets/images/accordup.svg"}
                        className=""
                        alt=""
                      />
                    </p> */}
                      <select
                        aria-label="Default select example"
                        value={exportImageType}
                        onChange={(e) => setExportImageType(e.target.value)}
                        className="custom-select jpg_select text-end"
                        style={{ border: "none" }}
                      >
                        <option value={"jpg"}>JPG</option>
                        <option value={"png"}>PNG</option>
                      </select>
                    </div>

                    {/* <div className="img_up img_up_mar">
                    <h6>CMYK</h6>
                    <p>
                      {" "}
                      Image Color
                      <img
                        src={"/assets/images/accordup.svg"}
                        className=""
                        alt=""
                      />
                    </p>
                  </div> */}

                    {/* <div className="img_up img_up_mar">
                    <h6>DPI</h6>
                  <p>
                      {" "}
                      Image DPI
                      <img
                        src={"/assets/images/accordup.svg"}
                        className=""
                        alt=""
                      />
                    </p> 
                    <select
                      aria-label="Default select example"
                      value={exportImageType}
                      onChange={(e) => setExportImageType(e.target.value)}
                      className="custom-select jpg_select text-end"
                      style={{ border: "none" }}
                    >
                      <option value={"150"}>150</option>
                      <option value={"300"}>300</option>
                    </select>
                  </div> */}

                    <button
                      className="download_all"
                      onClick={() => {
                        if (processing) {
                          return;
                        }
                        cancelDownloadRef.current = false;
                        handleDownloadAll();
                      }}
                      disabled={processing}
                    >
                      <img
                        src={"/assets/images/downloadall.svg"}
                        className=""
                        alt=""
                      />{" "}
                      {processing ? "Downloading" : "Download All"}
                    </button>

                    {/* <div className="proges_div">
                      <ul className="download_bartext">
                        <li>
                          <h6>Downloading</h6>
                        </li>
                        <li>
                          <h6>69%</h6>
                        </li>
                      </ul>

                      <div className="progress progess_cust">
                        <div
                          className="progress-bar progess_cus_bg"
                          style={{ width: "70%" }}
                        ></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xxl-10 col-xl-9 col-sm-9">
                <div className="row row_pad">
                  <ul className="d-flex col-4 custom-tabs">
                    <li
                      className=""
                      onClick={() => {
                        navigate("/Design", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Design
                    </li>
                    <li
                      className="mx-2"
                      onClick={() => {
                        navigate("/Variation", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Variation
                    </li>
                    <li
                      className="mx-2 active"
                      onClick={() => {
                        navigate("/export-new", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Export
                    </li>
                  </ul>
                  <div className="col-sm-4">
                    <ImagesRemaining
                      plan={plan}
                      tshirtdetails={tshirtdetails}
                      userVariantDetails={userVariantDetails}
                    />
                  </div>
                </div>

                <div className="row">
                  <ExportTopDownloadAll
                    processing={processing}
                    percentDownloaded={percentDownloaded}
                    cancelClickHandler={cancelClickHandler}
                    downloadClickHandler={downloadClickHandler}
                  />

                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div
                        className="col-lg-10"
                        style={{
                          backgroundColor: "#F9FAFD",
                          paddingLeft: "30px",
                          paddingTop: "10px",
                        }}
                      >
                        <div className="row mt-4">
                          <h5 style={{ width: "30%" }}>
                            {tshirtdetails && tshirtdetails.length} Variations
                            Created
                          </h5>
                          <div
                            className=""
                            style={{ width: "40%", alignItems: "right" }}
                          ></div>
                          <div
                            className=""
                            style={{
                              width: "30%",
                              alignItems: "right",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                width: "30px",
                                padding: "10px 5px 0 5px",
                                backgroundColor: "#EAEEF8",
                                borderRadius: "20px 0 0 20px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="mx-2"
                              />
                            </div>
                            <input
                              ref={inputSearch}
                              type="text"
                              placeholder="Search all files"
                              className="export-variation-search"
                              onChange={() => {
                                handelInputsearch();
                              }}
                            />
                          </div>
                        </div>
                        <div className="my-5 col-12 justify-content-center ">
                          <div className="row">
                            {tshirtdetails &&
                              tshirtdetails.map((val, index) => (
                                <div
                                  onContextMenu={handleRightClick}
                                  key={index}
                                  className="col-xl-3 col-md-6 col-lg-4 col-sm-6"
                                  style={{
                                    position: "relative",
                                    paddingLeft: "10px",
                                    marginBottom: "25px",
                                  }}
                                  ref={(ref) => {
                                    if (ref)
                                      ImgDivRef.current[val.indexSr] = ref;
                                  }}
                                >
                                  <div
                                    className="tshirtimg1"
                                    ref={(ref) => {
                                      if (ref)
                                        downladImage.current[val.indexSr] = ref;
                                    }}
                                  >
                                    <svg
                                      width="220"
                                      height="315"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ borderRadius: "10px" }}
                                    >
                                      {/* <rect x="40" y="0" width="160" height="315" fill="white" /> */}
                                      {selectedImage && (
                                        // <></>
                                        <image
                                          href={selectedImage}
                                          width="220"
                                          height="315"
                                          preserveAspectRatio="xMidYMid slice"
                                        />
                                      )}
                                      {/* <circle cx="110" cy="-55" r="73" fill="white" /> */}
                                    </svg>

                                    <Stage
                                      ref={(ref) => {
                                        if (ref)
                                          canvasRef.current[val.indexSr] = ref;
                                      }}
                                      width={220}
                                      height={315}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 10,
                                        border: "0px solid #000",
                                        borderRadius: "10px",
                                      }}
                                    ></Stage>
                                  </div>
                                  <div className="flex_expor">
                                    <button
                                      className="edit_export"
                                      onClick={() => {
                                        navigate("/EditDesign", {
                                          state: {
                                            indexSr: val.indexSr,
                                            selectedImage: selectedImage,
                                            side: state.side,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={"/assets/images/editpen.svg"}
                                        className=""
                                        alt=""
                                      />{" "}
                                      Edit
                                    </button>
                                    <button
                                      className="download_export"
                                      style={{
                                        backgroundColor:
                                          downloadedIndex.includes(index)
                                            ? "rgba(139, 60, 217, 0.4)"
                                            : "#8B3CD9",
                                      }}
                                      onClick={() => {
                                        if (processing) {
                                          $(".loader_bg").hide();
                                          return;
                                        }
                                        handleDownload(
                                          downladImage.current[val.indexSr],
                                          `${val.size}${
                                            dimensions[val.size]?.size
                                          }_${val.name}_${
                                            val.number
                                          }.${exportImageType}`,
                                          val.size,
                                          val.indexSr,
                                          index,
                                          "single"
                                        );
                                      }}
                                      disabled={processing}
                                    >
                                      {" "}
                                      Download{" "}
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="stagediv"
            className="container"
            style={{ visibility: "hidden", display: "none" }}
            ref={ResizeImagediv}
          >
            <Stage ref={stagediv}></Stage>
          </div>
        </section>
      </div>

      {showLimitReachedPopup && (
        <LimitExceedPopup
          handleDownloadAll={handleDownloadAll}
          imagesCanDownload={imagesCanDownload}
          close={() => setShowLimitReachesPopup(false)}
          imageLimit={userVariantDetails.remaning}
          tshirtdetails={tshirtdetails}
        />
      )}

      {/* <canvas ref={canvasRef} width={220} height={315} style={{padding: '0px', margin: '0px', border: '0px', background: 'transparent', position: 'absolute', top: '0px', left: '-100px', display: 'block'}} /> */}
    </>
  );
}

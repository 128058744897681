import React, { useEffect, useRef, useState } from "react";

export default function LocalFonts({ onSelect, selected, onHover }) {
  const [loading, setLoading] = useState(false);
  const [localFonts, setLocalFonts] = useState([]);
  const [filteredLocalFonts, setFilteredLocalFonts] = useState([]);
  const [apiSupported, setApiSupported] = useState(false);
  const [showApiSupported, setShowApiSupported] = useState(false)
  const [fontFaces, setFontFaces] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const activeListItemRef = useRef(null);

  async function getLocalFonts() {
    if ("queryLocalFonts" in window) {
      setApiSupported(true);
      setLoading(true);
    } else {
      setApiSupported(false);
      return;
    }
    try {
      const fonts = await window.queryLocalFonts();
      console.log(fonts); // Fonts is an array of FontData objects
      setLocalFonts(fonts);
      setFilteredLocalFonts(fonts);
    } catch (error) {
      console.error("Error fetching local fonts:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if ("queryLocalFonts" in window) {
      setApiSupported(true);
      getLocalFonts();
    } else {
      setShowApiSupported(true);
      setApiSupported(false);
    }
  }, []);
  useEffect(() => {
    if (localFonts.length > 0) {
      const loadFonts = async () => {
        const loadedFontFaces = {};
        for (const font of localFonts) {
          const sanitizedFontFamily = font.family.replace(/['"]/g, "");
          const fontFace = new FontFace(
            font.family,
            `local('${sanitizedFontFamily}')`
          );
          try {
            await fontFace.load();
            document.fonts.add(fontFace);
            loadedFontFaces[font.family] = fontFace;
          } catch (error) {
            console.error(`Error loading font ${font.family}:`, error);
          }
        }
        setFontFaces(loadedFontFaces);
      };
      loadFonts();
    }
  }, [localFonts]);
  const searchChangeHandler = (val) => {
    console.log("val", val);
    setSearchVal(val);
    let arr = localFonts.filter((i) =>
      i?.fullName?.toLowerCase().includes(val.toLowerCase())
    );
    setFilteredLocalFonts(arr);
  };
  if (!apiSupported && showApiSupported) {
    return <h1>Your browser does not support the Font Access API</h1>;
  }

  if (loading) {
    return <h1>Loading...</h1>;
  }
  return (
    <>
      <div className="search_flont_div">
        <input
          onChange={(e) => searchChangeHandler(e.target.value)}
          value={searchVal}
          type="text"
          placeholder="Search"
        />
        <img src={"/assets/images/searchfont.svg"} alt="" />
      </div>
      <select className="all_fonts_select">
        <option>All Fonts</option>
        <option>All Fonts</option>
        <option>Initialized by you</option>
      </select>

      <div className="font_pos">
        <ul className="options option_font">
          {filteredLocalFonts?.length > 0 &&
            filteredLocalFonts?.map((option, index) => (
              <li
                ref={selected === option.value ? activeListItemRef : null}
                className={selected === option.value ? "active" : ""}
                style={{ fontFamily: option.family }}
                key={index}
                onClick={() => {
                  setFontFaces(option.family);
                  onSelect(option.family);
                }}
                onMouseEnter={() => onHover(option.family)}
                onMouseLeave={() => onHover(null)}
              >
                {option.fullName}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

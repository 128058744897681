import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import { Row, Col, Nav, NavItem, NavLink as NavLinkStrap, TabContent, TabPane, Table, Button } from "reactstrap";
import clsx from 'clsx';
import IssueTab from "./IssueTab";
import FeedbackTab from "./FeedbackTab";

export default function FeedbackManagement() {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };


    return (
        <>
            <div
                className="container-fluid"
            >
                <Sidebar />
                <section className="home">
                    <div className="container-fluid">
                        <div className="searcmain_pad">
                            <div className="search_header">
                                <ul className="search_list">
                                    <li>
                                        <h3>Feedback Management</h3>
                                    </li>

                                    <li>
                                        <div className="cansear_div">
                                            <button className="can_sea">Cancel</button>
                                            <button className="sav_sea">Save</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="select_typelist">
                                <ul>
                                    <li>
                                        <button className="active">
                                            <img src={'/assets/images/checkblue.svg'} alt="" /> New
                                        </button>
                                    </li>

                                    <li>
                                        <button>
                                            <img src={'/assets/images/checkblue.svg'} alt="" /> Closed
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <Row>
                                <Col sm="12">
                                    <div className="mb-4">
                                        <Nav className="issue_tab">
                                            <NavItem>
                                                <NavLinkStrap
                                                    className={clsx({ active: activeTab === '1' })}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}>
                                                    <div className="count_fed">
                                                        <span className="issue_con">Issues</span>

                                                    </div>
                                                    <div className="divider"></div>
                                                </NavLinkStrap>
                                            </NavItem>
                                            <NavItem>
                                                <NavLinkStrap
                                                    className={clsx({ active: activeTab === '2' })}
                                                    onClick={() => {
                                                        toggle('2');
                                                    }}>
                                                    <div className="count_fed">
                                                        <span className="issue_con">Feedback</span>
                                                        <span className="cont_issue">2</span>
                                                    </div>
                                                    <div className="divider"></div>
                                                </NavLinkStrap>
                                            </NavItem>

                                        </Nav>
                                    </div>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                           <IssueTab />
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <FeedbackTab />
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>


                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
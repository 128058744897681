import React, { useEffect, useRef, useState } from "react";
import switchImage from '../../assets/img/switch_access_shortcut_black_24dp.svg';
import Sidebar from "../../component/Sidebar";
import { Layer, Stage,  Image as KonvaImage } from "react-konva";
import Konva from "konva";
import { useLocation, useNavigate } from "react-router-dom";
import useImage from "use-image";
import { Button } from "reactstrap";
import { dimensions } from "../../utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function FrontVariation() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [BgName, setBgName] = useState("");
  const [errmsg, setErrmsg] = useState(" ");
  const [excelData, setExcelData] = useState(null);
  const [appendingRow, setAppendingRow] = useState([1]);


  var selectedImage = state?.selectedImage;

  const canvasRef = useRef(null);
  const fileInputField = useRef();
  const message = useRef();
  const myDivRef = useRef(null);
  const SizeRef = useRef([]);

  const handleInputChange = (inputtype) => {
    const inputs = document.querySelectorAll('.tshirt-variant-data input, .tshirt-variant-data select');
    let isEmpty = false;

    inputs.forEach(input => {
        if (input.value.trim() === '') {
            isEmpty = true;
        }
    });
    // if (inputtype === 'size') {
    // setSaveDisabled(isEmpty);
    // setGenerateDisable(true);
    // }

};



  const LoadBGImage = () => {
    const [image] = useImage(selectedImage);
    // image.width = 320;
    // image.height = 500;
    return <KonvaImage image={image} width={220} height={315} style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }} x={56} />

    // return <Shape sceneFunc={(context, shape)=>{context.beginPath();
    //     context.moveTo(48, 6);
    //     context.quadraticCurveTo(100, 27, 153, 5);
    //     // context.lineTo(220, 50);
    //     context.lineTo(203, 20);
    //     context.lineTo(203, 315);
    //     context.lineTo(0, 500);
    //     context.lineTo(0, 23);
    //     context.closePath();
    //     context.fillStrokeShape(shape);}} strokeWidth={1} fillPatternImage={image} fillPatternScale={{x:0.41,y:0.44}} style={{ position: 'absolute', top: 0, left: 0,zIndex:0 }} x={56}></Shape>;

}

  useEffect(() => {
    var tshirtSizeSelected = localStorage.getItem("tshirtSize");
    var imgname = localStorage.getItem("bgname");
    // console.log(playernamedetails);

    //  settshirtSize(tshirtSizeSelected);
    setBgName(imgname);
    // drawTextOnCanvas(canvasRef.current);
  });
  return (
    <div id="main-container" className="container-fluid main">
      <Sidebar />
      <section className="home">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-2 col-xl-3 col-sm-3 sidebarchange">
              <div className="custom-side">
                <p className="side-title">preview</p>

                <input
                  className="form_var form-control"
                  type="text"
                  placeholder={`Selected Image: ${BgName}`}
                  readOnly
                />
              </div>

              {/* <img src={sidemenu} style={{ width: '100%' , textAlign: 'center'}} /> */}
              <div style={{ position: "relative" }}>
                <Stage width={315} height={315} className="stage1">
                  <Layer>
                    {/* <LoadImage /> */}
                    {selectedImage && <LoadBGImage x={285} />}
                  </Layer>
                </Stage>
                <Stage
                  width={220}
                  height={315}
                  className="stage2"
                  ref={canvasRef}
                ></Stage>
              </div>
            </div>

            <div className="col-xxl-10 col-xl-9 col-sm-9">
              <div className="row row_pad">
                <ul className="d-flex col-6 custom-tabs">
                  <li
                    className=""
                    onClick={() => {
                      navigate("/Design", {
                        state: {
                          selectedImage: selectedImage,
                          side: state.side,
                        },
                      });
                    }}
                  >
                    Design
                  </li>
                  <li
                    className="mx-2 active"
                    onClick={() => {
                      navigate("/Variation", {
                        state: {
                          selectedImage: selectedImage,
                          side: state.side,
                        },
                      });
                    }}
                  >
                    Variation
                  </li>
                  <li
                    className="mx-2"
                    onClick={() => {
                      navigate("/export-new", {
                        state: {
                          selectedImage: selectedImage,
                          side: state.side,
                        },
                      });
                    }}
                  >
                    Export
                  </li>
                </ul>
                <div className="col-6 custom-btn">
                  {/* <Button onClick={() => { navigate('/Export', { state: { selectedImage: selectedImage } }) }} className="float-end " variant="primary" disabled={generateDisable}> */}
                  {/* <Button onClick={() => generateButtonClickHandler()} className="float-end " variant="primary" disabled={generateDisable}> */}
                  {/* <Button onClick={() => generateButtonClickHandler()} className="float-end " variant="primary" disabled={isSaveDisabled}>
                                    <img src={switchImage} alt="home" style={{ width: '25px' }} /> Generate
                                </Button> */}
                  <Button
                    type="submit"
                    style={{
                      borderRadius: "30px",
                      backgroundColor: "#8B3CD9",
                      border: "0",
                      marginLeft: "20px",
                    }}
                    className="float-end "
                    variant="primary"
                
                    onClick={(e) => {}}
                  >
                    <img
                      src={switchImage}
                      alt="home"
                      style={{ width: "25px" }}
                    />
                    Generate
                  </Button>
                </div>
              </div>

              <div className="row row_excel">
                <div className="col-sm-12">
                  <div className="main_content">
                    <h4 className="h-variation2">Maintain Excel</h4>
                    <p className="p-variation2">
                      Efficient input all player name and number details into
                      the Excel.Our user-friendly system ensures accuracy and
                      saves you valuable time.
                    </p>
                    {/* <Button className="px-3 py-1" style={{borderRadius: '30px', backgroundColor:'#2b2b5e'}} type="file" > Upload Excel </Button> */}

                    <ul className="uploadexcel_list">
                      <li>
                        <button className="add_man">Add manually</button>
                      </li>
                      <li className="or_ex_pad">
                        <p>OR</p>
                      </li>

                      <li>
                        <div className="upload_excel_div">
                          <input
                            type="file"
                            id="uploadbtn"
                            className="upload_excel_input"
                            ref={fileInputField}
                            // onChange={selectFile}
                          />
                         <h6>Upload Excel</h6>
                        </div>
                      </li>
                    </ul>

                    <p
                      style={{
                        maxWidth: "300px",
                        textAlign: "center",
                        margin: "20px auto",
                      }}
                      ref={message}
                    >
                      {errmsg}
                    </p>
                  </div>
                </div>

                <div className="container mt-5">
                  <div className="row variation-inner">
                    <div className="col-3 text-center ">
                      <div className="card p-3">
                        <div className="card-inner">
                          <h6>Download Sample Excel</h6>
                          <p className="dwcard">
                            Maintain Excel Structure for the perfect output.
                          </p>
                          <div className="mx-auto mt-3 text-center">
                            <Button
                              style={{
                                backgroundColor: "#8B3CD9",
                                borderRadius: "52px",
                                border: "none",
                              }}
                            >
                              <a
                                href={
                                  "https://variantwebmedia.s3.ap-south-1.amazonaws.com/excel%20/Variant_Size_SampleSheet.xlsx"
                                }
                                className="exceldw"
                                target="_blank"
                              >
                                {" "}
                                Download Sample Excel
                              </a>
                            </Button>
                            <img
                              style={{ width: "100%" }}
                              alt="sizechart"
                              src="/assets/images/sizechart.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 variation-block">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingBottom: "5px",
                        }}
                      >
                        {/* <Button style={{ borderRadius: '30px', backgroundColor: '#8B3CD9', border: '0' }} className="custom-button" disabled={userIsFree && +variationcount > 19} onClick={() => addRow()}>Add</Button> */}
                        <Button
                          style={{
                            borderRadius: "30px",
                            backgroundColor: "#8B3CD9",
                            border: "0",
                          }}
                          className="custom-button"
                        //   onClick={() => addRow()}
                        >
                          Add
                        </Button>
                        {/* <Button type='submit' style={{ borderRadius: '30px', backgroundColor: '#8B3CD9', border: '0', marginLeft: '20px' }} className="custom-button" disabled={isSaveDisabled} onClick={(e) => upload_data(e)} >Save</Button> */}
                      </div>
                      <div
                        id="variations_table"
                        style={{
                          maxHeight: "50vh",
                          overflowY: "scroll",
                          paddingBottom: "10px",
                        }}
                      >
                        <form>
                          <table
                            ref={myDivRef}
                            responsive="sm"
                            className="table variation-inner-table"
                          >
                            <thead
                              className="theadd"
                              style={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#F9FAFD",
                              }}
                            >
                              <tr>
                                
                                <th style={{ width: "15%" }}>Size</th>
                                <th style={{ width: "1%" }}></th>
                              </tr>
                            </thead>
                            <tbody
                              className="custom-tbody"
                              style={{ backgroundColor: "#F9FAFD" }}
                            >
                              {/* <tr>
                                            <td>Jigar <span>5/8</span></td>
                                            <td>26 <span>2/3</span></td>
                                            <td>
                                            <select>
                                                <option>
                                                   Medium-21 x 29
                                                </option>
                                            </select>
                                            </td>
                                        </tr> */}

                              {excelData &&
                                excelData.map((value, index) => (
                                  <tr
                                    key={index}
                                    id={
                                      value["indexSr"] != undefined
                                        ? value["indexSr"]
                                        : value["__rowNum__"]
                                    }
                                  >
                                    
                                    <td>
                                      <select
                                        className="form-control tshirt-variant-data"
                                        style={{
                                          backgroundColor: "#EAEEF8",
                                          borderRadius: "52px",
                                          width: "100%",
                                        }}
                                        name="size[]"
                                        defaultValue={value["size"]}
                                        ref={(ref) => {
                                          if (ref)
                                            SizeRef.current[
                                              value["indexSr"] != undefined
                                                ? value["indexSr"]
                                                : value["__rowNum__"]
                                            ] = ref;
                                        }}
                                      >
                                        <option key={index} value={""}>
                                          Size
                                        </option>
                                        {Object.keys(dimensions).map(
                                          (size, index) => (
                                            <option key={index} value={size}>
                                              {size}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </td>
                                    <td>
                                      <span
                                        for={
                                          value["indexSr"] != undefined
                                            ? value["indexSr"]
                                            : value["__rowNum__"]
                                        }
                                        className="form-control tshirt-variant-data"
                                        style={{
                                          borderRadius: "52px",
                                          border: "none",
                                          color: "#000",
                                          backgroundColor: "#fff0",
                                        }}
                                        onClick={(ev) =>{
                                        //   removeExcelRow(
                                        //     ev,
                                        //     value["indexSr"] != undefined
                                        //       ? value["indexSr"]
                                        //       : value["__rowNum__"]
                                        //   )
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          style={{ marginRight: "5px" }}
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              {appendingRow.map((val, index) => (
                                <tr id={`row${val}`} key={`row${val}`}>
                                  
                                  <td>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                        width: "100%",
                                      }}
                                    >
                                      <select
                                        className="form-control tshirt-variant-data"
                                        style={{
                                          backgroundColor: "#EAEEF8",
                                          borderRadius: "52px",
                                          width: "100%",
                                          paddingRight: "20px",
                                        }}
                                        name={`size[${val}]`}
                                        onChange={() =>
                                          handleInputChange("size")
                                        }
                                        ref={(ref) => {
                                          if (ref) SizeRef.current[val] = ref;
                                        }}
                                      >
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          Select Size
                                        </option>
                                        {Object.keys(dimensions).map(
                                          (size, index) => (
                                            <option key={index} value={size}>
                                              {size}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          right: "5px",
                                          transform: "translateY(-50%)",
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-caret-down"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M8 10.598L1.646 4.354a.5.5 0 0 1 .708-.708L8 9.182l5.646-5.536a.5.5 0 1 1 .708.708L8 10.598z" />
                                        </svg>
                                      </span>
                                    </div>
                                  </td>

                                  <td key={`row${val}`}>
                                    <span
                                      for={`row${val}`}
                                      className="form-control tshirt-variant-dataa"
                                      style={{
                                        borderRadius: "52px",
                                        border: "none",
                                        color: "#000",
                                        backgroundColor: "#fff0",
                                      }}
                                    //   onClick={(ev) => removeAddedRow(ev, val)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ marginRight: "5px" }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                    {/* <div
                      className={`col-3 side-card ${
                        !excelFileType || !setFileStatus ? "hidden" : ""
                      }`}
                    >
                      <div className="card">
                        <div className="card-inner">
                          <h6 className="file-details">Uploaded File</h6>
                          <p>FILE NAME</p>
                          <h6 className="file-details">
                            {filedata != null ? filedata["name"] : ""}
                          </h6>
                          <p>FILE SIZE</p>
                          <h6 className="file-details">
                            {filedata != null
                              ? (filedata["size"] / 1024).toFixed(2) + " KB"
                              : ""}
                          </h6>
                          <div className="mx-auto mt-3 text-center">
                            <Button
                              className="rmfile"
                              style={{
                                backgroundColor: "#050505",
                                borderRadius: "52px",
                                border: "none",
                              }}
                              onClick={() => setFileData(null)}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{ marginRight: "5px" }}
                              />
                              Remove file
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <p>TOTAL</p>
                        <h6>{variationcount} Variations</h6>
                        <p>RENDERING TIME</p>

                        {renderingTime && (
                          <h6> {renderingTime.toFixed(2)} seconds</h6>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React, { useState } from "react";
import { config } from "../utils/config";
import { useNavigate } from "react-router-dom";
import { doRequest, plansId, submitUserPlans } from "../utils/helper";

export default function PlansList({ yearly, plansList }) {
  const initialUserPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS);
  const [errmsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(null);
  const navigate = useNavigate();
  console.log(
    "initialUserPlanDetails",
    JSON.parse(initialUserPlanDetails)?.plan_id
  );
  let user =
    localStorage.getItem(config.USER_LOGIN_DETAILS) &&
    JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));

  const submitUserPlan = async (planid) => {
    console.log("insidesubmituserplan", planid);
    try {
      let response = await submitUserPlans(planid, user);
      if (response && +response?.resultFlag === 1) {
        localStorage.removeItem("users_email");
        console.log("response of update is", response);
        let details = response?.response?.user_data;
        let userPlan = response?.response?.userPlan;
        let variant = response?.response?.variants;
        if (variant) {
          localStorage.setItem(config.VARIANT_COUNT, JSON.stringify(variant));
        }
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(details)
        );
        localStorage.setItem(
          config.USER_PLAN_DETAILS,
          JSON.stringify(userPlan)
        );
        if (initialUserPlanDetails) {
          navigate("/profile?plan=" + planid);
        } else {
          navigate("/profile?plan=" + planid + "&ftime=1");
        }
        // console.log("userplan", userPlan, "userdetails", details);

        // if (initialUserPlanDetails) {
        //   navigate("/Home");
        // } else {
        //   navigate("/profile?plan=" + planid + "&ftime=1");
        // }
      } else {
        setErrorMsg(
          response?.message ? response?.message : "Something went wrong"
        );
        setShowError(true);
      }
    } catch (error) {
      setErrorMsg(error?.message ? error?.message : "Something went wrong");
      setShowError(true);
    }
  };

  const planClickHandler = async (id) => {
    console.log("id is ", id);
    const loginUser = localStorage.getItem(config.USER_LOGIN_DETAILS)
      ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
      : null;
    if (!loginUser) {
      navigate("/Login?plan=" + id);
    } else {
      // if (id == "1" || id == "2") {
      //   let data = {
      //     user_id: loginUser?.id,
      //     plan_id: id,
      //   };
      //   try {
      //     let response = await doRequest(data, "POST", "plan/update");
      //     console.log("response is", response);
      //     if (response && +response?.resultFlag === 1) {
      //       let details = response?.response?.user_data;

      //       navigate("/Design");
      //     } else {
      //       setErrMsg(
      //         response?.message ? response?.message : "Something went wrong"
      //       );
      //       setShowError(true);
      //     }
      //   } catch (error) {
      //     setErrMsg(error?.message ? error?.message : "Something went wrong");
      //     setShowError(true);
      //   }
      // } else {
      if (id == "1" || id == "2") {
        await submitUserPlan(id);
      } else {
        navigate("/payment?plan=" + id);
      }
      // }
    }
  };
  return (
    <>
      <div className="row row_sub_flex">
        {plansList?.length > 0 &&
          plansList?.map((item) => {
            let btnText =
              item?.type === "Free"
                ? "Start Free Trial!"
                : item?.type === "PPV"
                ? "Get Started"
                : "Subscribe Now";
            let btnClass = item?.type === "Unlimited" ? "subbtn_bg" : "";
            let firstLine = "";
            if (item?.id == "1") {
              firstLine = `<div class="subpoint_list">
              <ul>
                <li>
                  <img src="/assets/images/check.svg" alt="" />
                </li>
                <li>
                  <p>Full access to design tools and features.</p>
                </li>
              </ul>
        
              <ul>
                <li>
                  <img src="/assets/images/warning.svg" alt="" />
                </li>
                <li>
                  <p>Limited to 20 variations during the trial period.</p>
                </li>
              </ul>
        
              <ul>
                <li>
                  <img src="/assets/images/warning.svg" alt="" />
                </li>
                <li>
                  <p>Experience the software for 2 days.</p>
                </li>
              </ul>
            </div>`;
            } else if (item?.id == "2" || item?.id == "5") {
              firstLine = ` <div class="subpoint_list">
              <ul>
                <li>
                  <img src="/assets/images/check.svg" alt="" />
                </li>
                <li>
                  <p>Full access to design tools and features.</p>
                </li>
              </ul>
        
              <ul>
                <li>
                  <img src="/assets/images/warning.svg" alt="" />
                </li>
                <li>
                  <p>Pay only for the actual number of Variations created.</p>
                </li>
              </ul>
        
              <ul>
                <li>
                  <img src="/assets/images/warning.svg" alt="" />
                </li>
                <li>
                  <p>Pay as you use. </p>
                </li>
              </ul>
            </div>`;
            } else {
              firstLine = `<div class="subpoint_list">
              <ul>
                <li>
                  <img src="/assets/images/check.svg" alt="" />
                </li>
                <li>
                  <p>Full access to design tools and features.</p>
                </li>
              </ul>
        
              <ul>
                <li>
                  <img src="/assets/images/check.svg" alt="" />
                </li>
                <li>
                  <p>Unlimited Creations can be created For single user</p>
                </li>
              </ul>
        
              <ul>
                <li>
                  <img src="/assets/images/check.svg" alt="" />
                </li>
                <li>
                  <p>
                    Experience the software with no commitment for 28 days.
                  </p>
                </li>
              </ul>
            </div>`;
            }
            if (yearly && item?.id != "6") {
              console.log("inside yearly condition");
              return (
                <div className="col-xl-4 col-sm-5">
                  <div className="sub_pos">
                    <img
                      src={`${config.s3BaseUrl}/images/gradcard.png`}
                      className="card_activegrad"
                      alt=""
                    />
                    <div className="sub_card">
                      <div className="sub_trail_flex">
                        <div className="trail_head">
                          <h4>{item?.name}</h4>
                          <h6>{item?.title}</h6>
                        </div>

                        <div className="trail_price">
                          <h3>
                            <sup>₹</sup>
                            {item?.price}{" "}
                            <sub>
                              /{item?.days}{" "}
                              {item?.id == 2
                                ? "per Variation Created"
                                : item?.id == 5
                                ? "per variation"
                                : "Days"}
                            </sub>
                          </h3>
                          {initialUserPlanDetails ? (
                            //check if current id is same as userplan id
                            item?.id ==
                            JSON.parse(initialUserPlanDetails)?.plan_id ? (
                              JSON.parse(
                                localStorage.getItem(config.USER_LOGIN_DETAILS)
                              )?.plan_status !== "active" ? (
                                <button
                                  className="btn btn-secondary"
                                  disabled
                                  // className={btnClass}
                                  // onClick={() => planClickHandler(item?.id)}
                                >
                                  {" "}
                                  Expired
                                </button>
                              ) : JSON.parse(
                                  localStorage.getItem(config.USER_PLAN_DETAILS)
                                )?.variant_count > 19 ? (
                                <button
                                  className="btn btn-secondary"
                                  disabled
                                  // className={btnClass}
                                  // onClick={() => planClickHandler(item?.id)}
                                >
                                  {" "}
                                  Expired
                                </button>
                              ) : (
                                <button
                                  disabled
                                  className="btn btn-secondary"
                                  // className={btnClass}

                                  // onClick={() => planClickHandler(item?.id)}
                                >
                                  {" "}
                                  Current Plan
                                </button>
                              )
                            ) : //check if userplan id is greater than 1 in that case 1 should be expired
                            JSON.parse(initialUserPlanDetails)?.plan_id > "1" &&
                              item?.id == "1" ? (
                              <button
                                disabled
                                className="btn btn-secondary"
                                // className={btnClass}
                                // onClick={() => planClickHandler(item?.id)}
                              >
                                {" "}
                                Expired
                              </button>
                            ) : (
                              <button
                                className={btnClass}
                                onClick={() => planClickHandler(item?.id)}
                              >
                                {" "}
                                {btnText}
                              </button>
                            )
                          ) : (
                            <button
                              className={btnClass}
                              onClick={() => planClickHandler(item?.id)}
                            >
                              {" "}
                              {btnText}
                            </button>
                          )}
                        </div>
                      </div>

                      <div
                        dangerouslySetInnerHTML={{ __html: firstLine }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            } else if (!yearly && item?.id != "7") {
              console.log("item.datays", item.days);
              return (
                <div className="col-xl-4 col-sm-5">
                  <div className="sub_pos">
                    <img
                      src={`${config.s3BaseUrl}/images/gradcard.png`}
                      className="card_activegrad"
                      alt=""
                    />
                    <div className="sub_card">
                      <div className="sub_trail_flex">
                        <div className="trail_head">
                          <h4>{item?.name}</h4>
                          <h6>{item?.title}</h6>
                        </div>

                        <div className="trail_price">
                          <h3>
                            <sup>₹</sup>
                            {item?.price}{" "}
                            <sub>
                              /{item?.days}{" "}
                              {item?.id == 2
                                ? "per Variation Created"
                                : item?.id == 5
                                ? "per variation"
                                : "Days"}
                            </sub>
                          </h3>
                          {initialUserPlanDetails ? (
                            //check if current id is same as userplan id
                            item?.id ==
                            JSON.parse(initialUserPlanDetails)?.plan_id ? (
                              JSON.parse(
                                localStorage.getItem(config.USER_LOGIN_DETAILS)
                              )?.plan_status !== "active" ? (
                                <button
                                  className="btn btn-secondary"
                                  disabled
                                  // className={btnClass}
                                  // onClick={() => planClickHandler(item?.id)}
                                >
                                  {" "}
                                  Expired
                                </button>
                              ) : JSON.parse(
                                  localStorage.getItem(config.USER_PLAN_DETAILS)
                                )?.variant_count > 19 ? (
                                <button
                                  className="btn btn-secondary"
                                  disabled
                                  // className={btnClass}
                                  // onClick={() => planClickHandler(item?.id)}
                                >
                                  {" "}
                                  Expired
                                </button>
                              ) : (
                                <button
                                  className="btn btn-secondary"
                                  disabled
                                  // className={btnClass}
                                  // onClick={() => planClickHandler(item?.id)}
                                >
                                  {" "}
                                  Current Plan
                                </button>
                              )
                            ) : //check if userplan id is greater than 1 in that case 1 should be expired
                            JSON.parse(initialUserPlanDetails)?.plan_id > "1" &&
                              item?.id == "1" ? (
                              <button
                                disabled
                                className="btn btn-secondary"
                                // className={btnClass}
                                // onClick={() => planClickHandler(item?.id)}
                              >
                                {" "}
                                Expired
                              </button>
                            ) : (
                              <button
                                className={btnClass}
                                onClick={() => planClickHandler(item?.id)}
                              >
                                {" "}
                                {btnText}
                              </button>
                            )
                          ) : (
                            <button
                              className={btnClass}
                              onClick={() => planClickHandler(item?.id)}
                            >
                              {" "}
                              {btnText}
                            </button>
                          )}
                        </div>
                      </div>

                      <div
                        dangerouslySetInnerHTML={{ __html: firstLine }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
      </div>
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import Sidebar from "../component/Sidebar";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import html2canvas from 'html2canvas';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Stage, Image as KonvaImage } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { useLocation, useNavigate } from 'react-router-dom';
import '../index.css';
import download from '../assets/img/file_download_black_24dp.svg'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import secureLocalStorage from 'react-secure-storage';
import verifytoken from '../env/verifytoken';
import { changeDpiDataUrl } from 'changedpi';
import Loader from '../Components/Loader';
import { dimensions } from '../utils/helper';


function Export() {
    const [loading, setLoading] = useState(false);
    const [downloadedIndex, setDownloadedIndex] = useState([])
    const navigate = useNavigate();
    var token = secureLocalStorage.getItem('Login')
    if (token == null) {
        navigate('/');
    }
    else {
        var tokenExpired = verifytoken();
        if (tokenExpired) {
            navigate('/')
        }
    }
    const [tshirtdetails, setTshirtDetails] = useState(JSON.parse(localStorage.getItem('tshirtDetails')));
    const { state } = useLocation();
    var selectedImage = state?.selectedImage;
    var [imagebg] = useImage(state?.selectedImage)
    const tshirtimg = useRef();
    const [dataURL, setDataURL] = useState([]);
    var backgroundImg = state?.selectedImage;
    const backgroundImage = backgroundImg; // Replace with your background image URL
    const canvasRef = useRef([]);
    const canvasRefName = useRef([]);
    const downladImage = useRef([]);
    var stagediv = useRef();
    var inputSearch = useRef();
    var ResizeImagediv = useRef();
    const ImgDivRef = useRef([]);

    var tshirtchangedetails = JSON.parse(localStorage.getItem('tshirtchangedetails'));


    const drawTextOnCanvas = (text, number, canvas, index, size) => {
        if (canvas) {

            var playernamedetails = JSON.parse(localStorage.getItem('playernamedetails'));
            var playernumberdetails = JSON.parse(localStorage.getItem('playernumberdetails'));

            var changedData = []

            if (tshirtchangedetails != null) {
                changedData = tshirtchangedetails.filter((item) => item.indexSr == index);

                if (changedData.length != 0) {
                    playernamedetails = changedData[0].playernamedetails;
                    playernumberdetails = changedData[0].playernumberdetails;
                }
            }

            const stage = canvas;
            // console.log(stage);
            //  stage.children = [];
            const layer = new Konva.Layer();
            //  console.log(layer);
            // var image = new Konva.Image({
            //     x:0,
            //     y:0,
            //     image:selectedImage,
            //     width:220,
            //     height:315
            // })
            // layer.add(image);

            var text1 = new Konva.Text({
                x: (playernamedetails.NametextPositionPer.x * canvas.attrs.width) / 100,
                y: (playernamedetails.NametextPositionPer.y * canvas.attrs.height) / 100,
                text: text,
                fontSize: ((playernamedetails.NametextSizePer * canvas.attrs.width) / 100),
                fontFamily: playernamedetails.NamefontFamily,
                fill: playernamedetails.NametextColor,
                stroke: playernamedetails.NameoutlineColor,
                strokeWidth: (playernamedetails.NametextBorderPer * canvas.attrs.width) / 100,
                align: 'center',
                width: (playernamedetails.NameWidthPer * canvas.attrs.width) / 100,
                rotation: playernamedetails.NamerotationAngle,
                scaleX: playernamedetails.NameScale.x,
                scaleY: playernamedetails.NameScale.y
            });

            layer.add(text1);

            var text2 = new Konva.Text({
                x: (playernumberdetails.textPositionPer.x * canvas.attrs.width) / 100,
                y: (playernumberdetails.textPositionPer.y * canvas.attrs.height) / 100,
                text: number,
                fontSize: ((playernumberdetails.textSizePer * canvas.attrs.width) / 100),
                fontFamily: playernumberdetails.fontFamily,
                fill: playernumberdetails.textColor,
                stroke: playernumberdetails.outlineColor,
                strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
                align: 'center',
                width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
                rotation: playernumberdetails.rotationAngles,
                scaleX: playernumberdetails.NoScale.x,
                scaleY: playernumberdetails.NoScale.y
            });

            layer.add(text2);


            var tshirtSizeWatermark = new Konva.Text({
                x: 0,
                y: 0,
                text: size,
                fontSize: 20,
                fontFamily: playernumberdetails.fontFamily,
                fill: 'black',
                stroke: playernumberdetails.outlineColor,
                // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
                // align: 'center',
                // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
                // rotation: playernumberdetails.rotationAngles,
                // scaleX: playernumberdetails.NoScale.x,
                // scaleY: playernumberdetails.NoScale.y
            });
            var textWidth = tshirtSizeWatermark.textWidth;
            var textHeight = tshirtSizeWatermark.textHeight;
            var backgroundRect = new Konva.Rect({
                x: tshirtSizeWatermark.x(),
                y: tshirtSizeWatermark.y(),
                width: textWidth,
                height: textHeight,
                fill: 'white' // Set background color to white
            });

            layer.add(backgroundRect);
            layer.add(tshirtSizeWatermark);



            stage.add(layer);
        }
    };

    const convertBase64ToImg = async (thumburl, filename) => {
        try {
            if (thumburl?.startsWith("data:image/")) {
                function base64ToBlobb(base64) {
                    var binaryString = window.atob(base64.split(',')[1]);
                    var arrayBuffer = new ArrayBuffer(binaryString?.length);
                    var byteArray = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < binaryString?.length; i++) {
                        byteArray[i] = binaryString.charCodeAt(i);
                    }
                    return new Blob([arrayBuffer], { type: 'image/png' }); // Adjust the type accordingly
                }
                var toblob = base64ToBlobb(thumburl);
                const imageUrl = URL.createObjectURL(toblob);
                const downloadLink = document.createElement("a");
                downloadLink.href = imageUrl;
                // downloadLink.download = "image.jpg";
                downloadLink.download = filename;

                // Trigger the click event of the anchor element
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

            }
        }

        catch (error) {
            console.log(error)
        }
    }
    const convertBase64ToZip = async (base64String) => {
        try {
            const zip = new JSZip();
            const folder = zip.folder('tshirt_variation');  // create a folder in the ZIP file
            // Convert Base64 string to Blob
            for (let val of base64String) {
                // console.log(val);
                const byteCharacters = atob(val.baseString.split(',')[1]);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: 'image/jpeg' });

                // Add the Blob to the ZIP folder
                await folder.file(val.fileName, blob);
            }


            // Generate the ZIP file
            const zipBlob = await zip.generateAsync({ type: 'blob' });

            // // Save the ZIP file
            saveAs(zipBlob, 'tshirt_variation.zip');

        } catch (error) {
            console.error('Error converting Base64 to ZIP:', error);
        }
    };

    useEffect(() => {
        if (tshirtdetails) {
            tshirtdetails.forEach((val, index) => {
                drawTextOnCanvas(val.name, val.number, canvasRef.current[val.indexSr], val.indexSr, val.size);
            });
        }
    }, [tshirtdetails]);
    const downloadImage = async () => {
        for (let i in tshirtdetails) {
            handleDownload(canvasRef.current[tshirtdetails[i].indexSr], `${tshirtdetails[i].size}_${tshirtdetails[i].name}_${tshirtdetails[i].number}.jpeg`, tshirtdetails[i].size, tshirtdetails[i].indexSr, i, 'zip');
        }
        setTimeout(() => {
            // console.log(listObj_data);
            convertBase64ToZip(listObj_data);
            setLoading(false)
        }, 3000)
    }

    const [bgimage] = useImage(backgroundImg);
    const drawResizeStage = (indexSr, index, width, height, size) => {
        // console.log(index, width, height);
        var playernamedetails = JSON.parse(localStorage.getItem('playernamedetails'));
        var playernumberdetails = JSON.parse(localStorage.getItem('playernumberdetails'));
        var changedData = []
        if (tshirtchangedetails != null) {
            changedData = tshirtchangedetails.filter((item) => item.indexSr == indexSr);
            console.log(changedData);
            if (changedData.length != 0) {
                playernamedetails = changedData[0].playernamedetails;
                playernumberdetails = changedData[0].playernumberdetails;

                console.log(playernamedetails, playernumberdetails);
            }
        }
        var convertedwidth = (width);
        var convertedheight = (height);
        console.log('(playernumberdetails.textSizePer * convertedwidth) / 100', (playernumberdetails.textSizePer * convertedwidth) / 100)
        stagediv.current.attrs.width = convertedwidth;
        stagediv.current.attrs.height = convertedheight;
        // console.log(stagediv);
        var stage = stagediv.current
        var layer = new Konva.Layer();
        var image = new Konva.Image({
            x: 0,
            y: 0,
            image: bgimage,
            width: convertedwidth,
            height: convertedheight
        })
        layer.add(image);

        var text1 = new Konva.Text({
            x: (playernamedetails.NametextPositionPer.x * convertedwidth) / 100,
            y: (playernamedetails.NametextPositionPer.y * convertedheight) / 100,
            text: tshirtdetails[index].name,
            fontSize: ((playernamedetails.NametextSizePer * convertedwidth) / 100),
            fontFamily: playernamedetails.NamefontFamily,
            fill: playernamedetails.NametextColor,
            stroke: playernamedetails.NameoutlineColor,
            strokeWidth: (playernamedetails.NametextBorderPer * convertedwidth) / 100,
            align: 'center',
            width: (playernamedetails.NameWidthPer * convertedwidth) / 100,
            rotation: playernamedetails.NamerotationAngle,
            scaleX: playernamedetails.NameScale.x,
            scaleY: playernamedetails.NameScale.y
        })

        layer.add(text1);
        var text2 = new Konva.Text({
            x: (playernumberdetails.textPositionPer.x * convertedwidth) / 100,
            y: (playernumberdetails.textPositionPer.y * convertedheight) / 100,
            text: tshirtdetails[index].number,
            fontSize: ((playernumberdetails.textSizePer * convertedwidth) / 100),
            fontFamily: playernumberdetails.fontFamily,
            fill: playernumberdetails.textColor,
            stroke: playernumberdetails.outlineColor,
            strokeWidth: (playernumberdetails.NotextBorderPer * convertedwidth) / 100,
            align: 'center',
            width: (playernumberdetails.NoWidthPer * convertedwidth) / 100,
            rotation: playernumberdetails.rotationAngles,
            scaleX: playernumberdetails.NoScale.x,
            scaleY: playernumberdetails.NoScale.y
        })
        layer.add(text2);
        var tshirtSizeWatermark = new Konva.Text({
            x: 0,
            y: 0,
            text: size,
            fontSize: (((playernumberdetails.textSizePer * convertedwidth) / 100) / 3) / 2,
            fontFamily: playernumberdetails.fontFamily,
            fill: 'black',
            stroke: playernumberdetails.outlineColor,
            // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
            // align: 'center',
            // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
            // rotation: playernumberdetails.rotationAngles,
            // scaleX: playernumberdetails.NoScale.x,
            // scaleY: playernumberdetails.NoScale.y
        });
        var textWidth = tshirtSizeWatermark.textWidth;
        var textHeight = tshirtSizeWatermark.textHeight;
        var backgroundRect = new Konva.Rect({
            x: tshirtSizeWatermark.x(),
            y: tshirtSizeWatermark.y(),
            width: textWidth,
            height: textHeight,
            fill: 'white' // Set background color to white
        });

        layer.add(backgroundRect);
        layer.add(tshirtSizeWatermark);
        stage.add(layer);
        return (stagediv.current);
    }



    var listObj_data = []
    const handleDownload = async (canvas, fileName, size, indexSr, index, file_type) => {
        const tshirtSize = size;
        console.log('size', size);
        setLoading(true);
        var stageRsize = drawResizeStage(indexSr, index, dimensions[tshirtSize].width, dimensions[tshirtSize].height, tshirtSize);
        var stageDataURL = stageRsize.toDataURL();

        const resizeImage = async (stageDataURL, maxWidth, maxHeight, fileName, file_type) => {
            let img = new Image()
            img.src = stageDataURL
            img.onload = async () => {
                let canvas = document.createElement('canvas')
                const MAX_WIDTH = maxWidth
                const MAX_HEIGHT = maxHeight
                let width = img.width
                let height = img.height
                //GIRISH CODE
                const aspectRatio = img.width / img.height;
                const targetWidth = 1440;
                const targetHeight = targetWidth / aspectRatio;
                canvas.width = width;
                canvas.height = height;


                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height); //currently downloading image
                var dataUrl = canvas.toDataURL('image/jpeg', 0.92);
                var daurl150dpi = changeDpiDataUrl(dataUrl, 300);
                if (file_type == 'single') {

                    if (!downloadedIndex.includes(index)) downloadedIndex.push(index)
                    await convertBase64ToImg(daurl150dpi, fileName)
                    setLoading(false);
                }
                else {
                    var canvasDataURL = canvas.toDataURL('image/jpeg', 1.0);
                    var daurl150dpii = changeDpiDataUrl(canvasDataURL, 300);
                    var new_obj = { fileName: fileName, baseString: daurl150dpii }
                    listObj_data = [...listObj_data, new_obj];
                }
            }
        }

        await resizeImage(stageDataURL, dimensions[tshirtSize].width, dimensions[tshirtSize].height, fileName, file_type);
        stagediv.current.children = [];
    };

    const handelInputsearch = () => {
        if (inputSearch.current.value != '') {
            var excelData = JSON.parse(localStorage.getItem('tshirtDetails'));
            var searchvalue = inputSearch.current.value;
            var filterData = excelData.filter((item, index) => (item.name.toLowerCase().includes(searchvalue.toLowerCase()) || item.number.toString().includes(inputSearch.current.value)))
            for (let val of excelData) {
                if (filterData.length > 0) {
                    var searchdata = filterData.filter(item => item.indexSr == val.indexSr)
                    if (searchdata.length == 0) {
                        ImgDivRef.current[val.indexSr].className = 'col-xl-3 col-3 hideimage'
                    }
                    else {
                        ImgDivRef.current[val.indexSr].className = 'col-xl-3 col-3'
                    }
                }
                else {
                    ImgDivRef.current[val.indexSr].className = 'col-xl-3 col-3 hideimage'
                }
            }
        }
        else {
            var filterData = JSON.parse(localStorage.getItem('tshirtDetails'))
            for (let val of filterData) {
                ImgDivRef.current[val.indexSr].className = 'col-xl-3 col-3'
            }
        }

    }


    return (
        <>
            {/* {loading && <Loader />} */}
            <div id="main-container" className="container-fluid main">
                <Sidebar></Sidebar>
                <section className="home" style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
                    <div className="row mx-2">
                        <div className="col-12">
                            <div className="row tab mt-3">
                                <ul className="d-flex col-6 custom-tabs">
                                    <li className="" onClick={() => { navigate('/Design', { state: { selectedImage: selectedImage, side: state.side } }) }}>Design</li>
                                    <li className="mx-2" onClick={() => { navigate('/Variation', { state: { selectedImage: selectedImage, side: state.side } }) }}>Variation</li>
                                    <li className="mx-2 active" onClick={() => { navigate('/Export', { state: { selectedImage: selectedImage, side: state.side } }) }}>Export</li>
                                </ul>

                            </div>

                            <div className="row mt-5">
                                <div className="col-12 text-center">
                                    <h4>Variation Completed - Unleash the Power of Personalisation</h4>
                                    <p>With a simple click of the "Create" button, watch as our software auto-generates<br /> a myriad of variations, effortlessly incorporating the selected fonts and Player
                                        <br /> details, Experience the power of automation and unleash your creativity.</p>
                                    {/* <Button className="px-3 py-1" style={{ borderRadius: '30px' }} onClick={downloadImage}><img src={download} alt="download" style={{ width: '20px' }} />
                                        Download All </Button> */}
                                </div>
                                <div className="container mt-5">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10" style={{ backgroundColor: '#F9FAFD', paddingLeft: "30px", paddingTop: "10px" }}>
                                            <div className='row mt-4'>
                                                <h5 style={{ width: '30%' }}>{tshirtdetails && tshirtdetails.length} Variations Created</h5>
                                                <div className='' style={{ width: '40%', alignItems: 'right' }}>
                                                </div>
                                                <div className='' style={{ width: '30%', alignItems: 'right', display: 'flex' }}>
                                                    <div style={{ width: '12%', padding: '10px 5px 0 5px', backgroundColor: '#EAEEF8', borderRadius: "20px 0 0 20px" }}><FontAwesomeIcon icon={faMagnifyingGlass} className='mx-2' /></div>
                                                    <input ref={inputSearch} type='text' placeholder='Search all files' className='export-variation-search' onChange={() => { handelInputsearch(); }} />
                                                </div>
                                            </div>
                                            <div className='my-5 col-12 justify-content-center ' >
                                                <div className='row'>
                                                    {tshirtdetails && tshirtdetails.map((val, index) => (

                                                        <div key={index} className='col-xl-3 col-md-3 col-lg-3 col-3' style={{ position: 'relative', paddingLeft: '10px', marginBottom: '25px' }}
                                                            ref={(ref) => {
                                                                if (ref) ImgDivRef.current[val.indexSr] = ref;
                                                            }}>
                                                            <div className='tshirtimg1' ref={(ref) => {
                                                                if (ref) downladImage.current[val.indexSr] = ref;
                                                            }}>
                                                                <svg width="220" height="315" xmlns="http://www.w3.org/2000/svg" style={{ borderRadius: "10px" }}>
                                                                    {/* <rect x="40" y="0" width="160" height="315" fill="white" /> */}
                                                                    {selectedImage && <image href={selectedImage} width="220" height="315" preserveAspectRatio="xMidYMid slice" />}
                                                                    {/* <circle cx="110" cy="-55" r="73" fill="white" /> */}
                                                                </svg>

                                                                <Stage ref={(ref) => {
                                                                    if (ref) canvasRef.current[val.indexSr] = ref;
                                                                }}
                                                                    width={220}
                                                                    height={315}
                                                                    style={{ position: 'absolute', top: 0, left: 10, border: '0px solid #000', borderRadius: "10px" }}></Stage>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <button className='px-3 py-1 me-3' style={{ borderRadius: '30px', backgroundColor: '#9fd3f7', border: '0' }} onClick={() => { navigate('/EditDesign', { state: { indexSr: val.indexSr, selectedImage: selectedImage, side: state.side } }) }}> Edit</button>
                                                                <button className='px-3 py-1' style={{ borderRadius: '30px', backgroundColor: downloadedIndex.includes(index) ? 'rgb(159 211 247 / 36%)' : '#9fd3f7', border: '0' }} onClick={() => handleDownload(downladImage.current[val.indexSr], `${val.size}${dimensions[val.size]?.size}_${val.name}_${val.number}.jpeg`, val.size, val.indexSr, index, 'single')}> Download </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="stagediv" className='container' style={{ visibility: 'hidden', display: 'none' }} ref={ResizeImagediv}>
                        <Stage ref={stagediv}></Stage>
                    </div>

                </section>
            </div>
        </>
    );
}

export default Export;
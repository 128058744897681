import React, { useState } from "react";
import { Table, Button } from "reactstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";

export default function FeedbackTab() {
    return (
        <>
            <div className="search_header">
                <ul className="search_list">
                    <li>
                        <div className="search_div">
                            <input type="search" className="searc_issue" placeholder="Search users…" />
                            <img src={'/assets/images/searchheader.svg'} alt="" />
                        </div>
                    </li>
                </ul>
            </div>


            <div className="table-responsive table_user_mar">
                <Table hover striped className="table_cust align-middle">
                    <thead className="thead_bg">
                        <th className="userth" style={{ width: '20%' }}>User</th>
                        <th className="userth" style={{ width: '20%' }}>Comments</th>
                        <th className="userth">Source</th>
                        <th className="userth">Date</th>
                        <th className="userth">Type</th>
                        <th className="userth">Status</th>
                        <th className="userth">ACTIONS</th>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <ul className="dasboar_table_list">
                                    <li>
                                        <img src={'/assets/images/img-8.jpg'} alt="" />
                                    </li>
                                    <li>
                                        <h5>Alexa</h5>
                                    </li>
                                </ul>
                            </td>

                            <td>
                                <p className="faq_para">
                                    <ReactReadMoreReadLess charLimit={100}>
                                        Comment will mention here
                                    </ReactReadMoreReadLess>
                                </p>
                            </td>

                            <td>Home</td>

                            <td>29/07/2023</td>

                            <td>
                                <p className="issue_dis">Issue</p>
                            </td>


                            <td>
                                <h6 className="enable_feed">Enable</h6>
                            </td>


                            <td>
                                <div className="viewProf_flex">
                                    <Button tag="a" href="#"
                                        className="healcheck_btn"
                                    >
                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                        View
                                    </Button>


                                    <Button tag="a" href="#"
                                        className="send_fed_btn"
                                    >
                                        Send Feedback
                                    </Button>

                                    <Button tag="a" href="#"
                                        className="del_user"
                                    >

                                        Disable
                                    </Button>

                                </div>
                            </td>
                        </tr>




                        <tr>
                            <td>
                                <ul className="dasboar_table_list">
                                    <li>
                                        <img src={'/assets/images/img-8.jpg'} alt="" />
                                    </li>
                                    <li>
                                        <h5>Alexa</h5>
                                    </li>
                                </ul>
                            </td>

                            <td>
                                <p className="faq_para">
                                    <ReactReadMoreReadLess charLimit={100}>
                                        Comment will mention here
                                    </ReactReadMoreReadLess>
                                </p>
                            </td>

                            <td>Home</td>

                            <td>29/07/2023</td>

                            <td>
                                <p className="issue_dis">Issue</p>
                            </td>


                            <td>
                                <h6 className="resolved_feed">Resolved</h6>
                            </td>


                            <td>
                                <div className="viewProf_flex">
                                    <Button tag="a" href="#"
                                        className="healcheck_btn"
                                    >
                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                        View
                                    </Button>


                                    <Button tag="a" href="#"
                                        className="send_fed_btn"
                                    >
                                        Send Feedback
                                    </Button>

                                    <Button tag="a" href="#"
                                        className="del_user"
                                    >

                                        Disable
                                    </Button>

                                </div>
                            </td>
                        </tr>


                    
                    </tbody>
                </Table>
            </div>
        </>
    )
}
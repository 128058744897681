import React, { useEffect, useRef, useState } from "react";
import { Stage, Image as KonvaImage } from "react-konva";
import Konva from "konva";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import useImage from "use-image";
import { dimensions, dimensionsKonva, doRequest } from "../utils/helper";
import { changeDpiDataUrl } from "changedpi";
import JSZip from "jszip";
import Button from "react-bootstrap/Button";
import download from "../assets/img/file_download_black_24dp.svg";
import { config } from "../utils/config";
import TryBeforeYouBuy from "./TryBeforeYouBuy";
import Loader from "../Components/Loader";
import $ from "jquery";
import PercentLoader from "./PercentLoader";
import { Form } from "reactstrap";

export default function ExportNew() {
  const disableBtn = localStorage.getItem("payasuusedisablebtn");
  const planDetails = localStorage.getItem(config.USER_PLAN_DETAILS)
    ? JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
    : null;
  const variationCounts = planDetails?.variant_count;
  var tshirtchangedetails = JSON.parse(
    localStorage.getItem("tshirtchangedetails")
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  var selectedImage = state?.selectedImage;
  const [tshirtdetails, setTshirtDetails] = useState(
    JSON.parse(localStorage.getItem("tshirtDetails"))
  );
  const [downloadedIndex, setDownloadedIndex] = useState([]);
  const [planIsPayAsUse, setPlanIsPayAsUse] = useState(
    planDetails && planDetails?.plan_id == "2" ? true : false
  );
  const [disableAllButton, setDisableAllButton] = useState(
    planDetails?.plan_id == "2" && (disableBtn || variationCounts > 0)
      ? true
      : false
  );
  const [firstImageDownloaded, setFirstImageDownloaded] = useState(
    planDetails?.plan_id == "2" && (disableBtn || variationCounts > 0)
      ? true
      : false
  );
  const [processing, setProcessing] = useState(false);
  const [percentDownloaded, setPercentDownloaded] = useState(0);

  const [exportImageType, setExportImageType] = useState("jpg");
  const [exportImageColor, setExportImageColor] = useState("rgb");
  const [exportImageDpi, setExportImageDpi] = useState("300");

  var inputSearch = useRef();
  const ImgDivRef = useRef([]);
  const downladImage = useRef([]);
  const canvasRef = useRef([]);
  var ResizeImagediv = useRef();
  var stagediv = useRef();

  const [bgimage] = useImage(selectedImage);
  const drawResizeStage = (indexSr, index, width, height, size) => {
    setProcessing(true);
    var playernamedetails = JSON.parse(
      localStorage.getItem("playernamedetails")
    );
    var playernumberdetails = JSON.parse(
      localStorage.getItem("playernumberdetails")
    );
    var changedData = [];
    if (tshirtchangedetails != null) {
      changedData = tshirtchangedetails.filter(
        (item) => item.indexSr == indexSr
      );
      if (changedData.length != 0) {
        playernamedetails = changedData[0].playernamedetails;
        playernumberdetails = changedData[0].playernumberdetails;
      }
    }
    var convertedwidth = width;
    var convertedheight = height;
    stagediv.current.attrs.width = convertedwidth;
    stagediv.current.attrs.height = convertedheight;
    var stage = stagediv.current;
    var layer = new Konva.Layer();
    var image = new Konva.Image({
      x: 0,
      y: 0,
      image: bgimage,
      width: dimensionsKonva[size]?.width,
      height: dimensionsKonva[size]?.height,
    });
    layer.add(image);

    var text1 = new Konva.Text({
      x: (playernamedetails.NametextPositionPer.x * convertedwidth) / 100,
      y: (playernamedetails.NametextPositionPer.y * convertedheight) / 100,
      text: tshirtdetails[index].name,
      fontSize: (playernamedetails.NametextSizePer * convertedwidth) / 100,
      fontFamily: playernamedetails.NamefontFamily,
      fill: playernamedetails.NametextColor,
      stroke: playernamedetails.NameoutlineColor,
      strokeWidth: (playernamedetails.NametextBorderPer * convertedwidth) / 100,
      align: "center",
      width: (playernamedetails.NameWidthPer * convertedwidth) / 100,
      rotation: playernamedetails.NamerotationAngle,
      scaleX: playernamedetails.NameScale.x,
      scaleY: playernamedetails.NameScale.y,
    });

    layer.add(text1);
    var text2 = new Konva.Text({
      x: (playernumberdetails.textPositionPer.x * convertedwidth) / 100,
      y: (playernumberdetails.textPositionPer.y * convertedheight) / 100,
      text: tshirtdetails[index].number,
      fontSize: (playernumberdetails.textSizePer * convertedwidth) / 100,
      fontFamily: playernumberdetails.fontFamily,
      fill: playernumberdetails.textColor,
      stroke: playernumberdetails.outlineColor,
      strokeWidth: (playernumberdetails.NotextBorderPer * convertedwidth) / 100,
      align: "center",
      width: (playernumberdetails.NoWidthPer * convertedwidth) / 100,
      rotation: playernumberdetails.rotationAngles,
      scaleX: playernumberdetails.NoScale.x,
      scaleY: playernumberdetails.NoScale.y,
    });
    layer.add(text2);
    var tshirtSizeWatermark = new Konva.Text({
      x: 0,
      y: 0,
      text: size,
      fontSize:
        (playernumberdetails.textSizePer * convertedwidth) / 100 / 3 / 2,
      fontFamily: "IBM Plex Sans",
      fill: "black",
      stroke: playernumberdetails.outlineColor,
    });
    var textWidth = tshirtSizeWatermark.textWidth;
    var textHeight = tshirtSizeWatermark.textHeight;
    var backgroundRect = new Konva.Rect({
      x: tshirtSizeWatermark.x(),
      y: tshirtSizeWatermark.y(),
      width: textWidth,
      height: textHeight,
      fill: "white",
    });

    layer.add(backgroundRect);
    layer.add(tshirtSizeWatermark);
    stage.add(layer);
    return stagediv.current;
  };
  const resizeAndConvertImage = async (
    dataURL,
    maxWidth,
    maxHeight,
    fileName,
    dpi
  ) => {
    setProcessing(true);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = dataURL;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let newWidth = img.width;
        let newHeight = img.height;

        if (newWidth > maxWidth) {
          newHeight *= maxWidth / newWidth;
          newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
          newWidth *= maxHeight / newHeight;
          newHeight = maxHeight;
        }

        if (newWidth > 0 && newHeight > 0) {
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
        } else {
          alert("image can not be created");
          return;
        }

        const resizedDataURL = canvas.toDataURL("image/jpeg", dpi);
        var imageWith300DPi = changeDpiDataUrl(resizedDataURL, 300);

        resolve(imageWith300DPi);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const downloadImage = (dataURL, fileName) => {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = fileName;
    link.click();
  };

  const updateVariationCount = async () => {
    const planDetails =
      localStorage.getItem(config.USER_PLAN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
    if (!planDetails || !planDetails.plan_id) {
      alert("please choose a plan first");
      navigate("/plans");
      return;
    }
    let user = JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));
    let data = {
      id: user?.user_plan_id,
      count: "1",
    };
    try {
      let response = await doRequest(data, "POST", "plan/add-variant ");
      if (+response?.resultFlag === 1) {
        console.log("video count updated");
        let obj = { ...planDetails };
        obj["variant_count"] = obj["variant_count"] + 1;
        localStorage.setItem(config.USER_PLAN_DETAILS, JSON.stringify(obj));
      } else {
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error.message ? error?.message : "Something went wrong");
    }
  };

  const handleDownload = async (
    canvas,
    fileName,
    size,
    indexSr,
    index,
    file_type
  ) => {
    const planDetails =
      localStorage.getItem(config.USER_PLAN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
    if (planDetails && planDetails?.plan_id == "1") {
      let vCount = planDetails?.variant_count;
      const variantLeft = 20 - vCount;
      if (variantLeft <= 0) {
        alert("You free trial is expired, please upgrade your plan");
        navigate("/plans");
        return;
      }
    }

    $(".loader_bg").show();
    setProcessing(true);
    if (planIsPayAsUse) {
      localStorage.setItem("payasuusedisablebtn", "true");
      setFirstImageDownloaded(true);
      setDisableAllButton(true);
    }
    if (!size) {
      alert("please select atleast size of tshirt");
      return;
    }
    const tshirtSize = size;

    setProcessing(true);
    $(".loader_bg").show();
    var stageRsize = await drawResizeStage(
      indexSr,
      index,
      dimensions[tshirtSize]?.width,
      dimensions[tshirtSize]?.height,
      tshirtSize
    );
    var stageDataURL = await stageRsize.toDataURL();

    resizeAndConvertImage(
      stageDataURL,
      dimensions[tshirtSize]?.width,
      dimensions[tshirtSize]?.height,
      fileName,
      "300"
    )
      .then(async (resizedDataURL) => {
        downloadImage(resizedDataURL, fileName);
        await updateVariationCount();
        if (file_type != "all") {
          setProcessing(false);
          $(".loader_bg").hide();
        }
      })
      .catch((error) => {
        setProcessing(false);
        $(".loader_bg").hide();
        console.error("Error:", error);
      });
  };

  const handleDownloadAll = async () => {
    $(".loader_bg").show();
    try {
      const zip = new JSZip();
      const promises = tshirtdetails.map((val, index) => {
        return new Promise((resolve, reject) => {
          handleDownload(
            downladImage.current[val.indexSr],
            `${val.size}${dimensions[val.size]?.size}_${val.name}_${val.number}.jpeg`,
            val.size,
            val.indexSr,
            index,
            "all"
          );
          setTimeout(resolve, 5000); // Delay between each download
        });
      });

      await Promise.all(promises);

      for (let i = 0; i < tshirtdetails.length; i++) {
        const fileName = `${tshirtdetails[i].size}${dimensions[tshirtdetails[i].size]?.size}_${tshirtdetails[i].name}_${tshirtdetails[i].number}.jpeg`;
        const dataURL = downladImage.current[tshirtdetails[i].indexSr].toDataURL();
        const base64Data = dataURL.split(",")[1];
        zip.file(fileName, base64Data, { base64: true });
      }

      const zipContent = await zip.generateAsync({ type: "blob" });
      const downloadUrl = URL.createObjectURL(zipContent);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "all_variations.zip";
      link.click();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      $(".loader_bg").hide();
    }
  };

  const upgradePlanBtnClick = () => {
    navigate("/plans");
  };

  const handelInputsearch = () => {
    if (inputSearch.current.value != "") {
      var excelData = JSON.parse(localStorage.getItem("tshirtDetails"));
      var searchvalue = inputSearch.current.value;
      var filterData = excelData.filter(
        (item, index) =>
          item.name.toLowerCase().includes(searchvalue.toLowerCase()) ||
          item.number.toString().includes(inputSearch.current.value)
      );
      for (let val of excelData) {
        if (filterData.length > 0) {
          var searchdata = filterData.filter(
            (item) => item.indexSr == val.indexSr
          );
          if (searchdata.length == 0) {
            ImgDivRef.current[val.indexSr].className =
              "col-xl-3 col-3 hideimage";
          } else {
            ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3";
          }
        } else {
          ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3 hideimage";
        }
      }
    } else {
      var filterData = JSON.parse(localStorage.getItem("tshirtDetails"));
      for (let val of filterData) {
        ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3";
      }
    }
  };


  return (
    <>
      <div id="main-container" className="container-fluid main">
        <Sidebar></Sidebar>
        <section className="home">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-3 sidebarchange">
                <div className="custom-side">
                  <p className="side-title">Export</p>

                  <div className="img_up img_up_mar">
                    <h6>Jpg</h6>
                    <select
                      aria-label="Default select example"
                      className="custom-select text-end"
                      style={{ border: "none" }}
                    >
                      <option value={"jpg"}>JPG</option>
                      <option value={"png"}>PNG</option>
                    </select>
                  </div>

                  <div className="img_up img_up_mar">
                    <h6>DPI</h6>
                    <select
                      aria-label="Default select example"
                      className="custom-select text-end"
                      style={{ border: "none" }}
                    >
                      <option value={"150"}>150</option>
                      <option value={"300"}>300</option>
                    </select>
                  </div>

                  <button className="download_all" onClick={handleDownloadAll}>
                    <img
                      src={"/assets/images/downloadall.svg"}
                      className=""
                      alt=""
                    />{" "}
                    Download All
                  </button>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="row row_pad">
                  <ul className="d-flex col-6 custom-tabs">
                    <li
                      className=""
                      onClick={() => {
                        navigate("/Design", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Design
                    </li>
                    <li
                      className="mx-2"
                      onClick={() => {
                        navigate("/Variation", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Variation
                    </li>
                    <li
                      className="mx-2 active"
                      onClick={() => {
                        navigate("/export-new", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Export
                    </li>
                  </ul>
                </div>

                <div className="row">
                  {planDetails && planDetails?.plan_id == "2" ? (
                    <TryBeforeYouBuy
                      variationCount={tshirtdetails?.length}
                      firstImageDownloaded={firstImageDownloaded}
                    />
                  ) : (
                    <div className="col-sm-12 text-center">
                      <img
                        src={"/assets/images/powepre.svg"}
                        className="unle_img"
                        alt=""
                      />
                      {planDetails &&
                      planDetails?.plan_id == "1" &&
                      planDetails?.variant_count < 20 ? (
                        <h4>
                          Congratulations on creating your stunning tee shirt
                          designs with Variant!
                        </h4>
                      ) : planDetails &&
                        planDetails?.plan_id == "1" &&
                        planDetails?.variant_count > 19 ? (
                        <>
                          <h4>Upgrade to Continue</h4>
                          <p>
                            Looks like you've reached your limit of free
                            downloads! Upgrade now to continue unleashing your
                            creativity with unlimited downloads. Upgrade today!
                          </p>
                        </>
                      ) : (
                        <>
                          <h4>
                            Variation Completed - Unleash the Power of
                            Personalisation
                          </h4>
                          <p>
                            With a simple click of the "Create" button, watch as
                            our software auto-generates
                            <br /> a myriad of variations, effortlessly
                            incorporating the selected fonts and Player
                            <br /> details, Experience the power of automation
                            and unleash your creativity.
                          </p>
                        </>
                      )}

                      <Button
                        className="px-3 py-1"
                        style={{ borderRadius: "30px" }}
                        onClick={handleDownloadAll}
                      >
                        <img
                          src={download}
                          alt="download"
                          style={{ width: "20px" }}
                        />
                        Download All{" "}
                      </Button>
                      {planDetails &&
                        planDetails?.plan_id == "1" &&
                        planDetails?.variant_count == "20" && (
                          <Button
                            className="primary"
                            style={{ borderRadius: "30px" }}
                            onClick={upgradePlanBtnClick}
                          >
                            Upgrade Plan and Download
                          </Button>
                        )}
                    </div>
                  )}

                  <div className="container mt-5">
                    <div className="row justify-content-center">
                      <div
                        className="col-lg-10"
                        style={{
                          backgroundColor: "#F9FAFD",
                          paddingLeft: "30px",
                          paddingTop: "10px",
                        }}
                      >
                        <div className="row mt-4">
                          <h5 style={{ width: "30%" }}>
                            {tshirtdetails && tshirtdetails.length} Variations
                            Created
                          </h5>
                          <div
                            className=""
                            style={{ width: "40%", alignItems: "right" }}
                          ></div>
                          <div
                            className=""
                            style={{
                              width: "30%",
                              alignItems: "right",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                width: "12%",
                                padding: "10px 5px 0 5px",
                                backgroundColor: "#EAEEF8",
                                borderRadius: "20px 0 0 20px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="mx-2"
                              />
                            </div>
                            <input
                              ref={inputSearch}
                              type="text"
                              placeholder="Search all files"
                              className="export-variation-search"
                              onChange={() => {
                                handelInputsearch();
                              }}
                            />
                          </div>
                        </div>
                        <div className="my-5 col-12 justify-content-center ">
                          <div className="row">
                            {tshirtdetails &&
                              tshirtdetails.map((val, index) => (
                                <div
                                  key={index}
                                  className="col-xl-3 col-md-6 col-lg-4 col-sm-6"
                                  style={{
                                    position: "relative",
                                    paddingLeft: "10px",
                                    marginBottom: "25px",
                                  }}
                                  ref={(ref) => {
                                    if (ref)
                                      ImgDivRef.current[val.indexSr] = ref;
                                  }}
                                >
                                  <div
                                    className="tshirtimg1"
                                    ref={(ref) => {
                                      if (ref)
                                        downladImage.current[val.indexSr] = ref;
                                    }}
                                  >
                                    <svg
                                      width="220"
                                      height="315"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ borderRadius: "10px" }}
                                    >
                                      {selectedImage && (
                                        <image
                                          href={selectedImage}
                                          width="220"
                                          height="315"
                                          preserveAspectRatio="xMidYMid slice"
                                        />
                                      )}
                                    </svg>

                                    <Stage
                                      ref={(ref) => {
                                        if (ref)
                                          canvasRef.current[val.indexSr] = ref;
                                      }}
                                      width={220}
                                      height={315}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 10,
                                        border: "0px solid #000",
                                        borderRadius: "10px",
                                      }}
                                    ></Stage>
                                  </div>
                                  <div className="flex_expor">
                                    <button
                                      className="edit_export"
                                      onClick={() => {
                                        navigate("/EditDesign", {
                                          state: {
                                            indexSr: val.indexSr,
                                            selectedImage: selectedImage,
                                            side: state.side,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={"/assets/images/editpen.svg"}
                                        className=""
                                        alt=""
                                      />{" "}
                                      Edit
                                    </button>
                                    <button
                                      className="download_export"
                                      style={{
                                        backgroundColor:
                                          downloadedIndex.includes(index)
                                            ? "rgba(139, 60, 217, 0.4)"
                                            : "#8B3CD9",
                                      }}
                                      onClick={() => {
                                        $(".loader_bg").show();
                                        if (
                                          processing ||
                                          (planDetails &&
                                            planDetails?.plan_id == "2" &&
                                            firstImageDownloaded) ||
                                          disableAllButton ||
                                          (planDetails &&
                                            planDetails?.plan_id == "1" &&
                                            planDetails?.variant_count == "20")
                                        ) {
                                          $(".loader_bg").hide();
                                          return;
                                        }
                                        setProcessing(true);
                                        handleDownload(
                                          downladImage.current[val.indexSr],
                                          `${val.size}${dimensions[val.size]?.size}_${val.name}_${val.number}.jpeg`,
                                          val.size,
                                          val.indexSr,
                                          index,
                                          "single"
                                        );
                                      }}
                                      disabled={
                                        processing ||
                                        (planDetails &&
                                          planDetails?.plan_id == "2" &&
                                          firstImageDownloaded) ||
                                        disableAllButton ||
                                        (planDetails &&
                                          planDetails?.plan_id == "1" &&
                                          planDetails?.variant_count == "20")
                                      }
                                    >
                                      {" "}
                                      Download{" "}
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="stagediv"
            className="container"
            style={{ visibility: "hidden", display: "none" }}
            ref={ResizeImagediv}
          >
            <Stage ref={stagediv}></Stage>
          </div>
        </section>
      </div>
    </>
  );
}
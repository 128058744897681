import React, { useEffect, useRef, useState } from "react";
import "./CustomDropdown.css";
const CustomFontDropdown = ({ options, onSelect, fontStyles, selected }) => {
  // const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const activeListItemRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleSelectOption = (option) => {
    // setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };
  useEffect(() => {
    // Scroll to the active list item when the dropdown opens
    if (isOpen && activeListItemRef.current) {
      activeListItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click occurred outside the dropdown, close it
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup: remove event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleBlur = (val) => {
    console.log('function called')
    onSelect(val);
  }
  return (
    <>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="selected-opt,ion" onClick={() => setIsOpen(!isOpen)}>
          {selected || "Select an option"}{" "}
          <span className={`arrow ${isOpen ? "up" : "down"}`}></span>
        </div>
        {isOpen && (
          <ul className="options">
            {options.map((option, index) => (
              <li
                ref={selected === option.value ? activeListItemRef : null}
                className={selected === option.value ? "active" : ""}
                style={{ ...fontStyles[option.value] }}
                key={index}
                onClick={() => handleSelectOption(option.value)}
                onBlur={() => handleBlur(option.value)}
                // onmouseo
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default CustomFontDropdown;

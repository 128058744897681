import React, { useState } from "react";
import { Row, Col, Nav, NavItem, NavLink as NavLinkStrap, TabContent, TabPane, Table, Button } from "reactstrap";
import clsx from 'clsx';
import Slider from "react-slick";
import Chart from 'react-apexcharts';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";


function SliderArrowNext(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={'/assets/images/nextarrow.svg'} className="nextarrow" alt="" />
            <img src={'/assets/images/nextarrowhover.svg'} className="nextarrowhover" alt="" />
        </div>
    );
}

function SliderArrowPrev(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={'/assets/images/prevarrow.svg'} className="prevarrow" alt="" />
            <img src={'/assets/images/prevhoverarrow.svg'} className="prevarrowhover" alt="" />
        </div>
    );
}


export default function DashboardGraph() {


    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    var payment = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        nextArrow: <SliderArrowNext />,
        prevArrow: <SliderArrowPrev />,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                },
            },
        ],
    };

    const [optionsMixedChart] = useState({
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                endingShape: 'arrow'
            }
        },
        stroke: {
            width: [4, 0, 0]
        },
        xaxis: {
            categories: ['10:00', '10:15', '10:30', '10:45']
        },
        markers: {
            size: 3,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8
            }
        },
        yaxis: {
            tickAmount: 3,
            min: 0,
            max: 100
        }
    });

    const [seriesMixedChart] = useState([
        {
            name: 'series-1',
            type: 'line',
            data: [30, 40, 25, 50]
        },
        // {
        //   name: 'series-2',
        //   type: 'column',
        //   data: [23, 12, 54, 61, 32, 56, 81, 19]
        // },
        // {
        //   name: 'series-3',
        //   type: 'column',
        //   data: [62, 12, 45, 55, 76, 41, 23, 43]
        // }
    ]);

    return (
        <>
            <div className="graph_mar">
                <Row className="row_paymen">
                    <Col sm="6">
                        <h5 className="admin_head">Payments</h5>
                    </Col>

                    <Col sm="6">
                        <div className="">
                            <Nav className="nav_custtab">
                                <NavItem>
                                    <NavLinkStrap
                                        className={clsx({ active: activeTab === '1' })}
                                        onClick={() => {
                                            toggle('1');
                                        }}>
                                        Amount
                                    </NavLinkStrap>
                                </NavItem>
                                <NavItem>
                                    <NavLinkStrap
                                        className={clsx({ active: activeTab === '2' })}
                                        onClick={() => {
                                            toggle('2');
                                        }}>
                                        Count
                                    </NavLinkStrap>
                                </NavItem>

                            </Nav>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">

                        <div className="payment_card">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="payment_graph">
                                        <h5 className="fund_head">Total Funds</h5>
                                        <div className="payment_slider">
                                            <Slider {...payment}>
                                                <div>
                                                    <ul className="refun_ant">
                                                        <li>
                                                            <h6>Refund Amout</h6>
                                                        </li>
                                                        <li>
                                                            <h5>₹ 0.00</h5>
                                                        </li>
                                                    </ul>

                                                    <Chart
                                                        options={optionsMixedChart}
                                                        series={seriesMixedChart}
                                                        type="line"
                                                        height={400}
                                                    />
                                                </div>


                                                <div>
                                                    <ul className="refun_ant">
                                                        <li>
                                                            <h6>Refund Amout</h6>
                                                        </li>
                                                        <li>
                                                            <h5>₹ 0.00</h5>
                                                        </li>
                                                    </ul>

                                                    <Chart
                                                        options={optionsMixedChart}
                                                        series={seriesMixedChart}
                                                        type="line"
                                                        height={400}
                                                    />
                                                </div>
                                            </Slider>
                                        </div>

                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="payment_graph">
                                        <div className="scroll-area shadow-overflowl dashscroll">
                                            <PerfectScrollbar>
                                                <div className="table-responsive">
                                                    <Table hover striped className="table_cust">
                                                        <thead className="thead_bg">
                                                            <th style={{ width: '40%'}} className="userth">User Name</th>
                                                            <th style={{ width: '40%'}} className="userth">Article Title</th>
                                                            <th style={{ width: '20%'}} className="userth">Actions</th>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <ul className="dasboar_table_list">
                                                                        <li>
                                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                                        </li>
                                                                        <li>
                                                                            <h5>Alexa</h5>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                                <td>Design</td>

                                                                <td>
                                                                    <Button tag="a" href="#"
                                                                        className="healcheck_btn"
                                                                    >
                                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                                        View
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <ul className="dasboar_table_list">
                                                                        <li>
                                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                                        </li>
                                                                        <li>
                                                                            <h5>Alexa</h5>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                                <td>Design</td>

                                                                <td>
                                                                    <Button tag="a" href="#"
                                                                        className="healcheck_btn"
                                                                    >
                                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                                        View
                                                                    </Button>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <ul className="dasboar_table_list">
                                                                        <li>
                                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                                        </li>
                                                                        <li>
                                                                            <h5>Alexa</h5>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                                <td>Design</td>

                                                                <td>
                                                                    <Button tag="a" href="#"
                                                                        className="healcheck_btn"
                                                                    >
                                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                                        View
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <ul className="dasboar_table_list">
                                                                        <li>
                                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                                        </li>
                                                                        <li>
                                                                            <h5>Alexa</h5>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                                <td>Design</td>

                                                                <td>
                                                                    <Button tag="a" href="#"
                                                                        className="healcheck_btn"
                                                                    >
                                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                                        View
                                                                    </Button>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <ul className="dasboar_table_list">
                                                                        <li>
                                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                                        </li>
                                                                        <li>
                                                                            <h5>Alexa</h5>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                                <td>Design</td>

                                                                <td>
                                                                    <Button tag="a" href="#"
                                                                        className="healcheck_btn"
                                                                    >
                                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                                        View
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <ul className="dasboar_table_list">
                                                                        <li>
                                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                                        </li>
                                                                        <li>
                                                                            <h5>Alexa</h5>
                                                                        </li>
                                                                    </ul>
                                                                </td>

                                                                <td>Design</td>

                                                                <td>
                                                                    <Button tag="a" href="#"
                                                                        className="healcheck_btn"
                                                                    >
                                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                                        View
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
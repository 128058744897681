import React, {useRef, useState} from "react";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';


export default function DesignTrial({items, backgroundImage}) {
    const canvasRef = useRef(null);
    const [processing, setProcessing] = useState(false);

    const handleDownloadAll = async () => {
        setProcessing(true);

        const zip = new JSZip();

        // Process items in batches
        const batchSize = 10;
        const batches = Math.ceil(items.length / batchSize);

        for (let i = 0; i < batches; i++) {
            const batchItems = items.slice(i * batchSize, (i + 1) * batchSize);

            await Promise.all(batchItems.map(async (item, index) => {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                const dpi = 300; // DPI for high-resolution image
                const scale = dpi / 96; // Scale factor for DPI

                // Create background image
                const backgroundImageElement = new Image();
                backgroundImageElement.src = backgroundImage;
                await new Promise(resolve => {
                    backgroundImageElement.onload = resolve;
                });

                // Set canvas dimensions based on background image size
                canvas.width = backgroundImageElement.width * scale;
                canvas.height = backgroundImageElement.height * scale;

                // Draw background image
                context.drawImage(backgroundImageElement, 0, 0, canvas.width, canvas.height);

                // Draw text and numbers from item
                context.font = '30px Arial';
                context.fillStyle = 'white';
                context.fillText(item.name, 50, 50);
                context.fillText(item.number, 50, 100);

                // Convert canvas to data URL with higher DPI
                const dataUrl = canvas.toDataURL('image/jpeg', dpi);

                // Add image to ZIP file
                zip.file(`image_${i * batchSize + index}.jpg`, dataUrl.split(',')[1], { base64: true });
            }));
        }

        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Save the ZIP file
        saveAs(zipBlob, 'images.zip');

        setProcessing(false);
    };
  return (
    <div>
    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    <button onClick={handleDownloadAll} disabled={processing}>
        {processing ? 'Processing...' : 'Download All'}
    </button>
</div>
  );
}

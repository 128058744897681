import React, { useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

export default function ConfirmationPopup({ close, confirm, selectedItem }) {
  return (
    <>
      <div className="success_bg">
        <div className="success_content" style={{ maxWidth: "615px" }}>
          <NavLink to={""} onClick={() => close()}>
            <img src="/assets/images/cross.svg" className="crossicon" alt="" />
          </NavLink>

          <img src="/assets/images/verify.svg" className="verify_img" alt="" />

          <div className="row">
            {/* <div className="text-center mb-4">
                  <a className="red-background mb-5">Subscription Alert</a>
                </div> */}
            <div className="justify-content-center text-center">
              <h2>Are you sure you want to cancel this subscription ?</h2>
            </div>
          </div>
          {/* <hr /> */}
          <div className="row mt-3">
            <div className="justify-content-center text-center">
              {/* {variationCountLeft > 0  ? 
                  <Button color="create_variations mb-3" onClick={() => close()}>
                    Create {variationCountLeft} Free Variations
                  </Button> : */}
              <Button color="create_variations mb-3" onClick={() => close()}>
                Cancel
              </Button>
              <Button
                color="create_variations mb-3 ms-3"
                onClick={() => {
                  confirm(selectedItem);
                  close();
                }}
              >
                Yes, do it
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalBody>
                <Button btn btn-warning>
                  Subscription reminder
                </Button>
                <h2>You have 10 Free Variations Left</h2>
                <p>
                  Get started with Variant's free plan! Create up to 20 stunning
                  variations and experience the power of Auto customization. Start
                  designing today!
                </p>
              </ModalBody>
              <ModalFooter>
               
               <Button color="primary" onClick={toggle}>
                  Create 10 Free Variations
                </Button>{" "}
                <p>
                  Want to upgrade to Unlimited Plan? <a href="#">Upgrade now</a>
                </p>
              </ModalFooter>
            </Modal>
          </div> */}
    </>
  );
}

import React, { useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function NameNotAvailPopup({ close }) {
  const navigate = useNavigate();
  const contentRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the contentRef element
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        close();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close]);
  return (
    <>
      <div className="success_bg">
        <div
          className="success_content"
          ref={contentRef}
          style={{ maxWidth: "615px" }}
        >
          <NavLink to={""} onClick={() => close()}>
            <img src="/assets/images/cross.svg" className="crossicon" alt="" />
          </NavLink>

          <img src="/assets/images/verify.svg" className="verify_img" alt="" />

          <div className="row">
            {/* <div className="text-center mb-4">
                      <a className="red-background mb-5">Subscription Alert</a>
                    </div> */}
            <div className="justify-content-center text-center">
              <h2>Missing Player Name</h2>

              <p
                style={{
                  color: "#433a3a",
                  fontSize: "14px",
                  width: "70%",
                  margin: "0 auto",
                }}
              >
                It looks like there are missing player names or numbers in your
                uploaded Excel file. Teedex cannot generate images without Name.
              </p>
            </div>
          </div>
          {/* <hr /> */}
          <div className="row mt-3">
            <div className="justify-content-center text-center">
              {/* {variationCountLeft > 0  ? 
                      <Button color="create_variations mb-3" onClick={() => close()}>
                        Create {variationCountLeft} Free Variations
                      </Button> : */}
              <Button onClick={close} color="create_variations mb-3">
                {" "}
                Review and Correct
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from "react";

export default function PercentLoader({ percent }) {
  let load = 0;

  //   setInterval(() => {
  //     load = load + Math.floor(Math.random() * 5 + 1);
  //     if (load < 100) {
  //       loader.textContent = load + "%";
  //       bar.style.transform = `scalex(${load}%)`;
  //       text.textContent = "Loading....";
  //     } else {
  //       bar.style.transform = `scalex(100%)`;
  //       loader.textContent = "100%";
  //       text.textContent = "Loaded";
  //     }
  //   }, 90);

  //   replay.addEventListener("mouseover", function () {
  //     load = 0;
  //   });

  useEffect(() => {
    const text = document.querySelector("#text");
    const bar = document.querySelector("#bar");
    const loader = document.querySelector("#percent");

    loader.textContent = percent + "%";
    bar.style.transform = `scalex(${percent}%)`;
    text.textContent = "Downloaded....";
  }, [percent]);

  return (
    <div style={{ display: "none" }} className="loader_bg">
    {/* <div className="loader_bg"> */}
      <div className="loader_img">
        <img src="/assets/images/loader.png" alt="" />
        <div id="loader">
          <span style={{color: 'white'}} id="text">Loading...</span>
          <span style={{color: 'white'}} id="percent"></span>
          <div style={{height: '4px', backgroundColor: 'white'}}  id="bar"></div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { config } from '../../utils/config'
import { BrowserRouter as Router, Link, useNavigate, useSearchParams } from "react-router-dom";
import titleimg from '../../assets/img/Layer_1-2.svg'
import Google from '../../assets/img/google.png'
import Varient from '../../assets/img/varient.png'
import { useForm } from 'react-hook-form';
import KEYS from "../../env/env";
import secureLocalStorage from 'react-secure-storage';
import { doRequest, submitUserPlans } from "../../utils/helper";
import verifytoken from "../../env/verifytoken";
import Loader from "../../Components/Loader";
import GoogleLoginButton from "../../Components/GoogleLoginButton";
import { Row, Col } from "reactstrap";

function Login() {
  const userIsLoggedIn = localStorage.getItem(config.USER_LOGIN_DETAILS)
  const loginToken = secureLocalStorage.getItem('Login')
  const initialUserPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS)
  const [showPassword, setShowPassword] = useState(false);
  const [errmsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const navigate = useNavigate()
  const plan = params.get('plan');
  if (plan) {
    localStorage.setItem('userselectedplanid', plan)
  } else {
    if (localStorage.getItem('userselectedplanid')) {
      localStorage.removeItem('userselectedplanid')
    }
  }
  // secureLocalStorage.removeItem('Login')
  useEffect(() => {
    if (loginToken && !verifytoken()) {
      console.log('login token if')
      navigate('/Home')
    } else {
      secureLocalStorage.removeItem('Login')
      localStorage.clear();
    }
  }, [])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);


  };

  const handleLoginSuccess = async (data) => {
    try {
      let obj = {
        ...data
      }
      window.gtag("event", "Login with Google", obj);
    } catch (error) {

    }
    if (!data) return;
    try {
      let response = await doRequest(data, "POST", "user/social-login");
      if (response && +response?.resultFlag === 1) {
        let userDetails = response?.response?.user;
        let userPlanDetails = response?.response?.userPlan;
        let variants = response?.response?.variants;
        let token = response?.response?.token;
        secureLocalStorage.setItem("Login", token);
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(userDetails)
        );
        localStorage.setItem(
          config.USER_PLAN_DETAILS,
          JSON.stringify(userPlanDetails)
        );
        if(variants){
          localStorage.setItem(
            config.VARIANT_COUNT,
            JSON.stringify(variants)
          );
        }
        if (!userDetails?.plan_status || (userDetails?.plan_status && userDetails?.plan_status === "inactive")) {
          navigate("/plans");
        } else {
          navigate("/Design");
        }
      } else {
        console.log(' response?.message', response?.message)
        setErrorMsg(
          response?.message ? response?.message : "Something went wrong!"
        );
      }
    } catch (error) {
      console.log(' error?.message', error?.message)
      setErrorMsg(error?.message ? error?.message : "Something went wrong!");
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Login error:", error);
  };


  const { register, formState: { errors }, handleSubmit } = useForm();

  const submitUserPlan = async (planid) => {
    console.log('insidesubmituserplan', planid)
    let user = localStorage.getItem(config.USER_LOGIN_DETAILS) && JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
    try {
      let response = await submitUserPlans(planid, user)
      if (response && +response?.resultFlag === 1) {

        localStorage.removeItem('users_email');
        console.log('response of update is', response)
        let details = response?.response?.user_data;
        let userPlan = response?.response?.userPlan;
        let variant = response?.response?.variants;
        if(variant){
          localStorage.setItem(
            config.VARIANT_COUNT,
            JSON.stringify(variant)
          );
        }
        localStorage.setItem(config.USER_LOGIN_DETAILS, JSON.stringify(details))
        localStorage.setItem(config.USER_PLAN_DETAILS, JSON.stringify(userPlan))
        console.log('userplan', userPlan, 'userdetails', details)

        if (initialUserPlanDetails) {
          navigate("/profile?plan=" + planid);
        } else {
          navigate("/profile?plan=" + planid + "&ftime=1");
        }

        // if (initialUserPlanDetails) {
        //   navigate('/profile?plan=' + 2);
        // } else {
        //   navigate('/profile?plan=' + 2 + 'ftime=1');
        // }
      } else {
        setErrorMsg(
          response?.message ? response?.message : "Something went wrong"
        );
        // setShowError(true);
      }
    } catch (error) {
      setErrorMsg(error?.message ? error?.message : "Something went wrong");
      // setShowError(true);
    }
  }

  const resendOtp = async (email) => {
    try {
      let data = {
        email
      }
      let response = await doRequest(data, 'POST', 'user/resend-otp')
      if (+response?.resultFlag === 1) {
        localStorage.setItem('users_email', email);
        navigate('/verify-email' + window.location.search)
      } else {
        setErrorMsg(response?.message ? response?.message : 'Something went wrong!')
      }
    } catch (error) {
      setErrorMsg(error?.message ? error?.message : 'Something went wrong!')
    }
  }
  var login = (data) => {
    setLoading(true)
    try {
      let obj = {
        email: data.emailid
      }
      window.gtag("event", "Login User", obj);
    } catch (error) {

    }
    var dataToPost = {
      email: data.emailid,
      password: data.password
    }

    fetch(KEYS.apiPath + 'user/login', {
      body: JSON.stringify(dataToPost),
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => response.json())
      .then(async (val) => {
        console.log(val);
        if (+val.resultFlag === 1) {
          let user = val?.response?.user;
          secureLocalStorage.setItem('Login', val?.response?.token)
          let userDetails = val?.response?.user;
          let userPlanDetails = val?.response?.userPlan;
          let variants = val?.response?.variants;

          localStorage.setItem(config.USER_LOGIN_DETAILS, JSON.stringify(userDetails));
          localStorage.setItem(
            config.USER_PLAN_DETAILS,
            JSON.stringify(userPlanDetails)
          );
          if(variants){
            localStorage.setItem(
              config.VARIANT_COUNT,
              JSON.stringify(variants)
            );
          }
          if (user?.status === 'inactive') {
            await resendOtp(user?.email);
          } else {
            if (user?.user_plan_id) {
              // localStorage.removeItem('token');

              if (userPlanDetails?.plan_id == '1') {
                localStorage.setItem('showfreevariantpopup', 'true');
              }
              navigate('/Home');
              setErrorMsg(null);
            } else {
              if (plan) {
                if (plan == '1' || plan == '2') {
                  await submitUserPlan(plan);
                } else {
                  navigate('/payment' + window.location.search);
                }
              } else {
                navigate('/plans' + window.location.search)
              }
            }
          }

        } else {
          console.log('inside else ', val);
          setErrorMsg(val['message'] ? val['message'] : 'Something went wrong');
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        console.log(err?.message ? err.message : 'Something went wrong!');
        setErrorMsg(err?.message ? err.message : 'Something went wrong!');
      })
  }


  return (
    <>
      {loading && <Loader />}
      <div id="main-container" className="container-fluid main">
        <div className="sidemenu d-flex flex-column justifycontent-between min-vh-100">
          <div className="text-center mt-3">
            <ul className="nav nav-pills flex-column">
              <li>
                <img src={Varient} alt="Varient logo" style={{ width: '50px', top: 0 }} />
              </li>
            </ul>
          </div>
        </div>

        <section className="home">
          <div className="container login-container"
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)"
           }}>
          <div className="row mt-4">
            <div className="mb-4">
              {/* <ul className="d-flex col-11 login-tabs">
                  <li className="">Signup</li>
                  <li className="mx-2">Payment</li>
                  <li className="mx-2">Register</li>
                </ul> */}
            </div>
          </div>
          <div className="row my-5">
            <div className="col-4">
              <div className="login">
                <h3 className="text-center">Login</h3>
                <p>Welcome back! Please enter your details</p>
                {/* <Row>
                    <Col lg="12">
                      <div className="soci_flex">
                        <GoogleLoginButton
                          onSuccess={handleLoginSuccess}
                          onFailure={handleLoginFailure}
                        />
                        <img
                          src={
                            config.AMAZON_ASSETS_URL +
                            "avatars/logingoggle.svg"
                          }
                          className="loggoogleimg"
                          alt=""
                        />
                      </div>
                      <span className="loginor">or</span>
                    </Col>
                  </Row>
                  <p className="or-text">or</p> */}
                <form onSubmit={handleSubmit(login)}>

                  <input type="email" name="email" placeholder="Email" {...register("emailid", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                  {errors.emailid?.type === "required" && (<span className="text-danger">Email is required.</span>)}
                  {errors.emailid?.type === "pattern" && (<span className="text-danger">Please enter valid email.</span>)}
                  <div style={{ position: 'relative', marginBottom: '20px' }}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="Password"
                      style={{ paddingRight: '30px' }} {...register("password", { required: true })}
                    />
                    {errors.password?.type === "required" && (<span className="text-danger">Password is required.</span>)}
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                      style={{ position: 'absolute', top: '40%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                    />
                  </div>

                  <input type="checkbox" name="" value="" style={{ width: '5%' }} /> Remember for 30 days
                  <br /><p className="text-danger">{(errmsg != null) ? errmsg : ''}</p>
                  <input className="submit" type="submit" value="Login" />
                </form>
                <div className="text-center mb-3">
                  <Link to="/Forgetpass" className="login-a">Forget Password?</Link>
                </div>
                <p>Don't have account? <Link to={"/Signup" + window.location.search} className="login-a mx-2">Sign up for free</Link></p>
              </div>
            </div>
            <div className="col-8 d-flex justify-content-center align-items-center">
              <div className="login-title">
                {/* <img src={titleimg} alt="title" /> */}
                <img style={{ maxWidth: '300px' }} src={"/assets/images/logo.svg"} alt="title" />
                <p style={{ fontSize: '90px' }}>Join the Crafters</p>
              </div>
            </div>
          </div>
      </div>


    </section >
      </div >
    </>
  );
}

export default Login;

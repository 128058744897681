import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function TryBeforeYouBuy({
  firstImageDownloaded,
  variationCount,
}) {
  const navigate = useNavigate();
  const goToPayment = () => {
    localStorage.setItem("variationcounts", variationCount);
    navigate("/payforpayasuse");
  };
  return (
    <>
      <div className=" text-center try_before_heading">
        <h4>Download any one sample for free</h4>
        <p>
          On our Pay-As-You-Use plan, you can download any one sample image for
          free. Get a taste of what Teedex has to offer before committing to a
          subscription. Explore our intuitive platform and unleash your
          creativity today!
        </p>
        <Button onClick={() => goToPayment()} className="create_variations">
          Pay and Download Images
        </Button>
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../component/Sidebar";
import Button from "react-bootstrap/Button";

import { useParams, useLocation, useNavigate, NavLink } from "react-router-dom";
// import { BrowserRouter as Router, Link, useLocation, useAsyncError, useNavigate } from "react-router-dom";

import Select from "react-select";
import {
  Form,
  InputGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fronttshirt from "../assets/img/Plain TeeShirt.png";
// import backtshirt from '../assets/img/t-shirt.png'
import * as htmlToImage from "html-to-image";
// import fronttshirt from '../assets/img/Front.svg';
import undoblack from "../assets/img/undo_black_24dp.svg";
import redoblack from "../assets/img/redo_black_24dp.svg";
import backtshirt from "../assets/img/Plain TeeShirt.png";
// import backtshirt from '../assets/img/homepage-first.png';
import redo from "../assets/img/outline_undo_black_24dp.png";
import undo from "../assets/img/outline_redo_black_24dp.png";
import addfile from "../assets/img/upload_file_FILL0_wght400_GRAD0_opsz24.svg";

import Accordion from "react-bootstrap/Accordion";
import { ChromePicker } from "react-color";

import {
  faUndo,
  faRedo,
  faSearchPlus,
  faShirt,
  faList,
  faCirclePlus,
  faImage,
  faTrash,
  faUnderline,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import "../styles.css";
import "../index.css";
import { Border, Link } from "react-bootstrap-icons";
import {
  Line,
  Stage,
  Layer,
  Rect,
  Circle,
  Image as KonvaImage,
  Text,
  Transformer,
  Shape,
} from "react-konva";
// import Konva from 'konva';
import useImage from "use-image";
import usePopup from "../hook/usePopUp";
import Popup from "./Popup";
import secureLocalStorage from "react-secure-storage";
import verifytoken from "../env/verifytoken";
import CustomFontDropdown from "./CustomFontDropdown";
import { config } from "../utils/config";
import ImagesRemaining from "../Components/ImagesRemaining";
import AddFonts from "./AddFonts";
import AddVariationPopup from "./AddVariationPopup";

const fontStyles = {
  Arial: { fontFamily: "Arial, sans-serif" },
  "Times New Roman": { fontFamily: "'Times New Roman', Times, serif" },
  "Euclid Circular A": { fontFamily: "'Euclid Circular A', sans-serif" },
  Medium: { fontFamily: "Medium, sans-serif" },
  BlinkMacSystemFont: { fontFamily: "BlinkMacSystemFont, sans-serif" },
  "Segoe UI": { fontFamily: "Segoe UI, sans-serif" },
  Ubuntu: { fontFamily: "Ubuntu, sans-serif" },
  Cantarell: { fontFamily: "Cantarell, sans-serif" },
  "Fira Sans": { fontFamily: "Fira Sans, sans-serif" },
  "Droid Sans": { fontFamily: "Droid Sans, sans-serif" },
  "Helvetica Neue": { fontFamily: "Helvetica Neue, sans-serif" },
  "American Captain": { fontFamily: "American Captain, sans-serif" },
  "Bruce Forever": { fontFamily: "Bruce Forever, sans-serif" },
  Freshman: { fontFamily: "Freshman, sans-serif" },
  "Govrnment Agent BB": { fontFamily: "Govrnment Agent BB, sans-serif" },
  "Govrnment Agent BB Italic": {
    fontFamily: "Govrnment Agent BB Italic, sans-serif",
  },
  "Jersey M54": { fontFamily: "Jersey M54, sans-serif" },
  "Rozha One": { fontFamily: "Rozha One, sans-serif" },
  "Sports Jersey": { fontFamily: "Sports Jersey, sans-serif" },
  "Sports World": { fontFamily: "Sports World, sans-serif" },
  atlanta_collegeregular: { fontFamily: "atlanta_collegeregular, sans-serif" },
};

var fontOptions = [
  { value: "Arial", label: "Arial" },
  { value: "Times New Roman", label: "Times New Roman" },
  { value: "Euclid Circular A", label: "Euclid Circular A" },
  { value: "Medium", label: "Medium" },
  { value: "BlinkMacSystemFont", label: "BlinkMacSystemFont" },
  { value: "Segoe UI", label: "Segoe UI" },
  { value: "Ubuntu", label: "Ubuntu" },
  { value: "Cantarell", label: "Cantarell" },
  { value: "Fira Sans", label: "Fira Sans" },
  { value: "Droid Sans", label: "Droid Sans" },
  { value: "Helvetica Neue", label: "Helvetica Neue" },
  { value: "American Captain", label: "American Captain" },
  { value: "Bruce Forever", label: "Bruce Forever" },
  { value: "Freshman", label: "Freshman" },
  { value: "Govrnment Agent BB", label: "Govrnment Agent BB" },
  { value: "Govrnment Agent BB Italic", label: "Govrnment Agent BB Italic" },
  { value: "Jersey M54", label: "Jersey M54" },
  { value: "Rozha One", label: "Rozha One" },
  { value: "Sports Jersey", label: "Sports Jersey" },
  { value: "Sports World", label: "Sports World" },
  { value: "atlanta_collegeregular", label: "ATLANTA COLLEGE REGULAR" },

  // Add more font options as needed
];
const fontSizes = [
  { value: "12", label: "12" },
  { value: "14", label: "14" },
  { value: "16", label: "16" },
  { value: "18", label: "18" },
  { value: "20", label: "20" },
  { value: "22", label: "22" },
  { value: "24", label: "24" },
  { value: "28", label: "28" },
  { value: "30", label: "30" },
  { value: "32", label: "32" },
  { value: "34", label: "34" },
  { value: "36", label: "36" },
  { value: "38", label: "38" },
  { value: "40", label: "40" },
  { value: "42", label: "42" },
  { value: "44", label: "44" },
  { value: "46", label: "46" },
  { value: "48", label: "48" },
  { value: "50", label: "50" },
  { value: "52", label: "52" },
  { value: "54", label: "54" },
  { value: "56", label: "56" },
  { value: "58", label: "58" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "64", label: "64" },
  { value: "68", label: "68" },
  { value: "70", label: "70" },
  { value: "72", label: "72" },

  // Add more font options as needed
];
const TextOutline = [
  { value: "12", label: "12" },
  { value: "14", label: "14" },
  { value: "16", label: "16" },
  { value: "18", label: "18" },
  { value: "20", label: "20" },
  { value: "22", label: "22" },
  { value: "24", label: "24" },
  { value: "28", label: "28" },
  { value: "30", label: "30" },
  { value: "32", label: "32" },
  { value: "34", label: "34" },
  { value: "36", label: "36" },
  { value: "38", label: "38" },
  { value: "40", label: "40" },
  { value: "42", label: "42" },
  { value: "44", label: "44" },
  { value: "46", label: "46" },
  { value: "48", label: "48" },
  { value: "50", label: "50" },
  { value: "52", label: "52" },
  { value: "54", label: "54" },
  { value: "56", label: "56" },
  { value: "58", label: "58" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "64", label: "64" },
  { value: "68", label: "68" },
  { value: "70", label: "70" },
  { value: "72", label: "72" },

  // Add more font options as needed
];

const fontWeight = [
  // { value: "100", label: "Thin" },
  // { value: "200", label: "Extra Light " },
  // { value: "300", label: "Light" },
  { value: "400", label: "Normal (Regular)" },
  // { value: "500", label: "Medium" },
  { value: "600", label: "Semi Bold" },
  { value: "700", label: "Bold" },
  // { value: "italic", label: "italic" },
  // { value: "800", label: "Extra Bold" },
  // { value: "900", label: "Black" },
  // { value: "950", label: "Extra Black" },
  // { value: "normal", label: "Normal" },
  // { value: "bold", label: "bold" },
  // { value: "lighter", label: "lighter" },
];

const fontWeightStyle = {
  // "100": { fontWeight: '100' },
  // "200": { fontWeight: '200' },
  // "300": { fontWeight: '300' },
  400: { fontWeight: "400" },
  // "500": { fontWeight: '500' },
  600: { fontWeight: "600" },
  700: { fontWeight: "700" },
  // "italic": { fontWeight: 'italic' },
  // "800": { fontWeight: '800' },
  // "900": { fontWeight: '900' },
  // "950": { fontWeight: '950' },
  // "lighter": { fontWeight: 'lighter' },
  // "bold": { fontWeight: 'bold' },
  // "normal": { fontWeight: 'normal' },
};
const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} style={{ fontFamily: data.value }}>
    {label}
  </div>
);
let historyStep = 0;
function Design() {
  const navigate = useNavigate();
  var token = secureLocalStorage.getItem("Login");
  // console.log("token", token);
  if (token == null) {
    navigate("/");
  } else {
    var tokenExpired = verifytoken();
    if (tokenExpired) {
      navigate("/");
    }
  }

  let userVariantDetails =
    localStorage.getItem(config.VARIANT_COUNT) &&
      localStorage.getItem(config.VARIANT_COUNT) != "undefined"
      ? JSON.parse(localStorage.getItem(config.VARIANT_COUNT))
      : null;

  var Existingplayernamedetails = JSON.parse(
    localStorage.getItem("playernamedetails")
  );
  var Existingplayernumberdetails = JSON.parse(
    localStorage.getItem("playernumberdetails")
  );
  // var selectedBGImage = localStorage.getItem('bgImageDetails');
  const { state } = useLocation();
  // console.log(state);

  const [view, setView] = useState("front");
  const [playerName, setPlayerName] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.Name
      : "Player Name"
  );
  const [playerNo, setPlayerNo] = useState(
    Existingplayernumberdetails != null ? Existingplayernumberdetails.No : "00"
  );

  // localStorage.setItem('tshirtSize',selectedValue);

  // console.log(Existingplayernamedetails,Existingplayernumberdetails);

  //Player Number
  const [textSize, setTextSize] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.textSize
      : "120"
  );
  const [fontFamily, setFontFamily] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.fontFamily
      : "Jersey M54"
  );
  const [numberFontWeight, setNumberFontWeight] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.numberFontWeight
      : "200"
  );
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [textPosition, setTextPosition] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.textPosition
      : { x: 11, y: 190 }
  ); // Initial position
  const [textColor, setTextColor] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.textColor
      : "#808080"
  ); // Default color
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [outlineColor, setOutLineColor] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.outlineColor
      : "#00000"
  );
  const [NotextBorder, setNoTextBorder] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.NotextBorder
      : ""
  );
  const [outLinecolorPickerVisible, setoutLineColorPickerVisible] =
    useState(false);
  const [rotationAngle, setRotationAngle] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.rotationAngle
      : 0
  );
  const [playerNoWidth, setPlayerNoWidth] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.NoWidth
      : 300
  );
  const [NoScale, setNoScale] = useState(
    Existingplayernumberdetails != null
      ? Existingplayernumberdetails.NoScale
      : { x: 1, y: 1 }
  );
  const rotationInputRef = useRef();

  //Player Name
  const [NametextSize, setNameTextSize] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NametextSize
      : "32"
  );
  const [NamefontFamily, setNameFontFamily] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NamefontFamily
      : "Jersey M54"
  );
  const [nameFontWeight, setNameFontWeight] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NamefontWeight
      : "200"
  );
  const [NameisDragging, setNameIsDragging] = useState(false);
  const [NamedragStart, setNameDragStart] = useState({ x: 0, y: 0 });
  const [NametextBorder, setNameTextBorder] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NametextBorder
      : ""
  );
  const [NametextPosition, setNameTextPosition] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NametextPosition
      : { x: 11, y: 150 }
  ); // Initial position
  const [NametextColor, setNameTextColor] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NametextColor
      : "#808080"
  ); // Default color
  const [NamecolorPickerVisible, setNameColorPickerVisible] = useState(false);
  const [NameoutlineColor, setNameOutLineColor] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NameoutlineColor
      : "#00000"
  );
  const [NameoutLinecolorPickerVisible, setNameoutLineColorPickerVisible] =
    useState(false);
  const [NamerotationAngle, setNameRotationAngle] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NamerotationAngle
      : 0
  );
  const [playerNameWidth, setPlayerNameWidth] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NameWidth
      : 300
  );
  const NamerotationInputRef = useRef();
  const [NametextPositionPer, setNametextPositionPer] = useState({
    x: 0,
    y: 0,
  });
  const [textPositionPer, setTextPositionPer] = useState({ x: 0, y: 0 });
  const [NameScale, setNameScale] = useState(
    Existingplayernamedetails != null
      ? Existingplayernamedetails.NameScale
      : { x: 1, y: 1 }
  );

  // const [selectedImage, setSelectedImage] = useState((selectedBGImage != null)?selectedBGImage:null);
  const [selectedImage, setSelectedImage] = useState(
    state !== null ? state.selectedImage : null
  );
  const [IsNameSelected, setNameSelected] = useState(false);
  const [IsNoSelected, setNoSelected] = useState(false);
  const canvasRef = useRef(null);
  const canvasRefName = useRef(null);
  const TextNameRef = useRef(null);
  const TextNameTranRef = useRef(null);
  const TextNoRef = useRef(null);
  const TextNoTranRef = useRef(null);
  const fontfamilyDropDown = useRef();
  const DesignImage = useRef();
  const [activeAccordionItem, setActiveAccordionItem] = useState("");
  const [history, setHistory] = useState([]);
  const [UndoRedo, setUndoRedo] = useState(0);
  const [playersName, setPlayersName] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const localObj = localStorage.getItem("showPlayerNameNum")
    ? JSON.parse(localStorage.getItem("showPlayerNameNum"))
    : null;
  const [showPlayerName, setShowPlayerName] = useState(
    localObj ? localObj.showName : true
  );
  const [showPlayerNum, setShowPlayerNum] = useState(
    localObj ? localObj.showNum : true
  );
  const [showGuideline, setShowGuideline] = useState(false);
  const [guidelineX, setGuidelineX] = useState(0);
  const [guidelineY, setGuidelineY] = useState(0);
  const [selectedText, setSelectedText] = useState(null);
  const [plan, setPlan] = useState({});
  const [showFontType, setShowFontType] = useState("name");
  const [showAddFontPopup, setShowAddFontPopup] = useState(false);
  const [hoveredFontName, setHoveredFontName] = useState(null);
  const [hoveredFontNum, setHoveredFontNum] = useState(null);
  const [showAddVariationPopup, setShowAddVariationPopup] = useState(false)
  const userPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS);

  useEffect(() => {
    if (userPlanDetails) {
      setPlan(JSON.parse(userPlanDetails));
    }
  }, [userPlanDetails]);

  const handleTextClick = (e) => {
    setSelectedText(e.target);
  };

  const handleKeyDown = (e) => {
    if (!selectedText) return;

    const dist = 1; // Distance to move on each key press
    let x = selectedText.x();
    let y = selectedText.y();

    switch (e.key) {
      case "ArrowUp":
        y -= dist;
        break;
      case "ArrowDown":
        y += dist;
        break;
      case "ArrowLeft":
        x -= dist;
        break;
      case "ArrowRight":
        x += dist;
        break;
      default:
        return;
    }

    // Update position of the selected text
    selectedText.position({ x, y });
    updateGuidelinePosition(selectedText); // Update guideline position based on text movement
  };

  const updateGuidelinePosition = (textNode) => {
    const stage = canvasRef.current;
    if (!stage || !textNode) return;

    const canvasCenterX = stage.width() / 2;
    const textWidth = textNode.width();
    const textCenterX = textNode.x() + textWidth / 2;
    const guidelineXPos = canvasCenterX - (textCenterX - canvasCenterX);

    setGuidelineX(guidelineXPos);
    setShowGuideline(true);
  };
  const handleClickOutsideCanvas = (e) => {
    // Check if the click target is not within the canvas
    if (canvasRef.current && !canvasRef.current.content.contains(e.target)) {
      setShowGuideline(false); // Close guideline when clicked outside canvas
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("mousedown", handleClickOutsideCanvas);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("mousedown", handleClickOutsideCanvas);
    };
  }, [selectedText]);

  const handleTextDragMove = (e) => {
    const stage = canvasRef.current;
    if (!stage) return;

    const stageWidth = stage.width();
    const textNode = e.target;
    const textWidth = textNode.width();
    const textCenterX = textNode.x() + textWidth / 2;

    // Calculate canvas center
    const canvasCenterX = stageWidth / 2;

    // Calculate guideline position relative to canvas center
    const guidelineXPos = canvasCenterX - (textCenterX - canvasCenterX);

    // Show the guideline and set its position
    setShowGuideline(true);
    setGuidelineX(guidelineXPos);
    setGuidelineY(0); // Align with top of the stage
  };

  const handleFontHoverName = (font) => {
    setHoveredFontName(font);
  };
  const handleFontHoverNum = (font) => {
    setHoveredFontNum(font);
  };

  const handleSelect = (option) => {
    setFontFamily(option);
  };

  const handleNameFontChange = (val) => {
    setNameFontFamily(val);
  };

  useEffect(() => {
    const userPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS)
      ? JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
      : null;
    if (!userPlanDetails) {
      navigate("/plans");
    }
    handleNameNumberDetails();
  }, []);

  useEffect(() => {
    const div1 = document.getElementById("div1");
    if (
      localStorage.getItem("bgname") &&
      localStorage.getItem("bgname") !== ""
    ) {
      div1.style.visibility = "hidden"; // Hide the div
    } else {
      div1.style.display = "inline-block"; // Show the div
    }
  });
  useEffect(() => {
    const div3 = document.getElementById("div3");
    if (playerNo !== "00" || playerNo == "") {
      div3.style.visibility = "hidden"; // Hide the div
    } else {
      div3.style.display = "inline-block"; // Show the div
    }
  });

  if (
    Existingplayernamedetails != null &&
    Existingplayernumberdetails != null
  ) {
    //Number Details
    // setPlayerName(Existingplayernamedetails.Name);
    // setNameTextSize(Existingplayernamedetails.NametextSize);
    // setNameFontFamily(Existingplayernamedetails.NamefontFamily);
    // setNameTextPosition(Existingplayernamedetails.NametextPosition);
    // setNameTextColor(Existingplayernamedetails.NametextColor);
    // setNameOutLineColor(Existingplayernamedetails.NameoutlineColor);
    // setNameTextBorder(Existingplayernamedetails.NametextBorder);
    // setNameRotationAngle(Existingplayernamedetails.NamerotationAngle);
    //Name Details
    // setPlayerNo(Existingplayernumberdetails.No);
    // setTextSize(Existingplayernumberdetails.textSize);
    // setFontFamily(Existingplayernumberdetails.fontFamily);
    // setTextPosition(Existingplayernumberdetails.textPosition);
    // setTextColor(Existingplayernumberdetails.textColor);
    // setOutLineColor(Existingplayernumberdetails.outlineColor);
    // setNoTextBorder(Existingplayernumberdetails.NotextBorder);
    // setRotationAngle(Existingplayernumberdetails.rotationAngle);
  }

  const { isOpen, message, openPopup, closePopup } = usePopup();

  const LoadImage = () => {
    // console.log(state.side);
    const [image] = useImage(
      state?.side === "front" ? fronttshirt : backtshirt
    );
    return <KonvaImage image={image} width={500} height={500} />;
  };
  const LoadBGImage = () => {
    const [image] = useImage(selectedImage);
    return (
      <KonvaImage
        image={image}
        width={320}
        height={500}
        style={{ position: "absolute", top: 0, left: 280, zIndex: 0 }}
        x={90.5}
      />
    );
    // return <Shape sceneFunc={(context, shape)=>{context.beginPath();
    //   context.moveTo(77, 8);
    //   context.quadraticCurveTo(155, 45, 240, 8);
    //   // context.lineTo(220, 50);
    //   context.lineTo(320, 33);
    //   context.lineTo(320, 500);
    //   context.lineTo(0, 500);
    //   context.lineTo(0, 33);
    //   context.closePath();
    //   context.fillStrokeShape(shape);}} strokeWidth={1} fillPatternImage={image} fillPatternScale={{x:0.65,y:0.7}} style={{ position: 'absolute', top: 0, left: 280,zIndex:0 }} x={90.5}></Shape>;
  };
  //Player No
  const handleTextColorClick = () => {
    setColorPickerVisible(!colorPickerVisible);
  };
  const handleOutlineColorClick = () => {
    setoutLineColorPickerVisible(!outLinecolorPickerVisible);
  };
  const handleToggleView = (newView) => {
    setView(newView);
  };

  const handlePlayerNoChange = (event) => {
    let inputValue = event.target.value;

    // Ensure only numeric characters are allowed
    inputValue = inputValue.replace(/\D/g, "");

    // Truncate the input value if it exceeds 3 digits
    if (inputValue.length > 3) {
      inputValue = inputValue.slice(0, 3);
    }

    // Update the state with the modified value
    setPlayerNo(inputValue);
    hideDiv3();
  };
  const handleTextSizeChange = (event) => {
    // console.log(event.target.value);
    setTextSize(event.target.value);
  };
  const handleTextColorChange = (color) => {
    setTextColor(color.target.value);
    hideDiv3();
  };
  const handleOutlineColorChange = (color) => {
    setOutLineColor(color.target.value);
  };
  const handleNoBorderChange = (event) => {
    setNoTextBorder(event.target.value);
  };
  const handleColorPickerClose = () => {
    setColorPickerVisible(false);
  };
  const handleFontFamilyChange = (selectedOption) => {
    setFontFamily(selectedOption.target.value);
  };

  const handleMouseDown = (event, type) => {
    // console.log(type);
    setDragStart({ x: event.clientX, y: event.clientY });
    // console.log(type);
    if (type === "number") {
      setIsDragging(true);
    } else if (type === "name") {
      setNameIsDragging(true);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setNameIsDragging(false);
  };

  const handleMouseMove = (event) => {
    const deltaX = event.clientX - dragStart.x;
    const deltaY = event.clientY - dragStart.y;

    if (isDragging) {
      setTextPosition({
        x: textPosition.x + deltaX,
        y: textPosition.y + deltaY,
      });
    } else if (NameisDragging) {
      setNameTextPosition({
        x: NametextPosition.x + deltaX,
        y: NametextPosition.y + deltaY,
      });
    }

    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleRotationChange = () => {
    const angle = parseFloat(rotationInputRef.current.value);
    setRotationAngle(isNaN(angle) ? 0 : angle);
  };

  //Player Name
  const handlePlayerNameToggleView = (newView) => {
    setView(newView);
  };

  const handlePlayerNameTextColorClick = () => {
    setNameColorPickerVisible(!NamecolorPickerVisible);
  };

  const handlePlayerNameColorPickerClose = () => {
    setNameColorPickerVisible(false);
  };

  const handlePlayerNameOutlineColorClick = () => {
    setNameoutLineColorPickerVisible(!NameoutLinecolorPickerVisible);
  };

  const handlePlayerNameChange = (event) => {
    setPlayerName(event.target.value);
  };
  const handlePlayerNameTextSizeChange = (event) => {
    setNameTextSize(event.target.value);
  };
  const handlePlayerNameBorderChange = (event) => {
    setNameTextBorder(event.target.value);
  };
  const handlePlayerNameTextColorChange = (color) => {
    setNameTextColor(color.target.value);
  };

  const handlePlayerNameOutlineColorChange = (color) => {
    setNameOutLineColor(color.target.value);
  };

  const handlePlayerNameFontFamilyChange = (selectedOption) => {
    setNameFontFamily(selectedOption.target.value);
  };

  const handlePlayerNameFontWeightChange = (selectedOption) => {
    setNameFontWeight(selectedOption.target.value);
  };
  const handlePlayerNumberFontWeightChange = (selectedOption) => {
    setNumberFontWeight(selectedOption.target.value);
  };

  const handlePlayerNameMouseMove = (event) => {
    if (isDragging) {
      const deltaX = event.clientX - dragStart.x;
      const deltaY = event.clientY - dragStart.y;

      setTextPosition({
        x: textPosition.x + deltaX,
        y: textPosition.y + deltaY,
      });

      setDragStart({ x: event.clientX, y: event.clientY });
    }
  };

  const handlePlayerNameRotationChange = () => {
    const angle = parseFloat(NamerotationInputRef.current.value);
    setNameRotationAngle(isNaN(angle) ? 0 : angle);
  };

  const handleImageChange = (e) => {
    handleNameNumberDetails(0);
    const file = e.target.files[0];
    localStorage.removeItem("bgImageDetails");
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        // console.log(reader.result);
        // localStorage.setItem('bgImageDetails',reader.result)
        localStorage.setItem("bgname", file.name);
        handleNameNumberDetails(0);
        hideDiv1();
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const handleNameClick = (e) => {
    setSelectedText(e.target);
    setNameSelected(true);
    setNoSelected(false);
    toggleAccordion1();
    setActiveAccordionItem("1");
    // console.log('accordion 1');// Open accordion item with eventkey 1
  };

  // Function to handle click or tap on number text
  const handleNumberClick = (e) => {
    setSelectedText(e.target);
    setNoSelected(true);
    setNameSelected(false);
    toggleAccordion2();
    setActiveAccordionItem("2");
    // console.log('accordion 2');// Open accordion item with eventkey 2
  };
  const handleNameNumberDetails = async (ev) => {
    const canvas = canvasRef.current;

    var canvaseNameX = (NametextPosition.x / canvas.attrs.width) * 100;
    var canvaseNameY = (NametextPosition.y / canvas.attrs.height) * 100;

    var canvaseNumberX = (textPosition.x / canvas.attrs.width) * 100;
    var canvaseNumberY = (textPosition.y / canvas.attrs.height) * 100;

    const textName = TextNameRef.current;

    var TextWidthPer = (textName.textWidth / canvas.attrs.width) * 100;

    setNametextPositionPer({ x: canvaseNameX, y: canvaseNameY });
    setTextPositionPer({ x: canvaseNumberX, y: canvaseNumberY });

    var playernamedetails = {
      Name: playerName,
      NametextSize: NametextSize,
      NamefontFamily: NamefontFamily,
      NametextPosition: NametextPosition,
      NametextColor: NametextColor,
      NameoutlineColor: NameoutlineColor,
      // NamerotationAngle:NamerotationAngle,
      NamerotationAngle: TextNameRef.current.attrs.rotation,
      NametextBorder: NametextBorder,
      NametextPositionPer: NametextPositionPer,
      NametextSizePer: (NametextSize / canvas.attrs.width) * 100,
      NametextBorderPer: (NametextBorder / canvas.attrs.width) * 100,
      TextWidthPer: TextWidthPer,
      NameFontStyle: nameFontWeight,
      NameWidth: playerNameWidth,
      NameWidthPer: (playerNameWidth / canvas.attrs.width) * 100,
      NameScale: {
        x: TextNameRef.current.attrs.scaleX,
        y: TextNameRef.current.attrs.scaleY,
      },
    };
    var playernumberdetails = {
      No: playerNo,
      textSize: textSize,
      fontFamily: fontFamily,
      textPosition: textPosition,
      textColor: textColor,
      outlineColor: outlineColor,
      // rotationAngle:rotationAngle,
      rotationAngle: TextNoRef.current.attrs.rotation,
      NotextBorder: NotextBorder,
      textPositionPer: textPositionPer,
      textSizePer: (textSize / canvas.attrs.width) * 100,
      NotextBorderPer: (NotextBorder / canvas.attrs.width) * 100,
      numberFontStyle: numberFontWeight,
      NoWidth: playerNoWidth,
      NoWidthPer: (playerNoWidth / canvas.attrs.width) * 100,
      NoScale: {
        x: TextNoRef.current.attrs.scaleX,
        y: TextNoRef.current.attrs.scaleY,
      },
    };

    // console.log(playernamedetails);
    setPlayersName(playernamedetails.Name);
    // console.log(playernumberdetails);
    if (history.length == 0) {
      historyStep = 0;
    } else if (history.length !== historyStep) {
      historyStep = history.length;
    } else {
      historyStep = historyStep + 1;
    }
    // console.log(historyStep);
    var historyrem = history.slice(0, historyStep + 1);
    var historydts = [
      ...historyrem,
      {
        selectedImage: selectedImage,
        playernamedetails: playernamedetails,
        playernumberdetails: playernumberdetails,
      },
    ];
    setHistory(historydts);
    // console.log(history);
    if (ev === 1) {
      localStorage.setItem(
        "playernamedetails",
        JSON.stringify(playernamedetails)
      );
      localStorage.setItem(
        "playernumberdetails",
        JSON.stringify(playernumberdetails)
      );
      // localStorage.setItem('bgImageDetails',selectedImage)
    }

    // const dataUrl = await htmlToImage.toPng(DesignImage.current);
    // const link = document.createElement('a');
    //         link.href = dataUrl;
    //         link.download = 'Designimage.png';
    //         link.click();
  };

  const handleMouseKeyUp = (e) => {
    console.log(e);
  };

  const UndoRedoUpdate = (obj) => {
    setPlayerName(obj.playernamedetails.Name);
    setNameTextSize(obj.playernamedetails.NametextSize);
    setNameFontFamily(obj.playernamedetails.NamefontFamily);
    setNameTextBorder(obj.playernamedetails.NametextBorder);
    setNameTextPosition(obj.playernamedetails.NametextPosition);
    setNameTextColor(obj.playernamedetails.NametextColor);
    setNameOutLineColor(obj.playernamedetails.NameoutlineColor);
    setNameRotationAngle(obj.playernamedetails.NamerotationAngle);
    setPlayerNameWidth(obj.playernamedetails.NameWidth);
    setNameScale(obj.playernamedetails.NameScale);
    setSelectedImage(obj.selectedImage);
    // console.log(obj.playernamedetails);
    setPlayerNo(obj.playernumberdetails.No);
    setTextSize(obj.playernumberdetails.textSize);
    setFontFamily(obj.playernumberdetails.fontFamily);
    setTextPosition(obj.playernumberdetails.textPosition);
    setTextColor(obj.playernumberdetails.textColor);
    setOutLineColor(obj.playernumberdetails.outlineColor);
    setNoTextBorder(obj.playernumberdetails.NotextBorder);
    setRotationAngle(obj.playernumberdetails.rotationAngle);
    setPlayerNoWidth(obj.playernumberdetails.NoWidth);
    setNoScale(obj.playernumberdetails.NoScale);
  };

  const handelUndo = (e) => {
    if (historyStep === 0) {
      return;
    }
    historyStep = historyStep - 1;
    // console.log(historyStep);
    // console.log(history);
    const previous = history[historyStep];
    // console.log(previous);
    setUndoRedo(1);
    if (previous != undefined) {
      UndoRedoUpdate(previous);
    }
  };

  const handelRedo = (e) => {
    if (historyStep === history.length - 1) {
      return;
    }
    historyStep = historyStep + 1;
    // console.log(historyStep);
    // console.log(history);
    const next = history[historyStep];
    // console.log(next);
    setUndoRedo(1);
    if (next != undefined) {
      UndoRedoUpdate(next);
    }
  };
  useEffect(() => {
    // document.addEventListener('mouseup',handleMouseKeyUp())
    if (IsNameSelected) {
      // console.log(TextNameRef.current);
      TextNoTranRef.current.nodes([TextNameRef.current]);
    }

    if (IsNoSelected) {
      TextNoTranRef.current.nodes([TextNoRef.current]);
    }

    // setPlayerNameWidth(300);
    // setPlayerNoWidth(300);
    if (UndoRedo === 0) {
      handleNameNumberDetails(0);
    }

    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      console.log(event);
      // Chrome requires returnValue to be set
      event.returnValue = "";
      // localStorage.removeItem('playernamedetails');
      // localStorage.removeItem('playernumberdetails');
      // localStorage.removeItem('bgImageDetails');
      // localStorage.removeItem('tshirtchangedetails');
      // localStorage.removeItem('tshirtDetails');

      // state.selectedImage= null;
      // setSelectedImage(null)

      // var tshirtDetails = JSON.parse(localStorage.getItem('tshirtDetails'));
      // console.log(tshirtDetails);
      // if(tshirtDetails != null || tshirtDetails != undefined)
      // {
      //   // const response = window.confirm('Are you sure you want to refresh? Your variation changes may be lost.')
      //   // if(response)
      //   // {
      //     console.log(tshirtDetails);
      //     localStorage.removeItem('playernamedetails');
      //     localStorage.removeItem('playernumberdetails');
      //     localStorage.removeItem('bgname');
      //     localStorage.removeItem('tshirtchangedetails');
      //   // }

      // }

      //   // Add your custom logic here, for example, showing a confirmation dialog
      const message =
        "Are you sure you want to refresh? Your unsaved changes may be lost.";
      event.returnValue = message;
      return message;
    };
    setUndoRedo(0);
    // window.addEventListener('beforeunload',handleBeforeUnload);

    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, [
    view,
    playerNo,
    textPosition,
    textColor,
    textSize,
    fontFamily,
    outlineColor,
    NametextBorder,
    rotationAngle,
    playerName,
    NametextPosition,
    NametextColor,
    NametextSize,
    NamefontFamily,
    NotextBorder,
    NameoutlineColor,
    NamerotationAngle,
    IsNameSelected,
  ]);

  useEffect(() => {
    const detectionElement = document.createElement("div");
    detectionElement.style.fontFamily = "inherit";
    document.body.appendChild(detectionElement);
    const computedFont = window.getComputedStyle(detectionElement).fontFamily;
    // console.log(typeof computedFont)
    document.body.removeChild(detectionElement);
    var SystemFonts = computedFont.split(",");

    var fontlistarr = [...fontOptions];

    for (let i in SystemFonts) {
      var repeatFont = fontlistarr.find(
        (items) =>
          items.value == SystemFonts[i].replace('"', "").replace('"', "").trim()
      );
      if (repeatFont == undefined) {
        fontlistarr = [
          ...fontlistarr,
          {
            value: SystemFonts[i].replace('"', "").replace('"', "").trim(),
            label: SystemFonts[i].replace('"', "").replace('"', "").trim(),
          },
        ];
      }
    }
    fontOptions = fontlistarr;
  }, []);
  // console.log(activeAccordionItem);

  const navigateToVariation = () => {
    try {
      let obj = {};
      window.gtag("event", "Add Variation", obj);
    } catch (error) { }
    const playerNamedetails = localStorage.getItem("playernamedetails");

    // Check if playerNamedetails exists in localStorage and if its "Name" value is not equal to 'Sample text'
    // if (playerNamedetails && JSON.parse(playerNamedetails).Name !== 'Sample text' && bgName) {
    // if (playerName !== 'Sample text') {
    let obj = {
      showName: showPlayerName,
      showNum: showPlayerNum,
    };
    localStorage.setItem("showPlayerNameNum", JSON.stringify(obj));

    // Navigate to Variation page
    handleNameNumberDetails(1);
    navigate("/Variation", {
      state: { selectedImage: selectedImage, side: state?.side },
    });
    // } else {
    //   // Display error messages based on conditions
    //   if (!playerNamedetails || JSON.parse(playerNamedetails).Name === 'Sample text') {
    //     openPopup('Please enter Name');
    //   }
    // }
  };
  const addVariation = () => {
    var variationdts = localStorage.getItem("tshirtDetails");
    if (variationdts == null || variationdts == undefined) {
      // openPopup("Add Variation");
      setShowAddVariationPopup(true);
    } else {
      navigate("/export-new", {
        state: { selectedImage: selectedImage, side: state.side },
      });
    }
  };
  const [accordion1Open, setAccordion1Open] = useState(false);
  const [accordion2Open, setAccordion2Open] = useState(false);

  const toggleAccordion1 = () => {
    setShowAddFontPopup(false);
    setAccordion1Open(!accordion1Open);
    if (accordion2Open) {
      setAccordion2Open(false); // Close accordion2 if it's open
    }
  };

  const toggleAccordion2 = () => {
    setShowAddFontPopup(false);
    setAccordion2Open(!accordion2Open);
    if (accordion1Open) {
      setAccordion1Open(false); // Close accordion1 if it's open
    }
  };

  // console.log(playersName);
  const hideDiv3 = () => {
    const div3 = document.getElementById("div3");
    if (playerNo !== "") {
      div3.style.visibility = "hidden"; // Hide the div
    } else {
      div3.style.display = "inline-block"; // Show the div
    }
  };

  const hideDiv1 = () => {
    const div1 = document.getElementById("div1");
    if (localStorage.getItem("bgname") !== "") {
      div1.style.visibility = "hidden"; // Hide the div
    } else {
      div1.style.display = "inline-block"; // Show the div
    }
  };

  const handleShowPlayerNameNum = (type) => {
    if (type === "name") {
      // let local = localStorage.getItem('showPlayerNameNum')
      // let obj = local ? JSON.parse(local) : {}
      // obj['showName'] = !showPlayerName
      // localStorage.setItem('showPlayerNameNum', JSON.stringify(obj))
      if (showPlayerName) {
        setPlayerName("");
      } else {
        setPlayerName("Sample Text");
      }
      setShowPlayerName(!showPlayerName);
    } else {
      // let local = localStorage.getItem('showPlayerNameNum')
      // let obj = local ? JSON.parse(local) : {}
      // obj['showNum'] = !showPlayerNum
      // localStorage.setItem('showPlayerNameNum', JSON.stringify(obj))
      if (showPlayerNum) {
        setPlayerNo("");
      } else {
        setPlayerNo("00");
      }
      setShowPlayerNum(!showPlayerNum);
    }
  };

  const fontClickHandler = (type) => {
    setShowFontType(type);
    setShowAddFontPopup(!showAddFontPopup);
  };
  const closeAddFontPopup = () => setShowAddFontPopup(false);

  return (
    <>
      <div id="main-container" className="container-fluid main">
        <Sidebar></Sidebar>
        <section className="home">
          <div className="container-fluid">
            <div className="row ">
              <div class="col-xxl-2 col-xl-3 col-sm-3  sidebarchange">
                <div className="sidebar_stick">
                  <div className="custom-side">
                    <p className="side-title">Steps</p>

                    {/* <Form.Select aria-label="Default select example" className="mb-2" value={selectedValue}>
                              <option>XS 34 (19 x 27 in)</option>
                              <option>S 36 (20 x 28 in)</option>  
                              <option>M 38 (21 x 29 in)</option>
                              <option>L 40 (22 x 30 in)</option>
                              <option>XL 42 (23 x 31 in)</option>
                              <option>2XL 44 (24 x 32 in)</option> 
                              <option>3XL 46 (25 x 33 in)</option>
                              <option>4XL 48 (26 x 34 in)</option>
                              <option>5XL 50 (27 x 35 in)</option>
                              <option>6XL 52 (28 x 35 in)</option>    
                            
                          </Form.Select> */}
                    {/* <Dropdown className="mb-2">
                            <Dropdown.Toggle id="dropdown-basic">Medium Size 38 (21 x 29)
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu">
                                <Dropdown.Item href="#/action-1">Medium Size 38 (21 x 29) </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Large Size 38 (21 x 29) </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}

                    {/* <Form.Group controlId="formFile" className="mb-2">
                            <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Add background image"
                                readOnly
                                style={{ color: 'black' }}
                            />
                            <InputGroup.Text id="inputGroupAppend">
                                
                                <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: '5px' }} />
                            </InputGroup.Text>
                            
                            </InputGroup>
                        </Form.Group> */}
                    <Form.Group controlId="formFile" className="mb-2 ">
                      <InputGroup>
                        {/* <Form.Control
                            type="text"
                            placeholder="Add background image"
                            readOnly
                            style={{ color: "black" }}
                            className="bg-img"
                          /> */}
                        <div className="img_up">
                          <h6>Add background image</h6>
                          <div className="img_up_div">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              id="imageInput"
                            />
                            <img
                              src={"/assets/images/uploadbgcm.svg"}
                              alt="file"
                            />
                          </div>
                          {/* <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                              <InputGroup.Text id="inputGroupAppend" className="bg-img">
                                <div className="bg-ff">
                                  
                                </div>

                                <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: '5px' }} />
                              </InputGroup.Text>
                            </label> */}
                        </div>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div>
                    <div className="accordionn mb-2">
                      <div className="custom-accordion acco1">
                        <div className="accordion-header mx-2">
                          {/* <label className="container_cm">
                          <input type="checkbox" checked={showPlayerName} onChange={() => handleShowPlayerNameNum('name')} />
                          <span className="checkmark_cm"></span>
                        </label> */}

                          <div
                            style={{
                              width: "100%",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={toggleAccordion1}
                          >
                            <span className="playname"> Player Name </span>
                            {/* <span className="bg-dd" style={{ position: 'absolute', right: '0' }}> */}
                            <span style={{ position: "absolute", right: "0" }}>
                              {" "}
                              <img
                                src={"/assets/images/accorddown.svg"}
                                alt="accord"
                              />
                              {/* <svg

                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down svgg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 10.598L1.646 4.354a.5.5 0 0 1 .708-.708L8 9.182l5.646-5.536a.5.5 0 1 1 .708.708L8 10.598z" />
                            </svg> */}
                            </span>
                          </div>
                        </div>
                        {accordion1Open && (
                          <div className="accordionn-content">
                            <div>
                              <Form className="pad_des">
                                {/* <FontAwesomeIcon icon="" /> */}
                                {/* <div className="mb-2 row custombackground">
                                <div className="col-3 d-flex align-items-center">
                                  <InputGroup.Text
                                    id="inputGroup-sizing-default"
                                    className="custom-input-group-text"
                                    style={{
                                      background: "white",
                                      height: "38px",
                                      fontSize: "12px",
                                      color: "gray",
                                    }}
                                  >
                                    Text
                                  </InputGroup.Text>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                  <Form.Control
                                    value={playerName}
                                    onChange={handlePlayerNameChange}
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    className="text-end"
                                    type="text"
                                    maxLength="12"
                                  />
                                </div>
                              </div> */}
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Font
                                    </InputGroup.Text>
                                  </div>
                                  <div
                                    onClick={() => fontClickHandler("name")}
                                    className="col-9 d-flex align-items-center"
                                  >
                                    <p
                                      className="tabHeading"
                                      style={{ margin: "0" }}
                                    >
                                      {NamefontFamily}
                                    </p>

                                    {/* <CustomFontDropdown
                                      options={fontOptions}
                                      onSelect={handleNameFontChange}
                                      fontStyles={fontStyles}
                                      selected={NamefontFamily}
                                    /> */}
                                    {/* <AddFonts fontOf={'name'} selected={NamefontFamily} onSelect={handleNameFontChange} /> */}
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Font Style
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center">
                                    <Form.Select
                                      aria-label="Default select example"
                                      value={nameFontWeight}
                                      onChange={
                                        handlePlayerNameFontWeightChange
                                      }
                                      className="custom-select text-end"
                                      style={{ border: "none" }}
                                    >
                                      {fontWeight.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                          style={fontWeightStyle[option.value]}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {/* <input type="number" value={nameFontWeight} onChange={(e) => setNameFontWeight(e.target.value)}  /> */}
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Text Color</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    {/* Additional content in the second column */}
                                    {/* <div
                          onClick={handlePlayerNameTextColorClick}
                          style={{
                            width: '20px',
                            height: '20px',
                            background: NametextColor,
                            marginLeft: 'auto',
                            border: '1px solid rgb(0,0,0,0.1)'
                          }}
                        ></div> */}

                                    <Form.Control
                                      type="color"
                                      style={{
                                        border: "1px solid black",
                                        padding: "0px",
                                        height: "25px",
                                        width: "30px",
                                        borderRadius: "8px",
                                      }}
                                      id="exampleColorInput"
                                      value={NametextColor}
                                      onChange={(e) => {
                                        handlePlayerNameTextColorChange(e);
                                      }}
                                      onBlur={handlePlayerNameTextColorClick} // Close the color picker on blur
                                    />
                                  </div>
                                </div>
                                <div className="mb-4 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Text Size</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    <input
                                      type="range"
                                      max={700}
                                      value={NametextSize}
                                      onChange={handlePlayerNameTextSizeChange}
                                      className="custom-select text-end texttb"
                                    />
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Border</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    <input
                                      type="number"
                                      value={NametextBorder}
                                      onChange={handlePlayerNameBorderChange}
                                      className="custom-select text-end texttb"
                                    />
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Border Color</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    {/* Additional content in the second column */}

                                    <Form.Control
                                      type="color"
                                      id="exampleColorInput"
                                      style={{
                                        border: "1px solid black",
                                        padding: "0px",
                                        height: "25px",
                                        width: "30px",
                                        borderRadius: "8px",
                                      }}
                                      defaultValue={NameoutlineColor}
                                      onChange={(e) => {
                                        handlePlayerNameOutlineColorChange(e);
                                      }}
                                      onBlur={handlePlayerNameOutlineColorClick} // Close the color picker on blur
                                    />
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Rotation</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    {/* Additional content in the second column */}
                                    <input
                                      type="range"
                                      min={0}
                                      max={360}
                                      value={NamerotationAngle}
                                      ref={NamerotationInputRef}
                                      onChange={handlePlayerNameRotationChange}
                                      placeholder="Enter rotation angle"
                                      className="custom-select text-end texttb"
                                    />
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="accordionn mb-2">
                      <div className="custom-accordion acco2 ">
                        <div className="accordion-header mx-2">
                          {/* <label className="container_cm">
                          <input

                            type="checkbox"
                            checked={showPlayerNum}
                            onChange={() => handleShowPlayerNameNum('number')}
                          />
                          <span className="checkmark_cm"></span>
                        </label> */}

                          <div
                            style={{
                              width: "100%",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={toggleAccordion2}
                          >
                            <span className="playname">Player Number</span>
                            {/* <span className="bg-dd" style={{ position: 'absolute', right: '0' }}> */}
                            <span style={{ position: "absolute", right: "0" }}>
                              <img
                                src={"/assets/images/accorddown.svg"}
                                alt="accord"
                              />
                            </span>
                          </div>
                        </div>
                        {accordion2Open && (
                          <div className="accordionn-content">
                            <div>
                              <Form className="pad_des">
                                {/* <InputGroup className="mb-2 custombackground">
                                        <InputGroup.Text id="inputGroup-sizing-default" className="custom-input-group-text">
                                        Text
                                        </InputGroup.Text>
                                        <Form.Control
        value={playerName}
        onChange={handlePlayerNameChange}
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        className="text-end"
    />
                                    </InputGroup> */}
                                {/* <FontAwesomeIcon icon="" /> */}
                                {/* <div className="mb-2 row custombackground">
                                <div className="col-3 d-flex align-items-center">
                                  <InputGroup.Text
                                    id="inputGroup-sizing-default"
                                    className="custom-input-group-text"
                                    style={{
                                      background: "white",
                                      height: "38px",
                                      fontSize: "12px",
                                      color: "gray",
                                    }}
                                  >
                                    Text
                                  </InputGroup.Text>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                  <Form.Control
                                    value={playerNo}
                                    type="number"
                                    maxLength="3"
                                    defaultValue={playerNo}
                                    onChange={handlePlayerNoChange}
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    className="text-end"
                                  />
                                </div>
                              </div> */}
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Font
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center">
                                    <p
                                      className="tabHeading"
                                      onClick={() => fontClickHandler("number")}
                                    >
                                      {fontFamily}
                                    </p>
                                    {/* <CustomFontDropdown
                                      options={fontOptions}
                                      onSelect={handleSelect}
                                      fontStyles={fontStyles}
                                      select  ed={fontFamily}
                                    /> */}
                                    {/* <AddFonts fontOf={'number'} selected={fontFamily} onSelect={handleSelect} /> */}
                                  </div>
                                </div>

                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Font Style
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center">
                                    <Form.Select
                                      aria-label="Default select example"
                                      value={numberFontWeight}
                                      onChange={
                                        handlePlayerNumberFontWeightChange
                                      }
                                      className="custom-select text-end"
                                      style={{ border: "none" }}
                                    >
                                      {fontWeight.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                          style={fontWeightStyle[option.value]}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    {/* <input type="number" value={nameFontWeight} onChange={(e) => setNameFontWeight(e.target.value)}  /> */}
                                  </div>
                                </div>

                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Text Color</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    {/* Additional content in the second column */}

                                    <Form.Control
                                      type="color"
                                      id="exampleColorInput"
                                      style={{
                                        border: "1px solid black",
                                        padding: "0px",
                                        height: "25px",
                                        width: "30px",
                                        borderRadius: "8px",
                                      }}
                                      defaultValue={textColor}
                                      onChange={(e) => {
                                        handleTextColorChange(e);
                                      }}
                                      onBlur={handleTextColorClick} // Close the color picker on blur
                                    />
                                  </div>
                                </div>
                                {/* <div className="mb-2 row custombackground">
  <div className="col-3 d-flex align-items-center">
    <InputGroup.Text
      id="inputGroup-sizing-default"
      className="custom-input-group-text"
      style={{ background: 'white', height: '38px',fontSize:'12px', color:'gray'  }}
    >
      Text Size
    </InputGroup.Text>
  </div>
  <div className="col-9 d-flex align-items-center">
  <Form.Select
                  aria-label="Default select example"
                  value={textSize}  
                   onChange={handleTextSizeChange}
                  className="custom-select text-end"
                  style={{ border: 'none' }}
                >
                         {fontSizes.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}

                 
                </Form.Select>
  </div>
 
</div> */}
                                <div className="mb-4 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Text Size
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    <input
                                      type="range"
                                      max={700}
                                      value={textSize}
                                      onChange={handleTextSizeChange}
                                      className="custom-select text-end texttb"
                                    />
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Border
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    <input
                                      type="number"
                                      value={NotextBorder}
                                      onChange={handleNoBorderChange}
                                      className="custom-select text-end texttb"
                                    />
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Border Color</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    {/* Additional content in the second column */}

                                    <Form.Control
                                      type="color"
                                      id="exampleColorInput"
                                      style={{
                                        border: "1px solid black",
                                        padding: "0px",
                                        height: "25px",
                                        width: "30px",
                                        borderRadius: "8px",
                                      }}
                                      defaultValue={outlineColor}
                                      onChange={(e) => {
                                        handleOutlineColorChange(e);
                                      }}
                                      onBlur={handleOutlineColorClick} // Close the color picker on blur
                                    />
                                  </div>
                                </div>
                                <div className="mb-2 row custombackground">
                                  <div className="col-3 d-flex align-items-center">
                                    <InputGroup.Text
                                      id="inputGroup-sizing-default"
                                      className="custom-input-group-text"
                                      style={{
                                        background: "white",
                                        height: "38px",
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      <div>Rotation</div>
                                    </InputGroup.Text>
                                  </div>
                                  <div className="col-9 d-flex align-items-center justify-content-end">
                                    {/* Additional content in the second column */}
                                    <input
                                      type="range"
                                      min={0}
                                      max={360}
                                      value={rotationAngle}
                                      ref={rotationInputRef}
                                      onChange={handleRotationChange}
                                      placeholder="Enter rotation angle"
                                      className="custom-select text-end texttb"
                                    />
                                  </div>
                                </div>
                              </Form>
                            </div>{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-10 col-xl-9 col-sm-9">
                <div className="row row_pad">
                  <ul className="d-flex col-4 custom-tabs">
                    <li
                      className="active"
                      onClick={() => {
                        handleNameNumberDetails(1);
                        navigate("/Design", {
                          state: {
                            selectedImage: selectedImage,
                            side: state.side,
                          },
                        });
                      }}
                    >
                      Design
                    </li>
                    <li className="mx-2" onClick={navigateToVariation}>
                      Variation
                    </li>
                    <li
                      className="mx-2"
                      onClick={() => {
                        handleNameNumberDetails(1);
                        addVariation();
                      }}
                    >
                      Export
                    </li>
                  </ul>
                  <div className="col-sm-4">
                    <ImagesRemaining
                      plan={plan}
                      userVariantDetails={userVariantDetails}
                    />
                  </div>
                  <div className="col-sm-4">
                    <button
                      className="addvar_btn"
                      onClick={navigateToVariation}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Add Variation
                    </button>
                  </div>
                </div>

                <div className="mt-5">
                  {/* <div className="col-1 tool-btn">
                            <Button className="mb-2">
                            <img src={undo} alt="undo" />
                            <span>Undo</span>
                            </Button> 
                            <Button className="mb-2">
                            <img src={redo} alt="redo" />
                                <span>Redo</span>
                            </Button> 
                            <Button className="mb-2">
                            <FontAwesomeIcon icon={faSearchPlus} style={{ marginRight: '5px' }} />
                                <span>Zoom</span>
                            </Button> 
                        </div> */}
                  <div className="ted_main">
                    <div id="container" style={{ display: "flex" }}>
                      <div className="Action-btn-div ted_action_div">
                        <button
                          className="btn undo-redo-btn"
                          onClick={(e) => {
                            handelUndo(e);
                          }}
                        >
                          <img src={undoblack} />
                        </button>
                        <br />
                        <button
                          className="btn undo-redo-btn"
                          onClick={(e) => {
                            handelRedo(e);
                          }}
                        >
                          <img src={redoblack} />
                        </button>

                        {/* <AddFonts /> */}
                        {showAddFontPopup &&
                          (accordion1Open || accordion2Open) && (
                            <AddFonts
                              fontOptions={fontOptions}
                              setShowAddFontPopup={setShowAddFontPopup}
                              close={closeAddFontPopup}
                              fontOf={showFontType}
                              selected={
                                showFontType === "number"
                                  ? fontFamily
                                  : NamefontFamily
                              }
                              onSelect={
                                showFontType === "number"
                                  ? handleSelect
                                  : handleNameFontChange
                              }
                              onHover={showFontType === "number" ? handleFontHoverNum : handleFontHoverName}
                            />
                          )}
                      </div>
                      <div
                        style={{ position: "relative" }}
                        className="tshirt-draw-canvas"
                        ref={DesignImage}
                      >
                        <svg
                          className="svg-bg-img"
                          xmlns="http://www.w3.org/2000/svg"
                          ref={canvasRefName}
                        >
                          <rect
                            x="0"
                            y="0"
                            width="320"
                            height="500"
                            fill="white"
                          />
                          {/* {selectedImage && <image href={selectedImage}  className='tshirt-bg-img'/>} */}
                          {/* {backgroundImage && <image href={backgroundImage} x="40" y="0" width="320" height="500" style={{border:'1px solid red;' }}/>} */}

                          <circle
                            cx="160"
                            cy="-123"
                            r="160"
                            fill="white"
                            className="neck-circle"
                          />
                        </svg>

                        <Stage width={500} height={500} className="ts-img">
                          <Layer>
                            <LoadImage />
                            {selectedImage && <LoadBGImage x={285} />}
                          </Layer>
                        </Stage>
                        <Stage
                          width={320}
                          height={500}
                          className="ts-detail"
                          ref={canvasRef}
                        >
                          <Layer>
                            {
                              <Text
                                key={`name-text-${nameFontWeight}`}
                                ref={TextNameRef}
                                text={playerName}
                                fontSize={NametextSize}
                                fontStyle={nameFontWeight}
                                draggable
                                x={NametextPosition.x}
                                y={NametextPosition.y}
                                fill={NametextColor}
                                fontFamily={hoveredFontName || NamefontFamily}
                                onDragMove={handleTextDragMove}
                                onDragEnd={(e) => {
                                  // console.log(e.target);
                                  setNameTextPosition({
                                    x: e.target.x(),
                                    y: e.target.y(),
                                  });
                                  setShowGuideline(false);
                                }}
                                stroke={NameoutlineColor}
                                strokeWidth={NametextBorder}
                                onClick={handleNameClick}
                                onTap={handleNameClick}
                                align="center"
                                width={playerNameWidth}
                                rotation={NamerotationAngle}
                                scaleX={NameScale.x}
                                scaleY={NameScale.y}
                              />
                            }
                            {showGuideline && (
                              <Line
                                points={[
                                  guidelineX,
                                  0,
                                  guidelineX,
                                  canvasRef.current.height(),
                                ]}
                                stroke="red"
                                strokeWidth={2}
                                dash={[5, 5]}
                              />
                            )}
                            {/* {IsNameSelected && <Transformer ref={TextNameTranRef} keepRatio={false} enabledAnchors={[
                                                              'top-left',
                                                              'top-right',
                                                              'bottom-left',
                                                              'bottom-right',
                                                            ]}/>} */}
                            <Text
                              key={`number-text-${numberFontWeight}`}
                              ref={TextNoRef}
                              text={playerNo}
                              fontSize={textSize}
                              draggable
                              x={textPosition.x}
                              y={textPosition.y}
                              fill={textColor}
                              fontFamily={hoveredFontNum || fontFamily}
                              fontStyle={numberFontWeight}
                              onDragMove={handleTextDragMove}
                              onDragEnd={(e) => {
                                // console.log(e.target);
                                setTextPosition({
                                  x: e.target.x(),
                                  y: e.target.y(),
                                });
                              }}
                              stroke={outlineColor}
                              strokeWidth={NotextBorder}
                              onClick={handleNumberClick}
                              onTap={handleNumberClick}
                              align="center"
                              width={playerNoWidth}
                              rotation={rotationAngle}
                              scaleX={NoScale.x}
                              scaleY={NoScale.y}
                            />
                            <Transformer ref={TextNoTranRef} />
                          </Layer>
                        </Stage>
                        <div
                          style={{ position: "absolute", top: 70, right: -285 }}
                        >
                          <div
                            style={{
                              marginBottom: 60,
                              display: "inline-block",
                              marginLeft: 40,
                            }}
                          >
                            <div id="div1">
                              <div
                                style={{
                                  width: 150,
                                  marginBottom: 5,
                                  height: 1,
                                  backgroundColor: "black",
                                  display: "inline-block",
                                }}
                              ></div>
                              <div className="rounded-div">1</div>
                              <span>&nbsp; Add Tee Shirt Design</span>
                            </div>
                          </div>
                          <br />
                          <div
                            style={{
                              marginBottom: 100,
                              display: "inline-block",
                              marginLeft: 30,
                            }}
                          >
                            {playersName?.toLowerCase() == "player name" && (
                              <div>
                                <div
                                  style={{
                                    width: 200,
                                    marginBottom: 5,
                                    height: 1,
                                    backgroundColor: "black",
                                    display: "inline-block",
                                  }}
                                ></div>
                                <div className="rounded-div">2</div>
                                <span>&nbsp; Add Name</span>
                              </div>
                            )}
                          </div>
                          <br />
                          <div
                            id="div3"
                            style={{
                              marginBottom: 10,
                              display: "inline-block",
                              marginRight: 80,
                              position: "sticky",
                            }}
                          >
                            {playerNo == "00" && (
                              <>
                                <div
                                  style={{
                                    width: 180,
                                    marginBottom: 5,
                                    height: 1,
                                    backgroundColor: "black",
                                    display: "inline-block",
                                  }}
                                ></div>
                                <div className="rounded-div">3</div>
                                <span>&nbsp; Add Number</span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tsize">
                      <div className="tsize-action-div">
                        <div className="tshitrt_size">
                          <h6>Upload Background Image</h6>
                          {selectedImage && (
                            <div className="px-1">
                              <img
                                onClick={handleImageRemove}
                                src={"/assets/images/delimg.svg"}
                                alt=""
                              />
                            </div>
                          )}
                          <div>
                            <div className="inputimg_pre tabHeading">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                id="imageInput2"
                              />
                              <img
                                htmlFor="imageInput2"
                                src={"/assets/images/uploadbgcm.svg"}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>

                        <h6 className="bet_head">
                          For better results maintain below size
                        </h6>

                        <h6 className="exp_pix">
                          Expected Image Pixels (11200 x 16092 Px) (300-DPI)
                        </h6>

                        {/* <Form.Group controlId="formFile" className="mb-3 mx-auto">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="Add background image"
                              readOnly
                            />

                            <InputGroup.Text id="inputGroupAppend">
                              <FontAwesomeIcon
                                onClick={handleImageRemove}
                                icon={faTrash}
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                                id="imageInput2"
                              />
                              <label
                                htmlFor="imageInput"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={addfile}
                                  htmlFor="imageInput2"
                                  alt="file"
                                />
                              </label>
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>

                        <Form.Label className="m-2">
                          Expected Image Pixels  (28 X 35 in) (11200 x 16092 Px)
                        </Form.Label> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-1 tool-btn">
                            <Button className={`mb-2 ${view === 'front' ? 'active' : ''}`} onClick={() => handleToggleView('front')}>                            <FontAwesomeIcon icon={faShirt} style={{ marginRight: '5px' }} />
                                <span>Front</span>
                            </Button> 
                            <Button className={`mb-2 ${view === 'back' ? 'active' : ''}`} onClick={()=> handleToggleView('back')}>
                            <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: '5px' }} />
                                <span>Add backside</span>
                            </Button> 
                           
                        </div> */}
                </div>
              </div>
            </div>
          </div>
          {showAddVariationPopup && <AddVariationPopup
            closePopup={() => setShowAddVariationPopup(false)}
            message={"Add variation first"}
          />}
          {/* <Popup isOpen={isOpen} message={message} closePopup={closePopup} /> */}
        </section>
        {/* <div ref={fontfamilyDropDown} style={{visibility:'hidden'}}></div> */}
      </div>
    </>
  );
}

export default Design;

import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { Check, X } from "react-feather";

import {
  Link,
  useSearchParams,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import Sidebar from "../component/Sidebar";
import { config } from "../utils/config";
import LazyImage from "../component/LazyImage";
import KEYS from "../env/env";
import { doRequest } from "../utils/helper";
import SearchHeader from "../Components/SearchHeader";
import SubscriptionEdit from "./SubscriptionEdit";
import UserBillingHistory from "./UserBillingHistory";

export default function EditProfile() {
  const [userDetails, setUserDetails] = useState({});
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [custImageValue, setCustImageValue] = useState("");
  const [existImage, setExistImage] = useState(true);
  const [changedImage, setChangedImage] = useState("");
  const [file, setFile] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fNameError, setfNameError] = useState(false);
  const [fNameTextError, setfNameTextError] = useState(false);
  const [lnameError, setLNameError] = useState(false);
  const [lNameTextError, setLNameTextError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);

  const [phoneTypeError, setPhoneTypeError] = useState(false);
  const [phone, setPhone] = useState("");
  const [gstValidateText, setGstValidateText] = useState("Validate");
  const [heading, setHeading] = useState("Your profile info in Teedex");

  const [params] = useSearchParams();
  const navigate = useNavigate();
  let { id } = useParams();
  const setup = params.get("setup");
  const plan = params.get("plan");
  const fTime = params.get("ftime");
  useEffect(() => {
    if (setup && setup == 1) {
      document.title = "Setup Your Profile";
      setHeading("Setup Your Profile");
    } else {
      document.title = "Teedex";
      setHeading("Your profile info in Teedex");
    }
  }, [setup]);

  // const mobileNumberInput = document.getElementById("mobileNumberInput");
  // if (mobileNumberInput) {
  //   mobileNumberInput.addEventListener("input", function () {
  //     let value = this.value;
  //     value = value.replace(/\D/g, ""); // Remove non-digit characters
  //     this.value = value;
  //     setUserDetails({
  //       ...userDetails,
  //       phone: value
  //     })
  //   });
  // }

  const gstValidateClickHandler = () => {
    setGstValidateText("Validating");
    setTimeout(() => setGstValidateText("Validate"), 400);
  };

  const imageChangeHandler = (img) => {
    if (!img) return;
    if (!img.type.startsWith("image/")) {
      setErrorMsg("Please upload an image file.");
      setShowError(true);
      console.log("image is not image");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setChangedImage(reader.result);
      setFile(img);
      setExistImage(false);
    };
    reader.readAsDataURL(img);
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      console.log("acceptedfiles ", acceptedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        let file = acceptedFiles[0];
        imageChangeHandler(file);
      }
    },
  });

  async function handleProfilePic(file) {
    if (!file) return;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("folder", "profile");
    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    try {
      let response = await fetch(`${KEYS.apiPath}file/save`, requestOptions);
      let data = await response.json();
      console.log("data", data);
      if (data && +data?.resultFlag === 1) {
        let imgUrl = data?.response?.url;
        // setChangedImage(config.s3BaseUrl + imgUrl);
        await updateUserDetails(config.s3BaseUrl + "/" + imgUrl);
      } else {
        setErrorMsg(data?.message ? data.message : "Something went wrong!");
        setShowError(true);
      }
    } catch (error) {
      setErrorMsg(error?.message ? error.message : "Something went wrong!");
      setShowError(true);
    }
  }

  const handleUpdateButton = async () => {
    if (changedImage) {
      await handleProfilePic(file);
    } else {
      await updateUserDetails();
    }
  };

  const updateUserDetails = async (image) => {
    let data = {
      id: userDetails["id"],
      data: {
        phone: userDetails["phone"],
        first_name: userDetails["first_name"],
        last_name: userDetails["last_name"],
        address: userDetails["address1"],
        email: userDetails["email"],
        state: userDetails["state"],
        address_2: userDetails["address2"],
        business_name: userDetails["business_name"],
        gst_no: userDetails["gst"],
      },
    };
    if (image) {
      data["data"]["user_image"] = image;
    }
    const response = await doRequest(data, "POST", "user/update");
    if (response && +response?.resultFlag === 1) {
      setUserDetails(response?.response?.user);
      localStorage.setItem(
        config.USER_LOGIN_DETAILS,
        JSON.stringify(response?.response?.user)
      );
      if (fTime == "1") {
        // navigate('/Design')
        navigate("/welcome" + window.location.search);
      } else {
        navigate("/Design");
      }
    }
  };

  console.log("userDetailsuserDetails", userDetails);

  const [showInput, setShowInput] = useState(false);
  const handleButtonClick = () => {
    setShowInput(!showInput);
  };

  const [showInputlastname, setShowInputlastname] = useState(false);
  const handleButtonClicklastname = () => {
    setShowInputlastname(!showInputlastname);
  };

  const [showInputemail, setShowInputemail] = useState(false);
  const handleButtonClickemail = () => {
    setShowInputemail(!showInputemail);
  };

  const [showInputphone, setShowInputphone] = useState(false);
  const handleButtonClickphone = () => {
    setShowInputphone(!showInputphone);
  };

  const [showInputbus, setShowInputbus] = useState(false);
  const handleButtonClickbus = () => {
    setShowInputbus(!showInputbus);
  };

  const [showInputaddress1, setShowInputaddress1] = useState(false);
  const handleButtonClickaddress1 = () => {
    setShowInputaddress1(!showInputaddress1);
  };

  const [showInputaddress2, setShowInputaddress2] = useState(false);
  const handleButtonClickaddress2 = () => {
    setShowInputaddress2(!showInputaddress2);
  };
  const [activeTab, setActiveTab] = useState("about");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getUserDetails = async (id) => {
    try {
      let res = await doRequest("", "GET", `user/list?id=${id}`);
      console.log("res is", res);
      if (res && +res?.resultFlag === 1) {
        let user = res?.response?.user;
        if (user?.length > 0) {
          setUserDetails(res?.response?.user[0]);
        } else {
          setUserDetails({});
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id && id != "") {
      getUserDetails(id);
    }
  }, [id]);

  return (
    <div id="main-container" className="container-fluid main">
      <Sidebar></Sidebar>
      <section className="home">
        <div className="container-fluid">
          <div className="searcmain_pad">
            <SearchHeader />
          </div>

          <div className="heading">
            <ul className="userman_breadcrumb">
              <li>
                <Link to="">User management</Link>
              </li>
              <li className="mang_pad">
                <img src={"/assets/images/man_chev.svg"} alt="" />
              </li>

              <li>
                <Link to="">User name</Link>
              </li>
            </ul>
          </div>

          <div className="container">
            <div className="editprofile_wid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card_profile">
                    <div className="editsva_flex">
                      <h4 className="basic_head">Basic Info</h4>
                      <button className="saveeditprofile_btn">Save Edit</button>
                    </div>

                    <div className="row row_cen">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5>Photo</h5>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="profile_desc">
                          <h5>A photo helps personalise your account</h5>
                        </div>
                      </div>

                      <div className="col-sm-3 btn_rank">
                        <div className="d-flex align-items-center phone_profile_center pb-2">
                          <Button
                            style={{ color: "" }}
                            onClick={() => open()}
                            className="upload_btn_position badge font-weight-bold"
                          >
                            {/* <Upload className="d-20" /> */}
                            {custImageValue === "" ? "Add Photo" : "Edit Photo"}
                          </Button>
                          <div className="dropzone rounded-circle imgbox_wid">
                            <div
                              {...getRootProps({
                                className: "dropzone-upload-wrapper",
                              })}
                            >
                              <input {...getInputProps()} />
                              <div className="dropzone-inner-wrapper imgbox_wid2 rounded-circle dropzone-avatar">
                                <div className="avatar-icon-wrapper imgbox_wid3 rounded-circle m-2">
                                  <div>
                                    {isDragAccept && (
                                      <div className="rounded-circle overflow-hidden imgbox_wid4 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                        <Check className="d-40" />
                                      </div>
                                    )}
                                    {isDragReject && (
                                      <div className="rounded-circle overflow-hidden imgbox_wid5 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                        <X className="d-60" />
                                      </div>
                                    )}
                                    {!isDragActive && (
                                      <div className="rounded-circle overflow-hidden imgbox_wid6 bg-neutral-dark text-center font-weight-bold text-black-50 d-flex justify-content-center align-items-center">
                                        {/* <User className="d-50" /> */}
                                      </div>
                                    )}
                                  </div>

                                  <div className="rounded-circle avatar-image overflow-hidden imgbox_wid7 text-center font-weight-bold text-success d-flex justify-content-center align-items-center up_file">
                                    <LazyImage
                                      className="img-fluid img-fit-container rounded-circle profile_img"
                                      src={
                                        existImage
                                          ? custImageValue
                                          : changedImage
                                      }
                                      // alt="..."
                                    />
                                    {/* {thumbs?.length > 0 && <div>{thumbs}</div>} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5>
                            <span className="text-danger">&#42;</span> First
                            Name
                          </h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInput ? (
                          <h3 className="usenameedit">
                            {userDetails["first_name"]}
                          </h3>
                        ) : (
                          <Input
                            required
                            className="form-control"
                            id="firstname"
                            invalid={
                              (fNameError ? true : false) ||
                              (fNameError ? true : false)
                            }
                            valid={userDetails["first_name"] ? true : false}
                            //  disabled={localStorage.getItem(config.CUST_SEARCH_USER_ID) == JSON.parse(localStorage.getItem(config.CUST_INFO_DETAILS)).CUST_ID ? false : true}
                            disabled={false}
                            placeholder="First Name"
                            type="text"
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                first_name: e.target.value.trim(),
                              });
                              if (e.target.value?.length > 0) {
                                setfNameError(false);
                                if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                  setfNameTextError(false);
                                } else {
                                  setfNameTextError(true);
                                }
                              } else {
                                setfNameError(true);
                              }
                            }}
                            value={userDetails["first_name"]}
                            onBlur={(e) => {
                              if (e.target.value?.length > 0) {
                                setfNameError(false);
                                if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                  setfNameTextError(false);
                                } else {
                                  setfNameTextError(true);
                                }
                              } else {
                                setfNameError(true);
                              }
                            }}
                          />
                        )}
                        {fNameError && (
                          <p style={{ color: "red" }}>
                            First name cannot be empty
                          </p>
                        )}
                        {fNameTextError && (
                          <p style={{ color: "red" }}>
                            First name should be text only
                          </p>
                        )}
                      </div>
                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClick}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5>
                            <span className="text-danger">&#42;</span> Last Name
                          </h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInputlastname ? (
                          <h3 className="usenameedit">
                            {userDetails["last_name"]}
                          </h3>
                        ) : (
                          <Input
                            required
                            className="form-control"
                            id="latname"
                            invalid={
                              (lnameError ? true : false) ||
                              (lnameError ? true : false)
                            }
                            valid={userDetails["last_name"] ? true : false}
                            //  disabled={localStorage.getItem(config.CUST_SEARCH_USER_ID) == JSON.parse(localStorage.getItem(config.CUST_INFO_DETAILS)).CUST_ID ? false : true}
                            disabled={false}
                            placeholder="Last Name"
                            type="text"
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                last_name: e.target.value.trim(),
                              });
                              if (e.target.value?.length > 0) {
                                setLNameError(false);
                                if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                  setLNameTextError(false);
                                } else {
                                  setLNameTextError(true);
                                }
                              } else {
                                setLNameError(true);
                              }
                            }}
                            value={userDetails["last_name"]}
                            onBlur={(e) => {
                              if (e.target.value?.length > 0) {
                                setLNameError(false);
                                if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                  setLNameTextError(false);
                                } else {
                                  setLNameTextError(true);
                                }
                              } else {
                                setLNameError(true);
                              }
                            }}
                          />
                        )}
                        {lnameError && (
                          <p style={{ color: "red" }}>
                            Last name cannot be empty
                          </p>
                        )}
                        {lNameTextError && (
                          <p style={{ color: "red" }}>
                            Last name should be text only
                          </p>
                        )}
                      </div>
                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClicklastname}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5 className="not_validate">Email</h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInputemail ? (
                          <h3 className="usenameedit">
                            {userDetails["email"]}
                          </h3>
                        ) : (
                          <Input
                            className="form-control"
                            id="email"
                            disabled={true}
                            placeholder="email"
                            type="email"
                            value={userDetails["email"]}
                          />
                        )}
                      </div>

                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClickemail}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5>
                            <span className="text-danger">&#42;</span> Whatsapp
                            Number
                          </h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInputphone ? (
                          <h3 className="usenameedit">
                            {userDetails["phone"] || ""}
                          </h3>
                        ) : (
                          <Input
                            required
                            className="form-control"
                            id="mobileNumberInput"
                            disabled={false}
                            placeholder="whatsapp number"
                            type="number"
                            value={userDetails["phone"] || ""}
                            // value={phone}
                            onChange={(e) => {
                              console.log("e.target.value", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                phone: e.target.value,
                              });
                              if (e.target.value) {
                                setPhoneError(false);
                              } else {
                                setPhoneError(true);
                              }
                            }}
                          />
                        )}
                        {phoneError && (
                          <p style={{ color: "red" }}>
                            Please enter your number
                          </p>
                        )}
                      </div>
                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClickphone}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5 className="not_validate">GST</h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        <Input
                          className="form-control"
                          id="gstnumberinput"
                          disabled={false}
                          placeholder="enter GST number"
                          type="text"
                          value={userDetails["gst"] || ""}
                          // value={phone}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            setUserDetails({
                              ...userDetails,
                              gst: e.target.value,
                            });
                            if (e.target.value) {
                              setGstError(false);
                            } else {
                              setGstError(true);
                            }
                          }}
                        />
                        {gstError && (
                          <p style={{ color: "red" }}>
                            Please enter your GST number
                          </p>
                        )}
                      </div>

                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={() => gstValidateClickHandler()}
                        >
                          {gstValidateText}
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5 className="not_validate">Business Name</h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInputbus ? (
                          <h3 className="usenameedit">
                            {userDetails["business_name"]}
                          </h3>
                        ) : (
                          <Input
                            className="form-control"
                            id="businessname"
                            disabled={false}
                            placeholder="Shop no, Area, Locality"
                            type="text"
                            value={userDetails["business_name"]}
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                business_name: e.target.value.trim(),
                              });
                              if (e.target.value) {
                                setBusinessNameError(false);
                              } else {
                                setBusinessNameError(true);
                              }
                            }}
                          />
                        )}

                        {businessNameError && (
                          <p style={{ color: "red" }}>
                            Please enter your business name
                          </p>
                        )}
                      </div>

                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClickbus}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5 className="not_validate">Address Line 1</h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInputaddress1 ? (
                          <h3 className="usenameedit">
                            {userDetails["address1"]}
                          </h3>
                        ) : (
                          <Input
                            className="form-control"
                            id="address1"
                            disabled={false}
                            placeholder="Shop no, Area, Locality"
                            type="text"
                            value={userDetails["address1"]}
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                address1: e.target.value.trim(),
                              });
                              // if (e.target.value) {
                              //   setBusinessNameError(false);
                              // } else {
                              //   setBusinessNameError(true);
                              // }
                            }}
                            autoComplete="off"
                          />
                        )}
                      </div>

                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClickaddress1}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="row row_cen2">
                      <div className="col-sm-3">
                        <div className="profile_label">
                          <h5 className="not_validate">Address Line 2</h5>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        {!showInputaddress2 ? (
                          <h3 className="usenameedit">
                            {userDetails["address2"]}
                          </h3>
                        ) : (
                          <form onSubmit={(e) => e.preventDefault()}>
                            <input
                              className="form-control"
                              id="address2"
                              disabled={false}
                              placeholder="City, State"
                              type="text"
                              value={userDetails["address2"]}
                              onChange={(e) => {
                                setUserDetails({
                                  ...userDetails,
                                  address2: e.target.value.trim(),
                                });
                                // if (e.target.value) {
                                //   setBusinessNameError(false);
                                // } else {
                                //   setBusinessNameError(true);
                                // }
                              }}
                              autoComplete={false}
                              auto
                            />
                          </form>
                        )}
                      </div>

                      <div className="col-sm-1">
                        <button
                          className="edit_form"
                          onClick={handleButtonClickaddress2}
                        >
                          <img src="/assets/images/editpen.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <SubscriptionEdit />

                  {/* <Button
                onClick={() => handleUpdateButton()}
                className="update-btn"
              >
                {setup == "1"
                  ? "Update Details & Open Variant"
                  : "Update Details"}
              </Button> */}
                </div>
              </div>
            </div>
          </div>

          <UserBillingHistory />
        </div>
      </section>
    </div>
  );
}

import React, { useState } from "react";
import Sidebar from "../../component/Sidebar";

export default function UploadFonts() {
    const [files, setFiles] = useState([])
    const onChangehandler = (e) => {
        const allFiles = e.target.files;
        console.log('allfiles',allFiles[0])
    }
  return (
    <>
      <div id="main-container" className="container-fluid main">
        <Sidebar></Sidebar>
        <div className="container">
          <h1 className="text-center">Upload Your Fonts</h1>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile02"
                  value={files}
                  onChange={onChangehandler}
                />
                <label className="input-group-text" htmlFor="inputGroupFile02">
                  Upload
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import { Link} from "react-router-dom";

export default function DashboardNumber() {
    return(
        <>
        <div className="dashboard_card">
            <ul className="dashboard_list">
                <li>
                    <div className="dashboard_num">
                        <h3>3247</h3>
                        <h5>Total Users</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>

                <li>
                    <div className="dashboard_num">
                        <h3>3211</h3>
                        <h5>Active Users</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>


                <li>
                    <div className="dashboard_num">
                        <h3>1234</h3>
                        <h5>Unlimited Plan</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>

                <li>
                    <div className="dashboard_num">
                        <h3>234</h3>
                        <h5>Pay As You Plan</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>

                <li>
                    <div className="dashboard_num">
                        <h3>233</h3>
                        <h5>FREE PLAN</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>

                <li>
                    <div className="dashboard_num">
                        <h3>244K</h3>
                        <h5>Total Variations</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>

                <li>
                    <div className="dashboard_num">
                        <h3>₹1.2L</h3>
                        <h5>Total Collection</h5>
                        <Link to="#" className="view_link">
                        View All <img src={'/assets/images/viewall.svg'} alt="" />
                            </Link>
                    </div>
                </li>
            </ul>
        </div>
        </>
    )
}
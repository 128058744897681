import React, { useState } from "react";
import moment from "moment";
import { config } from "../utils/config";
import { doRequest } from "../utils/helper";
import Loader from "./Loader";
import ConfirmationPopup from "./ConfirmationPopup";
import SubCancelledPopup from "./SubCancelledPopup";
export default function BillingHistoryItem({ list, getHistory }) {
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showCancelledPopup, setShowCancelledPopup] = useState(false);

  const cancelSub = async (item) => {
    if (!item?.id && !item?.user_id) return;
    let userLoginDetails = JSON.parse(
      localStorage.getItem(config.USER_LOGIN_DETAILS)
    );
    let reqData = {
      id: userLoginDetails.subs_id,
      user_id: item.user_id,
    };
    try {
      setLoading(true);
      let response = await doRequest(reqData, "POST", "order/subs-cancel");
      if (+response?.resultFlag === 1) {
        localStorage.removeItem(config.USER_LOGIN_DETAILS);
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(response?.response?.user_data)
        );
        setShowCancelledPopup(true);
        getHistory();
        setLoading(false);
      } else {
        alert(response?.message ? response?.message : "Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      console.log("error is", error);
      setLoading(false);
    }
  };

  const cancelClickHandler = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
  };
  const closePopup = () => setShowPopup(false);
  const closeCancelPopup = () => setShowCancelledPopup(false);
  return (
    <>
      {showCancelledPopup && <SubCancelledPopup close={closeCancelPopup} />}
      {loading && <Loader />}
      {showPopup && (
        <ConfirmationPopup
          close={closePopup}
          selectedItem={selectedItem}
          confirm={cancelSub}
        />
      )}
      {list?.map((item) => {
        return (
          <>
            <ul key={item?.id} className="plan_billheader plan_billheader_list">
              <li className="planbill_wid">
                <h6 className="plan_goldcol">{item?.name}</h6>
              </li>

              <li className="am_wid">
                <h6 className="amount_plali">
                  <sup>₹</sup>
                  <span>{item?.price + item?.price * (item?.tax / 100)}</span>
                </h6>
              </li>

              <li className="var_wid">
                <h6 className="var_wid_h">
                  {item?.variant_count ? item?.variant_count : "-"}
                </h6>
              </li>

              <li className="start_wid">
                <h6 className="var_wid_h">
                  {item?.start_date
                    ? moment(item?.start_date).format("DD-MM-yyyy")
                    : "-"}
                </h6>
              </li>

              <li className="end_wid">
                <h6 className="var_wid_h">
                  {item?.end_date
                    ? moment(item?.end_date).format("DD-MM-yyyy")
                    : "-"}
                </h6>
              </li>

              <li className="stat_wid">
                {localStorage.getItem(config.USER_LOGIN_DETAILS) &&
                JSON.parse(
                  localStorage.getItem(config.USER_LOGIN_DETAILS)
                )?.plan_status.toLowerCase() === "active" &&
                JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
                  ?.user_plan_id == item?.id ? (
                  <h6 style={{ color: "#18763C" }}>Active</h6>
                ) : (
                  <h6 style={{ color: "rgb(224 16 57)" }}>Expired</h6>
                )}
              </li>

              <li className="dwnin_wid d-flex justify-content-space-between">
                {localStorage.getItem(config.USER_LOGIN_DETAILS) &&
                  JSON.parse(
                    localStorage.getItem(config.USER_LOGIN_DETAILS)
                  )?.plan_status.toLowerCase() === "active" &&
                  JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
                    ?.user_plan_id == item?.id && (
                    <a
                      onClick={() => {
                        cancelClickHandler(item);
                      }}
                      className="down_inlink"
                      // target="_blank"
                      // href={`${config.s3BaseUrl}/invoice/${item?.invoice_no}.pdf`}
                    >
                      CANCEL
                    </a>
                  )}
                <a
                  className="down_inlink"
                  target="_blank"
                  href={`${config.s3BaseUrl}/invoice/${item?.invoice_no}.pdf`}
                >
                  DOWNLOAD
                </a>
              </li>
            </ul>

            {/* <div key={item?.id} className="row">
              <div className="col-2 s1 c1">{item?.name}</div>
              <div className="col s2 c2">
                <span className="amount">₹</span>
                {item?.price + item?.price * (item?.tax / 100)}
              </div>
              <div className="col s3 c3">
                {item?.variant_count ? item?.variant_count : "-"}
              </div>
              <div className="col s4 c4">
                {moment(item?.start_date).format("DD-MM-yyyy")}
              </div>
              <div className="col s5 c5">
                {moment(item?.end_date).format("DD-MM-yyyy")}
              </div>
              {localStorage.getItem(config.USER_LOGIN_DETAILS) &&
                JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
                  ?.user_plan_id == item?.id ? (
                <div className="col s6 c6" style={{ color: "#18763C" }}>
                  Active
                </div>
              ) : (
                <div className="col s7 c7 " style={{ color: "rgb(224 16 57)" }}>
                  Expired
                </div>
              )}
              
              <div className="col s8 c8">
                <div className="download">
                  <a
                    target="_blank"
                    href={`${config.s3BaseUrl}/invoice/${item?.invoice_no}.pdf`}
                  >
                    {" "}
                    DOWNLOAD
                  </a>
                </div>
              </div>
            </div> */}
          </>
        );
      })}
    </>
  );
}

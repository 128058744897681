import React from "react";
import { config } from "../utils/config";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import download from "../assets/img/file_download_black_24dp.svg";

export default function ExportTopDownloadAll ({
  processing,
  percentDownloaded,
  cancelClickHandler,
  downloadClickHandler,
}) {
  const navigate = useNavigate();
  const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS);
  const userPlans = localStorage.getItem(config.USER_PLAN_DETAILS);
  const variantDetails = userPlans ? JSON.parse(userPlans) : {};
  const variantCount = localStorage.getItem(config.VARIANT_COUNT) && localStorage.getItem(config.VARIANT_COUNT) != 'undefined' ? JSON.parse(localStorage.getItem(config.VARIANT_COUNT)) : null;
  let parsedLoginDetails = {};
  if (userLoginDetails) {
    parsedLoginDetails = JSON.parse(
      localStorage.getItem(config.USER_LOGIN_DETAILS)
    );
  } else {
    navigate("/");
  }
  const upgradePlanBtnClick = () => {
    navigate("/plans");
  };
  if (
    parsedLoginDetails &&
    parsedLoginDetails?.plan_status.toLowerCase() !== "active"
  ) {
    // alert(`You don't have any active plan, please select a plan first`);
    // navigate("/plans");
    return (
      <>
          <div className="col-sm-12 text-center">
            <img
              height={100}
              src={"/assets/images/download_outline.gif"}
              className="unle_img"
              alt=""
            />
            {/* <h4>Upgrade to Continue</h4>
            <p>
              Looks like you've reached your limit of free downloads! Upgrade
              now to continue unleashing your creativity with unlimited
              downloads. Upgrade today!
            </p> */}
            <Button
              className="primary"
              style={{ borderRadius: "30px" }}
              onClick={upgradePlanBtnClick}
            >
              {/* <img
                            src={download}
                            alt="download"
                            style={{ width: "20px" }}
                          /> */}
              Upgrade Plan and Download
            </Button>
          </div>
      </>
    );
  }

  if (
    variantDetails &&
    (variantDetails?.plan_id == "1" || variantDetails?.plan_id == "5")
  ) {
    if (variantCount?.remaning < 1) {
      return (
        <>
            <div className="col-sm-12 text-center">
              <h4>Upgrade to Continue</h4>
              <p>
                Looks like you've reached your limit of free downloads! Upgrade
                now to continue unleashing your creativity with unlimited
                downloads. Upgrade today!
              </p>
            </div>
        </>
      );
    }
  }

  if (processing) {
    return (
      <div className="col-sm-12 text-center">
        <h4>Shh... Magic is Happening!</h4>
        <p style={{ maxWidth: "600px", margin: "20px auto" }}>
          Teedex is working hard to generate your high-quality images. This
          process can take some time depending on the number of variations.
          Please be patient and do not navigate away from this page.
        </p>
        <div className="proges_div">
          <ul className="download_bartext">
            <li>
              <h6>Downloading</h6>
            </li>
            <li>
              <h6>{percentDownloaded}%</h6>
            </li>
          </ul>

          <div className="progress progess_cust">
            <div
              className="progress-bar progess_cus_bg"
              style={{ width: { percentDownloaded } }}
            ></div>
          </div>
        </div>

          <Button
            className="px-3 py-1"
            style={{ borderRadius: "50px" }}
            onClick={() => {
              cancelClickHandler();
            }}
          >
            <img src={download} alt="download" style={{ width: "20px" }} />
            Cancel Downloading
          </Button>
      </div>
    );
  } else {
    return (
      <>
          <div className="col-sm-12 text-center">
            <h4>Variation Completed - Unleash the Power of Personalisation</h4>
            <p>
              With a simple click of the "Create" button, watch as our software
              auto-generates
              <br /> a myriad of variations, effortlessly incorporating the
              selected fonts and Player
              <br /> details, Experience the power of automation and unleash
              your creativity.
            </p>
        <Button
          className="px-3 py-1"
          style={{ borderRadius: "30px" }}
          onClick={() => {
            // cancelDownloadRef.current = false;
            // handleDownloadAll();
            downloadClickHandler();
          }}
          disabled={processing}
        >
          <img src={download} alt="download" style={{ width: "20px" }} />
          {processing ? "Downloading" : "Download All"}
        </Button>
          </div>
      </>
    );
  }
}

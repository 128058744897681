import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import { Table, Button } from "reactstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";


export default function VariantFaq() {
    return (
        <>
            <div
                className="container-fluid"
            >
                <Sidebar />

                <section className="home">
                    <div className="container-fluid">
                        <div className="searcmain_pad">
                            <div className="search_header">
                                <ul className="search_list">
                                    <li>
                                        <h3>Teedex FAQs</h3>
                                    </li>

                                    <li>
                                        <div className="cansear_div">
                                            <button className="sav_sea sav_ques">Add Question Answer</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="container">

                                <div className="table-responsive table_user_mar">
                                    <Table hover striped className="table_cust align-middle">
                                        <thead className="thead_bg">
                                            <th className="userth" style={{ width: '25%' }}>PLAN</th>
                                            <th className="userth" style={{ width: '25%' }}>Answer</th>
                                            <th className="userth">Date created</th>
                                            <th className="userth">Status</th>
                                            <th className="userth">Actions</th>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className="faq_para">
                                                        What is Teedex
                                                    </p>
                                                </td>

                                                <td>
                                                    <p className="faq_para">
                                                        <ReactReadMoreReadLess charLimit={100}>
                                                            DKScore is an AI-powered astrology platform... that provides astrologers with predictive, analytical, and research tools to create detailed and accurate astrological profiles. Our platform is designed to empower astrologers with effective tools and learning resources, enabling them to offer personalized guidance to their clients.
                                                        </ReactReadMoreReadLess>
                                                    </p>
                                                </td>

                                                <td>Sep 9, 2022</td>


                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td>
                                                    <p className="faq_para">
                                                        What is Teedex
                                                    </p>
                                                </td>

                                                <td>
                                                    <p className="faq_para">
                                                        <ReactReadMoreReadLess charLimit={100}>
                                                            DKScore is an AI-powered astrology platform... that provides astrologers with predictive, analytical, and research tools to create detailed and accurate astrological profiles. Our platform is designed to empower astrologers with effective tools and learning resources, enabling them to offer personalized guidance to their clients.
                                                        </ReactReadMoreReadLess>
                                                    </p>
                                                </td>

                                                <td>Sep 9, 2022</td>


                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>


                                            <tr>
                                                <td>
                                                    <p className="faq_para">
                                                        What is Teedex
                                                    </p>
                                                </td>

                                                <td>
                                                    <p className="faq_para">
                                                        <ReactReadMoreReadLess charLimit={100}>
                                                            DKScore is an AI-powered astrology platform... that provides astrologers with predictive, analytical, and research tools to create detailed and accurate astrological profiles. Our platform is designed to empower astrologers with effective tools and learning resources, enabling them to offer personalized guidance to their clients.
                                                        </ReactReadMoreReadLess>
                                                    </p>
                                                </td>

                                                <td>Sep 9, 2022</td>


                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>




                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
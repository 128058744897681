import React, { useEffect, useState } from "react";
import { HouseDoor, Moon, PersonCircle, ChatDots } from 'react-bootstrap-icons';
import { BrowserRouter as Router, Link, useLocation, useAsyncError, useNavigate } from "react-router-dom";
import Varient from '../assets/img/Group 12302.svg'
import Home from '../assets/img/home_black_24dp.svg'
import Person from '../assets/img/account_circle_black_24dp.svg'
import Feedback from '../assets/img/rate_review_FILL0_wght400_GRAD0_opsz24.svg'
import FeedbackOffCanvas from "./FeedbackOffCanvas";
import FeedbackScreenShotModal from "./FeedbackScreenShotModal";
import secureLocalStorage from 'react-secure-storage';
import { config } from "../utils/config";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";


function Sidebar({ isOpen }) {
    const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS)
    let userLoginDetailsParsed = {}
    if (userLoginDetails) {
        userLoginDetailsParsed = JSON.parse(userLoginDetails)
    }
    let showDownloadVariations = false
    const userPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS) ? JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS)) : null;
    if (userPlanDetails?.plan_id == '2') {
        let userHasPaidForVariations = localStorage.getItem('userdownloadimage') ? JSON.parse(localStorage.getItem('userdownloadimage')) : null;
        if (userHasPaidForVariations) {
            let imageCanDownload = userHasPaidForVariations?.imageCanDownload;
            let downloadedCount = userHasPaidForVariations?.downloadedCount;
            if (downloadedCount < imageCanDownload) {
                showDownloadVariations = true;
            } else {
                showDownloadVariations = false;
            }
        }
    }
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [userIsAdmin, setUserIsAdmin] = useState(userLoginDetailsParsed?.type)
    const [showScreenShot, setShowScreenShot] = useState(false)
    const [description, setDescription] = useState(false);
    const [feedbackType, setFeedbackType] = useState('problem')
    const [previewSrc, setPreviewSrc] = useState(null);
    const [showDownloadVariationsBtn, setShowDownloadVariationBtn] = useState(showDownloadVariations);


    const handleFeedbackType = (type) => {
        setFeedbackType(type);
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (userLoginDetailsParsed?.type === 'admin') {
            setUserIsAdmin(true)
        } else {
            setUserIsAdmin(false)
        }
    }, [userLoginDetailsParsed])



    const handelScreenShotShow = async (isFirst) => {
        // setShowScreenShot(true)       
        try {
            // asking permission to use a media input to record current tab
            if (isFirst === true) {
                handleClose();
            }
            // else {
            //   toggle3()
            // }
            const stream = await navigator.mediaDevices.getDisplayMedia({
                // preferCurrentTab: true,
            });

            const video = document.createElement("video");

            video.addEventListener("loadedmetadata", () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // passing video width & height as canvas width & height
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                video.play(); // playing the video so the drawn image won't be black or blank
                // drawing an image of the captured video stream
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                stream.getVideoTracks()[0].stop(); // terminating first video track of the stream

                // passing canvas data url as screenshot preview src
                setPreviewSrc(canvas.toDataURL());
                // let preview = canvas.toDataURL();
                //setShowPreview(true);

            });
            video.srcObject = stream; // passing capture stream data as video source object

            setShow(true);
        } catch (error) {
            // if image couldn't capture by any reason, then alert the msg
            alert("Failed to capture screenshot!");
        }
    }
    const handelScreenShotHide = () => {
        setShowScreenShot(false)
        handleShow();
    }

    const handleLogout = () => {
        secureLocalStorage.removeItem('Login');
        secureLocalStorage.removeItem(config.USER_LOGIN_DETAILS);
        localStorage.removeItem(config.USER_LOGIN_DETAILS)
        localStorage.clear();
        navigate('/')
    }

    return (
        <>
            <div className={`sidemenu ${isOpen ? 'sidemenuopen' : 'sidemenuopen2'}`}>
                <div className="text-center mt-3">
                    <ul className="nav nav-pills flex-column">
                        <li>
                            <Link to="/">
                                <img src={'/assets/images/betanew.png'} alt="Varient logo" style={{ width: '50px', top: 0 }} />
                            </Link>

                        </li>
                        {/* <li>
                            <Link to="/Home">
                                <img src={'/assets/beta.svg'} alt="Varient beat logo" style={{ width: '50px', top: 0, marginTop: "5px" }} />
                            </Link>

                        </li>    */}
                    </ul>
                </div>

                <div className="text-center">
                    <div className="scroll-area sidemenuscroll">
                        <PerfectScrollbar
                            className="custom-scrollbar"
                            options={{ suppressScrollX: true }}
                        >
                            <ul className="nav nav-pills flex-column mnubar_cms">
                                <li className="nav-item">
                                    <Link to="/Home" className={`nav-link ${pathname === "/Home" ? "active" : ""}`} aria-current="page">
                                        <div className="home_menu_flex">
                                            <img src={'/assets/images/homeblack.svg'} alt="home" className="homeblack" />
                                            <img src={'/assets/images/homepurple.svg'} alt="home" className="homepurple" />
                                            <h5>Home</h5>
                                        </div>
                                    </Link>
                                </li>
                                {showDownloadVariationsBtn &&
                                    <li className="nav-item">
                                        <Link to="/payasuse/paid/export-new" className={`nav-link ${pathname === "/payasuse/paid/export-new" ? "active" : ""}`} aria-current="page">
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/homeblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/homepurple.svg'} alt="home" className="homepurple" />
                                                <h5>Download Created Variations</h5>
                                            </div>
                                        </Link>
                                    </li>}

                                {userIsAdmin && <>

                                    <li className="nav-item">
                                        <Link to="/admindashboard" className={`nav-link ${pathname === "/admindashboard" ? "active" : ""}`} aria-current="page">
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/dashboardblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/dashboardpurple.svg'} alt="home" className="homepurple" />
                                                <h5>Dashboard</h5>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/usermanagement" className={`nav-link ${pathname === "/usermanagement" ? "active" : ""}`} aria-current="page">
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/userblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/userpurple.svg'} alt="home" className="homepurple" />
                                                <h5>Users</h5>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/varintplans" className={`nav-link ${pathname === "/varintplans" ? "active" : ""}`} aria-current="page">
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/plansblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/planspurple.svg'} alt="home" className="homepurple" />
                                                <h5>Plans</h5>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/fontsdashboard" className={`nav-link ${pathname === "/fontsdashboard" ? "active" : ""}`} aria-current="page">
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/fontblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/fontblue.svg'} alt="home" className="homepurple" />
                                                <h5>Fonts</h5>
                                            </div>
                                        </Link>
                                    </li>

                                    {/* <li className="nav-item">
                                        <Link to="/feedbackmanagement" className={`nav-link ${pathname === "/feedbackmanagement" ? "active" : ""}`} aria-current="page" onClick={() => { handleShow() }}>
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/feedbackblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/feedbackpurple.svg'} alt="home" className="homepurple" />
                                                <h5>Feedback</h5>
                                            </div>
                                        </Link>
                                    </li> */}

                                    <li className="nav-item">
                                        <Link to="/varintfaq" className={`nav-link ${pathname === "/varintfaq" ? "active" : ""}`} aria-current="page">
                                            <div className="home_menu_flex">
                                                <img src={'/assets/images/faqblack.svg'} alt="home" className="homeblack" />
                                                <img src={'/assets/images/faqpurple.svg'} alt="home" className="homepurple" />
                                                <h5>F&Q</h5>
                                            </div>
                                        </Link>
                                    </li>
                                </>}




                                {/* <li className="nav-item">
                                    <Link to="#" className="nav-link" aria-current="page">
                                        <div className="home_menu_flex">
                                            <img src={'/assets/images/dashboardblack.svg'} alt="home" className="homeblack" />
                                            <img src={'/assets/images/dashboardpurple.svg'} alt="home" className="homepurple" />
                                            <h5>Dashboard</h5>
                                        </div>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" className="nav-link" aria-current="page">
                                        <div className="home_menu_flex">
                                            <img src={'/assets/images/userblack.svg'} alt="home" className="homeblack" />
                                            <img src={'/assets/images/userpurple.svg'} alt="home" className="homepurple" />
                                            <h5>Users</h5>
                                        </div>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" className="nav-link" aria-current="page">
                                        <div className="home_menu_flex">
                                            <img src={'/assets/images/plansblack.svg'} alt="home" className="homeblack" />
                                            <img src={'/assets/images/planspurple.svg'} alt="home" className="homepurple" />
                                            <h5>Plans</h5>
                                        </div>
                                    </Link>
                                </li>


                                <li className="nav-item">
                                    <Link to="#" className="nav-link" aria-current="page">
                                        <div className="home_menu_flex">
                                            <img src={'/assets/images/fontblack.svg'} alt="home" className="homeblack" />
                                            <img src={'/assets/images/fontblue.svg'} alt="home" className="homepurple" />
                                            <h5>Fonts</h5>
                                        </div>
                                    </Link>
                                </li> */}

                                {/* feedback commented */}
                                {/* <li className="nav-item">
                            <Link to="#" className="nav-link" aria-current="page" onClick={() => { handleShow() }}>
                                <div className="home_menu_flex">
                                    <img src={'/assets/images/feedbackblack.svg'} alt="home" className="homeblack" />
                                    <img src={'/assets/images/feedbackpurple.svg'} alt="home" className="homepurple" />
                                    <h5>Feedback</h5>
                                </div>
                            </Link>
                        </li> */}

                                {/* <li className="nav-item">
                                    <Link to="#" className="nav-link" aria-current="page">
                                        <div className="home_menu_flex">
                                            <img src={'/assets/images/faqblack.svg'} alt="home" className="homeblack" />
                                            <img src={'/assets/images/faqpurple.svg'} alt="home" className="homepurple" />
                                            <h5>F&Q</h5>
                                        </div>
                                    </Link>
                                </li> */}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>

                <div className="text-center">
                    <ul className="nav nav-pills flex-column user_profile">
                        {/* <li className="nav-item">
                        <a href="#" className="nav-link" aria-current="page">
                            <div className="circle-icon">
                                <Moon size={20} />
                            </div><br/>
                            <span>Theme</span>
                        </a>
                        </li> */}

                        <li className="nav-item" >
                            <div className="dropup">
                                {/* <Link to="/profile" className="nav-link" aria-current="page" style={{ cursor: 'pointer' }}> */}
                                <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                    <div className="user_profile_img">
                                        <img
                                            src={userLoginDetailsParsed &&
                                                userLoginDetailsParsed?.user_image
                                                ? userLoginDetailsParsed?.user_image
                                                : Person}
                                            alt="home"
                                            className="user_image_shape"
                                        />
                                        {/* <h6>9</h6> */}
                                    </div>
                                    {userLoginDetailsParsed?.first_name ? <h5>{userLoginDetailsParsed?.first_name}</h5> : <h5>User</h5>}
                                </button>
                                <ul className="dropdown-menu dropmenu_cust">
                                    <li>
                                        <Link to={'/profile'} className="dropdown-item" >
                                            <img src={'/assets/images/myprofile.svg'} alt="" className="" /> My Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to={'/billing-history'}>
                                            <img src={'/assets/images/billinghistory.svg'} alt="" className="" />
                                            Billing history</Link>
                                    </li>
                                    {/* <li>
                                        <Link className="dropdown-item" href="#">
                                            <img src={'/assets/images/share.svg'} alt="" className="" />
                                            Refers & Earn</Link>
                                    </li> */}

                                    <li>
                                        <Link to={'/plans'} className="dropdown-item">
                                            <img src={'/assets/images/variantdrop.svg'} alt="" className="" />
                                            Teedex Plans</Link>
                                    </li>

                                    <li onClick={() => { handleLogout() }} className="logout_brd">
                                        <Link className="dropdown-item" href="#">
                                            <img src={'/assets/images/logout.svg'} alt="" className="" />
                                            Logout</Link>
                                    </li>
                                </ul>
                                {/* </Link> */}
                            </div>
                            {/* <span style={{ marginTop: '20px' }} onClick={() => { handleLogout() }}>Logout</span> */}
                        </li>

                        {/* <li className="nav-item">
                            <Link to="#" className="leavefed_modal" onClick={() => { handleShow() }}>
                                <img src={Feedback} alt="home" />
                                <span>Leave Feedback</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div >

            {show && <FeedbackOffCanvas previewSrc={previewSrc} handleFeedbackType={handleFeedbackType} feedbackType={feedbackType} description={description} setDescription={setDescription} key={1} placement={'end'} name={'end'} handleClose={handleClose} show={show} handelScreenShotShow={handelScreenShotShow} />
            }
            {showScreenShot && <FeedbackScreenShotModal handelScreenShotHide={handelScreenShotHide} showScreenShot={showScreenShot} />}

        </>

    );
}

export default Sidebar;
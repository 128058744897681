import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import Toggle from "react-toggle";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";


export default function VariantPlansEdit() {

    const [tofuIsReady, setTofuIsReady] = useState(false);

    const handleTofuChange = () => {
        setTofuIsReady(!tofuIsReady);
    };


    return (
        <>
            <div className="container-fluid">
                <Sidebar />
                <section className="home">
                    <div className="container-fluid">
                        <div className="searcmain_pad">
                            <div className="search_header">
                                <ul className="search_list">
                                    <li>
                                        <h3>Teedex Plans</h3>
                                    </li>

                                    <li>
                                        <div className="cansear_div">
                                            <button className="can_sea">Cancel</button>
                                            <button className="sav_sea">Save</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="container">
                                <div className="varinatfaqsta_pad">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <Link to="">
                                                <img src={'/assets/images/backarrow.svg'} alt="" />
                                            </Link>
                                        </div>

                                        <div className="col-sm-11">
                                            <div className="varedit_card">
                                                <ul className="editsub_list">
                                                    <li>
                                                        <h4>Subscription</h4>
                                                    </li>
                                                    <li>
                                                        <button>Save Edit</button>
                                                    </li>
                                                </ul>

                                                <Row>
                                                    <Col sm="6">
                                                        <div className="var_edit_input">
                                                            <h6>Subscription Name</h6>
                                                            <div className="var_faqinput">
                                                                <input type="text" className="input_col" placeholder="Subscription Name" />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="var_edit_input">
                                                            <h6>Subscription ID</h6>
                                                            <div className="var_faqinput">
                                                                <input type="number" className="input_col" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </Col>


                                                    <Col sm="6">
                                                        <div className="var_edit_input">
                                                            <h6>Price</h6>
                                                            <div className="var_faqinput">
                                                                <input type="number" className="input_col" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="var_edit_input">
                                                            <h6>Duration</h6>
                                                            <div className="var_faqinput">
                                                                <input type="text" className="input_col" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="var_edit_input">
                                                            <h6>Currency</h6>
                                                            <div className="var_faqinput">
                                                                <select>
                                                                    <option>INR</option>
                                                                    <option>USD</option>
                                                                </select>
                                                                <img src={'/assets/images/arrowselect.svg'} className="arrow_sel" alt="" />
                                                            </div>
                                                        </div>
                                                    </Col>


                                                    <Col sm="6">
                                                        <div className="var_edit_input">
                                                            <h6>Status</h6>

                                                            <div className="var_edit_toggle var_edit_toggle2">
                                                                {tofuIsReady ? <h6>On</h6> : <h6>Off</h6>}
                                                                <Toggle
                                                                    defaultChecked={tofuIsReady}
                                                                    icons={false}
                                                                    onChange={handleTofuChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>


                                            {/* <div className="varint_edt_label">
                                                <h5>Status</h5>

                                                <div className="var_edit_toggle">
                                                    {tofuIsReady ? <h6>On</h6> : <h6>Off</h6>}
                                                    <Toggle
                                                        defaultChecked={tofuIsReady}
                                                        icons={false}
                                                        onChange={handleTofuChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="var_edit_input">
                                                <h6>Question</h6>
                                                <div className="var_faqinput">
                                                    <input type="text" placeholder="Type question here…" />
                                                    <p>15/40</p>
                                                </div>
                                            </div>


                                            <div className="var_edit_input">
                                                <h6>Answer</h6>
                                                <div className="var_faqinput">
                                                    <input type="text" placeholder="Type answer here…" />
                                                    <p>15/40</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
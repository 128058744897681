import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

export default function ContactUs() {
  return (
    <>
      <Navbar />
      <section className="privacy_sec">
        <div className="container">
          <div className="privacy_content">
            <h1>Contact Us</h1>
            <img src="/assets/images/logo.svg" className="about_logo" alt="" />
            <h5 className="my-4">
              Welcome to Teedex, your ultimate destination for personalized tee
              shirt design!
            </h5>

            <h5>Have questions or feedback?</h5>
            <p>
              We’d love to hear from you! Get in touch with our team using the
              contact information below or fill out the form to send us a
              message directly.
            </p>

            <h6>Contact Information</h6>
            <p className="contact_det">
              Email - teedex.in@gmail.com
            </p>
            <p className="contact_det">Phone - 84388 04555</p>
            <p className="contact_det">
              Address - 151/4, Goregaon (West), Mumbai 400104
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

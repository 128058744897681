import React, { useEffect, useRef, useState } from "react";
import { doRequest } from "../utils/helper";
import KEYS from "../env/env";
import { config } from "../utils/config";

export default function TeedexFonts({
  fontOptions,
  selected,
  onSelect,
  onHover,
}) {
  const [searchVal, setSearchVal] = useState("");
  const [fontsList, setFontsList] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const activeListItemRef = useRef(null);

  const searchChangeHandler = (val) => {
    setSearchVal(val);
    const filtered = fontsList.filter((font) =>
      font?.name?.toLowerCase().includes(val.toLowerCase())
    );
    setFilterOptions(filtered);
  };

  const loadFonts = (fonts) => {
    const styleSheet = document.styleSheets[0];
    

    fonts.forEach((font) => {
      const fontFace = new FontFace(font.name, `url(${config.s3BaseUrl}/${font.url})`);
      fontFace.load().then((loadedFontFace) => {
        document.fonts.add(loadedFontFace);

        const rule = `
          @font-face {
            font-family: '${font.name}';
            src: url(${config.s3BaseUrl}/${font.url});
          }
        `;

        if (styleSheet.insertRule) {
          styleSheet.insertRule(rule, styleSheet.cssRules.length);
        } else if (styleSheet.addRule) {
          styleSheet.addRule(`@font-face`, rule, -1);
        }
      }).catch((error) => {
        console.error(`Failed to load font: ${font.name}`, error);
      });
    });
  };

  const getfontsList = async () => {
    setLoading(true);
    try {
      const response = await doRequest("", "GET", "font/list");
     if (+response?.resultFlag === 1) {
        let fonts = response?.response?.font;
        setFontsList(fonts);
        setFilterOptions(fonts);
        loadFonts(fonts); // Load fonts dynamically
      } else {
        alert(response?.message ? response?.message : "Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message ? error?.message : "Something went wrong!");
    }
  };

  useEffect(() => {
    getfontsList();
  }, []);


  
  return (
    <>
      <div className="search_flont_div">
        <input
          onChange={(e) => searchChangeHandler(e.target.value)}
          value={searchVal}
          type="text"
          placeholder="Search"
        />
        <img src="/assets/images/searchfont.svg" alt="Search Icon" />
      </div>

      <div className="font_pos">
        <ul className="options option_font">
          {filterOptions?.length > 0 &&
            filterOptions.map((option, index) => (
              <li
                ref={selected === option.name ? activeListItemRef : null}
                className={selected === option.name ? "active" : ""}
                style={{ fontFamily: option.name }}
                key={index}
                onClick={() => onSelect(option.name)}
                onMouseEnter={() => onHover(option.name)}
                onMouseLeave={() => onHover(null)}
                role="option"
                aria-selected={selected === option.name}
              >
                {option.name}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Toggle from "react-toggle";


export default function SubscriptionEdit() {

    const [tofuIsReady, setTofuIsReady] = useState(false);

    const handleTofuChange = () => {
        setTofuIsReady(!tofuIsReady);
    };


    return (
        <>
            <div className="subr_card_edit">
                <div className="sub_edit_flex">
                    <h4>Subscription</h4>
                    <button className="savesub_edit">save edit</button>
                </div>

                <Row>
                    <Col sm="6">
                        <div className="var_edit_input">
                            <h6>Subscription Type</h6>

                            <div className="var_edit_toggle var_edit_toggle2">
                                {tofuIsReady ? <h6>On</h6> : <h6>Off</h6>}
                                <Toggle
                                    defaultChecked={tofuIsReady}
                                    icons={false}
                                    onChange={handleTofuChange}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col sm="6">
                        <div className="var_edit_input">
                            <h6>Subscription Type</h6>
                            <div className="var_faqinput">
                                <select className="font_m">
                                    <option>Unlimited Monthly</option>
                                    <option>USD</option>
                                </select>
                                <img src={'/assets/images/arrowselect.svg'} className="arrow_sel" alt="" />
                            </div>
                        </div>
                    </Col>


                    <Col sm="6">
                        <div className="var_edit_input">
                            <h6>From Date </h6>
                            <div className="var_faqinput">
                                <input type="date" className="input_date_bg" placeholder="DD-MM-YYYY" />
                                <img src={'/assets/images/calendar.svg'} className="arrow_sel" alt="" />
                            </div>
                        </div>
                    </Col>


                    <Col sm="6">
                        <div className="var_edit_input">
                            <h6>To Date </h6>
                            <div className="var_faqinput">
                                <input type="date" className="input_date_bg" placeholder="DD-MM-YYYY" />
                                <img src={'/assets/images/calendar.svg'} className="arrow_sel" alt="" />
                            </div>
                        </div>
                    </Col>


                    <Col sm="6">
                        <div className="var_edit_input">
                            <h6>Generate Coupon</h6>
                            <div className="var_faqinput">
                                <select className="font_m">
                                    <option>10%</option>
                                    <option>USD</option>
                                </select>
                                <img src={'/assets/images/arrowselect.svg'} className="arrow_sel" alt="" />
                            </div>
                        </div>
                    </Col>

                    <Col sm="6">
                        <div className="var_edit_input">
                            <h6>Generated Coupon</h6>
                            <div className="var_faqinput">
                                <input type="text" className="" style={{textTransform: 'uppercase'}} placeholder="Generated Coupon" />
                                
                            </div>
                        </div>
                    </Col>
                    <Col sm="12">
                        <button className="apply_couponbtn">Apply Coupon</button>
                    </Col>
                </Row>
            </div>
        </>
    )
}
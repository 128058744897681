import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { config } from "../utils/config";

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <footer className="footer_sec">
        <div className="container">
          <div className="update_bg">
            <div className="stayupdate_flex">
              <div className="update_number">
                <img src={`${config.s3BaseUrl}/images/variantfooterlogo.png`} alt="" />
                <h6>9</h6>
              </div>
              <div className="update_content">
                <h3>Stay Up to date</h3>
                <p>Subscribe for important news, resources and more…</p>
              </div>
            </div>
            <div className="subscribe_email">
              <input type="email" placeholder="Type your email id here…" />
              <button>Subscribe</button>
            </div>
          </div>

          <div className="copyright_flex">
            <h6 className="copyright_text">
              © 2024 Teedex All Rights Reserved. Variant Technology
            </h6>
            <div className="social_list">
              <ul>
                {/* <li>
                  <a href="#">License</a>
                </li> */}
                {/* <li>
                  <a onClick={() => {
                    try {
                      let obj = {}
                      window.gtag("event", "Facebook Page Visited", obj);
                    } catch (error) {
                
                    }
                  }} rel="noreferrer" href="https://www.facebook.com/variantproduct/" target="_blank">
                    Facebook
                  </a>
                </li> */}
                <li>
                  <a onClick={() => {
                    try {
                      let obj = {}
                      window.gtag("event", "Instagram Page Visited", obj);
                    } catch (error) {
                
                    }
                  }} rel="noreferrer" href="https://www.instagram.com/teedex.in/" target="_blank">
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <ul className="policy_list">
            <li>
              <NavLink to="/about">About Us</NavLink>
            </li>

            <li>
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/privacypolicy">Privacy Policy</NavLink>
            </li>
            <li>
              <NavLink to="/termspolicy">Terms & Conditions</NavLink>
            </li>
            <li>
              <NavLink to="/refundpolicy">Cancellation/Refund Policies</NavLink>
            </li>
            {/* <li>
              <NavLink to="">Payment Policy</NavLink>
            </li>
            <li>
              <NavLink to="">Sitemap</NavLink>
            </li> */}
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import { Button } from "reactstrap";

export default function OrientationSizePopup() {
  return (
    <>
      <div className="container">
        <div className="welcomer_dk_brd welcomer_dk_brd_pos">
        <img src={'/assets/images/betanew.png'} alt="Varient logo" style={{ width: '50px', top: 0 }} />
          <h2>Heads Up! Screen Size Matters</h2>
          <h6>
            It looks like you’re using a smaller device. For the best
            experience, please access Teedex on a larger screen such as a
            desktop, laptop, or tablet. Our platform is optimized for bigger
            screens to provide you with the most efficient and enjoyable design
            process.
          </h6>
          <h6>Thank you for your understanding!</h6>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ListGroup } from "reactstrap";
import { config } from "../utils/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { doRequest, isValidEmail, submitUserPlans } from "../utils/helper";
import secureLocalStorage from "react-secure-storage";
import verifytoken from "../env/verifytoken";

export default function PayAsUsePayment() {
  const initialUserPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS);
  const detail = localStorage.getItem(config.USER_LOGIN_DETAILS)
    ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
    : {};
  let obj = {
    ...detail,
    first_name: detail["first_name"] || "",
    last_name: detail["last_name"] || "",
    state: detail["state"] || "",
    country: detail["country"] || "",
    city: detail["city"] || "",
    postal_code: detail["postal_code"] || "",
    phone_no: detail["phone"] || "",
    email: detail["email"] || "",
    address: detail["address"] || "",
  };
  const [params] = useSearchParams();
  const initialSelectedPlan = params.get("plan");
  const [userDetails, setUserDetails] = useState({ ...obj });
  const [billingAddress, setBillingAddress] = useState({});
  const [billingSameAsUserDetails, setBillingSameAsUserDetails] =
    useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(
    initialSelectedPlan ? +initialSelectedPlan : ""
  );
  const [selectedPlan, setSelectedPlan] = useState({});
  const [gstCharges, setGstCharges] = useState("");
  const [discountApplied, setDiscountApplied] = useState(0);
  const [modal2, setModal2] = useState(false);
  const [errmsg, setErrorMsg] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastnameError] = useState(false);
  const [AddressError, setAddressError] = useState(false);
  const [phoneNoError, setPhoneNoError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [billCityError, setBillCityError] = useState(false);
  const [billCountryError, setBillCountryError] = useState(false);
  const [billStateError, setBillStateError] = useState(false);
  const [billPostalError, setBillPostalError] = useState(false);

  const totalCharges =
    (selectedPlan?.price && selectedPlan?.price + gstCharges) - discountApplied;

  const navigate = useNavigate();
  const updateUserDetails = async () => {
    let data = {
      id: userDetails["id"],
      data: {
        phone: userDetails["phone_no"],
        first_name: userDetails["first_name"],
        last_name: userDetails["last_name"],
        address: userDetails["address"],
        country: userDetails["country"],
        city: userDetails["city"],
        state: userDetails["state"],
        postal: userDetails["postal_code"],
      },
    };
    const response = await doRequest(data, "POST", "user/update");
    if (response && +response?.resultFlag === 1) {
      setUserDetails(response?.response?.user);
      localStorage.setItem(
        config.USER_LOGIN_DETAILS,
        JSON.stringify(response?.response?.user)
      );
    }
  };

  const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS);
  if (!userLoginDetails) {
    alert("please login first");
    navigate("/Login");
  }

  const submitUserFreePlan = async (planid) => {
    let user =
      localStorage.getItem(config.USER_LOGIN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));
    try {
      let response = await submitUserPlans(planid, user);
      if (response && +response?.resultFlag === 1) {
        let details = response?.response?.user_data;
        let userPlan = response?.response?.userPlan;
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(details)
        );
        localStorage.setItem(
          config.USER_PLAN_DETAILS,
          JSON.stringify(userPlan)
        );

        if (initialUserPlanDetails) {
          navigate("/profile?plan=" + planid);
        } else {
          navigate("/profile?plan=" + planid + "&ftime=1");
        }
      } else {
        setErrorMsg(
          response?.message ? response?.message : "Something went wrong"
        );
        // setShowError(true);
      }
    } catch (error) {
      setErrorMsg(error?.message ? error?.message : "Something went wrong");
      // setShowError(true);
    }
  };

  const getPlansList = async () => {
    try {
      let res = await doRequest({}, "GET", `plan/list`);
      if (+res?.resultFlag === 1) {
        setSubscriptionList(res?.response?.plans);
      }
    } catch (error) {
      console.log("erros in plan list", error);
    }
  };

  useEffect(() => {
    getPlansList();
  }, []);

  const isPayNowFormValid = (details) => {
    let err = 0;
    if (!details["first_name"] || details["first_na me"] === "") {
      err += 1;
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (!details["last_name"] || details["last_name"] === "") {
      err += 1;
      setLastnameError(true);
    } else {
      setLastnameError(false);
    }
    if (!details["address"] || details["address"] === "") {
      err += 1;
      setAddressError(true);
    } else {
      setAddressError(false);
    }
    if (!details["phone_no"] || details["phone_no"] === "") {
      err += 1;
      setPhoneNoError(true);
    } else {
      setPhoneNoError(false);
    }
    if (!details["email"] || details["email"] === "") {
      err += 1;
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!details["city"] || details["city"] === "") {
      err += 1;
      setCityError(true);
    } else {
      setCityError(false);
    }
    if (!details["country"] || details["country"] === "") {
      err += 1;
      setCountryError(true);
    } else {
      setCountryError(false);
    }

    if (!details["state"] || details["state"] === "") {
      err += 1;
      console.log("inside if condition");
      setStateError(true);
    } else {
      setStateError(false);
    }
    console.log("postal_code", details["postal_code"]);
    if (!details["postal_code"] || details["postal_code"] === "") {
      err += 1;
      setPostalCodeError(true);
    } else {
      setPostalCodeError(false);
    }

    if (!billingSameAsUserDetails) {
      if (!billingAddress["city"] || billingAddress["city"] === "") {
        err += 1;
        setBillCityError(true);
      } else {
        setBillCityError(false);
      }
      if (!billingAddress["country"] || billingAddress["country"] === "") {
        err += 1;
        setBillCountryError(true);
      } else {
        setBillCountryError(false);
      }
      if (!billingAddress["state"] || billingAddress["state"] === "") {
        err += 1;
        setBillStateError(true);
      } else {
        setBillStateError(false);
      }
      if (
        !billingAddress["postal_code"] ||
        billingAddress["postal_code"] === ""
      ) {
        err += 1;
        setBillPostalError(true);
      } else {
        setBillPostalError(false);
      }
    }

    if (err > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log("selectedPlanId", selectedPlanId);
    if (!selectedPlanId) return;
    let a = subscriptionList?.filter((i) => i.id == selectedPlanId);
    console.log("a", a);
    if (a && a.length > 0) {
      setSelectedPlan(a[0]);
      setGstCharges(a[0].price * (a[0]?.tax / 100));
    }
  }, [selectedPlanId, subscriptionList]);
  const onChangeHandler = (key, val) => {
    console.log("val is ", val);
    let details = { ...userDetails };
    details[key] = val;
    setUserDetails(details);
  };
  const billingAddressChangehandler = (key, val) => {
    let details = { ...billingAddress };
    details[key] = val;
    setBillingAddress(details);
  };

  const sameAddressClickHandler = (e) =>
    setBillingSameAsUserDetails(!billingSameAsUserDetails);
  const toggle2 = () => setModal2(!modal2);

  const onSuccessPaymentHandler = async (data) => {
    if (!data) {
      setErrorMsg("Something went wrong!");
      return;
    }
    console.log("data is ", data);

    let response = await doRequest(data, "POST", "order/validate");
    console.log("response is ", response);
    if (+response?.resultFlag === 1) {
      let obj = {
        imageCanDownload : localStorage.getItem("variationcounts"),
        downloadedCount: 0
      }
      localStorage.setItem('userdownloadimage', JSON.stringify(obj))
      alert(`Congratulations!, you can download ${localStorage.getItem("variationcounts")} images now`);

      navigate('/payasuse/paid/export-new')
    }
  };

  const completePayment = async (orderDetails) => {
    if (!orderDetails) {
      return;
    }
    console.log("orderdetails", orderDetails);
    const options = {
      key: "rzp_test_O1iat2Sl0gsmDm", // Enter the Key ID generated from the Dashboard
      amount: orderDetails?.amount.toString(),
      currency: "INR",
      name: "Variant",
      description: selectedPlan?.DESCR,
      image: `${config.s3BaseUrl}Logo/logo.jpg`,
      order_id: orderDetails?.order_id,
      handler: async function (response) {
        console.log("completePayment response handler is ", response);
        // return;
        let d = {
          city: userDetails["city"],
          state: userDetails["state"],
          country: userDetails["country"],
          postal_code: userDetails["postal_code"],
        };
        const userLoginDetails = localStorage.getItem(
          config.USER_LOGIN_DETAILS
        );
        const data = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          order_id: orderDetails?.order_id,
          contact_details: userDetails,
          billing_details: billingSameAsUserDetails
            ? userDetails
            : billingAddress,
          // cust_id:
          //   userLoginDetails && JSON.parse(userLoginDetails)?.CUST_ID
          //     ? JSON.parse(userLoginDetails)?.CUST_ID
          //     : "",
          // usr_id: userLoginDetails && JSON.parse(userLoginDetails)?.USR_ID,
          // plan_id: selectedPlan?.ID,
        };
        await onSuccessPaymentHandler(data);
      },
      prefill: {
        name: `${userDetails["first_name"]} ${userDetails["last_name"]}`,
        email: userDetails["email"],
        contact: userDetails["phone_no"],
      },
      notes: {
        address: userDetails["address"],
      },
      theme: {
        color: "#61dafb",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      console.log("payment is failed", response);
      // console.log("response.error.code", response.error.code);
      // console.log("response.error.description", response.error.description);
      // console.log("response.error.source", response.error.source);
      // console.log("response.error.step", response.error.step);
      // console.log("response.error.reason", response.error.reason);
      // console.log(
      //   "response.error.metadata.order_id",
      //   response.error.metadata.order_id
      // );
      // console.log(
      //   "response.error.metadata.payment_id",
      //   response.error.metadata.payment_id
      // );
    });
  };

  const submitHandler = async () => {
    const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS);
    if (!userLoginDetails) {
      alert("please login first");
      navigate("/Login");
    }
    let formIsValid = isPayNowFormValid(userDetails);
    if (!formIsValid) return;

    let reqData = {
      user_id: JSON.parse(userLoginDetails)?.id,
      plan_id: "2",
      variant: localStorage.getItem("variationcounts"),
    };
    // return;
    try {
      let response = await doRequest(reqData, "POST", "order/create");
      if (+response?.resultFlag === 1) {
        console.log("response", response);
        let orderDetails = response?.response?.orderCreate;
        completePayment(orderDetails);
      } else {
        alert(response?.message ? response?.message : "Something went wrong");
      }
    } catch (error) {
      console.log("error is ", error);
    }
  };

  useEffect(() => {
    var token = secureLocalStorage.getItem("Login");

    if (!token || token == null) {
      alert("please login first");
      navigate("/");
    } else {
      var tokenExpired = verifytoken();
      if (tokenExpired) {
        navigate("/");
      }
    }
    let userplanDetails = localStorage.getItem(config.USER_PLAN_DETAILS) ? JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS)) : null;
    if(!userplanDetails){
      alert('please choose a plan first');
    }
    let count = localStorage.getItem("variationcounts")
    if(count < 1){
      alert('please create variations first');
      navigate('/Design')
    }

  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-sm-12">
            <div className="payment_div">
              <h3>Contact address</h3>
              <form>
                <div className="row">
                  <div className="col-xl-6 col-sm-6 ">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="First name"
                        value={userDetails["first_name"]}
                        onChange={(e) => {
                          e.target.value && setFirstNameError(false);
                          onChangeHandler("first_name", e.target.value);
                        }}
                      />
                      {firstNameError && (
                        <p className="text-danger">
                          Please enter your first name
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="Last name"
                        value={userDetails["last_name"]}
                        onChange={(e) => {
                          e.target.value && setLastnameError(false);
                          onChangeHandler("last_name", e.target.value);
                        }}
                      />
                      {lastNameError && (
                        <p className="text-danger">
                          Please enter your last name
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="Address"
                        value={userDetails["address"]}
                        onChange={(e) => {
                          e.target.value && setAddressError(false);
                          onChangeHandler("address", e.target.value);
                        }}
                      />
                      {AddressError && (
                        <p className="text-danger">Please enter your address</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="number"
                        className="form-control card_form_height"
                        placeholder="Contact No."
                        value={userDetails["phone_no"]}
                        onChange={(e) => {
                          e.target.value && setPhoneNoError(false);
                          onChangeHandler("phone_no", e.target.value);
                        }}
                      />
                      {phoneNoError && (
                        <p className="text-danger">
                          Please enter your phone no
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        disabled
                        className="form-control card_form_height"
                        placeholder="Email Id"
                        value={userDetails["email"]}
                        // onChange={(e) =>
                        //   onChangeHandler("email", e.target.value)
                        // }
                      />
                      {emailError && (
                        <p className="text-danger">Please enter your email</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="City"
                        value={userDetails["city"]}
                        onChange={(e) => {
                          e.target.value && setCityError(false);
                          onChangeHandler("city", e.target.value);
                        }}
                      />
                      {cityError && (
                        <p className="text-danger">Please enter your city</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="Country"
                        value={userDetails["country"]}
                        onChange={(e) => {
                          e.target.value && setCountryError(false);
                          onChangeHandler("country", e.target.value);
                        }}
                      />
                      {countryError && (
                        <p className="text-danger">Please enter your country</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="State"
                        value={userDetails["state"]}
                        onChange={(e) => {
                          e.target.value && setStateError(false);
                          onChangeHandler("state", e.target.value);
                        }}
                      />
                      {stateError && (
                        <p className="text-danger">Please enter your state</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="number"
                        className="form-control card_form_height"
                        placeholder="Postal Code"
                        value={userDetails["postal_code"]}
                        onChange={(e) => {
                          e.target.value && setPostalCodeError(false);
                          onChangeHandler("postal_code", e.target.value);
                        }}
                      />
                      {postalCodeError && (
                        <p className="text-danger">Please enter postal code</p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-xl-6 col-sm-6">
                      <button className="fromdetai_save">Save</button>
                    </div> */}
                </div>
              </form>
              <h3>Billing Address</h3>

              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input custom_checkbox"
                    value=""
                    checked={billingSameAsUserDetails}
                    onChange={(e) => sameAddressClickHandler(e)}
                  />
                  <span className="label_check">Same as contact address</span>
                </label>
              </div>

              {!billingSameAsUserDetails && (
                <>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          type="text"
                          className="form-control card_form_height"
                          placeholder="City"
                          value={billingAddress["city"]}
                          onChange={(e) => {
                            e.target.value && setBillCityError(false);
                            billingAddressChangehandler("city", e.target.value);
                          }}
                        />
                        {billCityError && (
                          <p className="text-danger">
                            Please enter billing city
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          type="text"
                          className="form-control card_form_height"
                          placeholder="Country"
                          value={billingAddress["country"]}
                          onChange={(e) => {
                            e.target.value && setBillCountryError(false);
                            billingAddressChangehandler(
                              "country",
                              e.target.value
                            );
                          }}
                        />
                        {billCountryError && (
                          <p className="text-danger">
                            Please enter billing country
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          type="text"
                          className="form-control card_form_height"
                          placeholder="State"
                          value={billingAddress["state"]}
                          onChange={(e) => {
                            e.target.value && setBillStateError(false);
                            billingAddressChangehandler(
                              "state",
                              e.target.value
                            );
                          }}
                        />
                        {billStateError && (
                          <p className="text-danger">
                            Please enter billing state
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          typef="number"
                          className="form-control card_form_height"
                          placeholder="Postal Code"
                          value={billingAddress["postal_code"]}
                          onChange={(e) => {
                            e.target.value && setBillPostalError(false);
                            billingAddressChangehandler(
                              "postal_code",
                              e.target.value
                            );
                          }}
                        />
                        {billPostalError && (
                          <p className="text-danger">
                            Please enter billing postal code
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-sm-6">
                  <h3>Tax settings</h3>
                  <div className="tax_set">
                    <input
                      type="text"
                      className="form-control card_form_height"
                      placeholder="Registered VAT/GST/Tax ID"
                    />
                    <button className="taxsave_btn">Save</button>
                  </div>
                </div>

                {/* <div className="col-sm-6">
                  <h3>Apply invite code</h3>
                  <div className="tax_set">
                    <input
                      type="text"
                      className="form-control card_form_height"
                      placeholder="Enter Invite Code"
                    />
                    <button className="taxsave_btn" onClick={toggle2}>
                      Apply
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-sm-8">
            <div className="purchase_summary">
              <h3>Purchase summary</h3>
              <ul className="plan_currency">
                <li>
                  <h6>
                    Billed in{" "}
                    <span style={{ color: "#8b3cd9" }}>Indian Rupee</span>{" "}
                  </h6>
                </li>
              </ul>

              <ul className="silverplan_list">
                <li>
                  <h4>Total Variations Created</h4>
                  <h5 className="copupon">
                    {`${localStorage.getItem("variationcounts")} ${
                      localStorage.getItem("variationcounts") > 1
                        ? "Images"
                        : "Image"
                    }`}{" "}
                    X 10 Per Variation
                  </h5>
                </li>
                <li>
                  <h4 className="plan_cost">
                    {/* {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                        ? "₹"
                        : "$"} */}
                    ₹ {localStorage.getItem("variationcounts") * 10}
                  </h4>
                </li>
              </ul>
              <ul className="silverplan_list">
                <li>
                  <h4>Tax</h4>
                  <h5 className="copupon">18% GST Applied on</h5>
                  <h5 className="copupon_code">27CQOPP0264R1ZY</h5>
                </li>
                <li>
                  <h4 className="plan_cost">
                    {/* {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                        ? "₹"
                        : "$"} */}
                    ₹{" "}
                    {(
                      0.18 *
                      (localStorage.getItem("variationcounts") * 10)
                    ).toFixed(2)}
                  </h4>
                </li>
              </ul>

              {/* <ul className="silverplan_list">
                <li>
                  <h4>Discount</h4>
                  <h5>10% for Bharatiya Vidhya Bhavan Students</h5>
                </li>
                <li>
                  <h4 className="plan_cost">
                    {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                      ? "₹"
                      : "$"}
                    {discountApplied}
                  </h4>
                </li>
              </ul> */}

              {selectedPlan?.id != 2 && (
                <ul className="silverplan_list silverplan_list_total">
                  <li>
                    <h4>Total</h4>
                  </li>
                  <li>
                    <h4 className="total_cost">
                      {/* {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                        ? "₹"
                        : "$"} */}
                      ₹{" "}
                      {+localStorage.getItem("variationcounts") * 10 +
                        +(
                          0.18 *
                          (localStorage.getItem("variationcounts") * 10)
                        ).toFixed(2)}
                    </h4>
                  </li>
                </ul>
              )}

              <button
                className="pay_btn"
                onClick={(e) => {
                  submitHandler();
                }}
              >
                <img src={"/assets/images/lockwhite.svg"} alt="" />
                Pay Now
              </button>

              <p>
                By selecting “Pay now”, I authorize Variant Technology to charge
                my payment.
              </p>

              <ul className="email_receipt">
                {userDetails["email"] && isValidEmail(userDetails["email"]) && (
                  <li>
                    <p>Your receipt will be sent to</p>
                    <h6>{userDetails["email"]}</h6>
                  </li>
                )}
                {/* <li>
                  <a>Edit</a>
                </li> */}
              </ul>
              {/* <p>
                Actual price may vary. Prices displayed are estimates based on
                current exchange rates. Exact amount charged will vary and
                depends on the exchange rate at the time of payment processing.
                Prices displayed exclude any applicable taxes and fees, which
                will be added to the cost of all services purchased. For exact
                base pricing please refer to prices listed in USD.
              </p>

              <h6 className="char_colo">10% goes to charity</h6>
              <h6 className="char_colo">
                <a>joystoryfoundation.org</a> Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolore
              </h6> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cooupon_modal_div">
        <Modal
          zIndex={2000}
          centered
          scrollable
          className="event_modal cupon_modal"
          isOpen={modal2}
          toggle={toggle2}
        >
          <ModalHeader toggle={toggle2}>&nbsp;</ModalHeader>
          {/* <ModalBody>
              <div className="student_verfiy">
                <h5>Student Verification</h5>
                <div className="studentid_div">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Student ID"
                  />
                  <img
                    src={"/assets/images/greencheck.svg"}
                    className="student_check"
                    alt=""
                  />
                </div>
  
                <h6>OR</h6>
  
                <div className="dropzone">
                  <div
                    {...getRootProps({ className: "dropzone-upload-wrapper" })}
                  >
                    <input {...getInputProps()} />
                    <div className="dropzone-inner-wrapper innerwrap_cust">
                      {isDragAccept && (
                        <div>
                          <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                            <Check className="d-50" />
                          </div>
                          <div className="font-size-sm text-success">
                            All files will be uploaded!
                          </div>
                        </div>
                      )}
                      {isDragReject && (
                        <div>
                          <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                            <X className="d-50" />
                          </div>
                          <div className="font-size-sm text-danger">
                            Some files will be rejected!
                          </div>
                        </div>
                      )}
                      {!isDragActive && (
                        <div>
                          <div
                            className="font-size-sm"
                            style={{ color: "#495C6F" }}
                          >
                            Drag and Drop our file here OR Browse a file
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {files?.length > 0 && (
                    <div>
                      <ListGroup className="font-size-sm">{files}</ListGroup>
                    </div>
                  )}
                </div>
  
                <button className="sub_studen">Submit</button>
              </div>
            </ModalBody> */}
          <div className="modal_back"></div>
        </Modal>
      </div>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter as Router, Link, useNavigate, useSearchParams } from "react-router-dom";
import titleimg from '../../assets/img/Layer_1-2.svg'
import Google from '../../assets/img/google.png'
import Varient from '../../assets/img/varient.png'
import { useForm } from 'react-hook-form';
import KEYS from "../../env/env";
import secureLocalStorage from 'react-secure-storage';
import Loader from "../../Components/Loader";
import GoogleLoginButton from "../../Components/GoogleLoginButton";
import { doRequest } from "../../utils/helper";
import { config } from "../../utils/config";



function Signup() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [errmsg, setErrorMsg] = useState(null);
  const [params] = useSearchParams();
  const plan = params.get('plan');

  if (plan) {
    localStorage.setItem('userselectedplanid', plan)
  } else {
    if (localStorage.getItem('userselectedplanid')) {
      localStorage.removeItem('userselectedplanid')
    }
  }


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { register, formState: { errors }, handleSubmit } = useForm();



  var sign_up = (data) => {
    setLoading(true)
    if (data.password !== data.confirmpass) {
      setErrorMsg("Password and confirm password should be same");
      return false;
    }
    try {
      let obj = {
        email: data.emailid
      }
      window.gtag("event", "Signup User", obj);
    } catch (error) {

    }
    var dataToPost = {
      email: data.emailid,
      password: data.password
    }

    fetch(KEYS.apiPath + 'user/sign-up', {
      body: JSON.stringify(dataToPost),
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => response.json())
      .then(val => {
        if (val && +val?.resultFlag === 1) {
          setErrorMsg(null);
          secureLocalStorage.setItem('Login', val?.response?.token)
          localStorage.setItem('users_email', data.emailid);
          navigate('/verify-email' + window.location.search)
        } else {
          setErrorMsg(val?.message);
        }
        console.log("response is ", val);
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        setErrorMsg(err?.message ? err?.message : 'Something went wrong, Please try again later!')
      })
      
  }

  const handleLoginSuccess = async (data) => {
    try {
      let obj = {
        ...data
      }
      window.gtag("event", "Login with Google", obj);
    } catch (error) {

    }
    if (!data) return;
    try {
      let response = await doRequest(data, "POST", "user/social-login");
      if (response && +response?.resultFlag === 1) {
        let userDetails = response?.response?.user;
        let userPlanDetails = response?.response?.userPlan;
        let token = response?.response?.token;
        secureLocalStorage.setItem("Login", token);
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(userDetails)
        );
        localStorage.setItem(
          config.USER_PLAN_DETAILS,
          JSON.stringify(userPlanDetails)
        );
        if (!userDetails?.plan_status || (userDetails?.plan_status && userDetails?.plan_status === "inactive")) {
          navigate("/plans");
        } else {
          navigate("/Design");
        }
      } else {
        console.log(' response?.message', response?.message)
        setErrorMsg(
          response?.message ? response?.message : "Something went wrong!"
        );
      }
    } catch (error) {
      console.log(' error?.message', error?.message)
      setErrorMsg(error?.message ? error?.message : "Something went wrong!");
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Login error:", error);
  };

  return (
    <>
      {loading && <Loader />}
      <div id="main-container" className="container-fluid main">
        <div className="sidemenu d-flex flex-column justifycontent-between min-vh-100">
          <div className="text-center mt-3">
            <ul className="nav nav-pills flex-column">
              <li>
                <img src={Varient} alt="Varient logo" style={{ width: '50px', top: 0 }} />
              </li>
            </ul>
          </div>
        </div>

        <section className="home">
          <div className="container login-container">
            <div className="row mt-4">
              <div className="mb-4">
                <ul className="d-flex col-11 login-tabs">
                  <li className="">Signup</li>
                  <li className="mx-2">Payment</li>
                  <li className="mx-2">Register</li>
                </ul>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-4">
                <div className="login">
                  <h3 className="text-center">Sign Up</h3>
                  <p>Welcome back! Please enter your details</p>
                  {/* <GoogleLoginButton
                    onSuccess={handleLoginSuccess}
                    onFailure={handleLoginFailure}
                  />
                  <p className="or-text">or</p> */}
                  <form onSubmit={handleSubmit(sign_up)}>

                    <input type="email" name="email" placeholder="Email" {...register("emailid", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                    {errors.emailid?.type === "required" && (<span className="text-danger">Email is required.</span>)}
                    {errors.emailid?.type === "pattern" && (<span className="text-danger">Please enter valid email.</span>)}
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        style={{ paddingRight: '30px' }} {...register("password", { required: true })}
                      />
                      {errors.password?.type === "required" && (<span className="text-danger">Password is required.</span>)}
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        onClick={togglePasswordVisibility}
                        style={{ position: 'absolute', top: '40%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                      />
                    </div>

                    <div style={{ position: 'relative', marginBottom: '15px' }}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Confirm Password"
                        style={{ paddingRight: '30px' }} {...register("confirmpass", { required: true })}
                      />
                      {errors.confirmpass?.type === "required" && (<span className="text-danger">Confirm password is required.</span>)}
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        onClick={togglePasswordVisibility}
                        style={{ position: 'absolute', top: '40%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                      />
                    </div>
                    <p className="text-danger">{(errmsg != null) ? errmsg : ''}</p>
                    <input className="submit" type="submit" value="Sign Up" />
                  </form>

                  <p >Already have account? <Link to={"/Login" + window.location.search} className="login-a mx-2">Login</Link></p>
                  <p className="login_signup">By creating an account, you agree to our <Link to={'/termspolicy'}>Terms & policies </Link>.</p>
                </div>
              </div>

              <div className="col-8 d-flex justify-content-center align-items-center">
                <div className="login-title">
                  {/* <img src={titleimg} alt="title" /> */}
                  <img style={{ maxWidth: '300px' }} src={"/assets/images/logo.svg"} alt="title" />
                  <p style={{ fontSize: '90px' }}>Join the Crafters</p>
                </div>
              </div>

            </div>
          </div>


        </section>
      </div>
    </>
  );
}

export default Signup;
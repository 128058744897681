import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import randomize from "randomatic";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import $ from "jquery";
import KEYS from "../env/env";
import { submitUserPlans } from "../utils/helper";
import { config } from "../utils/config";

const Invoice = ({
  shouldGenerateInvoice,
  setShouldGenerateInvoice,
  userDetails,
  plans,
  initialUserPlanDetails,
  setLoading,
  setInvoiceNo,
  invoiceNo,
  prepaidPlanVariationCount,
}) => {
  //   const shouldGenerateInvoice = true;
  //   const plans = {
  //     id: 3,
  //     name: "Unlimited Creations",
  //     type: "Unlimited",
  //     title: "Unlock Limitless Design Possibilities",
  //     price: 5000,
  //     tax: 18,
  //     days: 28,
  //     status: "active",
  //     updated_at: "2024-04-14T07:56:08.000Z",
  //     created_at: "2024-04-14T07:54:07.000Z",
  //   };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const navigate = useNavigate();

  const getStartEndDate = () => {
    let start_date = moment();
    let end_date = "";
    const date = start_date.clone().add(plans?.days, "days");
    end_date = date.format("YYYY-MM-DD");
    if (plans?.days == 365) {
      const date = start_date.clone().add(1, "years");
      end_date = date.format("YYYY-MM-DD");
    }
    console.log(
      "startdate",
      start_date.format("YYYY-MM-DD"),
      "enddate",
      end_date
    );
    return {
      startDate: start_date.format("YYYY-MM-DD"),
      endDate: end_date,
    };
  };

  const uploadInvoiceToS3 = async (file) => {
    if (!file) {
      setLoading(false);
      return;
    }
    // console.log("file", file);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("folder", "invoice");
    formData.append("key", invoiceNo + ".pdf");
    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    try {
      let response = await fetch(`${KEYS.apiPath}file/save`, requestOptions);
      let data = await response.json();
      console.log("data", data);
      if (data && +data?.resultFlag === 1) {
        let user =
          localStorage.getItem(config.USER_LOGIN_DETAILS) &&
          JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));
        console.log("initialplandetails", initialUserPlanDetails);
        // if (initialUserPlanDetails && initialUserPlanDetails.id) {
        //   navigate("/Home");
        // } else {
          navigate("/welcome?plan=" + plans?.id);
        // }

        setLoading(false);
      } else {
        console.log("error ", data.message);

        setLoading(false);
      }
      setShouldGenerateInvoice(false);
    } catch (error) {
      setLoading(false);
      console.log("error is", error.message);
      alert("Error in generating your invoice, Contact Admin!");
    }
  };

  function blobToFile(blob, fileName, fileType) {
    // Create a new File object from the Blob
    const file = new File([blob], fileName, { type: fileType });

    return file;
  }

  const downloadInvoice = () => {
    setLoading(true);
    const cardElement = document.getElementById("downloadinvoiceid");
    if (cardElement) {
      html2canvas(cardElement, {
        // width: 462,
        // heigh: 639,
        scale: 2,
        useCORS: true,
      }).then(async (canvas) => {
        canvas.toBlob(async function (blob) {
          var file = new File([blob], "certificate" + Date.now() + ".png");

          console.log("file", file, "blob is", blob);
          var blobUrl = URL.createObjectURL(blob);

          // Calculate image dimensions for PDF
          const pdfWidth = 210; // A4 size width in mm
          const imgWidth = pdfWidth; // Image width equals PDF width
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Now 'blobUrl' contains the Blob URL which can be used as the src for an image element or as a href for a link, etc.
          // Example usage:
          var img = new Image();
          img.src = blobUrl;

          // Create PDF
          const pdf = new jsPDF("p", "mm", "a4");
          pdf.addImage(img, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST");
          var files = pdf.output("blob");
          console.log("files", files);
          const mimeType = "application/pdf";
          const pdfFile = blobToFile(files, invoiceNo + ".pdf", mimeType);
          console.log("pdfFile", pdfFile);
          uploadInvoiceToS3(pdfFile);
          pdf.save(invoiceNo);
        });
      });
    } else {
      console.error("imagenotfoun");
    }
  };

  useEffect(() => {
    let sDate = getStartEndDate()?.startDate;
    let eDate = getStartEndDate()?.endDate;
    setStartDate(sDate);
    setEndDate(eDate);
  }, [plans]);

  // useEffect(() => {
  //   let iNo = generateInvoice();
  //   setInvoiceNo(iNo);
  // }, []);
  useEffect(() => {
    if (shouldGenerateInvoice) {
      downloadInvoice();
    }
  }, [shouldGenerateInvoice]);

  function getFinancialYear(date) {
    // Get the current date
    const currentDate = moment();

    // Get the month and year of the current date
    const month = currentDate.month();
    const year = currentDate.year();

    // Determine the financial year start and end months
    const financialYearStartMonth = 3; // April (0-indexed)

    // Calculate the financial year based on the current date
    const financialYear = month < financialYearStartMonth ? year - 1 : year;

    // Extract the last two digits of the financial year
    const startYear = financialYear.toString().slice(-2);

    // Calculate the next year for the end of the financial year
    const endYear = (financialYear + 1).toString().slice(-2);

    // Construct the assessment year code
    const assessmentYearCode = `${startYear}-${endYear}`;

    return assessmentYearCode;
  }

  const generateInvoice = () => {
    const pattern = "A0000"; // A for capital alphabet, 0 for number
    let num = randomize(pattern);
    // let num = generator.getNextNumber();

    let financial_year = getFinancialYear();
    console.log("financial_year==", financial_year);
    let invoice_number = `VT_${financial_year}_${num}`;
    return invoice_number;
  };

  console.log('plan is', plans)

  return (
    <>
      <section
        style={{ position: "absolute", top: "-1000px", left: "-10000px" }}
        className="invoice_sec"
        id="downloadinvoiceid"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="var_reciept">
                <ul>
                  <li>
                    <NavLink to="">
                      <img src={"/assets/images/teedex_logo1.png"} alt="" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="">Receipt</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="invoice_address_bg">
                <div className="vard_address">
                  <h4>Variant Technology</h4>
                  <h5>
                    Floor No.: Ground Floor Building No./Flat No.: 151/4 Name Of
                    Premises/Building: Bhatia Co-Op HSL Road/Street: Road Number
                    9 Locality/Sub Locality: Jawahar Nagar City Town/Village:
                    Mumbai District: Mumbai Suburban State: Maharashtra PIN
                    Code: 400062
                  </h5>
                  <h6>GST - 27CQRPP1068G1ZE</h6>
                </div>

                <div className="var_billto">
                  <h4>{userDetails?.business_name}</h4>
                  <h5>
                    {`${userDetails?.address}, city: ${userDetails?.city}, state: ${userDetails?.state}, country : ${userDetails?.country}, ${userDetails?.postal_code}`}
                  </h5>

                  {userDetails?.gst_no && <h6>{userDetails?.gst_no}</h6>}
                </div>

                <div className="bill_to">
                  <ul className="bill_list">
                    <li>
                      <h5>Dated</h5>
                      <h6>{moment().format("DD/MM/yyyy")}</h6>
                    </li>
                    <li>
                      <h5>Invoice No.</h5>
                      {invoiceNo && <h6>{invoiceNo}</h6>}
                    </li>
                  </ul>

                  { plans?.id != '5' && <><h5 className="plan_period">Plan Period</h5>
                  {startDate && endDate && (
                    <h5 className="plan_perioddate">
                      {startDate} to {endDate}
                    </h5>
                  )}</>}
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="vargst">
                <h4 className="ser_head">Teedex Services</h4>
                <ul className="service_list">
                  <li>
                    <h5>Service</h5>
                  </li>
                  <li>
                    <h5 className="amt_r">Amount</h5>
                  </li>
                </ul>

                <ul className="unlimited_cre">
                  <li>
                    <img
                      src={"/assets/images/variantfooterlogo.png"}
                      className="un_logo"
                      alt=""
                    />
                  </li>
                  <li className="inlim_pad">
                    <h4>{plans?.name}</h4>
                    <h6>{plans?.title}</h6>
                  </li>
                  <li>{plans?.price && <h5>₹ {plans?.price}</h5>}</li>
                </ul>

                {/* <ul className="dis_list">
                  <li>
                    <h5>Discount Applied (WELCOME50)</h5>
                  </li>
                  <li>
                    <h5 className="disc_grren">- ₹ 500</h5>
                  </li>
                </ul> */}

                <ul className="dis_list">
                  <li>
                    <h5>Net Pricing</h5>
                  </li>
                  <li>
                    {plans?.id == "5" ? (
                      <h5>{plans?.price * prepaidPlanVariationCount}</h5>
                    ) : (
                      plans?.price && <h5>₹ {plans?.price}</h5>
                    )}
                  </li>
                </ul>

                {userDetails?.state === "Maharashtra" ? (
                  <ul className="dis_list">
                    <li>
                      <h5>CGST (9%)</h5>
                      <h5 className="mt-2">SGST (9%)</h5>
                    </li>
                    <li>
                      {plans?.price && (
                        <h5 className="tot_price">
                          ₹ {((9 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price)).toFixed(2)}
                        </h5>
                      )}
                      {plans?.price && (
                        <h5 className="tot_price mt-2">
                          ₹ {((9 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price)).toFixed(2)}
                        </h5>
                      )}
                    </li>
                  </ul>
                ) : (
                  <ul className="dis_list">
                    <li>
                      <h5>IGST (18%)</h5>
                    </li>
                    <li>
                      {plans?.price && (
                        <h5 className="tot_price mt-2">
                          ₹ {+((18 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price)).toFixed(2)}
                        </h5>
                      )}
                    </li>
                  </ul>
                )}

                <ul className="paidbank_list">
                  <li>
                    <h5>Paid </h5>
                  </li>
                  <li>
                    <h5 className="tot_price">
                      ₹{" "}
                      {(plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price) +
                        (9 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price) +
                        (9 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price)}
                    </h5>
                  </li>
                </ul>

                <ul className="order_total">
                  <li>
                    <h5>Order Total</h5>
                  </li>
                  <li>
                    <h5 className="order_amount">
                    ₹{" "}
                      {(plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price) +
                        (9 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price) +
                        (9 / 100) * (plans?.id == "5" ? plans?.price * prepaidPlanVariationCount :  plans?.price)}
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              display: "block",
              margin: "0px auto",
              padding: "20px 0px",
            }}
          >
            <a
              href="#"
              style={{ width: 150, margin: "0px auto", display: "block" }}
              target="_blank"
            >
              <img
                //   src="https://variantwebmedia.s3.ap-south-1.amazonaws.com/assets/varlink.png"
                src={"/assets/images/teedex_logo2.png"}
                style={{ width: 150, display: "block", margin: "20px auto" }}
                alt
              />
            </a>
          </div>
          <div>
            <ul
              style={{
                listStyleType: "none",
                margin: "0px auto",
                padding: 0,
                display: "table",
              }}
            >
              <li style={{ display: "table-cell", margin: 0, padding: 0 }}>
                <a
                  href="#"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#8B3CD9",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  Privacy Policy
                </a>
              </li>
              <li
                style={{
                  display: "table-cell",
                  margin: 0,
                  padding: "0px 10px",
                }}
              >
                <a
                  href="#"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#8B3CD9",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  Terms &amp; Conditions
                </a>
              </li>
              <li style={{ display: "table-cell", margin: 0, padding: 0 }}>
                <a
                  href="#"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#8B3CD9",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  Cancellation/Refund Policies
                </a>
              </li>
            </ul>
            <h5
              style={{
                color: "#423559",
                fontSize: 12,
                fontWeight: 500,
                margin: "0px auto",
                padding: "10px 0px",
                textAlign: "center",
              }}
            >
              Copyright @ 2024 Teedex
            </h5>
            <h6
              style={{
                color: "#423559",
                fontSize: 9,
                fontWeight: 400,
                margin: "0px auto 0px auto",
                maxWidth: 400,
                textAlign: "center",
              }}
            >
              Floor No.: Ground Floor Building No./Flat No.: 151/4 Name Of
              Premises/Building: Bhatia Co-Op HSL Road/Street: Road Number 9
              Locality/Sub Locality: Jawahar Nagar City Town/Village: Mumbai
              District: Mumbai Suburban State: Maharashtra PIN Code: 400062 GST
              - 27CQRPP1068G1ZE
            </h6>
            <p
              style={{
                textAlign: "center",
                fontSize: 9,
                fontWeight: 400,
                margin: "10px auto 20px auto",
              }}
            >
              <a
                href="#"
                target="_blank"
                style={{ textDecoration: "none", color: "#423559" }}
              >
                www.teedex.in
              </a>
            </p>
            <h6
              style={{
                textAlign: "center",
                fontSize: 9,
                fontWeight: 400,
                margin: "10px auto 20px auto",
              }}
            >
              Authorized Signatory
            </h6>
            <img
              style={{
                width: 70,
                display: "block",
                margin: "0px auto 40px auto",
              }}
              src="https://variantwebmedia.s3.ap-south-1.amazonaws.com/assets/sign.png"
              alt
            />
          </div>
        </div>
      </section>
      {/* </div> */}
    </>
  );
};

export default Invoice;

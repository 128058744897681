import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleLoginBtn from "./GoogleLoginBtn";
import secureLocalStorage from 'react-secure-storage';
import $ from 'jquery';
import verifytoken from "../env/verifytoken";

const Navbar = () => {
  let userIsLoggedIn = secureLocalStorage.getItem("Login");
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
 

  useEffect(() => {
    if (userIsLoggedIn == null) {
      
      setShowGoogleLogin(true);
    } else {
      var tokenExpired = verifytoken();
      if (tokenExpired) {
        setShowGoogleLogin(true);
      }else {
        setShowGoogleLogin(false);
      }
    }
    
  }, [userIsLoggedIn]);


  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
    setPrevScrollPos(currentScrollPos);
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [prevScrollPos]);


  const [activeItem, setActiveItem] = useState('Home');

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <>
      <nav className={`navbar navbar_pad navbar-expand-xl ${visible ? 'hidebar' : ''}`}>
        <div className="container-fluid">
          <div className="nav_pad">
            <div className="logo_grow">
              <Link className="navbar-brand navbar_logo" to={"/"}>
                <img src="/assets/images/logo.svg" alt="" />
              </Link>
            </div>
            <button
              className="navbar-toggler navbar_col_btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span></span>
              <span></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav custom_navbar">
                <li className={`nav-item ${activeItem === 'Home' ? 'active' : ''}`}>
                  <a className="nav-link" href="#header_sec"  onClick={() => handleItemClick('Home')}>
                    Home
                  </a>
                </li>
                <li className={`nav-item ${activeItem === 'Features' ? 'active' : ''}`}>
                  <a className="nav-link" href="#variant_sec" onClick={() => handleItemClick('Features')}>
                    Features
                  </a>
                </li>
                <li className={`nav-item ${activeItem === 'Pricing' ? 'active' : ''}`}>
                  <a className="nav-link" href="#sub_sec"onClick={() => handleItemClick('Pricing')}>
                    Pricing
                  </a>
                </li>
                <li className={`nav-item news_grow ${activeItem === 'F&Q' ? 'active' : ''}`}>
                  <a className="nav-link" href="#faq_sec" onClick={() => handleItemClick('F&Q')}>
                    F&Q
                  </a>
                </li>
                {showGoogleLogin ? (
                  <>
                    {" "}
                    <li className="nav-item">
                      <Link className="nav-link get_start" to="/Login">
                        Get Started - Its Free
                      </Link>
                    </li>
                    {/* <GoogleLoginBtn /> */}
                  </>
                ) : (
                  <li className="nav-item">
                    <Link className="nav-link get_start" to="/Home">
                      Go to Dashboard
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

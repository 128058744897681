import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { config } from "../utils/config";
import Sidebar from "../component/Sidebar";

export default function Welcome() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const plan = params.get("plan");
  const welcomeBtnClickHandler = () => {
    const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS)
      ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
      : null;
    if (!userLoginDetails) {
      navigate("/Login");
    } else {
      if (userLoginDetails?.first_name) {
        navigate("/Home");
      } else {
        navigate("/profile?setup=1");
      }
    }
  };

  useEffect(() => {
    const handleBackButton = () => {
      navigate("/Home");
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);
  return (
    <div id="main-container" className="container-fluid main">
      <Sidebar></Sidebar>
      <div className="container">
        <div className="welcomer_dk_brd">
          <h5>
            <img src={"/assets/images/checkbluefil.svg"} alt="" /> You have
            selected{" "}
            {plan == "1"
              ? "Free"
              : plan == "2"
              ? "Pay As You Use"
              : plan == "5"
              ? "Prepaid"
              : "Premium"}{" "}
            Plan
          </h5>

          <h2>Welcome to Teedex Beta</h2>
          <h6>
            Congratulations on joining Teedex! Get ready to revolutionize your
            tee shirt design experience. Explore our powerful features and
            unleash your creativity like never before.
          </h6>
          <Button
            onClick={() => welcomeBtnClickHandler()}
            className="btn start_journey_btn showscore cursor"
          >
            {plan == "1"
              ? "Start Creating 20 Free Variation"
              : plan == "2"
              ? "Start Creating Variation on Pay as you use"
              : plan == "6"
              ? "Start Creating Unlimited Variation for 28 Days"
              : plan == "5"
              ? `Start Creating ${
                  JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
                    .total_variant &&
                  JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
                    .total_variant != "undefined" &&
                  JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
                    .total_variant
                } Variation`
              : `Start Creating Unlimited Variation for 365 Days`}{" "}
            <img src={"/assets/imageschevronright.svg"} alt="" />
          </Button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Stage, Image as KonvaImage } from "react-konva";
import Konva from "konva";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../component/Sidebar";
import useImage from "use-image";
import { changeDpiDataUrl } from "changedpi";
import JSZip from "jszip";
import Button from "react-bootstrap/Button";
import { config } from "../../utils/config";
import download from "../../assets/img/file_download_black_24dp.svg";
import { dimensions, dimensionsKonva, doRequest } from "../../utils/helper";
import TryBeforeYouBuy from "../../component/TryBeforeYouBuy";

export default function ExportAfterPayForPayAsUse() {
  const userPaidDetails = localStorage.getItem("userdownloadimage")
    ? JSON.parse(localStorage.getItem("userdownloadimage"))
    : null;
  const disableBtn = localStorage.getItem("payasuusedisablebtn");
  const planDetails = localStorage.getItem(config.USER_PLAN_DETAILS)
    ? JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS))
    : null;
  const variationCounts = planDetails?.variant_count;
  var tshirtchangedetails = JSON.parse(
    localStorage.getItem("tshirtchangedetails")
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  var selectedImage = state?.selectedImage;
  const [tshirtdetails, setTshirtDetails] = useState(
    JSON.parse(localStorage.getItem("tshirtDetails"))
  );
  const [downloadedIndex, setDownloadedIndex] = useState([]);
  const [planIsPayAsUse, setPlanIsPayAsUse] = useState(
    planDetails && planDetails?.plan_id == "2" ? true : false
  );
  const [disableAllButton, setDisableAllButton] = useState(
    planDetails?.plan_id == "2" && (disableBtn || variationCounts > 0)
      ? true
      : false
  );
  const [firstImageDownloaded, setFirstImageDownloaded] = useState(
    planDetails?.plan_id == "2" && (disableBtn || variationCounts > 0)
      ? true
      : false
  );
  const [processing, setProcessing] = useState(false);

  var inputSearch = useRef();
  const ImgDivRef = useRef([]);
  const downladImage = useRef([]);
  const canvasRef = useRef([]);
  var ResizeImagediv = useRef();
  var stagediv = useRef();

  const [bgimage] = useImage(selectedImage);

  useEffect(() => {
    let userLoginDetails = localStorage.getItem(config?.USER_LOGIN_DETAILS)
      ? JSON.parse(localStorage.getItem(config?.USER_LOGIN_DETAILS))
      : null;
    if (!planDetails || planDetails?.plan_id != "2") {
      alert("you are not authorized for this page");
      if (userLoginDetails) {
        navigate("/Home");
      } else {
        localStorage.clear();
        navigate("/");
      }
    }
    if (!userPaidDetails) {
      alert("you are not authorized for this page");
      navigate("/Home");
    }
  }, [userPaidDetails, planDetails]);

  let downloadedImageIndex = [];

  const drawResizeStage = (indexSr, index, width, height, size) => {
    // console.log(index, width, height);
    var playernamedetails = JSON.parse(
      localStorage.getItem("playernamedetails")
    );
    var playernumberdetails = JSON.parse(
      localStorage.getItem("playernumberdetails")
    );
    var changedData = [];
    if (tshirtchangedetails != null) {
      changedData = tshirtchangedetails.filter(
        (item) => item.indexSr == indexSr
      );
      console.log(changedData);
      if (changedData.length != 0) {
        playernamedetails = changedData[0].playernamedetails;
        playernumberdetails = changedData[0].playernumberdetails;

        console.log(playernamedetails, playernumberdetails);
      }
    }
    var convertedwidth = width;
    var convertedheight = height;
    console.log(
      "(playernumberdetails.textSizePer * convertedwidth) / 100",
      (playernumberdetails.textSizePer * convertedwidth) / 100
    );
    stagediv.current.attrs.width = convertedwidth;
    stagediv.current.attrs.height = convertedheight;
    // console.log(stagediv);
    var stage = stagediv.current;
    var layer = new Konva.Layer();
    var image = new Konva.Image({
      x: 0,
      y: 0,
      image: bgimage,
      // width: convertedwidth,
      // height: convertedheight,
      width: dimensionsKonva[size]?.width,
      height: dimensionsKonva[size]?.height,
    });
    layer.add(image);

    var text1 = new Konva.Text({
      x: (playernamedetails.NametextPositionPer.x * convertedwidth) / 100,
      y: (playernamedetails.NametextPositionPer.y * convertedheight) / 100,
      text: tshirtdetails[index].name,
      fontSize: (playernamedetails.NametextSizePer * convertedwidth) / 100,
      fontFamily: playernamedetails.NamefontFamily,
      fill: playernamedetails.NametextColor,
      stroke: playernamedetails.NameoutlineColor,
      strokeWidth: (playernamedetails.NametextBorderPer * convertedwidth) / 100,
      align: "center",
      width: (playernamedetails.NameWidthPer * convertedwidth) / 100,
      rotation: playernamedetails.NamerotationAngle,
      scaleX: playernamedetails.NameScale.x,
      scaleY: playernamedetails.NameScale.y,
    });

    layer.add(text1);
    var text2 = new Konva.Text({
      x: (playernumberdetails.textPositionPer.x * convertedwidth) / 100,
      y: (playernumberdetails.textPositionPer.y * convertedheight) / 100,
      text: tshirtdetails[index].number,
      fontSize: (playernumberdetails.textSizePer * convertedwidth) / 100,
      fontFamily: playernumberdetails.fontFamily,
      fill: playernumberdetails.textColor,
      stroke: playernumberdetails.outlineColor,
      strokeWidth: (playernumberdetails.NotextBorderPer * convertedwidth) / 100,
      align: "center",
      width: (playernumberdetails.NoWidthPer * convertedwidth) / 100,
      rotation: playernumberdetails.rotationAngles,
      scaleX: playernumberdetails.NoScale.x,
      scaleY: playernumberdetails.NoScale.y,
    });
    layer.add(text2);
    var tshirtSizeWatermark = new Konva.Text({
      x: 0,
      y: 0,
      text: size,
      fontSize:
        (playernumberdetails.textSizePer * convertedwidth) / 100 / 3 / 2,
      fontFamily: playernumberdetails.fontFamily,
      fill: "black",
      stroke: playernumberdetails.outlineColor,
      // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
      // align: 'center',
      // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
      // rotation: playernumberdetails.rotationAngles,
      // scaleX: playernumberdetails.NoScale.x,
      // scaleY: playernumberdetails.NoScale.y
    });
    var textWidth = tshirtSizeWatermark.textWidth;
    var textHeight = tshirtSizeWatermark.textHeight;
    var backgroundRect = new Konva.Rect({
      x: tshirtSizeWatermark.x(),
      y: tshirtSizeWatermark.y(),
      width: textWidth,
      height: textHeight,
      fill: "white", // Set background color to white
    });

    layer.add(backgroundRect);
    layer.add(tshirtSizeWatermark);
    stage.add(layer);
    return stagediv.current;
  };
  const resizeAndConvertImage = async (
    dataURL,
    maxWidth,
    maxHeight,
    fileName,
    dpi
  ) => {
    return new Promise((resolve, reject) => {
      // Create a new image element
      const img = new Image();

      // Set the image source to the data URL
      img.src = dataURL;

      // When the image is fully loaded
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate the new dimensions while preserving aspect ratio
        let newWidth = img.width;
        let newHeight = img.height;

        if (newWidth > maxWidth) {
          newHeight *= maxWidth / newWidth;
          newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
          newWidth *= maxHeight / newHeight;
          newHeight = maxHeight;
        }

        // Set the canvas dimensions
        console.log("newwith", newWidth, " and newheight", newHeight);
        if (newWidth > 0 && newHeight > 0) {
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
        } else {
          alert("image can not be created");
          return;
        }
        // Draw the image onto the canvas with the new dimensions

        // Convert canvas content to a data URL with the specified DPI
        const resizedDataURL = canvas.toDataURL("image/jpeg", dpi);
        var imageWith300DPi = changeDpiDataUrl(resizedDataURL, 300);

        // Return the resized and converted data URL
        resolve(imageWith300DPi);
      };

      // If there's an error loading the image
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const downloadImage = (dataURL, fileName) => {
    // Create an anchor element
    const link = document.createElement("a");
    // Set the href attribute to the data URL
    link.href = dataURL;
    // Set the download attribute to the file name
    link.download = fileName;
    // Simulate a click on the anchor element to trigger download
    link.click();
  };

  const updateVariationCount = async (index) => {
    const planDetails =
      localStorage.getItem(config.USER_PLAN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
    if (!planDetails || !planDetails.plan_id) {
      alert("please choose a plan first");
      navigate("/plans");
      return;
    }
    let user = JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));
    let data = {
      id: user?.user_plan_id, ///user_plan_id
      count: "1",
    };
    try {
      let response = await doRequest(data, "POST", "plan/add-variant ");
      if (+response?.resultFlag === 1) {
        console.log("video count updated");
        let obj = { ...planDetails };
        obj["variant_count"] = obj["variant_count"] + 1;
        localStorage.setItem(config.USER_PLAN_DETAILS, JSON.stringify(obj));

        //for now i am handling downloading image count in local after paying for variation of  pay as u use plan
        if (planDetails.plan_id == "2") {
          if (!downloadedImageIndex.includes(index)) {
            downloadedImageIndex.push(index);
            let varr = localStorage.getItem("userdownloadimage");
            if (!varr) return;
            let c = JSON.parse(varr);
            let obj = {
              ...c,
              downloadedCount: c.downloadedCount + 1,
            };
            localStorage.setItem("userdownloadimage", JSON.stringify(obj));
          }
        }
      } else {
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error.message ? error?.message : "Something went wrong");
    }
  };

  const handleDownload = (
    canvas,
    fileName,
    size,
    indexSr,
    index,
    file_type
  ) => {
    let varr = localStorage.getItem("userdownloadimage");
    if (!varr) return;
    let c = JSON.parse(varr);
    let imageCanDownload = c.imageCanDownload;
    let count = c.downloadedCount;
    if (count >= imageCanDownload) {
      alert("You have downloaded all videos, Pay more to download more");
      return;
    }

    const planDetails =
      localStorage.getItem(config.USER_PLAN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_PLAN_DETAILS));
    if (planDetails && planDetails?.plan_id == "1") {
      let vCount = planDetails?.variant_count;
      const variantLeft = 20 - vCount;
      if (variantLeft <= 0) {
        alert("You free trial is expired, please upgrade your plan");
        navigate("/plans");
        return;
      }
    }

    setProcessing(true);
    if (planIsPayAsUse) {
      localStorage.setItem("payasuusedisablebtn", "true");
      setFirstImageDownloaded(true);
      setDisableAllButton(true);
    }
    if (!size) {
      alert("please select atleast size of tshirt");
      return;
    }
    const tshirtSize = size;

    // console.log(
    //   "size of image is ",
    //   tshirtSize,
    //   "width",
    //   dimensions[tshirtSize]?.width
    // );
    var stageRsize = drawResizeStage(
      indexSr,
      index,
      dimensions[tshirtSize]?.width,
      dimensions[tshirtSize]?.height,
      tshirtSize
    );
    var stageDataURL = stageRsize.toDataURL();
    // var imageWith300DPi = changeDpiDataUrl(stageDataURL, 300);
    // downloadImage(imageWith300DPi, fileName);

    // return;

    resizeAndConvertImage(
      stageDataURL,
      dimensions[tshirtSize]?.width,
      dimensions[tshirtSize]?.height,
      fileName,
      "300"
    )
      .then(async (resizedDataURL) => {
        // Do something with the resized and converted data URL
        console.log("Resized and converted data URL:", resizedDataURL);
        // For example, download the image
        downloadImage(resizedDataURL, fileName);
        await updateVariationCount(index);
        setProcessing(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  };

  const handelInputsearch = () => {
    if (inputSearch.current.value != "") {
      var excelData = JSON.parse(localStorage.getItem("tshirtDetails"));
      var searchvalue = inputSearch.current.value;
      var filterData = excelData.filter(
        (item, index) =>
          item.name.toLowerCase().includes(searchvalue.toLowerCase()) ||
          item.number.toString().includes(inputSearch.current.value)
      );
      for (let val of excelData) {
        if (filterData.length > 0) {
          var searchdata = filterData.filter(
            (item) => item.indexSr == val.indexSr
          );
          if (searchdata.length == 0) {
            ImgDivRef.current[val.indexSr].className =
              "col-xl-3 col-3 hideimage";
          } else {
            ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3";
          }
        } else {
          ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3 hideimage";
        }
      }
    } else {
      var filterData = JSON.parse(localStorage.getItem("tshirtDetails"));
      for (let val of filterData) {
        ImgDivRef.current[val.indexSr].className = "col-xl-3 col-3";
      }
    }
  };

  const drawTextOnCanvas = (text, number, canvas, index, size) => {
    if (canvas) {
      var playernamedetails = JSON.parse(
        localStorage.getItem("playernamedetails")
      );
      var playernumberdetails = JSON.parse(
        localStorage.getItem("playernumberdetails")
      );

      var changedData = [];

      if (tshirtchangedetails != null) {
        changedData = tshirtchangedetails.filter(
          (item) => item.indexSr == index
        );

        if (changedData.length != 0) {
          playernamedetails = changedData[0].playernamedetails;
          playernumberdetails = changedData[0].playernumberdetails;
        }
      }

      const stage = canvas;
      // console.log(stage);
      //  stage.children = [];
      const layer = new Konva.Layer();
      //  console.log(layer);
      // var image = new Konva.Image({
      //     x:0,
      //     y:0,
      //     image:selectedImage,
      //     width:220,
      //     height:315
      // })
      // layer.add(image);

      var text1 = new Konva.Text({
        x: (playernamedetails.NametextPositionPer.x * canvas.attrs.width) / 100,
        y:
          (playernamedetails.NametextPositionPer.y * canvas.attrs.height) / 100,
        text: text,
        fontSize:
          (playernamedetails.NametextSizePer * canvas.attrs.width) / 100,
        fontFamily: playernamedetails.NamefontFamily,
        fill: playernamedetails.NametextColor,
        stroke: playernamedetails.NameoutlineColor,
        strokeWidth:
          (playernamedetails.NametextBorderPer * canvas.attrs.width) / 100,
        align: "center",
        width: (playernamedetails.NameWidthPer * canvas.attrs.width) / 100,
        rotation: playernamedetails.NamerotationAngle,
        scaleX: playernamedetails.NameScale.x,
        scaleY: playernamedetails.NameScale.y,
      });

      layer.add(text1);

      var text2 = new Konva.Text({
        x: (playernumberdetails.textPositionPer.x * canvas.attrs.width) / 100,
        y: (playernumberdetails.textPositionPer.y * canvas.attrs.height) / 100,
        text: number,
        fontSize: (playernumberdetails.textSizePer * canvas.attrs.width) / 100,
        fontFamily: playernumberdetails.fontFamily,
        fill: playernumberdetails.textColor,
        stroke: playernumberdetails.outlineColor,
        strokeWidth:
          (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
        align: "center",
        width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
        rotation: playernumberdetails.rotationAngles,
        scaleX: playernumberdetails.NoScale.x,
        scaleY: playernumberdetails.NoScale.y,
      });

      layer.add(text2);

      var tshirtSizeWatermark = new Konva.Text({
        x: 0,
        y: 0,
        text: size,
        fontSize: 20,
        fontFamily: playernumberdetails.fontFamily,
        fill: "black",
        stroke: playernumberdetails.outlineColor,
        // strokeWidth: (playernumberdetails.NotextBorderPer * canvas.attrs.width) / 100,
        // align: 'center',
        // width: (playernumberdetails.NoWidthPer * canvas.attrs.width) / 100,
        // rotation: playernumberdetails.rotationAngles,
        // scaleX: playernumberdetails.NoScale.x,
        // scaleY: playernumberdetails.NoScale.y
      });
      var textWidth = tshirtSizeWatermark.textWidth;
      var textHeight = tshirtSizeWatermark.textHeight;
      var backgroundRect = new Konva.Rect({
        x: tshirtSizeWatermark.x(),
        y: tshirtSizeWatermark.y(),
        width: textWidth,
        height: textHeight,
        fill: "white", // Set background color to white
      });

      layer.add(backgroundRect);
      layer.add(tshirtSizeWatermark);

      stage.add(layer);
    }
  };

  useEffect(() => {
    if (tshirtdetails) {
      tshirtdetails.forEach((val, index) => {
        drawTextOnCanvas(
          val.name,
          val.number,
          canvasRef.current[val.indexSr],
          val.indexSr,
          val.size
        );
      });
    }
  }, [tshirtdetails]);

  const MAX_CONCURRENT_DOWNLOADS = 1;
  const handleDownloadAll = () => {
    setProcessing(true);

    const zip = new JSZip();
    const totalImages = tshirtdetails.length;
    let currentDownloadIndex = 0;
    let downloadsInProgress = 0;

    const downloadNextImage = () => {
      if (currentDownloadIndex >= totalImages && downloadsInProgress === 0) {
        // All images downloaded, generate and download the zip file
        zip
          .generateAsync({ type: "blob" })
          .then((content) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = "all_images.zip";
            link.click();
            setProcessing(false);
          })
          .catch((error) => {
            console.error("Error generating zip file:", error);
            setProcessing(false);
          });
        return;
      }

      while (
        currentDownloadIndex < totalImages &&
        downloadsInProgress < MAX_CONCURRENT_DOWNLOADS
      ) {
        const val = tshirtdetails[currentDownloadIndex];
        const canvas = canvasRef.current[val.indexSr];
        const fileName = `${val.size}${dimensions[val.size]?.size}_${
          val.name
        }_${val.number}.jpeg`;

        const stageRsize = drawResizeStage(
          val.indexSr,
          currentDownloadIndex,
          dimensions[val.size].width,
          dimensions[val.size].height,
          val.size
        );
        const stageDataURL = stageRsize.toDataURL();

        resizeAndConvertImage(
          stageDataURL,
          dimensions[val.size].width,
          dimensions[val.size].height,
          fileName,
          "300"
        )
          .then((resizedDataURL) => {
            zip.file(fileName, resizedDataURL.split("base64,")[1], {
              base64: true,
            });
            setDownloadedIndex((prev) => [...prev, currentDownloadIndex]);
            currentDownloadIndex++;
            downloadsInProgress--;
            downloadNextImage(); // Download next image
          })
          .catch((error) => {
            console.error("Error downloading image:", error);
            setProcessing(false);
          });

        currentDownloadIndex++;
        downloadsInProgress++;
      }
    };

    downloadNextImage(); // Start downloading images
  };

  const upgradePlanBtnClick = () => {
    navigate("/plans");
  };
  

  return (
    <>
      {/* {loading && <Loader />} */}
      <div id="main-container" className="container-fluid main">
        <Sidebar></Sidebar>
        <section
          className="home"
          style={{ maxHeight: "100vh", overflowY: "scroll" }}
        >
          <div className="row mx-2">
            <div className="col-12">
              <div className="row tab mt-3">
                <ul className="d-flex col-6 custom-tabs">
                  <li
                    className=""
                    onClick={() => {
                    //   navigate("/Design", {
                    //     state: {
                    //       selectedImage: selectedImage,
                    //       side: state.side,
                    //     },
                    //   });
                    }}
                  >
                    Design
                  </li>
                  <li
                    className="mx-2"
                    onClick={() => {
                    //   navigate("/Variation", {
                    //     state: {
                    //       selectedImage: selectedImage,
                    //       side: state.side,
                    //     },
                    //   });
                    }}
                  >
                    Variation
                  </li>
                  <li
                    className="mx-2 active"
                    onClick={() => {
                    //   navigate("/Export", {
                    //     state: {
                    //       selectedImage: selectedImage,
                    //       side: state.side,
                    //     },
                    //   });
                    }}
                  >
                    Export
                  </li>
                </ul>
              </div>

              <div className="row mt-5">
                {planDetails && planDetails?.plan_id == "2" ? (
                  <TryBeforeYouBuy
                    variationCount={tshirtdetails?.length}
                    firstImageDownloaded={firstImageDownloaded}
                  />
                ) : (
                  <div className="col-12 text-center">
                    <h4>
                      Variation Completed - Unleash the Power of Personalisation
                    </h4>
                    <p>
                      With a simple click of the "Create" button, watch as our
                      software auto-generates
                      <br /> a myriad of variations, effortlessly incorporating
                      the selected fonts and Player
                      <br /> details, Experience the power of automation and
                      unleash your creativity.
                    </p>
                    {/* <Button className="px-3 py-1" style={{ borderRadius: '30px' }} onClick={handleDownloadAll}><img src={download} alt="download" style={{ width: '20px' }} />
                                    Download All </Button> */}
                    {planDetails &&
                      planDetails?.plan_id == "1" &&
                      planDetails?.variant_count == "20" && (
                        <Button
                          className="px-3 py-1"
                          style={{ borderRadius: "30px" }}
                          onClick={upgradePlanBtnClick}
                        >
                          <img
                            src={download}
                            alt="download"
                            style={{ width: "20px" }}
                          />
                          Upgrade Plan and Download
                        </Button>
                      )}
                  </div>
                )}

                <div className="container mt-5">
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-10"
                      style={{
                        backgroundColor: "#F9FAFD",
                        paddingLeft: "30px",
                        paddingTop: "10px",
                      }}
                    >
                      <div className="row mt-4">
                        <h5 style={{ width: "30%" }}>
                          {tshirtdetails && tshirtdetails.length} Variations
                          Created
                        </h5>
                        <div
                          className=""
                          style={{ width: "40%", alignItems: "right" }}
                        ></div>
                        <div
                          className=""
                          style={{
                            width: "30%",
                            alignItems: "right",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              width: "12%",
                              padding: "10px 5px 0 5px",
                              backgroundColor: "#EAEEF8",
                              borderRadius: "20px 0 0 20px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="mx-2"
                            />
                          </div>
                          <input
                            ref={inputSearch}
                            type="text"
                            placeholder="Search all files"
                            className="export-variation-search"
                            onChange={() => {
                              handelInputsearch();
                            }}
                          />
                        </div>
                      </div>
                      <div className="my-5 col-12 justify-content-center ">
                        <div className="row">
                          {tshirtdetails &&
                            tshirtdetails.map((val, index) => (
                              <div
                                key={index}
                                className="col-xl-3 col-md-3 col-lg-3 col-3"
                                style={{
                                  position: "relative",
                                  paddingLeft: "10px",
                                  marginBottom: "25px",
                                }}
                                ref={(ref) => {
                                  if (ref) ImgDivRef.current[val.indexSr] = ref;
                                }}
                              >
                                <div
                                  className="tshirtimg1"
                                  ref={(ref) => {
                                    if (ref)
                                      downladImage.current[val.indexSr] = ref;
                                  }}
                                >
                                  <svg
                                    width="220"
                                    height="315"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ borderRadius: "10px" }}
                                  >
                                    {/* <rect x="40" y="0" width="160" height="315" fill="white" /> */}
                                    {selectedImage && (
                                      <image
                                        href={selectedImage}
                                        width="220"
                                        height="315"
                                        preserveAspectRatio="xMidYMid slice"
                                      />
                                    )}
                                    {/* <circle cx="110" cy="-55" r="73" fill="white" /> */}
                                  </svg>

                                  <Stage
                                    ref={(ref) => {
                                      if (ref)
                                        canvasRef.current[val.indexSr] = ref;
                                    }}
                                    width={220}
                                    height={315}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 10,
                                      border: "0px solid #000",
                                      borderRadius: "10px",
                                    }}
                                  ></Stage>
                                </div>
                                <div className="mt-3">
                                  {/* <button
                                    className="px-3 py-1 me-3"
                                    style={{
                                      borderRadius: "30px",
                                      backgroundColor: "#9fd3f7",
                                      border: "0",
                                    }}
                                    onClick={() => {
                                      navigate("/EditDesign", {
                                        state: {
                                          indexSr: val.indexSr,
                                          selectedImage: selectedImage,
                                          side: state.side,
                                        },
                                      });
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </button> */}
                                  <button
                                    className="px-3 py-1"
                                    style={{
                                      borderRadius: "30px",
                                      backgroundColor: downloadedIndex.includes(
                                        index
                                      )
                                        ? "rgb(159 211 247 / 36%)"
                                        : "#9fd3f7",
                                      border: "0",
                                    }}
                                    onClick={() =>
                                      handleDownload(
                                        downladImage.current[val.indexSr],
                                        `${val.size}${
                                          dimensions[val.size]?.size
                                        }_${val.name}_${val.number}.jpeg`,
                                        val.size,
                                        val.indexSr,
                                        index,
                                        "single"
                                      )
                                    }
                                    // disabled={
                                    //   processing ||
                                    //   firstImageDownloaded ||
                                    //   disableAllButton ||
                                    //   (planDetails &&
                                    //     planDetails?.plan_id == "1" &&
                                    //     planDetails?.variant_count == "20")
                                    // }
                                  >
                                    {" "}
                                    Download{" "}
                                  </button>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="stagediv"
            className="container"
            style={{ visibility: "hidden", display: "none" }}
            ref={ResizeImagediv}
          >
            <Stage ref={stagediv}></Stage>
          </div>
        </section>
      </div>
    </>
  );
}

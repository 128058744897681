import React, { useEffect, useState } from "react";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { config } from "../utils/config";
import { doRequest } from "../utils/helper";
import secureLocalStorage from "react-secure-storage";

export default function GoogleLoginBtn() {
  const [errMsg, setErrMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const handleGoogleLogin = async (data) => {
    try {
      let obj = {
        ...data
      }
      window.gtag("event", "Login with Google", obj);
    } catch (error) {

    }
    if (!data) return;
    try {
      let response = await doRequest(data, "POST", "user/social-login");
      console.log("response is ", response);
      if (response && +response?.resultFlag === 1) {
        let userDetails = response?.response?.user;
        let userPlanDetails = response?.response?.userPlan;
        let token = response?.response?.token;
        secureLocalStorage.setItem("Login", token);
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(userDetails)
        );
        localStorage.setItem(
          config.USER_PLAN_DETAILS,
          JSON.stringify(userPlanDetails)
        );
        if (userDetails?.plan_status === "inactive") {
          navigate("/plans");
        } else {
          navigate("/Design");
        }
      } else {
        console.log(' response?.message',  response?.message)
        setErrMsg(
          response?.message ? response?.message : "Something went wrong!"
        );
        setShowError(true);
      }
    } catch (error) {
      console.log(' error?.message',  error?.message)
      setErrMsg(error?.message ? error?.message : "Something went wrong!");
      setShowError(true);
    }
  };
  const handleSuccess = (res) => {
    var token = res.credential;
    var decoded = jwtDecode(token);
    console.log("decoded is ", decoded);
    let data = {
      first_name: decoded?.given_name,
      last_name: decoded?.family_name,
      email: decoded?.email,
      user_image: decoded?.picture,
    };
    handleGoogleLogin(data);
  };

  const clientId = config.GOOGLE_CLIENT_ID_CALENDER;
  useEffect(() => {
    if (clientId) {
      window?.google?.accounts?.id?.initialize({
        client_id: clientId,
        cancel_on_tap_outside: false,
        callback: (response) => {
          handleSuccess(response);
        },
      });
      window?.google?.accounts?.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
          window?.google?.accounts?.id.prompt();
        }
      });
    }
  }, [clientId]);

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      handleSuccess(credentialResponse);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  return <></>;
}

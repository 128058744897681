const KEYS = {
    // apiPath:'http://13.233.197.202:2001/v1/api/',
    // apiPath:'https://api.variant.technology/v1/api/',
    apiPath:'https://api.teedex.in/v1/api/',
    razorPayID: 'rzp_test_O1iat2Sl0gsmDm',
    razorPaySecret:'vCAY07EpTjE0yNrcm4U6mLvX',
    googleFontApiKey: 'AIzaSyDQixRHqZsDQz-lSgxDi_aqvZB9EmhFF-M'
}

export default KEYS;

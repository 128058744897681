import React, { useRef, useState } from 'react'
import Sidebar from '../../component/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { Layer, Stage, Image as KonvaImage, Text, Transformer, } from 'react-konva';
import fronttshirt from "../../assets/img/Plain TeeShirt.png"
import useImage from 'use-image';
import Popup from '../../component/Popup';
import usePopup from '../../hook/usePopUp';
// import fronttshirt from "../assets/img/Plain TeeShirt.png";

export default function FrontDesign() {
  const { state } = useLocation();
  const [selectedImage, setSelectedImage] = useState(
    state !== null ? state.selectedImage : null
  );
  const { isOpen, message, openPopup, closePopup } = usePopup();

  const canvasRef = useRef(null);

  const navigate = useNavigate();

  const navigateToVariation = () => {
    if(!selectedImage){
      openPopup("Add Background Image");
    }else {
      navigate('/frontvariation')
    }
  }

  const LoadBGImage = () => {
    const [image] = useImage(selectedImage);
    return (
      <KonvaImage
        image={image}
        width={320}
        height={500}
        style={{ position: "absolute", top: 0, left: 280, zIndex: 0 }}
        x={90.5}
      />
    );
  };
  const LoadImage = () => {
    // console.log(state.side);
    const [image] = useImage( fronttshirt );
    return <KonvaImage image={image} width={500} height={500} />;
  };
  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const hideDiv1 = () => {
    const div1 = document.getElementById("div1");
    if (localStorage.getItem("bgname") !== "") {
      div1.style.visibility = "hidden"; // Hide the div
    } else {
      div1.style.display = "inline-block"; // Show the div
    }
  }

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      localStorage.removeItem("bgImageDetails");
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
          // console.log(reader.result);
          // localStorage.setItem('bgImageDetails',reader.result)
          localStorage.setItem("bgname", file.name);
          // hideDiv1();
        };
        reader.readAsDataURL(file);
      }
    };



    const handleExportClick = () => { }
    return (
      <>
        <div
          id="main-container"
          className="container-fluid main"
          style={{ overflowY: "scroll" }}
        >
          <Sidebar />
          <section className="home">
            <div className="container-fluid">
              <div className="row ">
                <div class="col-xxl-2 col-xl-3 col-sm-3  sidebarchange">



                </div>
                <div className="col-xxl-10 col-xl-9 col-sm-9">
                  <div className="row row_pad">
                    <ul className="d-flex col-6 custom-tabs">
                      <li
                        className="active"
                        onClick={() => {
                          // handleNameNumberDetails(1);
                          // navigate("/Design", {
                          //   state: {
                          //     selectedImage: selectedImage,
                          //     side: state.side,
                          //   },
                          // });
                        }}
                      >
                        Design
                      </li>
                      <li className="mx-2" onClick={navigateToVariation}>
                        Variation
                      </li>
                      <li
                        className="mx-2"
                      // onClick={() => {
                      //   handleNameNumberDetails(1);
                      //   addVariation();
                      // }}
                      >
                        Export
                      </li>
                    </ul>
                    <div className="col-sm-6">
                      <button
                        className="addvar_btn"
                        onClick={navigateToVariation}
                      >
                        <FontAwesomeIcon
                          icon={faList}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Add Variation
                      </button>
                    </div>
                  </div>

                  <div className="mt-5">

                    <div className="">
                      <div id="container" style={{ display: "flex" }}>
                        <div className="Action-btn-div">
                          {/* <button
                          className="btn undo-redo-btn"
                          onClick={(e) => {
                            handelUndo(e);
                          }}
                        >
                          <img src={undoblack} />
                          <br />
                          Undo
                        </button>
                        <br />
                        <button
                          className="btn undo-redo-btn"
                          onClick={(e) => {
                            handelRedo(e);
                          }}
                        >
                          <img src={redoblack} />
                          <br />
                          Redo
                        </button> */}
                        </div>
                        <div
                          style={{ position: "relative" }}
                          className="tshirt-draw-canvas"
                        // ref={DesignImage}
                        >
                          <svg
                            className="svg-bg-img"
                            xmlns="http://www.w3.org/2000/svg"
                          // ref={canvasRefName}
                          >
                            <rect
                              x="0"
                              y="0"
                              width="320"
                              height="500"
                              fill="white"
                            />
                            <circle
                              cx="160"
                              cy="-123"
                              r="160"
                              fill="white"
                              className="neck-circle"
                            />
                          </svg>

                          <Stage width={500} height={500} className="ts-img">
                            <Layer>
                            <LoadImage />
                              {selectedImage && <LoadBGImage x={285} />}
                            </Layer>
                          </Stage>
                          {/* <Stage
                            width={320}
                            height={500}
                            className="ts-detail"
                            ref={canvasRef}
                          >
                            <Layer>
                            </Layer>
                          </Stage> */}

                        </div>
                      </div>

                      <div className="tsize">




                        <div className="tsize-action-div">
                          <div className="tshitrt_size">
                            <h6>Backgro…pattern.jpg</h6>
                            <div className="px-1">
                              <img onClick={handleImageRemove} src={'/assets/images/delimg.svg'} alt="" />
                            </div>
                            <div>

                              <div className="inputimg_pre">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange}

                                  id="imageInput2"
                                />
                                <img htmlFor="imageInput2" src={'/assets/images/uploadbgcm.svg'} alt="" />
                              </div>

                            </div>
                          </div>

                          <h6 className="bet_head">For better results maintain below size</h6>

                          <h6 className="exp_pix">Expected Image Pixels (11200 x 16092 Px) (300-DPI)</h6>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <Popup isOpen={isOpen} message={message} closePopup={closePopup} />

          </section >
        </div >
      </>
    );
  }

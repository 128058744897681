import React from 'react';
import { useLocation } from 'react-router-dom';

import useOrientationWarning from '../hook/useOrientationWarning';
import OrientationSizePopup from './OrientationSizePopup';

const Layout = ({ children }) => {
  const isPortrait = useOrientationWarning();
  const location = useLocation();

  const showOrientationComponentRoutes = ['/tshirtFront', '/design', '/variation', "export-new", "editDesign", "home"];
  const shouldShowOrientationComponent = showOrientationComponentRoutes.includes(location?.pathname?.toLowerCase());


  if (shouldShowOrientationComponent && isPortrait) {
    return (
    //   <div style={{
    //     position: 'fixed',
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     backgroundColor: 'white',
    //     color: 'red',
    //     fontSize: '24px',
    //     fontWeight: 'bold',
    //     textAlign: 'center',
    //     paddingTop: '50%',
    //     zIndex: 9999,
    //   }}>
    //     Please rotate your device to landscape mode to view this website.
    //   </div>
    <>
    <OrientationSizePopup />
    </>
    );
  }

  return (
    <div>
      {children}
    </div>
  );
};

export default Layout;

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Private/Login";
import Signup from "../pages/Private/Signup";
import Forgetpass from "../pages/Private/Forgetpass";
import Verifyotp from "../pages/Private/Verifyotp";
import TshirtFront from "../pages/Private/TshirtFront";
import Design from "../component/Design";
import Design1 from "../component/Design1";
import DesignBkp from "../component/DesignBkp";
import Variation from "../component/Variation";
import Export from "../component/Export";
import NewPassword from "../pages/Private/NewPassword";
import EditDesign from "../component/EditDesign";
import Plans from "../Components/Plans";
import Payment from "../Components/Payment";
import DesignTrial from "../Components/DesignTrial";
import Profile from "../pages/Private/Profile/Profile";
import BillingHistory from "../pages/BillingHistory";
import ExportNew from "../component/ExportNew";
import Welcome from "../Components/Welcome";
import PayAsUsePayment from "../Components/PayAsUsePayment";
import ExportAfterPayForPayAsUse from "../pages/Private/ExportAfterPayForPayAsUse";
import LaunchPage from "../pages/Public/LaunchPage/LaunchPage";
import VerifyForgetPasswordOtp from "../pages/VerifyForgetPasswordOtp";
import Invoice from "../Invvoice/Invoice";
import About from "../pages/Public/About";
import ContactUs from "../pages/Public/ContactUs";
import UploadFonts from "../pages/Private/UploadFonts";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import UserManagement from "../UserManagement/UserManagement";
import EditProfile from "../UserManagement/EditProfile";
import FontsDashboard from "../FontsDashboard/FontsDashboard";
import VariantPlans from "../VariantPlans/VariantPlans";
import VariantPlansEdit from "../VariantPlans/VariantPlansEdit";
import VariantFaq from "../VariantFaq/VariantFaq";
import FeedbackManagement from "../FeedbackManagement/FeedbackManagement";
import VariantFaqEdit from "../VariantFaq/VariantFaqEdit";
import NewExportTrial from "../component/NewExportTrial";
import FrontDesign from "../pages/Private/FrontDesign";
import FrontVariation from "../pages/Private/FrontVariation";
import ExportFinal from "../component/ExportFinal";
import LocalFonts from "../Components/LocalFonts";
const Home = lazy(() => import("../pages/Public/Home"));
const LoginHome = lazy(() => import("../pages/Private/Home"));
const PrivacyPolicy = lazy(() =>
  import("../pages/Public/Policy/PrivacyPolicy")
);
const TermsPolicy = lazy(() => import("../pages/Public/Policy/TermsPolicy"));
const RefundPolicy = lazy(() => import("../pages/Public/Policy/RefundPolicy"));

export default function PublicRoutes() {
  return (
    // <BrowserRouter>
      <Suspense>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/launchpage" element={<LaunchPage />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/termspolicy" element={<TermsPolicy />} />
          <Route exact path="/refundpolicy" element={<RefundPolicy />} />

          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/verify-email" element={<Verifyotp />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/Verifyotp" element={<Verifyotp />} />
          <Route path="/verify-forgetpassword-otp" element={<VerifyForgetPasswordOtp />} />
          <Route path="/Forgetpass" element={<Forgetpass />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/payforpayasuse" element={<PayAsUsePayment />} />
          <Route exact path="/payasuse/paid/export-new" element={<ExportAfterPayForPayAsUse />} />
          <Route path="/Home" element={<LoginHome />} />
          <Route path="/TshirtFront" element={<TshirtFront />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/frontdesign" element={<FrontDesign />} />
          <Route path="/Design1" element={<Design1 />} />
          <Route path="/DesignBkp" element={<DesignBkp />} />
          <Route path="/Variation" element={<Variation />} />
<Route path="/frontvariation" element={<FrontVariation />} />
          <Route path="/Export" element={<Export />} />
          <Route exact path="/export-new" element={<ExportNew />} />
          <Route exact path="/export-new-trial" element={<NewExportTrial />} />
          <Route exact path="/exportfinal" element={<ExportFinal />} />

          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/EditDesign" element={<EditDesign />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/billing-history" element={<BillingHistory />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/upload-fonts" element={<UploadFonts />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/usermanagement" element={<UserManagement />} />
          {/* <Route path="/usermanagement" element={<UserManagement />} /> */}
          <Route path="/editprofile/:id?" element={<EditProfile />} />
          <Route path="/feedbackmanagement" element={<FeedbackManagement />} />
          <Route path="/fontsdashboard" element={<FontsDashboard />} />
          <Route path="/varintplans" element={<VariantPlans />} />
          <Route path="/varintplansedit" element={<VariantPlansEdit />} />
          <Route path="/varintfaq" element={<VariantFaq />} />
          <Route path="/variantfaqedit" element={<VariantFaqEdit />} />
          <Route path="/localfonts" element={<LocalFonts />} />
          
         </Routes>
      </Suspense>
    // </BrowserRouter>
  );
}

import React, { useState } from "react";

export default function SearchHeader({ placeHolder, search, onChangeHandler }) {
  return (
    <>
      <div className="search_header">
        <ul className="search_list">
          <li>
            <div className="search_div">
              <input
                value={search}
                onChange={(e) => onChangeHandler(e.target.value)}
                type="search"
                placeholder={placeHolder ? placeHolder : "Search here.."}
              />
              <img src={"/assets/images/searchheader.svg"} alt="" />
            </div>
          </li>

          {/* <li>
            <div className="cansear_div">
              <button className="can_sea">Cancel</button>
              <button className="sav_sea">Save</button>
            </div>
          </li> */}
        </ul>
      </div>
    </>
  );
}

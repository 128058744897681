import React from "react";
import moment from "moment";
import { config } from "../utils/config";
export default function UserBillingItem() {
  return (
    <>
      
          

            <ul className="plan_billheader plan_billheader_list">
              <li className="planbill_wid">
                <h6 className="plan_goldcol">Unlimited Creations Yearly</h6>
              </li>

              <li className="am_wid">
                <h6 className="amount_plali"><sup>₹</sup>
                  <span>63720</span></h6>
              </li>

              <li className="var_wid">
                <h6 className="var_wid_h">572</h6>
              </li>

              <li className="start_wid">
                <h6 className="var_wid_h">02-05-2024</h6>
              </li>

              <li className="end_wid">
                <h6 className="var_wid_h">02-05-2024</h6>
              </li>

              <li className="stat_wid">
               
                  <h6 style={{ color: "#18763C" }}>
                    Active
                  </h6>
               
                  
                
              </li>

              <li className="dwnin_wid">
              <a
              className="down_inlink"
                    target="_blank"
                    href="#"
                  >DOWNLOAD</a>
              </li>
            </ul>



            <ul className="plan_billheader plan_billheader_list">
              <li className="planbill_wid">
                <h6 className="plan_goldcol">Unlimited Creations Yearly</h6>
              </li>

              <li className="am_wid">
                <h6 className="amount_plali"><sup>₹</sup>
                  <span>63720</span></h6>
              </li>

              <li className="var_wid">
                <h6 className="var_wid_h">572</h6>
              </li>

              <li className="start_wid">
                <h6 className="var_wid_h">02-05-2024</h6>
              </li>

              <li className="end_wid">
                <h6 className="var_wid_h">02-05-2024</h6>
              </li>

              <li className="stat_wid">
               
                  
               
                  <h6 style={{ color: "rgb(224 16 57)" }}>
                    Expired
                  </h6>
                
              </li>

              <li className="dwnin_wid">
              <a
              className="down_inlink"
                    target="_blank"
                    href="#"
                  >DOWNLOAD</a>
              </li>
            </ul>

            

          
        
    </>
  );
}

import React from 'react';
import {jwtDecode }  from "jwt-decode";
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({ onSuccess, onFailure, registerUserBySocialLogin }) => {
  const clientId = '171980855262-06sneiq2ejpmbrckkfd63p6tgo49nlb4.apps.googleusercontent.com'; // Replace with your actual Client ID

  const handleSuccess = (res) => {
    var token = res.credential;
    var decoded = jwtDecode(token);
    console.log("decoded is ", decoded);
    let data = {
      first_name: decoded?.given_name,
      last_name: decoded?.family_name,
      email: decoded?.email,
      user_image: decoded?.picture,
    };
    onSuccess(data);
   
  };

  const handleFailure = (error) => {
    // Handle login failure

    console.error('Login failed:', error);
    // console.error('Login failed:', onFailure(error));
    if (onFailure) {
      onFailure(error);
    }
  };

  return (
    <GoogleLogin
      // clientId={clientId}
      // buttonText="Login with Google"
      onSuccess={handleSuccess}
      onFailure={handleFailure}
    // className="btn_google btn_google2"
    // cookiePolicy={'single_host_origin'}
    />
  );
};

export default GoogleLoginButton;

import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function SubCancelledPopup({ close }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="success_bg">
        <div className="success_content" style={{ maxWidth: "615px" }}>
          <NavLink to={""} onClick={() => close()}>
            <img src="/assets/images/cross.svg" className="crossicon" alt="" />
          </NavLink>

          <img src="/assets/images/verify.svg" className="verify_img" alt="" />

          <div className="row">
            {/* <div className="text-center mb-4">
                  <a className="red-background mb-5">Subscription Alert</a>
                </div> */}
            <div className="justify-content-center text-center">
              <h2>We're sorry to see you go!</h2>
              <br />

              <h6>Your subscription has been successfully canceled.</h6>
              <h6>
                You can continue to use the subscription for the next 3 days.
              </h6>
              <h6>Please note that the money is not refundable.</h6>
              <h6>
                If you need any question or need assistance, please contact our
                support team.
              </h6>
              <br />
              <h6>We hope to see you again soon!</h6>
            </div>
          </div>
          {/* <hr /> */}
          <div className="row mt-3">
            <div className="justify-content-center text-center">
              {/* {variationCountLeft > 0  ? 
                  <Button color="create_variations mb-3" onClick={() => close()}>
                    Create {variationCountLeft} Free Variations
                  </Button> : */}
              <Button
                color="create_variations mb-3"
                onClick={() => navigate("/Home")}
              >
                Back to Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { config } from "../utils/config";
import { Button } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

export default function ImagesRemaining({ plan, tshirtdetails, userVariantDetails }) {
  const navigate = useNavigate();


  console.log('plan is', plan)
  if (plan && (plan?.plan_id == 5 || plan?.plan_id == "1") && userVariantDetails) {
    return (
      <>
        {userVariantDetails?.remaning > 0 ? (
          <p
            style={{
              backgroundColor: "#8b3cd945",
              color: "#8B3CD9",
              fontWeight: "600",
              borderRadius: "20px",
              textAlign: "center",
            }}
          >
            {userVariantDetails?.remaning} Image{userVariantDetails?.remaning > 1 ? "s" : ""} you
            can generate
          </p>
        ) : (
          <p
            style={{
              backgroundColor: "#8b3cd945",
              color: "#8B3CD9",
              fontWeight: "600",
              borderRadius: "20px",
              textAlign: "center",
            }}
          >
            You can not generate more images{" "}
            <Button
              color="create_variations"
              onClick={() => {
                // navigate(
                //   "/payment?plan=5&limit=" +
                //     (tshirtdetails ? tshirtdetails?.length : "")
                // );
                navigate('/plans')
              }}
            >
              Upgrade Now
            </Button>
          </p>
        )}
      </>
    );
  } else {
    return <></>;
  }
}

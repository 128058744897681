import React, { useEffect, useState } from "react";

// import { useHistory } from 'react-router-dom';/
import Sidebar from "../../component/Sidebar";
import front from '../../assets/img/Front.svg';
import back from '../../assets/img/Back.svg';
// import { Dropdown } from 'react-bootstrap';
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { Form, InputGroup } from 'react-bootstrap';
import secureLocalStorage from 'react-secure-storage';
import verifytoken from "../../env/verifytoken";
import { config } from "../../utils/config";
import VariationCountPopup from "../../Components/VariationCountPopup";

function Home() {
    const [showVariationCountPopup, setShowVariationCountPopup] = useState(false);
    const [variationCountLeft, setVariationCountLeft] = useState(0);
    const navigate = useNavigate();
    const closePopup = () => setShowVariationCountPopup(false);

    var token = secureLocalStorage.getItem('Login')
    useEffect(() => {
        console.log(token);
        if (!token) {
            console.log("inside if condition")
            navigate('/');
        } else if (token == null) {
            console.log("inside else if condition")
            navigate('/');
        } else {
            console.log("inside else condition")
            var tokenExpired = verifytoken();
            if (tokenExpired) {
                navigate('/')
            } else {
                console.log('token is not expired')
                let showP = localStorage.getItem('showfreevariantpopup');
                if (showP) {
                    console.log('token is not expired, inside if')

                    let planDetails = localStorage.getItem(
                        config.USER_PLAN_DETAILS
                    ) ? JSON.parse(localStorage.getItem(
                        config.USER_PLAN_DETAILS
                    )) : null
                    if (planDetails?.plan_id == '1') {
                        setVariationCountLeft(20 - planDetails?.variant_count
                        )
                        setShowVariationCountPopup(true);
                    }
                } else {
                    console.log('token is not expired inside else')

                    setShowVariationCountPopup(false);
                }
            }
        }
    }, [token])

    const [selectedValue, setSelectedValue] = useState(''); // Initialize the state
    localStorage.removeItem('bgImageDetails')
    localStorage.removeItem('playernamedetails')
    localStorage.removeItem('tshirtDetails')
    localStorage.removeItem('playernumberdetails')
    localStorage.removeItem('tshirtchangedetails')
    localStorage.removeItem('bgname')
    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);

        // Redirect to the "Design" page with the updated selected value
        // Redirect to the "Design" page without changing the URL
        navigate('/Design', { state: { selectedValue: value, selectedImage: null } });
    };

    const handleBackSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);

        // Redirect to the "Design" page with the updated selected value
        // Redirect to the "Design" page without changing the URL
        navigate('/DesignBkp', { state: { selectedValue: value } });
    };


    return (
        <>
            {showVariationCountPopup && <VariationCountPopup variationCountLeft={variationCountLeft} close={closePopup} />}
            <div id="main-container" className="container-fluid main">

                <Sidebar></Sidebar>
                <section className="home d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex home-content mb-3 text-center">
                        <h5>Design.</h5>
                        <h5>Variation.</h5>
                        <h5>Export.</h5>
                    </div>
                    <div className="container home-inner">
                        <div className="row home-col">
                            {/* <div className="col-6 tsselect" onClick={() => { navigate('/frontdesign', { state: { side: 'front' } }) }}>

                                <img
                                    src={front}
                                    className="img-fluid mx-auto d-block"
                                    alt="Responsive"
                                    al
                                />

                                <p className="text-center text-dark">Tee Shirt Front Side</p>



                            </div> */}
                            <div className="col-6 tsselect" onClick={() => { navigate('/Design', { state: { side: 'back' } }) }}>
                                {/* <Link to="/TshirtFront" className="custom-link"> */}

                                <img
                                    src={back}
                                    className="img-fluid mx-auto d-block"
                                    alt="Responsive"
                                />
                                {/* </Link>  */}
                                <p className="text-center text-dark">Tee Shirt Back Side</p>
                                {/* <Form.Select aria-label="Default select example" className="mb-2 custom-dropdown" onChange={handleBackSelectChange} value={selectedValue}>
                        <option>XS 34 (19 x 27 in)</option>
                            <option>S 36 (20 x 28 in)</option>  
                            <option>M 38 (21 x 29 in)</option>
                            <option>L 40 (22 x 30 in)</option>
                            <option>XL 42 (23 x 31 in)</option>
                            <option>2XL 44 (24 x 32 in)</option> 
                            <option>3XL 46 (25 x 33 in)</option>
                            <option>4XL 48 (26 x 34 in)</option>
                            <option>5XL 50 (27 x 35 in)</option>
                            <option>6XL 52 (28 x 35 in)</option>  
                        </Form.Select> */}

                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    );
}

export default Home;
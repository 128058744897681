import React from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import Layout from "../Components/Layout";
import { BrowserRouter as Routers } from "react-router-dom";

export default function Router() {
  return (
    <>
      <Routers>
        <Layout>
          <PublicRoutes />
          {/* <PrivateRoutes /> */}
        </Layout>
      </Routers>
    </>
  );
}

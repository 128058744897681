import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import { config } from "../utils/config";
import { doRequest } from "../utils/helper";
import Loader from "../Components/Loader";
import UserBillingItem from "./UserBillingItem";

export default function UserBillingHistory() {
 
  return (
    <>
      
      <div
        id="main-container"
        className="container-fluid main"
        style={{ overflowY: "scroll" }}
      >
        <Sidebar></Sidebar>
        <section className="home">
          <div className="container-fluid">
            <div>
              
              
              <div className="row_bill_pad">
                <div className="row row_billing">
                  <div className="col-sm-3">
                    <h6 className="history_header">Total History</h6>
                  </div>
                  <div className="col-sm-9">
                    <h6 className="var_total"></h6>
                    Total Amount
                    <h4 className="var_amount">
                      <sup>₹</sup>
                      <span>69620</span>
                    </h4>
                  </div>
      
                </div>



                <div className="">
                  <ul className="plan_billheader">
                    <li className="planbill_wid">
                      <h6>Plan</h6>
                    </li>

                    <li className="am_wid">
                      <h6>Amount</h6>
                    </li>

                    <li className="var_wid">
                      <h6>Variation Created</h6>
                    </li>

                    <li className="start_wid">
                      <h6>Start Date</h6>
                    </li>

                    <li className="end_wid">
                      <h6>End Date</h6>
                    </li>

                    <li className="stat_wid">
                      <h6>Status</h6>
                    </li>

                    <li className="dwnin_wid">
                      <h6 className="amt_right">Amount</h6>
                    </li>
                  </ul>

                  <UserBillingItem />
                </div>



              </div>



            </div>

          </div>
        </section >
      </div >
    </>
  );
}

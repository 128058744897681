import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import { Table, Button } from "reactstrap";

export default function VariantPlans() {
    return (
        <>
            <div
                className="container-fluid"
            >
                <Sidebar />

                <section className="home">
                    <div className="container-fluid">
                        <div className="searcmain_pad">
                            <div className="search_header">
                                <ul className="search_list">
                                    <li>
                                        <h3>Teedex Plans</h3>
                                    </li>

                                    <li>
                                        <div className="cansear_div">
                                            <button className="can_sea">Cancel</button>
                                            <button className="sav_sea">Save</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="container">

                                <div className="table-responsive table_user_mar">
                                    <Table hover striped className="table_cust align-middle">
                                        <thead className="thead_bg">
                                            <th className="userth">ID</th>
                                            <th className="userth">Plan</th>
                                            <th className="userth">DURATION</th>
                                            <th className="userth">PRICE</th>
                                            <th className="userth">CURRENCY</th>
                                            <th className="userth">DATE</th>
                                            <th className="userth">STATUS</th>
                                            <th className="userth">Actions</th>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="disable_var">
                                                        <img src={'/assets/images/disablecross.svg'} alt="" />Disable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    1
                                                </td>

                                                <td>Free</td>

                                                <td>2</td>

                                                <td>0</td>

                                                <td>₹</td>
                                                <td>Sep 9, 2022</td>
                                                <td>
                                                    <h6 className="enable_var">
                                                        <img src={'/assets/images/enablecheck.svg'} alt="" />Enable
                                                    </h6>
                                                </td>




                                                <td>
                                                    <div className="viewProf_flex">



                                                        <Button tag="a" href="#"
                                                            className="edit_btn edit_btn_nomar"
                                                        >
                                                            <img src={'/assets/images/editpurple.svg'} className="healeyeblue" alt="" />
                                                            <img src={'/assets/images/editwhite.svg'} className="healeyewhite" alt="" />
                                                            Edit
                                                        </Button>

                                                        <Button tag="a" href="#"
                                                            className="del_user"
                                                        >

                                                            Disable
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>



                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
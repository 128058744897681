import React, { useState } from "react";
import Sidebar from "../component/Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardNumber from "./DashboardNumber";
import DashboardGraph from "./DashboardGraph";
import FeedbackDashboard from "./FeedbackDashboard";


export default function AdminDashboard() {

    const [startDate, setStartDate] = useState(new Date());
    const ExampleCustomInput = ({ value, onClick }) => (
        <button block className="admindate" onClick={onClick}>
            <span className="btn-wrapper--label">{value}</span>
            <span className="btn-wrapper--icon">
                <img src={'/assets/images/chevrondown.svg'} alt="" />
            </span>
        </button>
    );


    const [endtDate, setEndDate] = useState(new Date());

    const ExampleCustomInput2 = ({ value, onClick }) => (
        <button block className="admindate" onClick={onClick}>
            <span className="btn-wrapper--label">{value}</span>
            <span className="btn-wrapper--icon">
                <img src={'/assets/images/chevrondown.svg'} alt="" />
            </span>
        </button>
    );
    return (
        <>
            <div
                className="container-fluid"
            >
                <Sidebar />
                <section className="home">
                    <div className="container-fluid">
                        <div className="admin_pad">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h5 className="admin_head">Admin Dashboard</h5>
                                </div>

                                <div className="col-sm-6">
                                    <ul className="frodate_list">
                                        <li className="form_pad">
                                            <h6>From</h6>
                                        </li>
                                        <li>
                                            <div className="datepicker_analytics">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    customInput={<ExampleCustomInput />}
                                                    dateFormat="MMM YYYY"
                                                />
                                            </div>
                                        </li>

                                        <li className="form_pad">
                                            <h6>To</h6>
                                        </li>

                                        <li>
                                            <div className="datepicker_analytics">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={endtDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    customInput={<ExampleCustomInput2 />}
                                                    dateFormat="MMM YYYY"
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <DashboardNumber />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <DashboardGraph />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-12">
                                    <FeedbackDashboard />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    )
}
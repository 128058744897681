import { useEffect, useState } from "react";

const LocalFonts = () => {
  const [loading, setLoading] = useState(false);
  const [localFonts, setLocalFonts] = useState([]);
  const [apiSupported, setApiSupported] = useState(false);
  const [fontFaces, setFontFaces] = useState({});

  async function getLocalFonts() {
    if ("queryLocalFonts" in window) {
      setApiSupported(true);
      setLoading(true);
    } else {
      setApiSupported(false);
      return;
    }
    try {
      const fonts = await window.queryLocalFonts();
      console.log(fonts); // Fonts is an array of FontData objects
      setLocalFonts(fonts);
    } catch (error) {
      console.error("Error fetching local fonts:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if ("queryLocalFonts" in window) {
      setApiSupported(true);
      getLocalFonts();
    } else {
      setApiSupported(false);
    }
  }, []);

  useEffect(() => {
    if (localFonts.length > 0) {
      const loadFonts = async () => {
        const loadedFontFaces = {};
        for (const font of localFonts) {
          const fontFace = new FontFace(font.family, `local(${font.fullName})`);
          try {
            await fontFace.load();
            document.fonts.add(fontFace);
            loadedFontFaces[font.family] = fontFace;
          } catch (error) {
            console.error(`Error loading font ${font.family}:`, error);
          }
        }
        setFontFaces(loadedFontFaces);
      };
      loadFonts();
    }
  }, [localFonts]);

  if (!apiSupported) {
    return <h1>Your browser does not support the Font Access API</h1>;
  }

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <button onClick={getLocalFonts}>
        Show my locally installed fonts
      </button>
      {localFonts.length > 0 ? (
        localFonts.map((item, index) => (
          <div
            key={index}
            style={{ fontFamily: item.family }}
            onClick={() => setFontFaces(item.family)}
          >
            <p>Font Family: {item.family}</p>
            <p>Font Full Name: {item.fullName}</p>
          </div>
        ))
      ) : (
        <h1>No Fonts Detected</h1>
      )}
    </div>
  );
};

export default LocalFonts;

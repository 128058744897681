import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import { config } from "../utils/config";
import { doRequest } from "../utils/helper";
import Loader from "../Components/Loader";
import BillingHistoryItem from "../Components/BillingHistoryItem";

export default function BillingHistory() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVariation, setTotalVariation] = useState(0);

  const getHistory = async () => {
    setLoading(true);
    let userId = localStorage.getItem(config.USER_LOGIN_DETAILS)
      ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))?.id
      : null;
    if (!userId) return;
    let data = {
      user_id: userId,
    };

    try {
      let response = await doRequest(data, "POST", "order/history");
      if (+response?.resultFlag === 1) {
        let a = response?.response?.results;
        let t = 0;
        let variation = 0;
        for (let i of a) {
          t += i.price + (i.tax / 100) * i.price;
          variation += i.variant_count;
        }
        setTotalAmount(t);
        setTotalVariation(variation);
        setList(a);
        setLoading(false);
      } else {
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistory();
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div
        id="main-container"
        className="container-fluid main"
        style={{ overflowY: "scroll" }}
      >
        <Sidebar></Sidebar>
        <section className="home">
          <div className="container-fluid">
            <div>
              <div className="round-button">
                <i style={{ color: "#8B3CD9" }} className="fas fa-arrow-left" />
              </div>

              <div className="heading">
                <div style={{ paddingLeft: 0 }} className="text-center m-5">
                  <h1>Billing History</h1>
                </div>
              </div>
              <div className="row_bill_pad">
                <div className="row row_billing">
                  <div className="col-sm-3">
                    <h6 className="history_header">Total History</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6 className="var_total">Total Amount</h6>
                    <h4 className="var_amount">
                      <sup>₹</sup>
                      <span>{totalAmount}</span>
                    </h4>
                  </div>
                  <div className="col-2 variation-header  ">
                    <h6 className="var_total">Variation Created</h6>
                    <h4 className="var_amount">
                      <span>{totalVariation}</span>
                    </h4>
                  </div>
                  {/* <div className="col-2 start-date-header">
                    Total Day Uses <div className="s2 c2">241</div>
                  </div> */}
                  {/* <div className="col end-date-header" />
                    <div className="col status-header" />
                    <div className="col empty-header" />
                    <div className="col s8 c8"> */}
                  {/* <div className="download">DOWNLOAD INVOICE</div> */}
                </div>

                <div className="">
                  <ul className="plan_billheader">
                    <li className="planbill_wid">
                      <h6>Plan</h6>
                    </li>

                    <li className="am_wid">
                      <h6>Amount</h6>
                    </li>

                    <li className="var_wid">
                      <h6>Variation Created</h6>
                    </li>

                    <li className="start_wid">
                      <h6>Start Date</h6>
                    </li>

                    <li className="end_wid">
                      <h6>End Date</h6>
                    </li>

                    <li className="stat_wid">
                      <h6>Status</h6>
                    </li>

                    <li className="dwnin_wid">
                      <h6 className="amt_right">Amount</h6>
                    </li>
                  </ul>

                  {list?.length > 0 ? (
                    <BillingHistoryItem list={list} getHistory={getHistory} />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

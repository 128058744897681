// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.custom-select {
  width: 100%;
    border: 1px solid;
    font-size: 12px;
    font-family: Arial;
    color: gray;
  }
  .custom-input-group-text {
    font-size: 12px;
    font-family: Arial;
    color: gray;
    /* padding-left: 10px !important;  */
  }
  .custombackground{
    background-color: white;
    margin: 1px 1px;
    border-radius: 5px;
  }
  .crlBackground{
    background: var(--unnamed-color-eee5ff) 0% 0% no-repeat padding-box;
    width: 32px;
    padding:10px;
    border-radius: 52px;
    /* Layout Properties */
/* UI Properties
background: var(--unnamed-color-eee5ff) 0% 0% no-repeat padding-box;
background: red 0% 0% no-repeat padding-box;
border-radius: 52px;
opacity: 2;
padding: 5px; */
  }

  .custom-text-end {
    text-align: right !important;
  }
  .pad_des .col-3{
    padding-left: 0px;
  }
  .pad_des .col-3 .input-group-text{
    padding-left: 8px;
    font-size: 11px;
  }`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,WAAW;IACT,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,oCAAoC;EACtC;EACA;IACE,uBAAuB;IACvB,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,mEAAmE;IACnE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;;;;eAKe;EACb;;EAEA;IACE,4BAA4B;EAC9B;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":["/* styles.css */\r\n\r\n.custom-select {\r\n  width: 100%;\r\n    border: 1px solid;\r\n    font-size: 12px;\r\n    font-family: Arial;\r\n    color: gray;\r\n  }\r\n  .custom-input-group-text {\r\n    font-size: 12px;\r\n    font-family: Arial;\r\n    color: gray;\r\n    /* padding-left: 10px !important;  */\r\n  }\r\n  .custombackground{\r\n    background-color: white;\r\n    margin: 1px 1px;\r\n    border-radius: 5px;\r\n  }\r\n  .crlBackground{\r\n    background: var(--unnamed-color-eee5ff) 0% 0% no-repeat padding-box;\r\n    width: 32px;\r\n    padding:10px;\r\n    border-radius: 52px;\r\n    /* Layout Properties */\r\n/* UI Properties\r\nbackground: var(--unnamed-color-eee5ff) 0% 0% no-repeat padding-box;\r\nbackground: red 0% 0% no-repeat padding-box;\r\nborder-radius: 52px;\r\nopacity: 2;\r\npadding: 5px; */\r\n  }\r\n\r\n  .custom-text-end {\r\n    text-align: right !important;\r\n  }\r\n  .pad_des .col-3{\r\n    padding-left: 0px;\r\n  }\r\n  .pad_des .col-3 .input-group-text{\r\n    padding-left: 8px;\r\n    font-size: 11px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

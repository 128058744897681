import React from "react";
// import confetti from "https://esm.run/canvas-confetti@1";
import confetti from "canvas-confetti";

import { useNavigate } from "react-router-dom";

const LaunchPage = () => {
  const navigate = useNavigate();
  function onClick() {
    console.log('clicked');
    confetti({
      particleCount: 150,
      spread: 60,
    });
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }
  return (
    <>
      <section className="laung_bg">
        <div className="container-fluid">
          <div className="lauch_pos">
            <div className="lauch_flex">
              <div>
                <img
                  src="/assets/images/variantfooterlogo.png"
                  className="launch_logo"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="/assets/images/logo.svg"
                  className="launch_logovar"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="/assets/images/baps.png"
                  className="launch_baps"
                  alt=""
                />
              </div>
            </div>

            <div className="launch_content">
              <img
                src="/assets/images/ring2.svg"
                className="ring_launch"
                alt=""
              />
              <h1 className="launch_head">Launch Teedex Website</h1>
              <h2>Welcome, Mahant Swami</h2>
              <p>
                We are honored to have you launch the Teedex website. Your
                presence signifies the beginning of a new chapter in our journey
                towards empowering users with innovative tee shirt design
                solutions.
              </p>
              <p>
                As we prepare to unveil the Teedex website to the world, we
                express our deepest gratitude for your esteemed presence and
                blessings.
              </p>

              <button className="launch_btn" onClick={onClick}>
                🎉 Launch Teedex Website
              </button>

              <img
                src="/assets/images/yuvak.png"
                className="yuvak_img"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="image_flex">
          <img src="/assets/images/swami2.png" className="swami2" alt="" />
          <img src="/assets/images/swami1.png" className="swami1" alt="" />
        </div>
      </section>
    </>
  );
};

export default LaunchPage;

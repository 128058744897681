import KEYS from "../env/env";
import secureLocalStorage from 'react-secure-storage';

export const doRequest = async (data, method, action) => {
  var myHeaders = new Headers();
  let token = secureLocalStorage.getItem("Login");
  myHeaders.append("Content-Type", "application/json");
  if (token) {
    myHeaders.append("Authorization", `Bearer ${token}`);
  }
  if (data) {
    var raw = JSON.stringify(data);
  }

  var requestOptions = {
    method: method ? method : "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  if (method === "POST") {
    requestOptions["body"] = raw;
  }

  let response = await fetch(`${KEYS.apiPath}${action}`, requestOptions);
  let result = await response.json();
  return result;
};

export const dimensionsOld = {
  XS: { width: 5700, height: 8100, size: 34 }, //34 size
  S: { width: 6000, height: 8526, size: 36 },  //36 size
  M: { width: 6300, height: 8953, size: 38 },  //38 size
  L: { width: 6600, height: 9379, size: 40 }, //40 size
  // L: { width: 6600, height: 9000, size: 40 }, //40 size
  XL: { width: 6900, height: 9805, size: 42 }, //42 size
  '2XL': { width: 7200, height: 10232, size: 44 }, //44 size
  '3XL': { width: 7500, height: 10658, size: 46 }, //46 size
  '4XL': { width: 7800, height: 11084, size: 48 }, //48 size
  '5XL': { width: 8100, height: 11511, size: 50 },  // 50size
  '6XL': { width: 8400, height: 11937, size: 52 }, //52 size
}

export const dimensionsKonva = {
  '1-2 Yr': { width: 4035, height: 5733, size: 19 },
  '3 Yr': { width: 4222, height: 6000, size: 20 },
  '4 Yr': { width: 4433, height: 6300, size: 21 },
  '5-6 Yr': { width: 4651, height: 6608, size: 22 },
  '7-8 Yr': { width: 4856, height: 6900, size: 23 },
  '9-10 Yr': { width: 5401, height: 7674, size: 24 },
  '11-12 Yr': { width: 5489, height: 7800, size: 26 },
  XS: { width: 5700, height: 8100, size: 34 }, //34 size
  S: { width: 6000, height: 8526, size: 36 },  //36 size
  M: { width: 6300, height: 8953, size: 38 },  //38 size
  L: { width: 6600, height: 9379, size: 40 }, //40 size
  // L: { width: 6600, height: 9000, size: 40 }, //40 size
  XL: { width: 6900, height: 9805, size: 42 }, //42 size
  '2XL': { width: 7200, height: 10232, size: 44 }, //44 size
  '3XL': { width: 7500, height: 10658, size: 46 }, //46 size
  '4XL': { width: 7800, height: 11084, size: 48 }, //48 size
  '5XL': { width: 8100, height: 11511, size: 50 },  // 50size
  '6XL': { width: 8400, height: 11937, size: 52 }, //52 size
}


export const dimensions = {
  '1-2 Yr': { width: 3750, height: 5700, size: 19 },
  '3 Yr': { width: 4050, height: 6000, size: 20 },
  '4 Yr': { width: 4350, height: 6300, size: 21 },
  '5-6 Yr': { width: 4650, height: 6600, size: 22 },
  '7-8 Yr': { width: 4800, height: 6900, size: 23 },
  '9-10 Yr': { width: 5400, height: 7200, size: 24 },
  '11-12 Yr': { width: 5400, height: 7800, size: 26 },
  XS: { width: 5700, height: 8100, size: 27 }, //34 size
  S: { width: 6000, height: 8400, size: 28 },  //36 size
  M: { width: 6300, height: 8700, size: 29 },  //38 size
  L: { width: 6600, height: 9000, size: 30 }, //40 size
  // L: { width: 6600, height: 9000, size: 40 }, //40 size
  XL: { width: 6900, height: 9300, size: 31 }, //42 size
  '2XL': { width: 7200, height: 9600, size: 32 }, //44 size
  '3XL': { width: 7500, height: 9900, size: 33 }, //46 size
  '4XL': { width: 7800, height: 10200, size: 34 }, //48 size
  '5XL': { width: 8100, height: 10500, size: 35 },  // 50size
  '6XL': { width: 8400, height: 10500, size: 35 }, //52 size
}

export const plansId = {
  free: '1',
  variation: '2',
  premium: '3'
}

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const submitUserPlans = async (planid, user, invoice) => {
  if (!planid) return;
  let data = {
    user_id: user?.id,
    plan_id: planid,
  };

  if (invoice) {
    data['invoice_no'] = invoice
  }
  let response = await doRequest(data, "POST", "plan/update");
  return response
}


export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];




export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry"
]

export const numberToWords = (num) => {
  if (num === 0) return "Zero";

  const belowTwenty = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
    "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen",
    "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion"];

  function helper(n) {
    if (n === 0) return "";
    if (n < 20) return belowTwenty[n] + " ";
    if (n < 100) return tens[Math.floor(n / 10)] + " " + helper(n % 10);
    if (n < 1000) return belowTwenty[Math.floor(n / 100)] + " Hundred " + helper(n % 100);
    for (let i = 0; i < thousands.length; i++) {
      const unit = 1000 ** i;
      if (n < unit * 1000) {
        return helper(Math.floor(n / unit)) + thousands[i] + " " + helper(n % unit);
      }
    }
  }

  return helper(num).trim();
}

import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

export default function About() {
  return (
    <>
      <Navbar />
      <section className="privacy_sec">
        <div className="container">
          <div className="privacy_content">
            <h1>About Us</h1>
            <img src="/assets/images/logo.svg" className="about_logo" alt="" />
            <h5 className="my-4">
              Welcome to Teedex, your ultimate destination for personalized tee
              shirt design!
            </h5>

            <h5>Our Mission</h5>
            <p>
              At Teedex, we believe that creativity knows no bounds. Our mission
              is to empower individuals and businesses to express themselves
              through customizable tee shirt designs. Whether you’re a fashion
              enthusiast, sports team, or small business owner, we’re here to
              help you stand out with unique products that will help you to
              deliver.
            </p>

            <h5>Our Story</h5>
            <p>
              Teedex was born out of a passion for innovation and design.
              Originally known as Variant Technology, we rebranded to Teedex to
              better reflect our focus on personalized tee shirt design. Founded
              by a team of tech enthusiasts and creative minds, our platform
              combines cutting-edge technology with intuitive tools to
              revolutionize the tee shirt design experience. Since our
              inception, we’ve been committed to providing users with the tools
              they need to unleash their creativity and bring their ideas to
              life.
            </p>

            <h5>What We Offer</h5>
            <p>
              With Teedex, the possibilities are endless. Our user-friendly
              interface makes it easy to create personalized tee shirt designs
              in just a few simple steps. From choosing fonts and colors to
              uploading graphics and text, our platform puts you in control of
              your design journey. Plus, with a range of subscription plans to
              choose from, you can find the perfect option to suit your needs
              and budget.
            </p>

            <h5>Get in Touch</h5>
            <p>
              Ready to take your tee shirt designs to the next level? Join the
              Teedex community today and start creating! Have questions or
              feedback? We’d love to hear from you. Reach out to our team at
              <a href="#" className="emaillink" target="_blank">
                teedex.in@gmail.com
              </a>{" "}
              or connect with us on social media. Let’s create something amazing
              together!
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

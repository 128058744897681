import React, { useState } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function FeedbackDashboard() {
    return (
        <>
            <Row>
                <Col sm="6">
                    <ul className="feedbac_headlist">
                        <li>
                            <h5>Feedbacks</h5>
                        </li>
                        <li>
                            <select className="newselect">
                                <option>Recent</option>
                            </select>
                        </li>
                    </ul>

                    <div className="payment_graph">
                        <div className="scroll-area shadow-overflowl dashscroll">
                            <PerfectScrollbar>
                                <div className="table-responsive">
                                    <Table hover striped className="table_cust">
                                        <thead className="thead_bg">
                                            <th style={{ width: '40%' }} className="userth">User Name</th>
                                            <th style={{ width: '40%' }} className="userth">Article Title</th>
                                            <th style={{ width: '20%' }} className="userth">Actions</th>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ul className="dasboar_table_list">
                                                        <li>
                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                        </li>
                                                        <li>
                                                            <h5>Alexa</h5>
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>Design</td>

                                                <td>
                                                    <Button tag="a" href="#"
                                                        className="healcheck_btn"
                                                    >
                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ul className="dasboar_table_list">
                                                        <li>
                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                        </li>
                                                        <li>
                                                            <h5>Alexa</h5>
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>Design</td>

                                                <td>
                                                    <Button tag="a" href="#"
                                                        className="healcheck_btn"
                                                    >
                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <ul className="dasboar_table_list">
                                                        <li>
                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                        </li>
                                                        <li>
                                                            <h5>Alexa</h5>
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>Design</td>

                                                <td>
                                                    <Button tag="a" href="#"
                                                        className="healcheck_btn"
                                                    >
                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ul className="dasboar_table_list">
                                                        <li>
                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                        </li>
                                                        <li>
                                                            <h5>Alexa</h5>
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>Design</td>

                                                <td>
                                                    <Button tag="a" href="#"
                                                        className="healcheck_btn"
                                                    >
                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <ul className="dasboar_table_list">
                                                        <li>
                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                        </li>
                                                        <li>
                                                            <h5>Alexa</h5>
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>Design</td>

                                                <td>
                                                    <Button tag="a" href="#"
                                                        className="healcheck_btn"
                                                    >
                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ul className="dasboar_table_list">
                                                        <li>
                                                            <img src={'/assets/images/img-8.jpg'} alt="" />
                                                        </li>
                                                        <li>
                                                            <h5>Alexa</h5>
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>Design</td>

                                                <td>
                                                    <Button tag="a" href="#"
                                                        className="healcheck_btn"
                                                    >
                                                        <img src={'/assets/images/eyeblue.svg'} className="healeyeblue" alt="" />
                                                        <img src={'/assets/images/eyewhite.svg'} className="healeyewhite" alt="" />
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { useDropzone } from "react-dropzone";
import { Check, X } from "react-feather";

import { Link, useSearchParams, useNavigate, NavLink } from "react-router-dom";
import Sidebar from "../../../component/Sidebar";
import { config } from "../../../utils/config";
import LazyImage from "../../../component/LazyImage";
import KEYS from "../../../env/env";
import { doRequest } from "../../../utils/helper";

export default function Profile() {
  const localData = localStorage.getItem(config.USER_LOGIN_DETAILS);
  let obj = {};
  if (localData) {
    let a = JSON.parse(localData);
    obj = {
      ...a,
      first_name: a["first_name"],
      last_name: a["last_name"],
      phone: a["phone"] || "",
      address: a["address"] || "",
      gst: a["gst_no"],
      business_name: a["business_name"],
      address1: a["address"] || "",
      address2: a["address_2"] || "",
    };
  }

  const [userDetails, setUserDetails] = useState({ ...obj });
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [custImageValue, setCustImageValue] = useState(
    obj["user_image"] ? obj["user_image"] : ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const [existImage, setExistImage] = useState(true);
  const [changedImage, setChangedImage] = useState("");
  const [file, setFile] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fNameError, setfNameError] = useState(false);
  const [fNameTextError, setfNameTextError] = useState(false);
  const [lnameError, setLNameError] = useState(false);
  const [lNameTextError, setLNameTextError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);

  const [phoneTypeError, setPhoneTypeError] = useState(false);
  const [phone, setPhone] = useState("");
  const [gstValidateText, setGstValidateText] = useState("Validate");
  const [heading, setHeading] = useState("Your profile info in Teedex");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const setup = params.get("setup");
  const plan = params.get("plan");
  const fTime = params.get("ftime");
  // console.log('ftime is',fTime)
  useEffect(() => {
    if (setup && setup == 1) {
      document.title = "Setup Your Profile";
      setHeading("Setup Your Profile");
    } else {
      document.title = "Teedex";
      setHeading("Your profile info in Teedex");
    }
  }, [setup]);

  const resetPassword = async () => {
    let err = 0;
    if (!password) {
      err += 1;
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (password && password.length < 8) {
      err += 1;
      setPasswordLengthError(true);
    } else {
      setPasswordLengthError(false);
    }
    if (!confirmPassword) {
      err += 1;
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }

    if (password && confirmPassword && password !== confirmPassword) {
      err += 1;
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }

    if (err > 0) {
      return;
    }

    var users_email = localStorage.getItem(config.USER_LOGIN_DETAILS)
      ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))?.email
      : null;
    if (!users_email) {
      return;
    }
    var dataToPost = {
      email: users_email,
      password: password,
      confirmpass: confirmPassword,
    };

    fetch(KEYS.apiPath + "user/set-new-pwd", {
      body: JSON.stringify(dataToPost),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((val) => {
        // console.log(val);
        if (+val && val.resultFlag === 1) {
          setErrorMsg("");
          alert(val?.message ? val?.message : "Password Updated Succesfully!");
        } else {
          setErrorMsg(val?.msg);
        }
        // if (val.status == true) {
        //   console.log(val);
        //   localStorage.removeItem('users_email');
        //   localStorage.removeItem('verifyotp');
        //   navigate('/login');
        // }
        // else {
        //   console.log(val);
        // }
      })
      .catch((err) => {
        setErrorMsg(err?.message ? err?.message : "Something went wrong!");
      });
  };

  // const mobileNumberInput = document.getElementById("mobileNumberInput");
  // if (mobileNumberInput) {
  //   mobileNumberInput.addEventListener("input", function () {
  //     let value = this.value;
  //     value = value.replace(/\D/g, ""); // Remove non-digit characters
  //     this.value = value;
  //     setUserDetails({
  //       ...userDetails,
  //       phone: value
  //     })
  //   });
  // }

  const gstValidateClickHandler = () => {
    setGstValidateText("Validating");
    setTimeout(() => setGstValidateText("Validate"), 400);
  };

  const imageChangeHandler = (img) => {
    if (!img) return;
    if (!img.type.startsWith("image/")) {
      setErrorMsg("Please upload an image file.");
      setShowError(true);
      console.log("image is not image");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setChangedImage(reader.result);
      setFile(img);
      setExistImage(false);
    };
    reader.readAsDataURL(img);
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      console.log("acceptedfiles ", acceptedFiles);
      if (acceptedFiles && acceptedFiles.length > 0) {
        let file = acceptedFiles[0];
        imageChangeHandler(file);
      }
    },
  });

  async function handleProfilePic(file) {
    if (!file) return;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("folder", "profile");
    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };
    try {
      let response = await fetch(`${KEYS.apiPath}file/save`, requestOptions);
      let data = await response.json();
      console.log("data", data);
      if (data && +data?.resultFlag === 1) {
        let imgUrl = data?.response?.url;
        // setChangedImage(config.s3BaseUrl + imgUrl);
        await updateUserDetails(config.s3BaseUrl + "/" + imgUrl);
      } else {
        setErrorMsg(data?.message ? data.message : "Something went wrong!");
        setShowError(true);
      }
    } catch (error) {
      setErrorMsg(error?.message ? error.message : "Something went wrong!");
      setShowError(true);
    }
  }

  const handleUpdateButton = async () => {
    if (changedImage) {
      await handleProfilePic(file);
    } else {
      await updateUserDetails();
    }
  };

  const updateUserDetails = async (image) => {
    let data = {
      id: userDetails["id"],
      data: {
        phone: userDetails["phone"],
        first_name: userDetails["first_name"],
        last_name: userDetails["last_name"],
        address: userDetails["address1"],
        email: userDetails["email"],
        state: userDetails["state"],
        address_2: userDetails["address2"],
        business_name: userDetails["business_name"],
        gst_no: userDetails["gst"],
      },
    };
    if (image) {
      data["data"]["user_image"] = image;
    }
    const response = await doRequest(data, "POST", "user/update");
    if (response && +response?.resultFlag === 1) {
      setUserDetails(response?.response?.user);
      localStorage.setItem(
        config.USER_LOGIN_DETAILS,
        JSON.stringify(response?.response?.user)
      );
      if (fTime == "1") {
        // navigate('/Design')
        navigate("/welcome" + window.location.search);
      } else {
        navigate("/Home");
      }
    }
  };

  console.log("userDetailsuserDetails", userDetails);

  const [showInput, setShowInput] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClick = () => {
    setShowInput(!showInput);
  };

  const [showInputlastname, setShowInputlastname] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClicklastname = () => {
    setShowInputlastname(!showInputlastname);
  };

  const [showInputemail, setShowInputemail] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClickemail = () => {
    setShowInputemail(!showInputemail);
  };

  const [showInputphone, setShowInputphone] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClickphone = () => {
    setShowInputphone(!showInputphone);
  };

  const [showInputbus, setShowInputbus] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClickbus = () => {
    setShowInputbus(!showInputbus);
  };

  const [showInputaddress1, setShowInputaddress1] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClickaddress1 = () => {
    setShowInputaddress1(!showInputaddress1);
  };

  const [showInputaddress2, setShowInputaddress2] = useState(
    fTime && fTime == "1" ? true : false
  );
  const handleButtonClickaddress2 = () => {
    setShowInputaddress2(!showInputaddress2);
  };
  const [activeTab, setActiveTab] = useState("about");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  return (
    <div id="main-container" className="container-fluid main">
      <Sidebar></Sidebar>
      <section className="home">
        <div style={{ width: "100%" }} className="container">
          <div className="heading">
            <div className="text-center m-5">
              <h1>{heading}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2">
              <div className="custom-tab-bar">
                <a
                  href="#about"
                  className={`nav-link ${
                    activeTab === "about" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("about")}
                >
                  About You
                </a>
               {!fTime && <a
                  href="#membership"
                  className={`nav-link ${
                    activeTab === "membership" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("membership")}
                >
                  Membership
                </a>}
                <a
                  href="#changepassword"
                  className={`nav-link ${
                    activeTab === "changepassword" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("changepassword")}
                >
                  Change Password
                </a>
              </div>
            </div>
            <div className="col-sm-10">
              <div id="about" className="card_profile">
                <h4 className="basic_head">Basic Info</h4>

                <div className="row row_cen">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5>Photo</h5>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="profile_desc">
                      <h5>A photo helps personalise your account</h5>
                    </div>
                  </div>

                  <div className="col-sm-3 btn_rank">
                    <div className="d-flex align-items-center phone_profile_center pb-2">
                      <Button
                        style={{ color: "" }}
                        onClick={() => open()}
                        className="upload_btn_position badge font-weight-bold"
                      >
                        {/* <Upload className="d-20" /> */}
                        {custImageValue === "" ? "Add Photo" : "Edit Photo"}
                      </Button>
                      <div className="dropzone rounded-circle imgbox_wid">
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-wrapper",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="dropzone-inner-wrapper imgbox_wid2 rounded-circle dropzone-avatar">
                            <div className="avatar-icon-wrapper imgbox_wid3 rounded-circle m-2">
                              <div>
                                {isDragAccept && (
                                  <div className="rounded-circle overflow-hidden imgbox_wid4 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                    <Check className="d-40" />
                                  </div>
                                )}
                                {isDragReject && (
                                  <div className="rounded-circle overflow-hidden imgbox_wid5 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                    <X className="d-60" />
                                  </div>
                                )}
                                {!isDragActive && (
                                  <div className="rounded-circle overflow-hidden imgbox_wid6 bg-neutral-dark text-center font-weight-bold text-black-50 d-flex justify-content-center align-items-center">
                                    {/* <User className="d-50" /> */}
                                  </div>
                                )}
                              </div>

                              <div className="rounded-circle avatar-image overflow-hidden imgbox_wid7 text-center font-weight-bold text-success d-flex justify-content-center align-items-center up_file">
                                <LazyImage
                                  className="img-fluid img-fit-container rounded-circle profile_img"
                                  src={
                                    existImage ? custImageValue : changedImage
                                  }
                                  // alt="..."
                                />
                                {/* {thumbs?.length > 0 && <div>{thumbs}</div>} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5>
                        <span className="text-danger">&#42;</span> First Name
                      </h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInput ? (
                      <h3 className="usenameedit">
                        {userDetails["first_name"]}
                      </h3>
                    ) : (
                      <Input
                        required
                        className="form-control"
                        id="firstname"
                        invalid={
                          (fNameError ? true : false) ||
                          (fNameError ? true : false)
                        }
                        valid={userDetails["first_name"] ? true : false}
                        //  disabled={localStorage.getItem(config.CUST_SEARCH_USER_ID) == JSON.parse(localStorage.getItem(config.CUST_INFO_DETAILS)).CUST_ID ? false : true}
                        disabled={false}
                        placeholder="First Name"
                        type="text"
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            first_name: e.target.value.trim(),
                          });
                          if (e.target.value?.length > 0) {
                            setfNameError(false);
                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                              setfNameTextError(false);
                            } else {
                              setfNameTextError(true);
                            }
                          } else {
                            setfNameError(true);
                          }
                        }}
                        value={userDetails["first_name"]}
                        onBlur={(e) => {
                          if (e.target.value?.length > 0) {
                            setfNameError(false);
                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                              setfNameTextError(false);
                            } else {
                              setfNameTextError(true);
                            }
                          } else {
                            setfNameError(true);
                          }
                        }}
                      />
                    )}
                    {fNameError && (
                      <p style={{ color: "red" }}>First name cannot be empty</p>
                    )}
                    {fNameTextError && (
                      <p style={{ color: "red" }}>
                        First name should be text only
                      </p>
                    )}
                  </div>
                  <div className="col-sm-1">
                    <button className="edit_form" onClick={handleButtonClick}>
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5>
                        <span className="text-danger">&#42;</span> Last Name
                      </h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInputlastname ? (
                      <h3 className="usenameedit">
                        {userDetails["last_name"]}
                      </h3>
                    ) : (
                      <Input
                        required
                        className="form-control"
                        id="latname"
                        invalid={
                          (lnameError ? true : false) ||
                          (lnameError ? true : false)
                        }
                        valid={userDetails["last_name"] ? true : false}
                        //  disabled={localStorage.getItem(config.CUST_SEARCH_USER_ID) == JSON.parse(localStorage.getItem(config.CUST_INFO_DETAILS)).CUST_ID ? false : true}
                        disabled={false}
                        placeholder="Last Name"
                        type="text"
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            last_name: e.target.value.trim(),
                          });
                          if (e.target.value?.length > 0) {
                            setLNameError(false);
                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                              setLNameTextError(false);
                            } else {
                              setLNameTextError(true);
                            }
                          } else {
                            setLNameError(true);
                          }
                        }}
                        value={userDetails["last_name"]}
                        onBlur={(e) => {
                          if (e.target.value?.length > 0) {
                            setLNameError(false);
                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                              setLNameTextError(false);
                            } else {
                              setLNameTextError(true);
                            }
                          } else {
                            setLNameError(true);
                          }
                        }}
                      />
                    )}
                    {lnameError && (
                      <p style={{ color: "red" }}>Last name cannot be empty</p>
                    )}
                    {lNameTextError && (
                      <p style={{ color: "red" }}>
                        Last name should be text only
                      </p>
                    )}
                  </div>
                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={handleButtonClicklastname}
                    >
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5 className="not_validate">Email</h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInputemail ? (
                      <h3 className="usenameedit">{userDetails["email"]}</h3>
                    ) : (
                      <Input
                        className="form-control"
                        id="email"
                        disabled={true}
                        placeholder="email"
                        type="email"
                        value={userDetails["email"]}
                      />
                    )}
                  </div>

                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={handleButtonClickemail}
                    >
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5>
                        <span className="text-danger">&#42;</span> Whatsapp
                        Number
                      </h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInputphone ? (
                      <h3 className="usenameedit">
                        {userDetails["phone"] || ""}
                      </h3>
                    ) : (
                      <Input
                        required
                        className="form-control"
                        id="mobileNumberInput"
                        disabled={false}
                        placeholder="whatsapp number"
                        type="number"
                        value={userDetails["phone"] || ""}
                        // value={phone}
                        onChange={(e) => {
                          console.log("e.target.value", e.target.value);
                          setUserDetails({
                            ...userDetails,
                            phone: e.target.value,
                          });
                          if (e.target.value) {
                            setPhoneError(false);
                          } else {
                            setPhoneError(true);
                          }
                        }}
                      />
                    )}
                    {phoneError && (
                      <p style={{ color: "red" }}>Please enter your number</p>
                    )}
                  </div>
                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={handleButtonClickphone}
                    >
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5 className="not_validate">GST</h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    <Input
                      className="form-control"
                      id="gstnumberinput"
                      disabled={false}
                      placeholder="enter GST number"
                      type="text"
                      value={userDetails["gst"] || ""}
                      // value={phone}
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        setUserDetails({
                          ...userDetails,
                          gst: e.target.value,
                        });
                        if (e.target.value) {
                          setGstError(false);
                        } else {
                          setGstError(true);
                        }
                      }}
                    />
                    {gstError && (
                      <p style={{ color: "red" }}>
                        Please enter your GST number
                      </p>
                    )}
                  </div>

                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={() => gstValidateClickHandler()}
                    >
                      {gstValidateText}
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5 className="not_validate">Business Name</h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInputbus ? (
                      <h3 className="usenameedit">
                        {userDetails["business_name"]}
                      </h3>
                    ) : (
                      <Input
                        className="form-control"
                        id="businessname"
                        disabled={false}
                        placeholder="Busines Legal Name"
                        type="text"
                        value={userDetails["business_name"]}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            business_name: e.target.value.trim(),
                          });
                          if (e.target.value) {
                            setBusinessNameError(false);
                          } else {
                            setBusinessNameError(true);
                          }
                        }}
                      />
                    )}

                    {businessNameError && (
                      <p style={{ color: "red" }}>
                        Please enter your business name
                      </p>
                    )}
                  </div>

                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={handleButtonClickbus}
                    >
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5 className="not_validate">Address Line 1</h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInputaddress1 ? (
                      <h3 className="usenameedit">{userDetails["address1"]}</h3>
                    ) : (
                      <Input
                        className="form-control"
                        id="address1"
                        disabled={false}
                        placeholder="Shop no, Area, Locality"
                        type="text"
                        value={userDetails["address1"]}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            address1: e.target.value.trim(),
                          });
                          // if (e.target.value) {
                          //   setBusinessNameError(false);
                          // } else {
                          //   setBusinessNameError(true);
                          // }
                        }}
                        autoComplete="off"
                      />
                    )}
                  </div>

                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={handleButtonClickaddress1}
                    >
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="row row_cen2">
                  <div className="col-sm-3">
                    <div className="profile_label">
                      <h5 className="not_validate">Address Line 2</h5>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    {!showInputaddress2 ? (
                      <h3 className="usenameedit">{userDetails["address2"]}</h3>
                    ) : (
                      <form onSubmit={(e) => e.preventDefault()}>
                        <input
                          className="form-control"
                          id="address2"
                          disabled={false}
                          placeholder="City, State"
                          type="text"
                          value={userDetails["address2"]}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              address2: e.target.value.trim(),
                            });
                            // if (e.target.value) {
                            //   setBusinessNameError(false);
                            // } else {
                            //   setBusinessNameError(true);
                            // }
                          }}
                          autoComplete={false}
                          auto
                        />
                      </form>
                    )}
                  </div>

                  <div className="col-sm-1">
                    <button
                      className="edit_form"
                      onClick={handleButtonClickaddress2}
                    >
                      <img src="/assets/images/editpen.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>

              <Button
                onClick={() => handleUpdateButton()}
                className="update-btn"
              >
                {setup == "1"
                  ? "Update Details & Open Teedex"
                  : "Update Details"}
              </Button>

              {!fTime && (
                <>
                  <div id="membership" className="card_profile">
                    <h4 className="basic_head">Membership</h4>
                    <div className="mem_detail">
                      <h4 className="member_subhead">
                        Membership billing history
                      </h4>
                      <h6 className="videfetmem">
                        View details of your membership payments
                      </h6>
                      <Link to={"/billing-history"} className="lin_his">
                        VIEW BILLING HISTORY{" "}
                      </Link>
                    </div>
                  </div>

                  <div
                    id="changepassword"
                    className="card_profile change_passmar"
                  >
                    <h4 className="basic_head">Change Password</h4>
                    <div className="mem_detail">
                      <div className="row">
                        <div className="col-sm-4">
                          <div
                            className="pass_change"
                            style={{
                              position: "relative",
                              marginBottom: "20px",
                            }}
                          >
                            <label htmlfor="new-password">New Password</label>
                            <input
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type={showPassword ? "text" : "password"}
                              id="new-password"
                              name="new-password"
                            />
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                              onClick={togglePasswordVisibility}
                              style={{
                                position: "absolute",
                                top: "65%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            />
                            {passwordError && (
                              <p className="r21 ptag text-danger">
                                Please enter password first
                              </p>
                            )}
                            {passwordLengthError && (
                              <p className="r21 ptag text-danger">
                                Must be at least 8 characters
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div
                            className="pass_change"
                            style={{
                              position: "relative",
                              marginBottom: "20px",
                            }}
                          >
                            <label htmlfor="new-password">
                              Confirm Password
                            </label>
                            <input
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              type="password"
                              id="confirm-password"
                              name="confirm-password"
                            />
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                              onClick={togglePasswordVisibility}
                              style={{
                                position: "absolute",
                                top: "65%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            />
                            {confirmPasswordError && (
                              <p className="r21 ptag text-danger">
                                Please enter confirm password first
                              </p>
                            )}
                            {passwordLengthError && (
                              <p className="r21 ptag text-danger">
                                Must be at least 8 characters
                              </p>
                            )}

                            {passwordMatchError && (
                              <p className="r21 ptag text-danger">
                                Password and confirm password are not same
                              </p>
                            )}

                            {errorMsg && (
                              <p className="r21 ptag text-danger">{errorMsg}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-4">
                          <button className="rest_passbtn">
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Private/Login";
import Signup from "../pages/Private/Signup";
import Forgetpass from "../pages/Private/Forgetpass";
import Verifyotp from "../pages/Private/Verifyotp";
import Home from "../pages/Private/Home";
import TshirtFront from "../pages/Private/TshirtFront";
import Design from "../component/Design";
import Design1 from "../component/Design1";
import DesignBkp from "../component/DesignBkp";
import Variation from "../component/Variation";
import Export from "../component/Export";
import NewPassword from "../pages/Private/NewPassword";
import EditDesign from "../component/EditDesign";
const Dashboard = lazy(() => import("../pages/Public/Dashboard"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));

export default function PrivateRoutes() {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          {/* <Route path="*" element={<Login />} /> */}
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Forgetpass" element={<Forgetpass />} />
          <Route path="/Verifyotp" element={<Verifyotp />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/TshirtFront" element={<TshirtFront />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/Design1" element={<Design1 />} />
          <Route path="/DesignBkp" element={<DesignBkp />} />
          <Route path="/Variation" element={<Variation />} />
          <Route path="/Export" element={<Export />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/EditDesign" element={<EditDesign />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

import React, { useState } from 'react';

const LazyImage = ({ src, className, type ,onClick, style}) => {
  const [imageLoaded, setImageLoaded] = useState(false);


  return (
      <div className="image-container">
        <img
        style={style ? style : {}}
          onClick={onClick}
          src={imageLoaded ? src : src}
          alt={""}
          className={`blurry-image ${imageLoaded ? 'loaded' : ''} ${className} ${type ? "ytloaded" :""}`}
          onLoad={() => {
            setImageLoaded(true) 
            }}
        />
      </div>
  );
};

export default LazyImage;

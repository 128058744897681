import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ListGroup } from "reactstrap";
import { config } from "../utils/config";
import { useSearchParams, useNavigate } from "react-router-dom";
import KEYS from "../env/env";
import {
  doRequest,
  isValidEmail,
  monthNames,
  numberToWords,
  states,
  submitUserPlans,
} from "../utils/helper";
import Loader from "./Loader";
import Invoice from "../Invvoice/Invoice";

export default function Payment() {
  const initialUserPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS);
  const detail = localStorage.getItem(config.USER_LOGIN_DETAILS)
    ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
    : {};
  let obj = {
    ...detail,
    first_name: detail["first_name"] || "",
    last_name: detail["last_name"] || "",
    state: detail["state"] || "",
    // country: detail["country"] || "India",
    country: "India",
    city: detail["city"] || "",
    postal_code: detail["postal"] || "",
    phone_no: detail["phone"] || "",
    email: detail["email"] || "",
    address: detail["address"] || "",
  };
  const [params] = useSearchParams();
  const initialSelectedPlan = params.get("plan");
  const [userDetails, setUserDetails] = useState({ ...obj });
  const [billingAddress, setBillingAddress] = useState({});
  const [billingSameAsUserDetails, setBillingSameAsUserDetails] =
    useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(
    initialSelectedPlan ? +initialSelectedPlan : ""
  );
  const [selectedPlan, setSelectedPlan] = useState({});
  const [gstCharges, setGstCharges] = useState("");
  const [discountApplied, setDiscountApplied] = useState(0);
  const [modal2, setModal2] = useState(false);
  const [errmsg, setErrorMsg] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastnameError] = useState(false);
  const [AddressError, setAddressError] = useState(false);
  const [phoneNoError, setPhoneNoError] = useState(false);
  const [phoneNoLengthError, setPhoneNoLengthError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [billCityError, setBillCityError] = useState(false);
  const [billCountryError, setBillCountryError] = useState(false);
  const [billStateError, setBillStateError] = useState(false);
  const [billPostalError, setBillPostalError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showGstBreakout, setShowGstBreakout] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [gsValidtError, setGstValidError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);
  const [shouldGenerateInvoice, setShouldGenerateInvoice] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [prepaidPlanVariationCount, setPrepaidPlanVariationCount] =
    useState(200);
  const [totalCharges, setTotalCharges] = useState(0);

  useEffect(() => {
    if (selectedPlan?.id != 5) {
      setTotalCharges(
        (selectedPlan?.price && +selectedPlan?.price + +gstCharges) -
          +discountApplied
      );
    } else {
      let charge =
        +(prepaidPlanVariationCount * selectedPlan?.price) +
        +gstCharges -
        +discountApplied;
      setTotalCharges(charge);
    }
  }, [selectedPlan, gstCharges, discountApplied, prepaidPlanVariationCount]);
  // const totalCharges =
  //   (selectedPlan?.price && selectedPlan?.price + gstCharges) - discountApplied;

  const navigate = useNavigate();
  const updateUserDetails = async () => {
    let data = {
      id: userDetails["id"],
      data: {
        phone: userDetails["phone_no"],
        first_name: userDetails["first_name"],
        last_name: userDetails["last_name"],
        address: userDetails["address"],
        country: userDetails["country"],
        city: userDetails["city"],
        state: userDetails["state"],
        postal: userDetails["postal_code"],
        gst_no: userDetails["gst_no"],
        business_name: userDetails["business_name"],
      },
    };

    try {
      let obj = {};
      window.gtag("event", "User Details Updated", obj);
    } catch (error) {}
    const response = await doRequest(data, "POST", "user/update");
    if (response && +response?.resultFlag === 1) {
      setUserDetails(response?.response?.user);
      localStorage.setItem(
        config.USER_LOGIN_DETAILS,
        JSON.stringify(response?.response?.user)
      );
    }
  };

  const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS);
  if (!userLoginDetails) {
    alert("please login first");
    navigate("/Login");
  }

  const submitUserFreePlan = async (planid) => {
    setLoading(true);
    let user =
      localStorage.getItem(config.USER_LOGIN_DETAILS) &&
      JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS));
    try {
      let obj = {
        email: user?.email,
        phone: user?.phone,
      };
      window.gtag(
        "event",
        planid == "1" ? "Free Plan Selected" : "Pay as You Use Plan Selected",
        obj
      );
    } catch (error) {}
    try {
      let response = await submitUserPlans(planid, user);
      if (response && +response?.resultFlag === 1) {
        let details = response?.response?.user_data;
        let userPlan = response?.response?.userPlan;
        let variant = response?.response?.variants;
        if (variant) {
          localStorage.setItem(config.VARIANT_COUNT, JSON.stringify(variant));
        }
        localStorage.setItem(
          config.USER_LOGIN_DETAILS,
          JSON.stringify(details)
        );
        let planDetails = localStorage.getItem(config.USER_PLAN_DETAILS);
        if (planDetails) {
          localStorage.removeItem(config.USER_PLAN_DETAILS);
        }
        localStorage.setItem(
          config.USER_PLAN_DETAILS,
          JSON.stringify(userPlan)
        );

        if (initialUserPlanDetails) {
          navigate("/profile?plan=" + planid);
        } else {
          navigate("/profile?plan=" + planid + "&ftime=1");
        }

        // if (planDetails) {
        //   navigate("/Home");
        // } else {
        //   navigate("/profile?plan=" + planid + "ftime=1");
        // }
      } else {
        setErrorMsg(
          response?.message ? response?.message : "Something went wrong"
        );
        // setShowError(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error?.message ? error?.message : "Something went wrong");
      // setShowError(true);
    }
  };

  const getPlansList = async () => {
    setLoading(true);
    try {
      let res = await doRequest({}, "GET", `plan/list`);
      if (+res?.resultFlag === 1) {
        setSubscriptionList(res?.response?.plans);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("erros in plan list", error);
    }
  };

  useEffect(() => {
    getPlansList();
  }, []);

  const isPayNowFormValid = (details) => {
    let err = 0;
    if (!details["first_name"] || details["first_na me"] === "") {
      err += 1;
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (!details["last_name"] || details["last_name"] === "") {
      err += 1;
      setLastnameError(true);
    } else {
      setLastnameError(false);
    }

    console.log("detailsphone", details["phone_no"]);

    if (!details["phone_no"] || details["phone_no"] === "") {
      err += 1;
      setPhoneNoError(true);
    } else {
      setPhoneNoError(false);
    }

    if (details["phone_no"] && details["phone_no"].length != "10") {
      err += 1;
      setPhoneNoLengthError(true);
    } else {
      setPhoneNoLengthError(false);
    }
    if (!details["email"] || details["email"] === "") {
      err += 1;
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!details["city"] || details["city"] === "") {
      err += 1;
      setCityError(true);
    } else {
      setCityError(false);
    }
    // if (!details["country"] || details["country"] === "") {
    //   err += 1;
    //   setCountryError(true);
    // } else {
    //   setCountryError(false);
    // }
    if (!details["state"] || details["state"] === "") {
      err += 1;
      setStateError(true);
    } else {
      setStateError(false);
    }
    if (!details["postal_code"] || details["postal_code"] === "") {
      err += 1;
      setPostalCodeError(true);
    } else {
      setPostalCodeError(false);
    }

    if (!details["business_name"] || details["business_name"] === "") {
      err += 1;
      setBusinessNameError(true);
    } else {
      setBusinessNameError(false);
    }

    if (!details["address"] || details["address"] === "") {
      err += 1;
      setAddressError(true);
    } else {
      setAddressError(false);
    }

    // if (!userDetails["gst_no"]) {
    //   setGstError(true);
    //   return;
    // } else {
    //   setGstError(false);
    // }
    if (userDetails["gst_no"] && userDetails["gst_no"].length != "15") {
      setGstValidError(true);
      return;
    } else {
      setGstValidError(false);
    }
    setShowGstBreakout(true);

    // saveTaxClickHandler()

    // if (!billingSameAsUserDetails) {
    //   if (!billingAddress["city"] || billingAddress["city"] === "") {
    //     err += 1;
    //     setBillCityError(true);
    //   } else {
    //     setBillCityError(false);
    //   }
    //   if (!billingAddress["country"] || billingAddress["country"] === "") {
    //     err += 1;
    //     setBillCountryError(true);
    //   } else {
    //     setBillCountryError(false);
    //   }
    //   if (!billingAddress["state"] || billingAddress["state"] === "") {
    //     err += 1;
    //     setBillStateError(true);
    //   } else {
    //     setBillStateError(false);
    //   }
    //   if (
    //     !billingAddress["postal_code"] ||
    //     billingAddress["postal_code"] === ""
    //   ) {
    //     err += 1;
    //     setBillPostalError(true);
    //   } else {
    //     setBillPostalError(false);
    //   }
    // }

    if (err > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log("selectedPlanId", selectedPlanId);
    if (!selectedPlanId) return;
    let a = subscriptionList?.filter((i) => i.id == selectedPlanId);
    console.log("a", a);
    if (a && a.length > 0) {
      setSelectedPlan(a[0]);
      if (selectedPlanId == 5) {
        setGstCharges(
          (a[0].price * prepaidPlanVariationCount * (a[0]?.tax / 100))?.toFixed(
            2
          )
        );
      } else {
        setGstCharges((a[0].price * (a[0]?.tax / 100)).toFixed(2));
      }
    }
  }, [selectedPlanId, subscriptionList, prepaidPlanVariationCount]);
  const onChangeHandler = (key, val) => {
    let details = { ...userDetails };
    details[key] = val;
    setUserDetails(details);
  };
  const billingAddressChangehandler = (key, val) => {
    let details = { ...billingAddress };
    details[key] = val;
    setBillingAddress(details);
  };

  const saveTaxClickHandler = () => {
    if (!userDetails["gst_no"]) {
      setGstError(true);
      return;
    } else {
      setGstError(false);
    }
    if (userDetails["gst_no"] && userDetails["gst_no"].length != "15") {
      setGstValidError(true);
      return;
    } else {
      setGstValidError(false);
    }
    setShowGstBreakout(true);
  };

  const sameAddressClickHandler = (e) =>
    setBillingSameAsUserDetails(!billingSameAsUserDetails);
  const toggle2 = () => setModal2(!modal2);

  const onSuccessPaymentHandler = async (data) => {
    try {
      let obj = {
        plan_id: selectedPlan?.id,
        phone: userDetails["phone_no"],
        email: userDetails["email"],
      };
      if (selectedPlan?.id == 5) {
        obj["variant"] = prepaidPlanVariationCount;
      }
      window.gtag("event", "Complete Payment", obj);
    } catch (error) {}
    setLoading(true);
    if (!data) {
      setErrorMsg("Something went wrong!");
      return;
    }
    if (selectedPlan?.id == 5) {
      data["variant"] = prepaidPlanVariationCount;
    }

    // if (selectedPlan?.id == 5) {
    let response = await doRequest(data, "POST", "order/validate");
    // } else {
    //   response = await doRequest(data, "POST", "order/subs-validate");
    // }

    // console.log("response is ", response);
    if (+response?.resultFlag === 1) {
      let details = response?.response?.user_data;
      let userPlan = response?.response?.userPlan;
      let variants = response?.response?.variants;
      let plans = { ...JSON.parse(initialUserPlanDetails) };
      setPlanDetails(plans);
      localStorage.setItem(config.USER_LOGIN_DETAILS, JSON.stringify(details));
      localStorage.setItem(config.USER_PLAN_DETAILS, JSON.stringify(userPlan));
      localStorage.setItem(config.VARIANT_COUNT, JSON.stringify(variants));
      setShouldGenerateInvoice(true);
      setInvoiceNo(userPlan?.invoice_no);
      // setTimeout(()=> {
      //   if (initialUserPlanDetails) {
      //     navigate("/profile");
      //   } else {
      //     navigate("/welcome?plan=" + selectedPlan?.id);
      //   }
      // },[2000])
      setLoading(false);

      // let obj = {};
      // obj["SUBS_PAID"] = "Paid";
      // obj["CUST_SUB_ACTIVE"] = "Y";
      // obj["USR_FNAME"] = userDetails["first_name"];
      // obj["USR_LNAME"] = userDetails["last_name"];
      // obj["USR_EMAIL"] = userDetails["email"];
      // localStorage.setItem(config.USER_LOGIN_DETAILS, JSON.stringify(obj));
      // localStorage.setItem(
      //   config.USER_DETAILS_DURING_FIRST_LOGIN,
      //   JSON.stringify(userDetails)
      // );
      // navigate("/onboarding");
    } else {
      setLoading(false);
    }
  };

  const completePayment = async (orderDetails, planid) => {
    if (!orderDetails) {
      return;
    }
    const options = {
      // key: "rzp_test_O1iat2Sl0gsmDm", // Enter the Key ID generated from the Dashboard
      key: "rzp_live_kmzsSSDBF6WxTr", // Enter the Key ID generated from the Dashboard
      currency: "INR",
      name: "Teedex",
      description: selectedPlan?.DESCR,
      image: `${config.s3BaseUrl}/Logo/logo.jpg`,
      // subscription_id: orderDetails?.order_id,
      handler: async function (response) {
        console.log("completePayment response handler is ", response);
        // return;
        let d = {
          city: userDetails["city"],
          state: userDetails["state"],
          country: userDetails["country"],
          postal_code: userDetails["postal_code"],
        };
        const userLoginDetails = localStorage.getItem(
          config.USER_LOGIN_DETAILS
        );
        const data = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          order_id: orderDetails?.order_id,
          contact_details: userDetails,
          billing_details: userDetails,
          user_id: JSON.parse(userLoginDetails)?.id,
          plan_id: selectedPlan?.id,
        };
        // if (planid == "5") {
        data["razorpay_order_id"] = response.razorpay_order_id;
        // } else {
        //   data["razorpay_subscription_id"] = response.razorpay_subscription_id;
        // }

        console.log("data is", data);

        await onSuccessPaymentHandler(data);
      },
      prefill: {
        name: `${userDetails["first_name"]} ${userDetails["last_name"]}`,
        email: userDetails["email"],
        contact: userDetails["phone_no"],
      },
      notes: {
        address: userDetails["address"],
      },
      theme: {
        color: "#61dafb",
      },
    };

    // if (planid == "5") {
    options["amount"] = orderDetails?.amount.toString();
    options["order_id"] = orderDetails?.order_id;
    // } else {
    //   options["subscription_id"] = orderDetails?.order_id;
    // }

    setLoading(false);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      console.log("payment is failed", response);
      // console.log("response.error.code", response.error.code);
      // console.log("response.error.description", response.error.description);
      // console.log("response.error.source", response.error.source);
      // console.log("response.error.step", response.error.step);
      // console.log("response.error.reason", response.error.reason);
      // console.log(
      //   "response.error.metadata.order_id",
      //   response.error.metadata.order_id
      // );
      // console.log(
      //   "response.error.metadata.payment_id",
      //   response.error.metadata.payment_id
      // );
    });
  };

  const submitHandler = async () => {
    const userLoginDetails = localStorage.getItem(config.USER_LOGIN_DETAILS);
    if (!userLoginDetails) {
      alert("please login first");
      navigate("/Login");
    }
    let planid = selectedPlanId;
    if (planid ? planid == "5" : selectedPlan?.id == "5") {
      if (prepaidPlanVariationCount < 200) {
        alert("Please select at least 200 variations for this plan");
        return;
      }
    }
    let formIsValid = isPayNowFormValid(userDetails);
    if (!formIsValid) return;
    setLoading(true);
    await updateUserDetails();
    if (!planid) {
      setErrorMsg("Please select a plan first!");
    }
    if (selectedPlanId == "1" || selectedPlanId == "2") {
      await submitUserFreePlan(selectedPlanId);
      return;
    }
    // userLoginDetails["first_name"] = userDetails["first_name"];
    // userLoginDetails["last_name"] = userDetails["last_name"];

    console.log("userDetailsis", userDetails);
    let reqData = {
      user_id: JSON.parse(userLoginDetails)?.id,
      plan_id: planid ? planid : selectedPlan?.id,
      name: userDetails["first_name"] + " " + userDetails["last_name"],
      phone: "+91" + userDetails["phone_no"],
      email: userDetails["email"],
      gst: "",
    };

    if (planid ? planid == "5" : selectedPlan?.id == "5") {
      reqData["variant"] = prepaidPlanVariationCount;
    }
    // return;
    try {
      // if (planid ? planid == "5" : selectedPlan?.id == "5") {
      let response = await doRequest(reqData, "POST", "order/create");
      // let response = await doRequest(reqData, "POST", "order/subscription");
      if (+response?.resultFlag === 1) {
        console.log("response", response);
        let orderDetails = response?.response?.orderCreate;
        completePayment(orderDetails, selectedPlan?.id);
      } else {
        alert(response?.message ? response?.message : "Something went wrong");
        setLoading(false);
      }
      // } else {
      //   let response = await doRequest(reqData, "POST", "order/subscription");
      //   if (+response?.resultFlag === 1) {
      //     console.log("response", response);
      //     let orderDetails = response?.response?.orderCreate;
      //     completePayment(orderDetails, selectedPlan?.id);
      //   } else {
      //     alert(response?.message ? response?.message : "Something went wrong");
      //     setLoading(false);
      //   }
      // }
    } catch (error) {
      setLoading(false);
      console.log("error is ", error);
    }
  };

  let today = new Date();
  let day = today?.getDate();
  let month = today?.getMonth();
  let year = today?.getFullYear();
  const getEndDate = (dayCount) => {
    let currentDate = new Date();

    // Add 28 days to the current date
    currentDate.setDate(currentDate.getDate() + (dayCount - 1));
    // Extract the month, day, and year
    let month = currentDate.getMonth().toString(); // Adding 1 because months are zero-indexed
    let day = currentDate.getDate().toString().padStart(2, "0");
    let year = currentDate.getFullYear();

    // Construct the formatted date string
    let formattedDate = `${day} ${monthNames[month]}, ${year}`;
    return formattedDate;
  };

  const generateInvoice = async () => {};
  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-sm-12">
            <div className="payment_div">
              <h3>Billing address</h3>
              <form>
                <div className="row">
                  <div className="col-xl-6 col-sm-6 ">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="First name"
                        value={userDetails["first_name"]}
                        onChange={(e) => {
                          e.target.value && setFirstNameError(false);
                          onChangeHandler("first_name", e.target.value);
                        }}
                      />
                      {firstNameError && (
                        <p className="text-danger">
                          Please enter your first name
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="Last name"
                        value={userDetails["last_name"]}
                        onChange={(e) => {
                          e.target.value && setLastnameError(false);
                          onChangeHandler("last_name", e.target.value);
                        }}
                      />
                      {lastNameError && (
                        <p className="text-danger">
                          Please enter your last name
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        disabled
                        className="form-control card_form_height"
                        placeholder="Country"
                        value={userDetails["country"]}
                        onChange={(e) => {
                          e.target.value && setCountryError(false);
                          onChangeHandler("country", e.target.value);
                        }}
                      />
                      {countryError && (
                        <p className="text-danger">Please enter your country</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="number"
                        className="form-control card_form_height"
                        placeholder="Whatsapp No."
                        value={userDetails["phone_no"]}
                        minLength={10}
                        maxLength={10}
                        onChange={(e) => {
                          e.target.value && setPhoneNoError(false);
                          onChangeHandler("phone_no", e.target.value);
                        }}
                      />
                      {phoneNoError && (
                        <p className="text-danger">
                          Please enter your phone no
                        </p>
                      )}
                      {phoneNoLengthError && (
                        <p className="text-danger">
                          Please enter valid phone no
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        disabled
                        className="form-control card_form_height"
                        placeholder="Email Id"
                        value={userDetails["email"]}
                        // onChange={(e) =>
                        //   onChangeHandler("email", e.target.value)
                        // }
                      />
                      {emailError && (
                        <p className="text-danger">Please enter your email</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-12 col-sm-12">
                    <div className="form_detail">
                      <input
                        type="text"
                        // disabled
                        className="form-control card_form_height"
                        placeholder="Business Name"
                        value={userDetails["business_name"]}
                        onChange={(e) => {
                          e.target.value && setBusinessNameError(false);
                          onChangeHandler("business_name", e.target.value);
                        }}
                      />
                      {businessNameError && (
                        <p className="text-danger">
                          Please enter your business name
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="Business Address"
                        value={userDetails["address"]}
                        onChange={(e) => {
                          e.target.value && setAddressError(false);
                          onChangeHandler("address", e.target.value);
                        }}
                      />
                      {AddressError && (
                        <p className="text-danger">Please enter your address</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      {/* <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="State"
                        value={userDetails["state"]}
                        onChange={(e) => {
                          e.target.value && setStateError(false);
                          onChangeHandler("state", e.target.value);
                        }}
                      /> */}
                      <select
                        value={userDetails["state"]}
                        onChange={(e) => {
                          e.target.value && setStateError(false);
                          onChangeHandler("state", e.target.value);
                        }}
                        className="form-control card_form_height"
                      >
                        <option value={""}>Select a state</option>
                        {states?.map((state, index) => {
                          return (
                            <option value={state} key={index}>
                              {state}
                            </option>
                          );
                        })}
                      </select>
                      {stateError && (
                        <p className="text-danger">Please enter your state</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="text"
                        className="form-control card_form_height"
                        placeholder="City"
                        value={userDetails["city"]}
                        onChange={(e) => {
                          e.target.value && setCityError(false);
                          onChangeHandler("city", e.target.value);
                        }}
                      />
                      {cityError && (
                        <p className="text-danger">Please enter your city</p>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-6">
                    <div className="form_detail">
                      <input
                        type="number"
                        className="form-control card_form_height"
                        placeholder="Postal Code"
                        value={userDetails["postal_code"]}
                        onChange={(e) => {
                          e.target.value && setPostalCodeError(false);
                          onChangeHandler("postal_code", e.target.value);
                        }}
                      />
                      {postalCodeError && (
                        <p className="text-danger">Please enter postal code</p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-xl-6 col-sm-6">
                    <button className="fromdetai_save">Save</button>
                  </div> */}
                </div>
              </form>
              {/* <h3>Business Details :</h3> */}

              {/* <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input custom_checkbox"
                    value=""
                    checked={billingSameAsUserDetails}
                    onChange={(e) => sameAddressClickHandler(e)}
                  />
                  <span className="label_check">Same as contact address</span>
                </label>
              </div> */}

              {/* {!billingSameAsUserDetails && (
                <>
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          type="text"
                          className="form-control card_form_height"
                          placeholder="City"
                          value={billingAddress["city"]}
                          onChange={(e) => {
                            e.target.value && setBillCityError(false);
                            billingAddressChangehandler("city", e.target.value);
                          }}
                        />
                        {billCityError && (
                          <p className="text-danger">
                            Please enter billing city
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          type="text"
                          className="form-control card_form_height"
                          placeholder="Country"
                          value={billingAddress["country"]}
                          onChange={(e) => {
                            e.target.value && setBillCountryError(false);
                            billingAddressChangehandler(
                              "country",
                              e.target.value
                            );
                          }}
                        />
                        {billCountryError && (
                          <p className="text-danger">
                            Please enter billing country
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          type="text"
                          className="form-control card_form_height"
                          placeholder="State"
                          value={billingAddress["state"]}
                          onChange={(e) => {
                            e.target.value && setBillStateError(false);
                            billingAddressChangehandler(
                              "state",
                              e.target.value
                            );
                          }}
                        />
                        {billStateError && (
                          <p className="text-danger">
                            Please enter billing state
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="form_detail">
                        <input
                          typef="number"
                          className="form-control card_form_height"
                          placeholder="Postal Code"
                          value={billingAddress["postal_code"]}
                          onChange={(e) => {
                            e.target.value && setBillPostalError(false);
                            billingAddressChangehandler(
                              "postal_code",
                              e.target.value
                            );
                          }}
                        />
                        {billPostalError && (
                          <p className="text-danger">
                            Please enter billing postal code
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )} */}

              <div className="row">
                <div className="col-sm-6">
                  <h3>Tax settings</h3>
                  <div className="tax_set">
                    <input
                      value={userDetails["gst_no"]}
                      onChange={(e) =>
                        onChangeHandler("gst_no", e.target.value)
                      }
                      type="text"
                      className="form-control card_form_height"
                      placeholder="Registered VAT/GST/Tax ID"
                    />

                    <button
                      onClick={() => saveTaxClickHandler()}
                      className="taxsave_btn"
                    >
                      Save
                    </button>
                  </div>
                  {gstError && (
                    <p className="text-danger">Please enter GST Number first</p>
                  )}
                  {gsValidtError && (
                    <p className="text-danger">Please enter valid GST Number</p>
                  )}
                </div>

                {/* <div className="col-sm-6">
                  <h3>Apply invite code</h3>
                  <div className="tax_set">
                    <input
                      type="text"
                      className="form-control card_form_height"
                      placeholder="Enter Invite Code"
                    />
                    <button className="taxsave_btn" onClick={toggle2}>
                      Apply
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-sm-8">
            <div className="purchase_summary">
              <h3>Purchase summary</h3>
              {/* <ul className="plan_currency">
                <li>
                  <h6>Billed in {selectedPlanId} </h6>
                </li>
              </ul> */}

              <ul className="silverplan_list">
                <li>
                  <select
                    className="plan_gold"
                    onChange={(e) => setSelectedPlanId(+e.target.value)}
                    value={selectedPlanId}
                  >
                    {subscriptionList?.length > 0 &&
                      subscriptionList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name + " "} Plan
                          </option>
                        );
                      })}
                  </select>
                  <h5>
                    {selectedPlan && selectedPlan.title && selectedPlan.title}
                  </h5>
                </li>
                <li>
                  <h4 className="plan_cost">
                    ₹ {selectedPlan?.price}/{selectedPlan?.days}{" "}
                    {selectedPlan.type === "PPV" ||
                    selectedPlan.type === "Prepaid"
                      ? "Variation"
                      : "Days"}
                  </h4>
                </li>
                {/* {selectedPlan?.time !== "" && selectedPlan?.PRICE ? (
                  <li>
                    <h4 className="plan_cost">
                      {selectedPlan?.CURRENCY === "Rs" ? "₹" : "$"}{" "}
                      {selectedPlan?.PRICE} /{" "}
                      {selectedPlan?.DURATION && selectedPlan?.DURATION != "1"
                        ? selectedPlan?.DURATION +
                          " " +
                          selectedPlan?.DURATION_TYPE
                        : selectedPlan?.DURATION_TYPE}
                    </h4>
                  </li>
                ) : (
                  <li>
                    <h4 className="plan_cost">
                      {selectedPlan?.CURRENCY === "Rs" ? "₹" : "$"}{" "}
                      {selectedPlan?.PRICE} /{" "}
                      {selectedPlan?.DURATION + " " + selectedPlan?.DURATION_TYPE}
                    </h4>
                  </li>
                )} */}
              </ul>

              {selectedPlan?.id == 5 && (
                <>
                  <ul>
                    <li>
                      <h4>Enter the tee shirt variation to be added</h4>
                    </li>
                    <li style={{ position: "relative", marginBottom: "20px" }}>
                      <input
                        type="number"
                        className="form-control card_form_height"
                        placeholder="Business Address"
                        value={prepaidPlanVariationCount}
                        onChange={(e) => {
                          setPrepaidPlanVariationCount(e.target.value);
                        }}
                      />
                      <p
                        style={{
                          position: "absolute",
                          top: "53%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                      >
                        Tee Shirts
                      </p>
                    </li>
                  </ul>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: "rgb(139 60 217 / 76%)",
                        borderRadius: "11px",
                        padding: "0 5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => setPrepaidPlanVariationCount(200)}
                    >
                      200
                    </li>
                    <li
                      style={{
                        backgroundColor: "rgb(139 60 217 / 76%)",
                        borderRadius: "11px",
                        padding: "0 5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => setPrepaidPlanVariationCount(500)}
                    >
                      500
                    </li>
                    <li
                      style={{
                        backgroundColor: "rgb(139 60 217 / 76%)",
                        borderRadius: "11px",
                        padding: "0 5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => setPrepaidPlanVariationCount(1000)}
                    >
                      1000
                    </li>
                    <li
                      style={{
                        backgroundColor: "rgb(139 60 217 / 76%)",
                        borderRadius: "11px",
                        padding: "0 5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => setPrepaidPlanVariationCount(3000)}
                    >
                      3000
                    </li>
                    <li
                      style={{
                        backgroundColor: "rgb(139 60 217 / 76%)",
                        borderRadius: "11px",
                        padding: "0 5px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => setPrepaidPlanVariationCount(5000)}
                    >
                      5000
                    </li>
                  </ul>
                </>
              )}
              {selectedPlan?.id > 2 && selectedPlan?.id != '5' && (
                <ul className="silverplan_list">
                  <li>
                    <h4>Start Date</h4>
                    <h5 className="copupon">{`${day} ${monthNames[month]}, ${year}`}</h5>
                  </li>
                  <li>
                    <h4>End Date</h4>
                    <h5 className="copupon">
                      {getEndDate(selectedPlan?.id == "6" ? 28 : 365)}
                    </h5>
                  </li>
                </ul>
              )}
              {selectedPlan?.id != 1 && selectedPlan?.id != 2 && (
                <>
                  <ul className="silverplan_list">
                    <li>
                      <h4>Tax</h4>
                      <h5 className="copupon">18% GST Applied on</h5>
                      <h5 className="copupon_code">{userDetails["gst_no"]}</h5>
                    </li>
                    <li>
                      <h4 className="plan_cost">
                        {/* {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                      ? "₹"
                      : "$"} */}
                        ₹ {gstCharges}
                      </h4>
                    </li>
                  </ul>

                  {/* <ul className="silverplan_list">
                    <li>
                      <h4>Discount</h4>
                      <h5>10% for Bharatiya Vidhya Bhavan Students</h5>
                    </li>
                    <li>
                      <h4 className="plan_cost">
                        {selectedPlan?.CURRENCY &&
                        selectedPlan.CURRENCY === "Rs"
                          ? "₹"
                          : "$"}
                        {discountApplied}
                      </h4>
                    </li>
                  </ul> */}
                </>
              )}

              {selectedPlan?.id != 2 && (
                <>
                  <ul className="silverplan_list silverplan_list_total">
                    <li>
                      <h4>Total</h4>
                    </li>
                    <li>
                      <h4 className="total_cost">
                        {/* {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                      ? "₹"
                      : "$"} */}
                        ₹ {totalCharges && totalCharges}
                      </h4>
                    </li>
                  </ul>
                  <ul className="silverplan_list">
                    <li>
                      <h4>Amound (in words)</h4>
                    </li>
                    <li>
                      <h6 className="total_cost" style={{textAlign:'right'}}>
                        {/* {selectedPlan?.CURRENCY && selectedPlan.CURRENCY === "Rs"
                      ? "₹"
                      : "$"} */}
                         {totalCharges && numberToWords(totalCharges) + 'only'}
                      </h6>
                    </li>
                  </ul>
                </>
              )}

              <button
                className="pay_btn"
                onClick={(e) => {
                  submitHandler(selectedPlanId);
                }}
              >
                <img src={"/assets/images/lockwhite.svg"} alt="" />
                {selectedPlan?.type === "Free"
                  ? "Start Free Trial"
                  : selectedPlan?.type === "PPV"
                  ? "Start Creation"
                  : "Pay Now"}
              </button>

              <p>
                By selecting “Pay now”, I authorize Teedex to charge my payment.
              </p>

              <ul className="email_receipt">
                {userDetails["email"] && isValidEmail(userDetails["email"]) && (
                  <li>
                    <p>Your receipt will be sent to</p>
                    <h6>{userDetails["email"]}</h6>
                  </li>
                )}
                {/* <li>
                  <a>Edit</a>
                </li> */}
              </ul>
              {/* <p>
                Actual price may vary. Prices displayed are estimates based on
                current exchange rates. Exact amount charged will vary and
                depends on the exchange rate at the time of payment processing.
                Prices displayed exclude any applicable taxes and fees, which
                will be added to the cost of all services purchased. For exact
                base pricing please refer to prices listed in USD.
              </p>

              <h6 className="char_colo">10% goes to charity</h6>
              <h6 className="char_colo">
                <a>joystoryfoundation.org</a> Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolore
              </h6> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cooupon_modal_div">
        <Modal
          zIndex={2000}
          centered
          scrollable
          className="event_modal cupon_modal"
          isOpen={modal2}
          toggle={toggle2}
        >
          <ModalHeader toggle={toggle2}>&nbsp;</ModalHeader>
          {/* <ModalBody>
            <div className="student_verfiy">
              <h5>Student Verification</h5>
              <div className="studentid_div">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Student ID"
                />
                <img
                  src={"/assets/images/greencheck.svg"}
                  className="student_check"
                  alt=""
                />
              </div>

              <h6>OR</h6>

              <div className="dropzone">
                <div
                  {...getRootProps({ className: "dropzone-upload-wrapper" })}
                >
                  <input {...getInputProps()} />
                  <div className="dropzone-inner-wrapper innerwrap_cust">
                    {isDragAccept && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                          <Check className="d-50" />
                        </div>
                        <div className="font-size-sm text-success">
                          All files will be uploaded!
                        </div>
                      </div>
                    )}
                    {isDragReject && (
                      <div>
                        <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                          <X className="d-50" />
                        </div>
                        <div className="font-size-sm text-danger">
                          Some files will be rejected!
                        </div>
                      </div>
                    )}
                    {!isDragActive && (
                      <div>
                        <div
                          className="font-size-sm"
                          style={{ color: "#495C6F" }}
                        >
                          Drag and Drop our file here OR Browse a file
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {files?.length > 0 && (
                  <div>
                    <ListGroup className="font-size-sm">{files}</ListGroup>
                  </div>
                )}
              </div>

              <button className="sub_studen">Submit</button>
            </div>
          </ModalBody> */}
          <div className="modal_back"></div>
        </Modal>
      </div>
      <Invoice
        shouldGenerateInvoice={shouldGenerateInvoice}
        setShouldGenerateInvoice={setShouldGenerateInvoice}
        userDetails={userDetails}
        plans={selectedPlan}
        prepaidPlanVariationCount={prepaidPlanVariationCount}
        initialUserPlanDetails={planDetails}
        setLoading={setLoading}
        invoiceNo={invoiceNo}
        setInvoiceNo={setInvoiceNo}
      />
    </>
  );
}

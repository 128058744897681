import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
function VariationCountPopup({ variationCountLeft, close }) {
  const [modal, setModal] = useState(true);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
const navigate = useNavigate();
  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  return (
    <>
      <div className="success_bg">
        <div className="success_content">
          <NavLink to={""} onClick={() => close()}>
            <img src="/assets/images/cross.svg" className="crossicon" alt="" />
          </NavLink>

          <img src="/assets/images/verify.svg" className="verify_img" alt="" />

          <div className="row">
            <div className="text-center mb-4">
              <a className="red-background mb-5">Subscription Alert</a>
            </div>
            <div className="justify-content-center text-center">
              <h2>You have {variationCountLeft} Free Variations Left</h2>

              <p
                style={{
                  color: "#433a3a",
                  fontSize: "14px",
                  width: "70%",
                  margin: "0 auto",
                }}
              >
                Get started with Teedex's free plan! Create up to 20 stunning
                variations and experience the power of Auto customization. Start
                designing today!
              </p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="justify-content-center text-center">
              {variationCountLeft > 0  ? <Button color="create_variations mb-3" onClick={() => close()}>
                Create {variationCountLeft} Free Variations
              </Button> : <Button color="create_variations mb-3" onClick={() => navigate('/plans')}>
                Upgrade Now
              </Button>}

              <p>
                Want to upgrade to Unlimited Plan?{" "}
                <NavLink to={"/plans"}>
                  Upgrade now
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            <Button btn btn-warning>
              Subscription reminder
            </Button>
            <h2>You have 10 Free Variations Left</h2>
            <p>
              Get started with Variant's free plan! Create up to 20 stunning
              variations and experience the power of Auto customization. Start
              designing today!
            </p>
          </ModalBody>
          <ModalFooter>
           
           <Button color="primary" onClick={toggle}>
              Create 10 Free Variations
            </Button>{" "}
            <p>
              Want to upgrade to Unlimited Plan? <a href="#">Upgrade now</a>
            </p>
          </ModalFooter>
        </Modal>
      </div> */}
    </>
  );
}

export default VariationCountPopup;

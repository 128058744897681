import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import SearchHeader from "../Components/SearchHeader";
import { Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { config } from "../utils/config";
import KEYS from "../env/env";
import { doRequest } from "../utils/helper";

export default function FontsDashboard() {
  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const handleFontChange = async (e) => {
    let fontList = e.target.files;
    try {
      setLoading(true);
      let user = localStorage.getItem(config.USER_LOGIN_DETAILS);
      if (!user || user == "undefined") {
        alert("Please login to upload fonts");
        return;
      }
      const userLogin = user ? JSON.parse(user) : null;
      let formData = new FormData();
      for (let i of fontList) {
        formData.append("file", i);
      }
      //   formData.append("file", fontList);
      formData.append("uploaded_by", userLogin?.id);
      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };
      let response = await fetch(`${KEYS.apiPath}font/save`, requestOptions);
      let data = await response.json();
      console.log("data", data);
      if (data && +data?.resultFlag === 1) {
        alert(data?.message ? data?.message : "Fonts uploaded succesfully!");
      } else {
        alert(data?.message ? data?.message : "Something went wrong!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message ? error?.message : "Something went wrong!");
    }
  };

  const getfontsList = async () => {
    setLoading(true);
    try {
      const response = await doRequest("", "GET", "font/list");
      console.log("response", response);
      if (+response?.resultFlag === 1) {
        let fonts = response?.response?.font;
        setList(fonts);
        setFilterList(fonts);
      } else {
        alert(response?.message ? response?.message : "Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message ? error?.message : "Something went wrong!");
    }
  };
  const searchValChangeHandler = (val) => {
    setSearch(val);

    let filtered = list?.filter((i) =>
      i?.name?.toLowerCase().includes(val?.toLowerCase())
    );
    setFilterList(filtered);
  };

  const deleteFontHandler = async (id) => {
    try {
      let data = {
        id,
      };
      let response = await doRequest(data, "POST", "font/delete");
      if (+response?.resultFlag === 1) {
        alert('font deleted');
        let arr = filterList.filter((i) => i.id != id);
        setFilterList(arr);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getfontsList();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <Sidebar />

        <section className="home">
          <div className="container-fluid">
            <div className="searcmain_pad">
              <SearchHeader
                placeHolder={"Search Font.."}
                search={search}
                onChangeHandler={searchValChangeHandler}
              />

              <ul className="font_updlist">
                <li>
                  <h4>Fonts</h4>
                </li>
                <li>
                  <Button>
                    <input
                      multiple
                      type="file"
                      //   accept=""
                      onChange={handleFontChange}
                      id="imageInput2"
                    />
                    <img src={"/assets/images/addfont.svg"} alt="" /> Add Fonts
                  </Button>
                </li>
              </ul>
              <div className="font_upd_div"></div>

              <div className="table-responsive table_user_mar">
                <Table hover striped className="table_cust align-middle">
                  <thead className="thead_bg">
                    {/* <th className="userth">s.No.</th> */}
                    <th className="userth">Position</th>
                    <th className="userth">Font Name</th>
                    {/* <th className="userth">Font Style</th>
                    <th className="userth" style={{ width: "30%" }}>
                      Preview
                    </th> */}
                    <th className="userth">Status</th>
                    <th className="userth">Actions</th>
                  </thead>

                  <tbody>
                    {filterList?.length > 0 &&
                      filterList?.map((font, index) => {
                        return (
                          <tr key={font?.id}>
                            {/* <td>{index}</td> */}
                            <td>{font?.sequence}</td>

                            <td>{font?.name}</td>

                            {/* <td>14</td>

                            <td>IBM Plex Sans</td> */}

                            <td>
                              <h6 className="enable_t">{font?.status}</h6>
                            </td>

                            <td>
                              <div className="viewProf_flex">
                                {/* <Button
                                  tag="a"
                                  href="#"
                                  className="edit_btn edit_btn_nomar"
                                >
                                  <img
                                    src={"/assets/images/editpurple.svg"}
                                    className="healeyeblue"
                                    alt=""
                                  />
                                  <img
                                    src={"/assets/images/editwhite.svg"}
                                    className="healeyewhite"
                                    alt=""
                                  />
                                  Edit
                                </Button> */}

                                <Button
                                  onClick={() => deleteFontHandler(font.id)}
                                  tag="a"
                                  href="#"
                                  className="del_user"
                                >
                                  <img
                                    src={"/assets/images/delred.svg"}
                                    className="healeyeblue"
                                    alt=""
                                  />
                                  <img
                                    src={"/assets/images/delwhite.svg"}
                                    className="healeyewhite"
                                    alt=""
                                  />
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                    {/* <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>1</td>

                      <td>IBM Plex Sans</td>

                      <td>14</td>

                      <td>IBM Plex Sans</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button
                            tag="a"
                            href="#"
                            className="edit_btn edit_btn_nomar"
                          >
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>

                          <Button tag="a" href="#" className="del_user">
                            <img
                              src={"/assets/images/delred.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/delwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import './Toggle.css'
import { config } from "../utils/config";
import { doRequest } from "../utils/helper";
import PlansList from "./PlansList";
import Sidebar from "../component/Sidebar";

export default function Plans() {
  const initialUserPlanDetails = localStorage.getItem(config.USER_PLAN_DETAILS);

  const userIsLoggedIn = localStorage.getItem(config.USER_LOGIN_DETAILS)
    ? JSON.parse(localStorage.getItem(config.USER_LOGIN_DETAILS))
    : null;
  const [tofuIsReady, setTofuIsReady] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [plansList, setPlansList] = useState([]);

  const handleTofuChange = () => {
    setTofuIsReady((prevState) => {
      setYearly(!yearly);
      return !prevState;
    });
  };

  const getPlansList = async () => {
    try {
      let res = await doRequest({}, "GET", `plan/list`);
      if (+res?.resultFlag === 1) {
        setPlansList(res?.response?.plans);
      }
    } catch (error) {
      console.log("erros in plan list", error);
    }
  };

  useEffect(() => {
    getPlansList();
  }, []);
  console.log("planslist", plansList);

  return (
    <>
      <div
        id="main-container"
        className="container-fluid main"
        style={{ overflowY: "scroll" }}
      >
        {userIsLoggedIn && window.location.pathname != '/' && initialUserPlanDetails && <Sidebar />}
        <div style={{ width: "100%" }} className="container">
        <section className="sub_sec sub_sec_cms" id="sub_sec">
          <img src="/assets/images/ringcms.svg" className="ring_img_cms" alt="" />
          <div className="container container_sub">
            <div className="row">
              <div className="col-sm-12">
                <div className="sub_head">
                  <h2 style={{fontSize: userIsLoggedIn && window.location.pathname != '/' && initialUserPlanDetails ? '80px' : ''}}>Pricing for Productivity</h2>
                  <div className="toggle_div">
                    <ul className="toggle_list">
                      <li>
                        <h6>Monthly</h6>
                      </li>
                      <li className="toogle_pad">
                        <div className="custom_toogle">
                          <Toggle
                            defaultChecked={tofuIsReady}
                            icons={false}
                            onChange={handleTofuChange}
                          />
                        </div>
                      </li>
                      <li className="yearly_pad">
                        <h6>Yearly</h6>
                      </li>
                      <li>
                        <h6 className="disc_hig">Save 10%</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <h5 className="planechoose">Choose your plan</h5>
              </div>
            </div>

            <PlansList yearly={yearly} plansList={plansList} />

            <div className="row">
              <div className="col-sm-12">
                <div className="var_plan">
                  <p>
                    Unlock the full potential of your tee shirt designs with our
                    flexible subscription options tailored to your unique needs.
                    Whether you’re a sporadic designer or a high-volume creator.
                  </p>
                  <h6>Teedex has a plan for you.</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
    </>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "../component/Sidebar";
import RcPagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_US";
import SearchHeader from "../Components/SearchHeader";
import { Table, Button } from "reactstrap";
import { doRequest } from "../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import Person from "../assets/img/account_circle_black_24dp.svg";
import Loader from "../Components/Loader";

export default function UserManagement() {
  const [page, setPage] = useState(1);
  const [usersList, setUsersList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const limit = 20;
  const navigate = useNavigate()
  const textItemRender = (current, type, element) => {
    if (type === "prev") {
      return (
        <FontAwesomeIcon
          icon={["fas", "chevron-left"]}
          className="font-size-sm"
        />
      );
    }
    if (type === "next") {
      return (
        <FontAwesomeIcon
          icon={["fas", "chevron-right"]}
          className="font-size-sm"
        />
      );
    }
    return element;
  };

  const getUsersList = useCallback(
    async (pageno) => {
      try {
        setLoading(true);
        let response = await doRequest(
          "",
          "GET",
          `user/list?page=${pageno ? pageno : page}&limit=${limit}`
        );
        console.log("response is", response);
        if (+response?.resultFlag === 1) {
          setUsersList(response?.response?.user);
          setTotalRecords(response?.response?.totalRecords);
        } else {
          alert(
            response?.message ? response?.message : "Something went wrong!"
          );
        }
        setLoading(false);
      } catch (error) {
        console.log("error is", error);
        setLoading(false);
      }
    },
    [page]
  );

  useEffect(() => {
    getUsersList(page);
  }, [getUsersList, page]);
  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <Sidebar />
        <section className="home">
          <div className="container-fluid">
            <div className="searcmain_pad">
              <SearchHeader />

              <div className="table-responsive table_user_mar">
                <Table hover striped className="table_cust align-middle">
                  <thead className="thead_bg">
                    <th className="userth">User Name</th>
                    <th className="userth">SUBSCRIPTION Plan</th>
                    {/* <th className="userth">Start Date</th>
                    <th className="userth">End Date</th> */}
                    {/* <th className="userth">Variation Created</th>
                    <th className="userth">Discount</th> */}
                    <th className="userth">Status</th>
                    <th className="userth">Actions</th>
                  </thead>

                  <tbody>
                    {usersList?.length > 0 &&
                      usersList?.map((item) => {
                        return (
                          <tr key={item?.id}>
                            <td>
                              <ul className="dasboar_table_list">
                                <li>
                                  <img
                                    src={
                                      item?.user_image
                                        ? item?.user_image
                                        : Person
                                    }
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <h5>{`${item?.first_name} ${item?.last_name}`}</h5>
                                </li>
                              </ul>
                            </td>

                            <td>
                              {item?.plan_status === "active" ? (
                                <h6 className="enable_t">Enable</h6>
                              ) : (
                                <h6 className="expire_t">Expired</h6>
                              )}
                            </td>

                            {/* <td>1 Jan 2024</td>

                            <td>2 Jan 2024</td> */}

                            {/* <td>20</td>
                            <td>0</td> */}

                            <td>
                              <h6 className="enable_t">{item?.status}</h6>
                            </td>

                            <td>
                              <div className="viewProf_flex">
                                <Button
                                  tag="a"
                                  href="#"
                                  className="healcheck_user"
                                  onClick={() => navigate('/editprofile/' + item.id)}
                                >
                                  <img
                                    src={"/assets/images/eyepurple.svg"}
                                    className="healeyeblue"
                                    alt=""
                                  />
                                  <img
                                    src={"/assets/images/eyewhite.svg"}
                                    className="healeyewhite"
                                    alt=""
                                  />
                                  View Profile
                                </Button>

                                <Link
                                  to={`/editprofile/${item?.id}`}
                                  tag="a"
                                  href="#"
                                  className="edit_btn"
                                >
                                  <img
                                    src={"/assets/images/editpurple.svg"}
                                    className="healeyeblue"
                                    alt=""
                                  />
                                  <img
                                    src={"/assets/images/editwhite.svg"}
                                    className="healeyewhite"
                                    alt=""
                                  />
                                  Edit
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {/* <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="enable_t">Enable</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <ul className="dasboar_table_list">
                          <li>
                            <img src={"/assets/images/img-8.jpg"} alt="" />
                          </li>
                          <li>
                            <h5>Alexa</h5>
                          </li>
                        </ul>
                      </td>

                      <td>Active</td>

                      <td>1 Jan 2024</td>

                      <td>2 Jan 2024</td>

                      <td>20</td>
                      <td>0</td>

                      <td>
                        <h6 className="expire_t">Expired</h6>
                      </td>

                      <td>
                        <div className="viewProf_flex">
                          <Button tag="a" href="#" className="healcheck_user">
                            <img
                              src={"/assets/images/eyepurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/eyewhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            View Profile
                          </Button>

                          <Button tag="a" href="#" className="edit_btn">
                            <img
                              src={"/assets/images/editpurple.svg"}
                              className="healeyeblue"
                              alt=""
                            />
                            <img
                              src={"/assets/images/editwhite.svg"}
                              className="healeyewhite"
                              alt=""
                            />
                            Edit
                          </Button>
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
              <div className="mt-2 p-4 d-flex justify-content-center">
                <RcPagination
                  showSizeChanger
                  defaultPageSize={limit}
                  defaultCurrent={1} // Start from the first page
                  total={totalRecords}
                  locale={localeInfo}
                  itemRender={textItemRender}
                  onChange={(page, pageSize) => getUsersList(page, pageSize)}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

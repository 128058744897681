import React, { useState, useEffect, useRef } from "react";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";
import clsx from "clsx";
import LocalFonts from "./LocalFonts";
import TeedexFonts from "./TeedexFonts";

const AddFonts = ({
  selected,
  onSelect,
  fontOf,
  fontOptions,
  setShowAddFontPopup,
  onHover,
}) => {
  const addFontRef = useRef(null);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleClickOutside = (event) => {
    if (addFontRef.current && !addFontRef.current.contains(event.target)) {
      // Click occurred outside the dropdown, close it
      setShowAddFontPopup(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup: remove event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="add_fonts_box" ref={addFontRef}>
        <div className="name_font_flex">
          <h5>Player {fontOf} font</h5>
        </div>

        <div className="nav-tabs-primary">
          <Nav className="nav-line nav_add_fonts">
            <NavItem>
              <NavLinkStrap
                className={clsx({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                <span className="tabHeading">Teedex Fonts</span>
                <div className="divider" />
              </NavLinkStrap>
            </NavItem>
            <NavItem>
              <NavLinkStrap
                className={clsx({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                <span className="tabHeading">Your Systems Fonts</span>
                <div className="divider" />
              </NavLinkStrap>
            </NavItem>
          </Nav>
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <TeedexFonts
              fontOptions={fontOptions}
              onSelect={onSelect}
              selected={selected}
              onHover={onHover}
            />
          </TabPane>

          <TabPane tabId="2">
            {/* <h2>System font component</h2> */}
            {activeTab === "2" && (
              <LocalFonts
                onSelect={onSelect}
                selected={selected}
                onHover={onHover}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default AddFonts;

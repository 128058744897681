import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

const Popup = ({ isOpen, message, closePopup }) => {
  // console.log('working');
  return (
 

<div className={`success_bg ${isOpen ? 'open' : ''}`}>
<div className="success_content" style={{ maxWidth: "615px" }}>
  <NavLink to={""} onClick={() => closePopup()}>
    <img src="/assets/images/cross.svg" className="crossicon" alt="" />
  </NavLink>

  <img src="/assets/images/verify.svg" className="verify_img" alt="" />

  <div className="row">
    {/* <div className="text-center mb-4">
          <a className="red-background mb-5">Subscription Alert</a>
        </div> */}
    <div className="justify-content-center text-center">
      <h2>{message}</h2>
    </div>
  </div>
  {/* <hr /> */}
  <div className="row mt-3">
    <div className="justify-content-center text-center">
      {/* {variationCountLeft > 0  ? 
          <Button color="create_variations mb-3" onClick={() => close()}>
            Create {variationCountLeft} Free Variations
          </Button> : */}
      {/* <Button color="create_variations mb-3" onClick={() => close()}>
        Cancel
      </Button> */}
      <Button
        color="create_variations mb-3 ms-3"
        onClick={() => {
          closePopup();
        }}
      >
        OK
      </Button>
    </div>
  </div>
</div>
</div>
  );
};

export default Popup;
